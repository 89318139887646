import React from 'react';
import { useObserver } from 'mobx-react';
import { useStore } from '../../common/stores/useStore';
import NotificationConfiguration from './notificationConfiguration';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { NotificationConfigurationModel } from '../stores/deviceStore/notificationConfigurationModel';
import { assertState } from '../../common/utils/modelState';

import './systemServices.scss';
import { createDeviceTypedLabels } from '../../common/utils/localization/createDeviceTypedLabels';
import { DeviceType } from '../stores/deviceStore/deviceContracts';

function createEmptyNotificationModels(): NotificationConfigurationModel[] {
    const emptyModels: NotificationConfigurationModel[] = [];

    for (let index = 0; index < 6; index++) {
        emptyModels.push(NotificationConfigurationModel.create(DeviceType.Hyen, '', '', ''));
    }
    return emptyModels;
}

const NotificationRow = (props: { notificationConfiguration: NotificationConfigurationModel; allEmails: string[] }) => (
    <>
        <NotificationConfiguration notificationConfiguration={props.notificationConfiguration} allEmails={props.allEmails} />
    </>
);

export const HyenNotificationSettings = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const {
        viewStore: { devicesViewStore },
        deviceStore: { deviceDetails }
    } = useStore();

    return useObserver(() => {
        if (deviceDetails.data === null) {
            return null;
        }

        const { systemServiceConfigurations } = deviceDetails.data;
        const NotificationRowMap = (nc: NotificationConfigurationModel, index: number) => (
            <NotificationRow key={index} notificationConfiguration={nc} allEmails={systemServiceConfigurations.allEmails} />
        );

        const isReadyOrError = assertState(systemServiceConfigurations.notificationConfigurationsState, 'ready', 'error');

        return (
            <>
                <div className="systemServices">
                    <h2 className="typo-h6 pb-3">{t(createDeviceTypedLabels('SySe.Enable_disable_service_notifications', devicesViewStore.lastKnownDeviceType))}</h2>
                    <p className="typo-c1">{t(createDeviceTypedLabels('SySe.ServiceNotification.Explanation', devicesViewStore.lastKnownDeviceType))}</p>
                    {isReadyOrError && systemServiceConfigurations.notificationConfigurations.map(NotificationRowMap)}
                    {!isReadyOrError && createEmptyNotificationModels().map(NotificationRowMap)}
                </div>
            </>
        );
    });
};
