import './searchPage.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { Back2Top } from '../common/components/top/back2top';
import Input from '../common/components/input';
import TagBoxInput from '../common/components/tagBoxInput';
import { ToggleButton } from '../common/components/togglebutton/toggleButton';
import { ABUSCloudPortalCommonFeaturesSearchSearchResultType } from 'portal-bff-proxy-ts';
import { SearchResults } from './searchResults';
import { CustomPaging, PagingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel } from '@devexpress/dx-react-grid-bootstrap4';
import { useStore } from '../common/stores/useStore';
import { KnownRoles } from '../common/routing/knownroles';
import { ModalPopup } from '../common/components/modalPopup/modalPopup';
import { AddDeviceModalContent } from './components/addDeviceModalContent';

export const SearchPage = () => {
    const { t } = useTranslation('portal_reseller');
    const { searchStore, viewStore } = useStore();
    const [isAddDeviceModalOpen, setIsAddDeviceModalOpen] = useState(false);

    return useObserver(() => {
        const updateSearchQuery = (elementName: any, newValue: string) => {
            searchStore.updateSearchQuery(newValue);
        };

        const updateShowOnlyDevicesWithOutdatedFirmware = () => {
            searchStore.showOnlyDevicesWithOutdatedFirmware = !searchStore.showOnlyDevicesWithOutdatedFirmware;
        };

        const updateShowOnlyDevices = () => {
            searchStore.setSearchResultType(ABUSCloudPortalCommonFeaturesSearchSearchResultType.Devices);
        };

        const updateShowOnlyResellers = () => {
            searchStore.setSearchResultType(ABUSCloudPortalCommonFeaturesSearchSearchResultType.ResellerProfiles);
        };

        const search = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            searchStore.search(true);
        };

        const displayResultCount = () => {
            if (searchStore.searchResults !== undefined && searchStore.searchResults !== null) {
                const min = searchStore.searchResultOffset + 1;
                const max = searchStore.searchResultOffset + searchStore.searchResults!.length;
                const total = searchStore.totalCount;

                return (
                    <React.Fragment>
                        <div>
                            {min} - {max} {t('Search.TotalCountOf')} {total} {t('Search.SearchResults')}
                        </div>
                    </React.Fragment>
                );
            } else {
                return null;
            }
        };

        const handleCloseAddDeviceModal = () => {
            setIsAddDeviceModalOpen(false);
            searchStore.search(true);
        };

        const offset = searchStore.searchResultOffset ?? 0;
        const limit = searchStore.numberOfSearchResultsPerPage ?? 1;

        return (
            <div className="container page searchPage">
                <a id="top" />
                <div className="resellerOperatorOverview">
                    <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => search(e)}>
                        <div className={'row justify-content-center mb-4'}>
                            <div className={'col-6'}>
                                <div className="row">
                                    <div className="col-11">
                                        <Input type="text" name="search" value={searchStore.searchQuery} placeholder={t('Search.InputPlaceholder').toString()} onChange={updateSearchQuery} />
                                    </div>
                                    <div className="col-1 search-button">
                                        <button className="btn btn-outline-dark" type="submit">
                                            <i className="icon-icon_navigation_search" />
                                        </button>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    {searchStore.canUserFilterForDevicesAndResellers
                                        ? (
                                            <React.Fragment>
                                                <div className={'col-3 pr-0'} style={{ borderTopRightRadius: 0 }}>
                                                    <ToggleButton
                                                        classNameButton={'toggleButtonLeft'}
                                                        isToggled={searchStore.searchResultType === ABUSCloudPortalCommonFeaturesSearchSearchResultType.Devices}
                                                        onClick={updateShowOnlyDevices}
                                                    >
                                                        {t('Search.SearchForDevicesOnly')}
                                                    </ToggleButton>
                                                </div>
                                                <div className={'col-3 pl-0'}>
                                                    <ToggleButton
                                                        classNameButton={'toggleButtonRight'}
                                                        isToggled={searchStore.searchResultType === ABUSCloudPortalCommonFeaturesSearchSearchResultType.ResellerProfiles}
                                                        onClick={updateShowOnlyResellers}
                                                    >
                                                        {t('Search.SearchForResellersOnly')}
                                                    </ToggleButton>
                                                </div>
                                            </React.Fragment>
                                        )
                                        : null}
                                    <div className={'col-3'}>
                                        <ToggleButton isToggled={searchStore.showOnlyDevicesWithOutdatedFirmware} onClick={updateShowOnlyDevicesWithOutdatedFirmware}>
                                            {t('Search.FilterForDevicesWithOutdatedFirmware')}
                                        </ToggleButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {searchStore.customerSearchFilter
                        ? (
                            <div className="row justify-content-left">
                                <div className="col-5 offset-3">
                                    <TagBoxInput
                                        name="CompanySearchFilterInput"
                                        showClearButton={false}
                                        onChange={() => searchStore.updateCustomerSearchFilter(0)}
                                        value={[searchStore.filteredCompanyName]}
                                        acceptCustomValue={false}
                                        hoverStateEnabled={false}
                                        focusStateEnabled={false}
                                        activeStateEnabled={false}
                                        isValid={true}
                                        className="border-none"
                                    />
                                </div>
                            </div>
                        )
                        : null}
                    <div className={'row justify-content-center cardsVerticalMargin no-gutter d-flex align-items-center'}>
                        {viewStore.user.hasRoles(KnownRoles.Operator) && (
                            <div className={'col-sm-6 col-md-5 col-lg-5 nopadding-lr'}>
                                <button className="btn btn-outline-dark" onClick={() => setIsAddDeviceModalOpen(true)}>
                                    {t('Search.OperatorAddDeviceButton')}
                                </button>
                            </div>
                        )}
                        <div className={'col-sm-6 col-md-5 col-lg-5 d-none d-md-block clearfix dx-g-bs4-paging-panel text-right nopadding-lr totalCount'}>{displayResultCount()}</div>
                    </div>
                    <SearchResults />
                    <div className={'row justify-content-center cardsVerticalMargin no-gutter'}>
                        <div className={'col-sm-12 col-md-10 col-lg-10 columnInfo'}>
                            {/* The grid is intentionally empty (rows={[]}, columns={[]}). This is a hacky solution to use the pagination component */}
                            <Grid rows={[]} columns={[]}>
                                <PagingState
                                    currentPage={offset / limit}
                                    onCurrentPageChange={(currentPage) => searchStore.setOffset(searchStore.numberOfSearchResultsPerPage * currentPage)}
                                    onPageSizeChange={(size) => searchStore.setNumberOfSearchResultsPerPage(size)}
                                    pageSize={searchStore.numberOfSearchResultsPerPage}
                                />
                                <PagingPanel pageSizes={[25, 50, 100]} />
                                <CustomPaging totalCount={searchStore.totalCount} />
                            </Grid>
                        </div>
                    </div>
                </div>
                <ModalPopup size="xl" title={t('Search.AddDeviceModalTitle')!} isOpen={isAddDeviceModalOpen} closeRequested={handleCloseAddDeviceModal}>
                    <AddDeviceModalContent onCloseModal={handleCloseAddDeviceModal}></AddDeviceModalContent>
                </ModalPopup>
                <Back2Top />
            </div>
        );
    });
};
