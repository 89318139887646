import React from 'react';
import Button from '../button';
import { ModalPopup } from '../modalPopup/modalPopup';
import { useTranslation } from 'react-i18next';

export enum ConfirmResult {
    Ok,
    Close
}

export interface IConfirmProps {
    isOpen: boolean;
    callbackClosing: (result: ConfirmResult) => void;
    options?: IConfirmOptions;
}

export interface IConfirmOptions {
    calingaNamespace?: string;
    title?: string;
    description?: string;
    continue?: string;
    reject?: string;
}

const defaultOptions: IConfirmOptions = {
    title: 'ConfirmDialog.DefaultTitle',
    description: 'ConfirmDialog.DefaultDescription',
    continue: 'ConfirmDialog.DefaultConfirmation',
    reject: 'ConfirmDialog.DefaultRejection'
};

export const Confirm = (props: IConfirmProps) => {
    const { t } = useTranslation(props.options?.calingaNamespace);
    const options = { ...defaultOptions, ...props.options };
    const Footer = () => <React.Fragment>
        <Button className="mr-1" style="outline-secondary" onClick={() => props.callbackClosing(ConfirmResult.Close)}>{t(options.reject!)}</Button>
        <Button className="" style="danger" onClick={() => props.callbackClosing(ConfirmResult.Ok)} >{t(options.continue!)}</Button>
    </React.Fragment>;

    return (
        <ModalPopup
            isOpen={props.isOpen}
            closeRequested={() => props.callbackClosing(ConfirmResult.Close)}
            title={t(options.title!)!}
            footerChildren={<Footer />}
        >
            {t(options.description!)}
            <div className="d-flex">
            </div>
        </ModalPopup>
    );
};