import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { DataTile } from '../../common/components/dataTile/dataTile';
import { ITabConfig, Tabs } from '../../common/components/tab/tabs';
import { useStore } from '../../common/stores/useStore';
import { createDeviceTypedLabels } from '../../common/utils/localization/createDeviceTypedLabels';
import { TabContent } from './tabContent';

export const MainDeviceDetailsView = () => {
    const { viewStore } = useStore();
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    return useObserver(() => {
        const tabsModels = viewStore.devicesViewStore.tabs;
        const tabs: ITabConfig[] | undefined = tabsModels
            ?.filter((x) => x.isVisible)
            .map((tabModel) => {
                return {
                    exact: tabModel.exact,
                    options: {
                        onSelected: tabModel.onSelected,
                        text: t(createDeviceTypedLabels(`Devices.${tabModel.name}`, viewStore.devicesViewStore.lastKnownDeviceType))
                    },
                    path: tabModel.path,
                    displayOrder: tabModel.displayOrder
                };
            });

        return (
            <DataTile>
                <Tabs tabs={tabs} content={(tabConfig?: ITabConfig) => <TabContent tabConfig={tabConfig} />} />
            </DataTile>
        );
    });
};
