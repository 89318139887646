import { observable } from 'mobx';
import { BaseModelStore } from '../../../common/stores/baseModelStore';
import { DeviceType } from './deviceContracts';
import { DeviceFeatures, DeviceModel, IDeviceModelState } from './deviceModel';
import DeviceStore from './deviceStore';

export interface IComfionModelState extends IDeviceModelState {
    availableZones: number | null;
    zonesUsed: number | null;
    macAddress: string | null;
    numberOfAutomations: number | null;
    numberOfUsers: number | null;
    remoteAccessUrl: string | null;
}

export class ComfionDeviceModel extends DeviceModel implements BaseModelStore<IComfionModelState> {
    readonly supportedFeatures: DeviceFeatures = DeviceFeatures.Components | DeviceFeatures.RemoteConnect;

    @observable
    availableZones: number | null = null;

    @observable
    zonesUsed: number | null = null;

    @observable
    macAddress: string | null = null;

    @observable
    numberOfAutomations: number | null = null;

    @observable
    numberOfUsers: number | null = null;

    @observable
    remoteAccessUrl: string | null = null;

    static create(parent: DeviceStore, state: IComfionModelState) {
        const model = new ComfionDeviceModel(parent, DeviceType.Comfion).updateFromJSON(state);
        model.type = DeviceType.Comfion;
        return model;
    }

    updateFromJSON(state: IComfionModelState): this {
        super.updateFromJSON(state);
        this.availableZones = state.availableZones;
        this.zonesUsed = state.zonesUsed;
        this.macAddress = state.macAddress;
        this.numberOfAutomations = state.numberOfAutomations;
        this.numberOfUsers = state.numberOfUsers;
        this.remoteAccessUrl = state.remoteAccessUrl;
        return this;
    }

    toJSON(): IComfionModelState {
        return {
            ...super.toJSON(),
            availableZones: this.availableZones,
            zonesUsed: this.zonesUsed,
            macAddress: this.macAddress,
            numberOfAutomations: this.numberOfAutomations,
            numberOfUsers: this.numberOfUsers,
            remoteAccessUrl: this.remoteAccessUrl
        };
    }
}
