import { useObserver } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import Button from '../../common/components/button';
import { ModalPopup } from '../../common/components/modalPopup/modalPopup';
import { useStore } from '../../common/stores/useStore';
import { getPortalConfiguration } from '../../getPortalConfiguration';
import { getViewStore } from '../../storeRegistry';
import { DeviceState, DeviceType } from '../stores/deviceStore';
import { HyenDeviceModel } from '../stores/deviceStore/hyenDeviceModel';
import { ComfionDeviceModel } from '../stores/deviceStore/comfionDeviceModel';

interface RemoteAccessProps {
    deviceType: DeviceType;
}

export const RemoteAccess: React.FC<RemoteAccessProps> = ({ deviceType }) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const {
        deviceStore,
        viewStore: { devicesViewStore }
    } = useStore();
    const [popupIsOpen, setPopupIsOpen] = useState(false);

    return useObserver(() => {
        const clientPortalConfiguration = getPortalConfiguration();

        const deviceDetails: HyenDeviceModel | ComfionDeviceModel =
            deviceType === DeviceType.Hyen ? (deviceStore.deviceDetails.data as HyenDeviceModel) : (deviceStore.deviceDetails.data as ComfionDeviceModel);

        if (deviceDetails == null) {
            return null;
        }

        const deviceId = deviceDetails.deviceId;

        const handleOpenPopup = () => {
            setPopupIsOpen(true);
            getViewStore().removeTimers();
        };

        const handleClosePopup = () => {
            setPopupIsOpen(false);
        };

        if (deviceId == null || deviceId === '') {
            return null;
        }
        const isDisconnected = deviceDetails.state !== DeviceState.Connected;
        const isCalling = deviceDetails.state === DeviceState.Calling;

        const remoteAccessNotPossibleLable = isCalling ? t('RemoteAccess.DeviceIsCalling') : isDisconnected ? t('RemoteAccess.DeviceIsDisconnected') : '';

        const remoteAccessDescription = deviceType === DeviceType.Hyen ? t('RemoteAccess.Description.Hyen') : t('RemoteAccess.Description.Comfion');
        const remoteAccessSource = deviceType === DeviceType.Hyen ? `${clientPortalConfiguration.scsEndpoint()}/relay-${deviceId}/` : (deviceDetails as ComfionDeviceModel).remoteAccessUrl;

        return (
            <React.Fragment>
                <ModalPopup fullscreen={true} isOpen={popupIsOpen} closeRequested={() => handleClosePopup()} title={devicesViewStore.title}>
                    <iframe src={remoteAccessSource!} className="w-100" />
                </ModalPopup>
                <p className="typo-c1">{remoteAccessDescription}</p>
                {remoteAccessNotPossibleLable !== '' && <p className="typo-highlight">{remoteAccessNotPossibleLable}</p>}
                <Button type="button" style="primary" className="pl-5 pr-5" disabled={isDisconnected} onClick={() => handleOpenPopup()}>
                    {t('RemoteAccess.OpenWebView')}
                </Button>
            </React.Fragment>
        );
    });
};
