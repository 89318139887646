import { useObserver } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../common/components/button/button';
import { LabeledData } from '../../common/components/labeledData/labeledData';
import { useStore } from '../../common/stores/useStore';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../../devices';

function filterEmptyAndJoin(...args: Array<string | undefined>): string | undefined {
    return args.filter((x) => x !== undefined).join(' ');
}

export const AocMetadata = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const { aocStore } = useStore();

    return useObserver(() => {
        const operatorMetadata = aocStore.deviceDetails?.data?.operatorMetadata;
       
        const isLoading = false;
        const showSkeleton = isLoading;
        
        return (
            <React.Fragment>
                <div className="mb-3">{t('Device.Metadata.Headline')}</div>
                <LabeledData
                    labelName={t('Device.Metadata.OperatorName')}
                    data={filterEmptyAndJoin(operatorMetadata?.firstName, operatorMetadata?.lastName)}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.Street')}
                    data={filterEmptyAndJoin(operatorMetadata?.street, operatorMetadata?.houseNumber)}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.City')}
                    data={filterEmptyAndJoin(operatorMetadata?.postalCode, operatorMetadata?.city)}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.Phone')}
                    data={operatorMetadata?.phone}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.EMail')}
                    data={operatorMetadata?.email}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.DateOfInstallation')}
                    data={operatorMetadata?.installationDate ? moment(operatorMetadata?.installationDate).format('DD/MM/YYYY') : null}
                    showSkeleton={showSkeleton}
                />

                <Button
                    type="button"
                    style="link"
                    disabled={operatorMetadata?.isInEditMode}
                    onClick={() => operatorMetadata?.startEdit()}
                    className="btn btn-link metadata-edit-button"
                >
                    {t('Device.Metadata.EditLink') + ' >'}
                </Button>
            </React.Fragment>
        );
    });
};