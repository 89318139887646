import React from 'react';
import '../generalErrorComponent.scss';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/useStore';
import Button from '../../button';
import { KnownRoutes } from '../../../routing/knownroutes';

export const E403Component = () => {
    const { t } = useTranslation('portal_default');
    const { viewStore } = useStore();

    return (
        <div className="w-50 mx-auto errorComponent">
            <h3>{t(`AccessDenied.Header.${viewStore.user.portalAuthorization.relationship}`)}</h3>
            <br />
            <p>{t(`AccessDenied.Infotext.${viewStore.user.portalAuthorization.relationship}`)}</p>
            <br />
            <p className="typo-c1">{t(`AccessDenied.Contacttext.${viewStore.user.portalAuthorization.relationship}`)}</p>
            <br />
            <div className="text-center">
                <a href={KnownRoutes.Logout}>
                    <Button style="outline-secondary">{t('AccessDenied.LogoutButton')}</Button>
                </a>
            </div>
        </div>
    );
};