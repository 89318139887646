import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation, WithTranslation } from 'react-i18next';

import 'open-iconic/font/css/open-iconic-bootstrap.scss';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'devextreme/dist/css/dx.common.css';
import './client/assets/dx/devextreme.scss';
import './client/assets/icons/icons.scss';
import './client/assets/fonts/font.scss';
import './app.scss';
import './client/assets/backgrounds/background.scss';

import Header from './client/common/components/header/header';
import RoutingService, { IRouteDefinition } from './client/common/routing/routingservice';
import Footer from './client/common/components/footer/footer';
import ScreenlockOverlay from './client/common/components/screenlockOverlay';
import { observer, inject } from 'mobx-react';
import { ViewStore } from './client/common/stores/viewStore';
import { Stores } from './client/common/stores/stores';
import { Confirm } from './client/common/components/confirmation/confirm';

interface IAppState {
    lang: string;
}

interface IAppProps extends WithTranslation {
    cookies: Cookies;
    viewStore: ViewStore;
}

class App extends React.Component<IAppProps, IAppState> {
    private routes: IRouteDefinition[];

    constructor(props: IAppProps) {
        super(props);
        this.routes = [];
    }

    public render() {
        return (this.renderContent());
    }

    private renderContent() {
        this.routes = RoutingService.getNavigatableRoutes(this.props.viewStore.rootStore.authorization);
        const dialog = this.props.viewStore.dialog;
        const MainAppWrapper = observer(
            () => {
                return (
                    <>
                        <ScreenlockOverlay />
                        <Confirm options={dialog.options} isOpen={dialog.isOpen} callbackClosing={dialog.notifyClose} />
                        <div className={`App ${this.props.viewStore.currentAppContext} ${this.props.viewStore.lockedUI ? 'greyscale' : ''}`}>
                            <Header hidden={!this.props.viewStore.isPortalAppContext} {...this.props} />
                            <main>
                                <Switch>
                                    {this.routes.map(({ path, exact, component: Component, ...rest }) => (// tslint:disable-line
                                        <Route
                                            key={path}
                                            path={path}
                                            exact={exact}
                                            render={(props) => (<Component {...props} {...rest} />)}
                                        />
                                    ), this)}
                                </Switch>
                            </main>
                            <Footer hidden={!this.props.viewStore.isPortalAppContext} />
                        </div>
                    </>
                );
            });

        return (
            <MainAppWrapper />
        );
    }
}

export default inject(Stores.ViewStore)(withCookies<Pick<IAppProps, 'cookies'> & any>(withTranslation()(App)));
