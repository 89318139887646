import * as React from 'react';
import SwitchBase, { ISwitchOptions as ISwitchOptionsBase } from 'devextreme-react/switch';
import { useMemo } from 'react';

export type ISwitchOptions = Pick<ISwitchOptionsBase, 'value' | 'onValueChange' | 'disabled' | 'className'>

export const Switch: React.FunctionComponent<ISwitchOptions> = (props) => {
    const allprops: ISwitchOptions = { ...props };
    const actualValue = useMemo(() => props.value, [props.value]);
    const onValueChange = (e: boolean) => {
        if (actualValue !== e) {
            props.onValueChange!(e);
        }
    };

    return (<React.Fragment>
        <SwitchBase {...{ ...allprops, onValueChange }} />
    </React.Fragment>);
};