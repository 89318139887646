import httpContext from 'express-http-context';
import { defaultLogger } from '../logger';
import { registryItemNames, ICommonRegistery } from '../client/commonRegistry';

const commonServerRegistryFn = (): ICommonRegistery => {
    const registerObject = (itemName: registryItemNames, item: any) => {
        httpContext.set(`commonRegistry::${itemName}`, item);
    };

    const getRegisteredObject = (itemName: registryItemNames) => {
        return httpContext.get(`commonRegistry::${itemName}`);
    };
    
    defaultLogger.verbose('using commonServerRegistryFn');
    return { registerObject, getRegisteredObject };
};

export const commonServerRegistry = commonServerRegistryFn();