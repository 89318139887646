import React from 'react';
import { DeviceComponentTile } from './deviceComponentTile';

export const SkeletonTile = () => {
    return (
        <DeviceComponentTile
            componentTileHeader={undefined}
            componentTileIcon={undefined}
            componentTileBodyElements={undefined}
        />
    );
};