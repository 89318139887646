import * as React from 'react';

export interface IButtonProps {
    style: ButtonStyles;
    className?: string;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    onClick?: () => void;
    children: React.ReactNode;
}

export type ButtonStyles = 'danger' | 'primary' | 'secondary' | 'link' | 'outline-secondary' | 'outline-danger' | 'success';

export default class Button extends React.Component<IButtonProps> {
    public render() {
        const flavors: { [style: string]: string } = {
            ['danger' as ButtonStyles]: 'btn-danger',
            ['primary' as ButtonStyles]: 'btn-primary',
            ['success' as ButtonStyles]: 'btn-success',
            ['secondary' as ButtonStyles]: 'btn-secondary',
            ['outline-secondary' as ButtonStyles]: 'btn-outline-secondary',
            ['outline-danger' as ButtonStyles]: 'btn-outline-danger',
            ['link' as ButtonStyles]: 'btn-link p-0'
        };
        
        return (
            <button
                type={this.props.type || 'button'}
                disabled={this.props.disabled || false}
                onClick={this.props.onClick}
                className={`btn ${flavors[this.props.style]} ${this.props.className || ''}`}
            >
                {this.props.children}
            </button>
        );
    }
}
