import { observable, toJS } from 'mobx';

import UserRepository from './userRepository';
import { UserSettingsModel, IUserSettingsModel } from './userSettingsModel';
import { ViewStore } from '../stores/viewStore';
import { KnownRoles } from '../routing/knownroles';
import RoutingService from '../routing/routingservice';
import { KnownRoutes } from '../routing/knownroutes';
import {
    IPortalAuthorizationState,
    PortalAuthorization
} from './portalAuthorization';
import { getPortalConfiguration } from '../../getPortalConfiguration';

export interface IUserState {
  userSettings: IUserSettingsModel;
  portalAuthorization: IPortalAuthorizationState;
}

export class UserModel {
  private _userRepository: UserRepository;

  @observable
  public userSettings: UserSettingsModel = new UserSettingsModel();

  @observable
  public portalAuthorization: PortalAuthorization = new PortalAuthorization();

  public get companyMember(): boolean {
      return (
          this.userSettings.companyId !== undefined &&
      this.userSettings.companyId !== ''
      );
  }

  constructor(private context: ViewStore) {
      this._userRepository = new UserRepository(getPortalConfiguration());
  }

  public hasRoles(...roles: KnownRoles[]): boolean {
      return roles.every((role) =>
          this.context.rootStore.authorization.roles.includes(role as string)
      );
  }

  public hasEmail(emailToVerify: string): boolean {
      return this.portalAuthorization.email?.toLowerCase() === emailToVerify.toLowerCase();
  }

  public canNavigateTo(route: KnownRoutes): boolean {
      return RoutingService.canNavigateToRoute(
          route,
          this.context.rootStore.authorization
      );
  }

  public toJSON(): IUserState {
      return toJS({
          userSettings: this.userSettings.toJSON(),
          portalAuthorization: this.portalAuthorization.toJSON()
      });
  }

  public updateFromJson(user: IUserState) {
      this.userSettings.updateFromJson(user.userSettings);
      this.portalAuthorization.updateFromJson(user.portalAuthorization);
  }
}
