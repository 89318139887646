import { assertState, ModelState } from '../utils/modelState';
import { observable, computed } from 'mobx';

export class DeferredDataModel<T> {
    @observable
    private _state: ModelState = 'initial';

    @computed
    public get state(): ModelState {
        return this._state;
    }

    @computed
    public get isReady(): boolean {
        return !assertState(this._state, 'initial', 'loading');
    }

    @observable
    private _data: T | null = null;

    @computed
    public get data(): T | null {
        return this._data;
    }

    public setInitialData(data: T| null) {
        this._state = 'initial';
        this._data = data;
    }

    public setReadyData(data: T| null) {
        this._state = 'ready';
        this._data = data;
    }

    public setError() {
        this._state = 'error';
    }

    public setLoading(clear?: boolean) {
        this._state = 'loading';
        if (clear === true) {
            this._data = null;
        }
    }
}