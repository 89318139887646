import { t } from 'i18next';
import React, { useState } from 'react';
import SearchPageResultView, { IKeyValue, ISearchResultDetails } from '../../search/searchPageResultView';
import { useStore } from '../../common/stores/useStore';
import { DeviceIcon } from '../../devices/layout/icon/deviceIcon';
import { DeviceState, DeviceType } from '../../devices/stores/deviceStore';
import { AccessRequestContent } from '../../search/accessRequestContent';
import { SearchResultModel } from '../../search/stores';
import { ResetOrDeleteDeviceConfirmation } from '../../search/device/resetOrDeleteConfirmation';
import { IMenuAction } from '../../search/actionsMenu';
import { firmwareOutdatedLabelWrapper, firmwareOutdatedValueWrapper } from '../../search/components/firmwareOutdatedWrappers';
import { KnownRoles } from '../../common/routing/knownroles';

export const HyenSearchResultTile = (props: { device: SearchResultModel }) => {
    const { viewStore } = useStore();
    const { searchStore } = useStore();
    function getProjectNameOrFallback(props: { device: SearchResultModel }) {
        if (props.device.projectName) {
            return { label: 'Devices.Projectname', value: props.device.projectName };
        } else {
            if (props.device.siteId !== '') {
                return { label: 'Devices.SiteId', value: props.device.siteId };
            } else {
                return { label: 'Devices.DeviceId', value: props.device.registeredDeviceId };
            }
        }
    }

    const searchResultBodyItems: IKeyValue[] = [
        ...(!viewStore.user.hasRoles(KnownRoles.Operator) ? [getProjectNameOrFallback(props)] : []),
        { label: 'Devices.DeviceName', value: props.device.deviceName },
        {
            label: 'Devices.FirmwareVersion',
            value: props.device.firmwareVersion,
            wrapperForLabel: props.device.hasLatestFirmware ? undefined : firmwareOutdatedLabelWrapper,
            wrapperForValue: props.device.hasLatestFirmware ? undefined : firmwareOutdatedValueWrapper
        },
        { label: 'Devices.SerialNumber', value: props.device.serialNumber }
    ];

    const resultView: ISearchResultDetails = {
        searchResultBody: {
            items: searchResultBodyItems
        },
        isEnabled: props.device.deviceAccess?.userCanAccessDevice === true,
        id: props.device.registeredDeviceId,
        action: () =>
            props.device.deviceAccess?.userCanAccessDevice === true ? viewStore.devicesViewStore.navigateToDevice({ deviceid: props.device.registeredDeviceId, deviceType: DeviceType.Hyen }) : null
    };

    const deviceState = props.device.isCalling ? DeviceState.Calling : props.device.isConnected ? DeviceState.Connected : DeviceState.Disconnected;

    const [resetRequested, setResetRequested] = useState(false);
    const [deletionRequested, setDeletionRequested] = useState(false);

    const closeDeviceResetDialog = () => {
        setResetRequested(false);
    };

    const closeDeviceDeletionDialog = () => {
        setDeletionRequested(false);
    };

    const resetDevice = async () => {
        await props.device.resetDevice();
        await searchStore.search();
        closeDeviceResetDialog();
    };

    const deleteDevice = async () => {
        await props.device.deleteDevice();
        await searchStore.search();
        closeDeviceDeletionDialog();
    };

    const resetMenuAction = { actionName: t('ResetDevice.MenuButton').toString(), onActionSelectedCallback: () => setResetRequested(true) };

    const getMenuActions = () => {
        const menuActions: Array<IMenuAction> = [];
        if (props.device.userHasResetOrDeleteDevicePermission) {
            menuActions.push(resetMenuAction);
        }
        return menuActions;
    };

    return (
        <React.Fragment>
            {resetRequested && (
                <ResetOrDeleteDeviceConfirmation
                    isOpen={resetRequested}
                    onCancel={closeDeviceResetDialog}
                    onConfirmed={resetDevice}
                    matchValue={props.device.serialNumber}
                    title={t('ResetDevice.Dialog.Title')}
                    actionButtonText={t('ResetDevice.Dialog.ResetButton')}
                    descriptionText={t('ResetDevice.Dialog.DescriptionText')}
                />
            )}

            {deletionRequested && (
                <ResetOrDeleteDeviceConfirmation
                    isOpen={deletionRequested}
                    onCancel={closeDeviceDeletionDialog}
                    onConfirmed={deleteDevice}
                    matchValue={props.device.serialNumber}
                    title={t('DeleteDevice.Dialog.Title')}
                    actionButtonText={t('DeleteDevice.Dialog.DeleteButton')}
                    descriptionText={t('DeleteDevice.Dialog.DescriptionText')}
                />
            )}

            <SearchPageResultView
                actionsColumn={<AccessRequestContent key={props.device.registeredDeviceId} deviceAccess={props.device.deviceAccess} />}
                menuActions={getMenuActions()}
                searchResult={resultView}
                key={props.device.registeredDeviceId}
                icon={<DeviceIcon deviceType={DeviceType.Hyen} deviceSubType={props.device.deviceSubType} deviceState={deviceState} iconSize={80} />}
            />
        </React.Fragment>
    );
};
