import React, { useState } from 'react';
import { ModalPopup } from '../../common/components/modalPopup/modalPopup';
import { FirmwareStore } from '../store/firmwareStore';
import { getViewStore } from '../../storeRegistry';
import { inject } from 'mobx-react';
import { Stores } from '../../common/stores/stores';
import { withTranslation } from 'react-i18next';
import ComfionFirmwareReleaseData from './ComfionFirmwareReleaseData';
import './ComfionFirmwarePublishModal.scss';

interface ComfionFirmwarePublishModalProps {
    isOpen: boolean;
    closeRequested: () => void;
    t: (key: string) => string;
    firmwareStore: FirmwareStore | undefined;
}

const ComfionFirmwarePublishModal: React.FC<ComfionFirmwarePublishModalProps> = ({ isOpen, closeRequested, t, firmwareStore }) => {
    const viewStore = getViewStore();
    const [version, setVersion] = useState<string>('1.0');

    const handleVersionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVersion(e.target.value);
    };

    return (
        <React.Fragment>
            <ModalPopup size="xl" isOpen={isOpen} closeRequested={closeRequested} title={t('Comfion.PublishModal.Title')}>
                <p> {t('Comfion.PublishModal.Description')}</p>

                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        const result = await firmwareStore!.createNewComfionFirmwareRelease(new ComfionFirmwareReleaseData(firmwareStore!.selectedFirmwareType!.id, version));
                        if (result) {
                            viewStore.notifySuccess(t('Common.PublishModal.Success'));
                            closeRequested();
                        } else {
                            viewStore.notifyError(t('Common.PublishModal.FillOutAllFields'), '❌');
                        }
                    }}
                >
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{t('Comfion.PublishModal.Form.Version')} </label>
                        <div className="col-sm-2">
                            <input type="text" className="form-control" id="minHardwareVersion" placeholder="1.0" defaultValue="1.0" onChange={handleVersionChange} />
                        </div>
                    </div>
                    <button type="button" className="btn btn-secondary" onClick={closeRequested}>
                        {t('Comfion.PublishModal.Form.Close')}{' '}
                    </button>
                    <button type="submit" className="btn btn-primary">
                        {t('Comfion.PublishModal.Publish')}
                    </button>
                </form>
            </ModalPopup>
        </React.Fragment>
    );
};

const withHoC = inject(Stores.FirmwareStore, Stores.ViewStore)(withTranslation('portal_firmware')(ComfionFirmwarePublishModal));
export { withHoC as ComfionFirmwarePublishModal };
