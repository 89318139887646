import { IEnvironmentStamping, IPortalConfiguration, IPortalConfigurationExport } from './common/interfaces';
import { getServerPortalConfiguration } from '../server/getServerPortalConfiguration';
import { isServer } from '../isServer';

export const apiBaseBath = '/rest';
export const notifyPath = `${apiBaseBath}/notify`;
export const bffPath = `${apiBaseBath}/bff`;
export const scsPath = `${apiBaseBath}/scs`;

class ClientPortalConfiguration implements IPortalConfiguration {
    private readonly appConfiguration: IPortalConfigurationExport = window.appConfiguration;
    private readonly selfurlbase = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

    public readonly accessToken: string | undefined;
    public readonly clientId: string = this.appConfiguration.clientId;
    public readonly loginBaseUrl: string = this.appConfiguration.loginBaseUrl;
    public readonly envStamping: IEnvironmentStamping = this.appConfiguration.envStamping;

    public scsEndpoint(): string {
        return this.selfurlbase + scsPath;
    }

    public bffEndpoint(): string {
        return this.selfurlbase + bffPath;
    }

    public toExternal(): IPortalConfigurationExport {
        return {
            clientId: this.clientId,
            envStamping: this.envStamping,
            loginBaseUrl: this.loginBaseUrl
        };
    }
}

const getClientPortalConfiguration = (): IPortalConfiguration => {
    return new ClientPortalConfiguration();
};

export const getPortalConfiguration = (): IPortalConfiguration => {
    return isServer() ? getServerPortalConfiguration() : getClientPortalConfiguration();
};