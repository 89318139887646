import React from 'react';
import { ITabConfig } from '../../common/components/tab/tabs';
import { KnownRoutes } from '../../common/routing/knownroutes';
import { AocDeviceSettingsComponent } from './aocDeviceSettingsComponent';

export const AocTabContent = (props: { tabConfig?: ITabConfig }) => {
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.AocDevice)) {
        return <AocDeviceSettingsComponent/>;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.AocDeviceSettings)) {
        return <AocDeviceSettingsComponent/>;
    }
    return (<>no tabcomponent defined for path: {props.tabConfig?.path}</>);
};