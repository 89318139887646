/* eslint-disable quote-props */
/* eslint-disable dot-notation */
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { SearchResultModelState } from '.';
import { Helper } from '../../common';
import { KnownSearchResultTypes } from '../KnownSearchResultTypes';
import { DeviceAccessStateDto, IDeviceAccessSearchModel } from './deviceAccessSearchModel';

export const mapDeviceAccessState = (dtoDeviceAccessState: AbusCloudApi.ABUSCloudPortalCommonFeaturesAccessDeviceAccessState | undefined): DeviceAccessStateDto => {
    if (dtoDeviceAccessState) {
        return dtoDeviceAccessState;
    }
    return 'None';
};

export const mapDeviceAccessDtoToDeviceAccessModel = (c: AbusCloudApi.ABUSCloudPortalCommonFeaturesAccessDeviceAccessDto): IDeviceAccessSearchModel => {
    return {
        accessValidUntil: Helper.getStringValueOrDefault(c.accessValidUntil),
        approvingUserEmail: Helper.getStringValueOrDefault(c.approvingUserEmail),
        requestValidUntil: Helper.getStringValueOrDefault(c.requestValidUntil),
        requestingUserEmail: Helper.getStringValueOrDefault(c.requestingUserEmail),
        state: mapDeviceAccessState(c.state)
    };
};

const mapDexitSearchResultDTOToSearchResultStateModel = (searchResult: AbusCloudApi.ABUSCloudPortalCommonFeaturesSearchSearchItemDto): SearchResultModelState => {
    return {
        deviceName: searchResult.content!['DeviceName'],
        cloudCompanyId: searchResult.content!['CloudCompanyId'],
        firmwareVersion: searchResult.content!['FirmwareVersion'],
        isConnected: searchResult.content!['IsConnected'],
        numberOfConnectedComponents: searchResult.content!['NumberOfConnectedComponents'],
        projectName: searchResult.content!['ProjectName'],
        serialNumber: searchResult.content!['SerialNumber'],
        registeredDeviceId: searchResult.content!['RegisteredDeviceId'],
        searchResultSortingOrder: 0,
        searchResultType: KnownSearchResultTypes.Dexit,
        deviceAccess: searchResult.access ? mapDeviceAccessDtoToDeviceAccessModel(searchResult.access!) : undefined,
        companyAddress: undefined,
        companyName: undefined,
        isEnabled: undefined,
        mail: undefined,
        phone: undefined,
        hasLatestFirmware: searchResult.content!['HasLatestFirmware'],
        siteId: undefined,
        deviceSubType: undefined,
        isCalling: undefined
    };
};

const mapHyenSearchResultDTOToSearchResultStateModel = (searchResult: AbusCloudApi.ABUSCloudPortalCommonFeaturesSearchSearchItemDto): SearchResultModelState => {
    return {
        deviceName: searchResult.content!['DeviceName'],
        cloudCompanyId: searchResult.content!['CloudCompanyId'],
        firmwareVersion: searchResult.content!['FirmwareVersion'],
        isConnected: searchResult.content!['IsConnected'],
        projectName: searchResult.content!['ProjectName'],
        serialNumber: searchResult.content!['SerialNumber'],
        registeredDeviceId: searchResult.content!['RegisteredDeviceId'],
        searchResultSortingOrder: 1,
        searchResultType: KnownSearchResultTypes.Hyen,
        deviceAccess: searchResult.access ? mapDeviceAccessDtoToDeviceAccessModel(searchResult.access!) : undefined,
        numberOfConnectedComponents: undefined,
        companyAddress: undefined,
        companyName: undefined,
        isEnabled: undefined,
        mail: undefined,
        phone: undefined,
        hasLatestFirmware: searchResult.content!['HasLatestFirmware'],
        siteId: searchResult.content!['SiteId'],
        deviceSubType: (searchResult.content as any)?.FirmwareType?.FirmwareType,
        isCalling: searchResult.content!['IsCalling']
    };
};

const mapComfionSearchResultDTOToSearchResultStateModel = (searchResult: AbusCloudApi.ABUSCloudPortalCommonFeaturesSearchSearchItemDto): SearchResultModelState => {
    return {
        deviceName: searchResult.content!['DeviceName'],
        cloudCompanyId: searchResult.content!['CloudCompanyId'],
        firmwareVersion: searchResult.content!['FirmwareVersion'],
        isConnected: searchResult.content!['IsConnected'],
        numberOfConnectedComponents: undefined,
        projectName: searchResult.content!['ProjectName'],
        serialNumber: searchResult.content!['SerialNumber'],
        registeredDeviceId: searchResult.content!['RegisteredDeviceId'],
        searchResultSortingOrder: 2,
        searchResultType: KnownSearchResultTypes.Comfion,
        deviceAccess: searchResult.access ? mapDeviceAccessDtoToDeviceAccessModel(searchResult.access!) : undefined,
        companyAddress: undefined,
        companyName: undefined,
        isEnabled: undefined,
        mail: undefined,
        phone: undefined,
        hasLatestFirmware: searchResult.content!['HasLatestFirmware'],
        siteId: undefined,
        deviceSubType: undefined,
        isCalling: undefined
    };
};

const mapAocSearchResultDTOToSearchResultStateModel = (searchResult: AbusCloudApi.ABUSCloudPortalCommonFeaturesSearchSearchItemDto): SearchResultModelState => {
    return {
        deviceName: searchResult.content!['DeviceName'],
        cloudCompanyId: searchResult.content!['CloudCompanyId'],
        firmwareVersion: searchResult.content!['FirmwareVersion'],
        isConnected: searchResult.content!['IsConnected'],
        projectName: searchResult.content!['ProjectName'],
        serialNumber: searchResult.content!['SerialNumber'],
        registeredDeviceId: searchResult.content!['RegisteredDeviceId'],
        searchResultSortingOrder: 3,
        searchResultType: KnownSearchResultTypes.Aoc,
        deviceAccess: searchResult.access ? mapDeviceAccessDtoToDeviceAccessModel(searchResult.access!) : undefined,
        numberOfConnectedComponents: undefined,
        companyAddress: undefined,
        companyName: undefined,
        isEnabled: undefined,
        mail: undefined,
        phone: undefined,
        hasLatestFirmware: searchResult.content!['HasLatestFirmware'],
        siteId: searchResult.content!['SiteId'],
        deviceSubType: undefined,
        isCalling: undefined
    };
};

const mapResellerSearchResultDTOToSearchResultStateModel = (searchResult: AbusCloudApi.ABUSCloudPortalCommonFeaturesSearchSearchItemDto): SearchResultModelState => {
    return {
        cloudCompanyId: searchResult.content!['cloudCompanyId'],
        companyAddress: searchResult.content!['companyAddress'],
        companyName: searchResult.content!['companyName'],
        isEnabled: searchResult.content!['isEnabled'],
        phone: searchResult.content!['phone'],
        mail: searchResult.content!['mail'],
        searchResultSortingOrder: 4,
        searchResultType: KnownSearchResultTypes.Reseller,
        deviceName: undefined,
        firmwareVersion: undefined,
        isConnected: undefined,
        projectName: undefined,
        serialNumber: undefined,
        registeredDeviceId: undefined,
        numberOfConnectedComponents: undefined,
        hasLatestFirmware: undefined,
        siteId: undefined,
        deviceSubType: undefined,
        isCalling: undefined
    };
};

export const mapDtoToSearchResultState = (c: AbusCloudApi.ABUSCloudPortalCommonFeaturesSearchSearchItemDto): SearchResultModelState => {
    const map: { [searchResultType: string]: SearchResultModelState } = {
        dexit: mapDexitSearchResultDTOToSearchResultStateModel(c),
        hyen: mapHyenSearchResultDTOToSearchResultStateModel(c),
        aoc: mapAocSearchResultDTOToSearchResultStateModel(c),
        comfion: mapComfionSearchResultDTOToSearchResultStateModel(c),
        'reseller-profile': mapResellerSearchResultDTOToSearchResultStateModel(c)
    };
    return map[c.itemType!];
};
