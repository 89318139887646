import { action, computed, observable } from 'mobx';
import { defaultLogger } from '../../../logger';
import { Helper } from '../../common';
import { KnownRoles } from '../../common/routing/knownroles';
import { IModelWithTimeUpdates } from '../../common/stores/modelWithTimeUpdates.interface';
import { ViewStore } from '../../common/stores/viewStore';
import { DeviceSubtype } from '../../devices/stores/deviceStore/deviceContracts';
import { getPortalConfiguration } from '../../getPortalConfiguration';
import { getViewStore } from '../../storeRegistry';
import { KnownSearchResultTypes } from '../KnownSearchResultTypes';
import { DeviceAccessSearchModel, IDeviceAccessSearchModel } from './deviceAccessSearchModel';
import DeviceRepository from './deviceRepository';

export interface SearchResultModelState {
    cloudCompanyId: string | undefined;
    numberOfConnectedComponents: number | undefined;
    registeredDeviceId: string | undefined;
    projectName: string | undefined;
    deviceName: string | undefined;
    firmwareVersion: string | undefined;
    serialNumber: string | undefined;
    isConnected: boolean | undefined
    companyName: string | undefined;
    companyAddress: string | undefined;
    phone: string | undefined;
    mail: string | undefined;
    isEnabled: boolean | undefined
    searchResultType: KnownSearchResultTypes;
    searchResultSortingOrder: number;
    deviceAccess?: IDeviceAccessSearchModel;
    hasLatestFirmware: boolean | undefined;
    siteId: string | undefined;
    deviceSubType: DeviceSubtype | undefined;
    isCalling: boolean | undefined;
}

export class SearchResultModel implements IModelWithTimeUpdates {
    private _viewStore?: ViewStore;
    private _deviceRepository = new DeviceRepository(getPortalConfiguration());

    @observable
    public cloudCompanyId = '';

    @observable
    public numberOfConnectedComponents = 0;

    @observable
    public registeredDeviceId = '';

    @observable
    public projectName = '';

    @observable
    public deviceName = '';

    @observable
    public firmwareVersion = '';

    @observable
    public serialNumber = '';

    @observable
    public isConnected = false;

    @observable
    public companyName = '';

    @observable
    public companyAddress = '';

    @observable
    public phone = '';

    @observable
    public mail = '';

    @observable
    public isEnabled = false;

    @observable
    public searchResultType = KnownSearchResultTypes.Unknown;

    @observable
    public searchResultSortingOrder = 0;

    @observable
    public deviceAccess?: DeviceAccessSearchModel;

    @observable
    public hasLatestFirmware = false;

    @observable
    public siteId = '';

    @observable
    public deviceSubType = DeviceSubtype.Unknown;

    @observable
    public isCalling? = false;

    deviceTypes = [KnownSearchResultTypes.Aoc, KnownSearchResultTypes.Dexit, KnownSearchResultTypes.Hyen, KnownSearchResultTypes.Comfion];

    @computed get userHasResetOrDeleteDevicePermission(): boolean {
        return this.viewStore.user.hasRoles(KnownRoles.ProductManager);
    }

    public get viewStore(): ViewStore {
        if (this._viewStore == null) {
            this._viewStore = getViewStore();
        }
        return this._viewStore;
    }

    constructor(state?: SearchResultModelState) {
        if (state != null) {
            this.updateFromJSON(state);
        }
    }

    @action.bound
    public timeChanged(): void {
        this.deviceAccess?.timeChanged();
    }

    @action.bound
    async resetDevice(): Promise<void> {
        if (this.userHasResetOrDeleteDevicePermission && this.registeredDeviceId) {
            try {
                await this._deviceRepository.resetDexitDevice(this.registeredDeviceId);
            } catch (e:any) {
                defaultLogger.error(e);
                this.viewStore.notifyError('Search', 'ResetDeviceFailed');
            }
        }
    }

    @action.bound
    async deleteDevice(): Promise<void> {
        if (this.userHasResetOrDeleteDevicePermission && this.registeredDeviceId) {
            try {
                await this._deviceRepository.deleteDevice(this.registeredDeviceId);
            } catch (e: any) {
                defaultLogger.error(e);
                this.viewStore.notifyError('Search', 'DeleteDeviceFailed');
            }
        }
    }

    private isDevice(searchResultType: KnownSearchResultTypes): boolean {
        return this.deviceTypes.indexOf(searchResultType) > -1;
    }

    public updateFromJSON(state: SearchResultModelState) {
        this.cloudCompanyId = Helper.getStringValueOrDefault(state.cloudCompanyId);
        this.numberOfConnectedComponents = Helper.getNumberValueOrDefault(state.numberOfConnectedComponents);
        this.registeredDeviceId = Helper.getStringValueOrDefault(state.registeredDeviceId);
        this.projectName = Helper.getStringValueOrDefault(state.projectName);
        this.deviceName = Helper.getStringValueOrDefault(state.deviceName);
        this.firmwareVersion = Helper.getStringValueOrDefault(state.firmwareVersion);
        this.serialNumber = Helper.getStringValueOrDefault(state.serialNumber);
        this.isConnected = state.isConnected!;
        this.companyName = Helper.getStringValueOrDefault(state.companyName);
        this.companyAddress = Helper.getStringValueOrDefault(state.companyAddress);
        this.phone = Helper.getStringValueOrDefault(state.phone);
        this.mail = Helper.getStringValueOrDefault(state.mail);
        this.isEnabled = state.isEnabled!;
        this.searchResultType = state.searchResultType;
        this.searchResultSortingOrder = state.searchResultSortingOrder;
        if (this.isDevice(state.searchResultType)) {
            this.deviceAccess = new DeviceAccessSearchModel(state.deviceAccess ?? null, this);
        }
        this.hasLatestFirmware = state.hasLatestFirmware!;
        this.siteId = state.siteId ?? '';
        this.deviceSubType = state.deviceSubType!;
        this.isCalling = state.isCalling ?? undefined;
    }

    public toJSON(): SearchResultModelState {
        return {
            cloudCompanyId: this.cloudCompanyId,
            numberOfConnectedComponents: this.numberOfConnectedComponents,
            registeredDeviceId: this.registeredDeviceId,
            projectName: this.projectName,
            deviceName: this.deviceName,
            firmwareVersion: this.firmwareVersion,
            serialNumber: this.serialNumber,
            isConnected: this.isConnected,
            companyName: this.companyName,
            companyAddress: this.companyAddress,
            phone: this.phone,
            mail: this.mail,
            isEnabled: this.isEnabled,
            searchResultType: this.searchResultType,
            deviceAccess: this.deviceAccess !== undefined ? this.deviceAccess.toJSON() : undefined,
            searchResultSortingOrder: this.searchResultSortingOrder,
            hasLatestFirmware: this.hasLatestFirmware,
            siteId: this.siteId,
            deviceSubType: this.deviceSubType,
            isCalling: this.isCalling
        };
    }
}