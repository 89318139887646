import * as React from 'react';
import { useTranslation } from 'react-i18next';
import HtmlEditor from 'devextreme-react/html-editor';
import './imprint.scss';

export const Imprint = () => {
    const { t } = useTranslation('portal_legal');
    return (
        <div className="imprint">
            <div className="container imprint-mwidth pt-5">
                <section className="">
                    <h2 className="typo-h">{t('Imprint.Heading')}</h2>
                    <HtmlEditor className="m-0 typo-c1" valueType="markdown" readOnly={true} defaultValue={t('Imprint.Body')}></HtmlEditor>
                </section>
            </div>
        </div>
    );
};
