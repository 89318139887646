import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useStore } from '../../common/stores/useStore';
import { DeviceAccessStateDto } from '../../search/stores/deviceAccessSearchModel';
import { DeviceAccessPrefixedTrans } from './deviceAccess/deviceAccessPrefixedTrans';
import { DeviceStateApprovalBox } from './deviceAccess/deviceStateApprovalBox';
import { ExtendDeviceAccess } from './deviceAccess/extendDeviceAccess';

export const DeviceStateInfo = (props: { state: DeviceAccessStateDto }) => {
    return (
        <React.Fragment>
            <p className="typo-c1-bold mb-1">
                <DeviceAccessPrefixedTrans label="StateInfo.Header" />
            </p>
            <div>
                {props.state !== 'Approved' && (
                    <p className="typo-t3 m-0 text-danger">
                        <DeviceAccessPrefixedTrans label="StateInfo.LockedForProducer" />
                    </p>
                )}
                {props.state === 'Approved' && (
                    <p className="typo-t3 m-0 text-success">
                        <DeviceAccessPrefixedTrans label="StateInfo.Approved" />
                    </p>
                )}
                {props.state === 'None' && (
                    <p className="typo-t3 m-0 text-danger">
                        <DeviceAccessPrefixedTrans label="StateInfo.NoRequest" />
                    </p>
                )}
            </div>
            <div className="mt-2">
                {props.state === 'None' && (
                    <p className="typo-t3 m-0">
                        <DeviceAccessPrefixedTrans label="StateInfo.NoRequestDescription" />
                    </p>
                )}
                {props.state === 'Requested' && (
                    <p className="typo-t3 m-0">
                        <DeviceAccessPrefixedTrans label="StateInfo.HasRequest" />
                    </p>
                )}
            </div>
        </React.Fragment>
    );
};

export const DeviceAccess = () => {
    const { deviceStore } = useStore();
    return useObserver(() => {
        const deviceAccess = deviceStore.deviceDetails.data?.deviceAccess;
        if (deviceAccess == null) {
            return null;
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="typo-h6 pb-3">
                            <DeviceAccessPrefixedTrans label="Header" />
                        </h2>
                        <p className="typo-c1">
                            <DeviceAccessPrefixedTrans label="Description" />
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <DeviceStateInfo state={deviceAccess.state} />
                        <DeviceStateApprovalBox deviceAccess={deviceAccess} />
                    </div>
                    <div className="col-12 col-lg-5 offset-lg-1">{deviceAccess.userHasExtendAccessPermission && <ExtendDeviceAccess />}</div>
                </div>
            </React.Fragment>
        );
    });
};

export const DeviceAccessChildComponens = { DeviceStateInfo };
