import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../..';
import { LabeledData } from '../../../common/components/labeledData/labeledData';
import { HyenDeviceModel } from '../../stores/deviceStore/hyenDeviceModel';
import { upperCaseFirstLetter } from '../../../common/utils';

export const HyenProperties = (props: { device: HyenDeviceModel | null; showSkeleton: boolean }) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    return useObserver(() => {
        const zones = [props.device?.zonesUsed?.toString() ?? '-', props.device?.zonesAvailable?.toString() ?? '-'];
        return (
            <React.Fragment>
                <LabeledData labelName={t('Devices.MacAddress')} data={props.device?.macAddress?.toString()} showSkeleton={props.showSkeleton} />
                <LabeledData labelName={t('Devices.ConnectionType')} data={t('Device.ConnectionType.' + upperCaseFirstLetter(props.device?.connectionType?.toString()))} showSkeleton={props.showSkeleton} />
                <LabeledData labelName={t('Devices.Zones')} data={zones.join(' / ')} showSkeleton={props.showSkeleton} />
                <LabeledData labelName={t('Devices.HardwareExpanders')} data={props.device?.hardwareExpanders?.toString()} showSkeleton={props.showSkeleton} />
                <LabeledData labelName={t('Devices.RFExpanders')} data={props.device?.rfExpanders?.toString()} showSkeleton={props.showSkeleton} />
                <LabeledData labelName={t('Devices.PsuExpanders')} data={props.device?.psuExpanders?.toString()} showSkeleton={props.showSkeleton} />
                <LabeledData labelName={t('Devices.Keypads')} data={props.device?.keypads?.toString()} showSkeleton={props.showSkeleton} />
            </React.Fragment>
        );
    });
};
