import './modalPopup.scss';
import * as React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import { className } from '../../utils/helper';

export interface IModalPopupProps {
    isOpen: boolean;
    closeRequested: () => void;
    size?: 'sm' | 'lg' | 'xl';
    className?: string;
    contentClassName?: string;
    title?: string;
    fullscreen?: boolean;
    footerChildren?: React.ReactNode;
}

export const ModalPopup = (props: React.PropsWithChildren<IModalPopupProps>) => {
    function close() {
        props.closeRequested();
    }

    const cs = className('customModalPopup', { condition: props.fullscreen === true, className: 'modal-full-screen' });
    const closeBtn = <button className="close" onClick={close}>
        <span className={`${props.className || ''} icon-icon_navigation_close closebutton`} />
    </button>;

    return (
        <Modal
            modalClassName={cs}
            isOpen={props.isOpen}
            toggle={close}
            className={className(props.className)}
            contentClassName={props.contentClassName}
            size={props.size}
        >
            <ModalHeader toggle={close} close={closeBtn}>{props.title}</ModalHeader>
            <ModalBody>
                {props.children}
            </ModalBody>
            {props.footerChildren && <ModalFooter>{props.footerChildren}</ModalFooter>}
        </Modal>
    );
};