import React, { useEffect, useMemo, useState } from 'react';
import TagBox from 'devextreme-react/tag-box';
import Validator, { PatternRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import {
    FormGroup,
    FormText
} from 'reactstrap';
import ValidationSummary from 'devextreme-react/validation-summary';
import validationEngine from 'devextreme/ui/validation_engine';
import { v4 } from 'uuid';
import isEqual from 'lodash.isequal';
import { Skeleton } from '../skeleton';
import { className } from '../../utils/helper';

import './tagBoxInput.scss';

export interface IGenericTagBoxInputProps<TModel> {
    modelTypeRef?: TModel;
    className?: string;
    placeholder?: string;
    name: keyof TModel;
    errorMessage?: string[] | null;
    value: string[] | undefined;
    help?: string;
    isValid?: boolean;
    disabled?: boolean;
    onChange?: (name: keyof TModel, value: string[] | undefined) => void;
    readOnly?: boolean;
    isLoading?: boolean;
    customValuesPattern?: RegExp | string;
    onValidation?: (name: keyof TModel, isValid: boolean, errors?: string[]) => void;
    valuesList?: string[];
    autoOpenList?: boolean;
    showClearButton: boolean;
    acceptCustomValue: boolean;
    hoverStateEnabled: boolean;
    activeStateEnabled: boolean;
    focusStateEnabled: boolean;
}

export const InnerSkeleton = () => {
    return (<div className="skeleton-placeholder"><Skeleton className="used" /></div>);
};

export const InnerTagBox = (props: IGenericTagBoxInputProps<any> & { id: string, setFocused: (value: boolean) => void }) => {
    const [allMailOptions, setAllMailOptions] = useState(props.valuesList);

    const onValueChanged = (value: string[] | undefined, previousValue: string[] | undefined) => {
        if (isEqual(value ? value.slice().sort() : undefined, previousValue ? previousValue.slice().sort() : undefined)) {
            return;
        }
        const result = validationEngine.validateGroup(props.id);
        if (props.onValidation) {
            props.onValidation(props.name as any, result.isValid!, result.brokenRules?.map((br) => br.message || 'unknown'));
        }
        if (props.onChange) {
            props.onChange(props.name as any, value);
        }
    };

    useEffect(() => {
        const valuesList = props.valuesList !== undefined ? [...props.valuesList] : undefined;
        if (!isEqual(allMailOptions, valuesList)) {
            setAllMailOptions(valuesList);
        }
    }, [props.valuesList, allMailOptions, setAllMailOptions]);

    return useMemo(() => {
        const onCustomItemCreating = (e: { text: string | undefined, customItem: (item: string | undefined) => void }) => {
            const existingValues = props.value || [];
            if (!existingValues.includes(e.text!)) {
                e.customItem(e.text);
            } else {
                e.customItem('');
            }
        };

        const isValidCalc = props.isValid === undefined ? props.errorMessage?.length === 0 : props.isValid;
        const disabled = false;
        return <TagBox
            showDropDownButton={false}
            onFocusIn={() => props.setFocused(true)}
            onFocusOut={() => props.setFocused(false)}
            onValueChanged={(changeEvent) => {
                onValueChanged(changeEvent.value, changeEvent.previousValue);
            }}
            openOnFieldClick={props.autoOpenList || false}
            onCustomItemCreating={(e) => onCustomItemCreating({ text: e.text, customItem: (c) => { e.customItem = c } })}
            items={allMailOptions}
            placeholder={props.placeholder}
            acceptCustomValue={props.acceptCustomValue}
            stylingMode="underlined"
            disabled={disabled}
            isValid={isValidCalc}
            readOnly={props.readOnly}
            value={props.value !== undefined ? [...props.value] : undefined}
            validationMessageMode={undefined}
            hoverStateEnabled={props.hoverStateEnabled}
            activeStateEnabled={props.activeStateEnabled}
            focusStateEnabled={props.focusStateEnabled}
            showClearButton={props.showClearButton}
            showSelectionControls={true}
        >
            <Validator validationGroup={props.id}>
                {props.customValuesPattern && <PatternRule pattern={props.customValuesPattern} message="must be an email" />}
            </Validator>
        </TagBox>;
    }, [
        props.value,
        allMailOptions,
        props.readOnly,
        props.showClearButton,
        props.focusStateEnabled,
        props.activeStateEnabled,
        props.hoverStateEnabled,
        props.acceptCustomValue,
        props.placeholder]);
};

export const InvalidFeedback = (props: { errorMessage: string[] | null | undefined }) => {
    if (!props.errorMessage) {
        return null;
    }
    return (<div className="invalid-feedback d-block">{props.errorMessage.map((msg: string, index: number) => <div key={index}>{msg}</div>)}</div>);
};

// tslint:disable-next-line: only-arrow-functions
const TagBoxInput = function <T>(props: IGenericTagBoxInputProps<T>) {
    const [id] = React.useState(v4());
    const [focused, setFocused] = React.useState(false);

    return (
        <FormGroup className={className(props.className, 'abus-tagboxinput-group')}>
            <div className={className('abus-tagboxinput-group abus-tagboxinput-group-container', focused ? 'dx-state-focused:before' : undefined)}>
                <ValidationGroup id={id}>
                    {props.isLoading ? <InnerSkeleton /> : <InnerTagBox id={id} setFocused={setFocused} {...props as any} />}
                    <span className="highlight" />
                    <InvalidFeedback errorMessage={props.errorMessage} />
                    <ValidationSummary validationGroup={id} />
                    <FormText>{props.help}</FormText>
                </ValidationGroup>
            </div>
        </FormGroup>
    );
};

export default TagBoxInput;