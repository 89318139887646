import React from 'react';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DeviceComponentTile } from '../deviceComponentTile';
import { DeviceComponentTileHeader } from '../deviceComponentTileHeader';
import { ISlaveComponent } from '../../stores/deviceStore/deviceContracts';
import { TRANSLATIONS_NAMESPACE } from '../index';
import { SlaveIcon } from './slaveTileIcon';

export const SlaveTile = (props: ISlaveComponent) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    const bodyElements: string[] = [
        t('Components.Type.Slave'),
        t('Components.Firmwareversion') + ' ' + props.firmwareVersion,
        t('Components.IPv4Adress') + ' ' + props.ipv4Addr,
        t('Components.SerialNumber') + ' ' + props.serialNumber,
        t('Components.ConnectedComponents') + ' ' + props.connectedComponents
    ];

    return useObserver(() => {
        return (
            <DeviceComponentTile
                componentTileHeader={<DeviceComponentTileHeader name={props.name} connectionStatus={props.connectionState}/>}
                componentTileIcon={<SlaveIcon />}
                componentTileBodyElements={bodyElements}
            />
        );
    });
};