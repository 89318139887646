import { ErrorType } from './errorType';
import { validateSync, ValidationError } from 'class-validator';

function mapToErrorType<T>(validationError: ValidationError[]): ErrorType<T> {
    const ret: ErrorType<T> = {};
    for (const item of validationError as any) {
        ret[item.property] = Object.values(item.constraints).reverse();
    }
    return ret;
}

export const validateModel = <T extends object>(model: T): ErrorType<T> | null => {
    const validationResult = validateSync(model);
    return validationResult.length === 0 ? null : mapToErrorType(validationResult);
};