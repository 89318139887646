import React from 'react';
import { useObserver } from 'mobx-react';
import { Skeleton } from '../../common/components/skeleton';
import './deviceComponentTile.scss';

export interface IDeviceComponentTileBodyPorps {
    elements: string[] | undefined;
    numberOfSkeletonRows: number;
}

function getSkeletonBody(numberOfSkeletonRows: number) {
    const skeletonBody: JSX.Element[] = [];
    for (let i = 0; i < numberOfSkeletonRows; i++) {
        skeletonBody.push(<Skeleton key={i} />);
    }
    return skeletonBody;
}

export const DeviceComponentTileBody = (props: IDeviceComponentTileBodyPorps) => {
    return useObserver(() => {
        return (
            <React.Fragment>
                {props.elements === undefined && getSkeletonBody(props.numberOfSkeletonRows)}
                {props.elements?.map((element, key) => <span key={key} className="componentData truncate">{element}</span>)}
            </React.Fragment>
        );
    });
};
