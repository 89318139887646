import HttpStatusCode from './httpStatusCode';
import { ResponseError } from './responseError';
import { AxiosResponse, AxiosError } from 'axios';
import { getRootStore } from '../storeRegistry';
import RootStore from './stores/rootStore';

interface IOptions<T> {
    dataParser?: (raw: any) => T;
}

export const handleResponseErrors = <T>(options: IOptions<T>) => {
    return async (error: any): Promise<ResponseError<T> | null> => {
        const axiosError: AxiosError<any> = error as AxiosError<any>;
        const response: AxiosResponse<any> | undefined = axiosError.response;

        if (response && response.status) {
            if (response.status === HttpStatusCode.CONFLICT) {
                const rawData = response.data;
                const parsedData: T | null = rawData && options.dataParser ? options.dataParser(rawData) : null;
                return new ResponseError(HttpStatusCode.CONFLICT, parsedData, null);
            } else if (response.status === HttpStatusCode.UNAUTHORIZED || response.status === HttpStatusCode.FORBIDDEN) {
                // At this point, we can not be completely sure that a RootStore has
                // really been registered as it is when calling this function within stores itself.
                const rootStore: RootStore | undefined = getRootStore();
                if (rootStore) {
                    rootStore.viewStore.navigateToUnauthorized();
                }
            }
        
            return new ResponseError<any>(response.status as HttpStatusCode, null, response.data);
        }
        
        return null;
    };
};