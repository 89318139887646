import { storeServerRegistry } from '../server/storeServerRegistry';
import { isServer } from '../isServer';
import { defaultLogger } from '../logger';
import RootStore from './common/stores/rootStore';
import { ViewStore } from './common/stores/viewStore';

export type globalStores = 'rootStore' | 'viewStore';

export interface IStoreRegistery {
    registerStore: (storeName: globalStores, store: any) => void;
    getRegisteredStore: (storeName: globalStores) => any;
}

export const storeClientRegistryFn = (): IStoreRegistery => {
    const stores = {};

    const registerStoreWithName = (storeName: globalStores, store: any) => {
        stores[storeName] = store;
    };

    const getRegisteredStore = (storeName: globalStores) => {
        return stores[storeName];
    };

    defaultLogger.warn('using storeClientRegistryFn');
    return { registerStore: registerStoreWithName, getRegisteredStore };
};

const storeRegistry: IStoreRegistery = isServer() ? storeServerRegistry : storeClientRegistryFn();

export const registerStore = (storeName: globalStores, store: any) => {
    storeRegistry.registerStore(storeName, store);
};

export const getRootStore = (): RootStore => {
    return storeRegistry.getRegisteredStore('rootStore');
};

export const getViewStore = (): ViewStore => {
    return storeRegistry.getRegisteredStore('viewStore');
};
