import { useObserver } from 'mobx-react';
import React from 'react';
import { useStore } from '../../common/stores/useStore';
import { DeviceAccess } from './deviceAccess';
import { NotificationLanguageSettings } from './notificationLanguageSettings';

export const DeviceSettings = () => {
    const rootStore = useStore();

    return useObserver(() => {
        const deviceStore = rootStore.deviceStore;
        const deviceAccess = deviceStore.deviceDetails.data?.deviceAccess;

        return (
            <div className="deviceSettings">
                <NotificationLanguageSettings />
                {(deviceAccess!.userHasAccessStatusViewPermission || deviceAccess!.userHasExtendAccessPermission) && <DeviceAccess />}
            </div>);
    });
};