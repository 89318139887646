import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

const withScrollToTop = <P extends Record<string, unknown>>(Component: React.ComponentType<P>) => {
    class WithScrollToTop extends React.Component<P> {
        public componentDidMount() {
            window.scrollTo(0, 0);
        }

        public render() {
            return <Component {...this.props} />;
        }
    }
    hoistNonReactStatic(WithScrollToTop, Component);
    return WithScrollToTop;
};

export default withScrollToTop;