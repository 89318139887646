import React from 'react';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { InputType } from 'reactstrap/types/lib/Input';
import { Prompt } from 'react-router';
import Input from '../input';
import { useStore } from '../../stores/useStore';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../../../devices/index';
import { OperatorMetadataModel } from '../../../devices/stores/deviceStore/operatorMetadataModel';
import { DataTile } from '../dataTile/dataTile';
import DateTimeInput from '../dateTimeInput';
import { Alert } from '../alert';
import { CancelButton } from '../button/cancelButton';
import { SaveButton } from '../button/saveButton';
import { observable } from 'mobx';
import { ErrorType } from '../../validation/errorType';

const dateOrUndefined = (ndate: Date | undefined | null) => {
    if (ndate == null) {
        return undefined;
    }
    return ndate;
};

export const InputRow = (props: React.PropsWithChildren<{ className?: string }>) => {
    return <div className="d-flex flex-wrap">{props.children}</div>;
};

export const InputSelector = (props: {
    className?: string;
    modelkey: keyof OperatorMetadataModel;
    placeholderLabelKey: string;
    type: InputType;
    helpLabelKey?: string;
    readOnly?: boolean;
    model: OperatorMetadataModel | undefined;
    onValidation?: (name: string, isValid: boolean, errors?: string[]) => void;
}) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        const { model, modelkey, type, helpLabelKey, className } = props;

        const value: any = model != null ? model[modelkey] : '';
        const isloading = model?.isLoading;

        const hasErrors = model?.validationErrors && model.validationErrors[modelkey];

        switch (type) {
        case 'datetime':
            return (
                <DateTimeInput
                    className="col-12"
                    name="birthdate"
                    value={dateOrUndefined(value)}
                    placeholder={t(props.placeholderLabelKey).toString()}
                    malformattedErrorMessage={t('Errors.InvalidFormat').toString()}
                    help={helpLabelKey ? t(helpLabelKey).toString() : undefined}
                    onChange={(name, date) => {
                            model![modelkey as string] = date ?? null;
                    }}
                    onValidation={props.onValidation}
                    errorMessage={hasErrors ? model?.validationErrors![modelkey]!.map((e: any) => t(e)) : null}
                    isLoading={isloading}
                    showClearButton={true}
                />
            );
        default:
            return (
                <Input
                    className={className}
                    type={type}
                    id={modelkey as string}
                    name={modelkey as string}
                    placeholder={t(props.placeholderLabelKey).toString()}
                    value={value}
                    help={helpLabelKey ? t(helpLabelKey).toString() : undefined}
                    onChange={(name, val) => {
                            model![modelkey as string] = val !== '' ? val : undefined;
                    }}
                    errorMessage={hasErrors ? model?.validationErrors![modelkey]!.map((e: any) => t(e)) : null}
                    isLoading={isloading}
                />
            );
        }
    });
};

type EditDeviceMetadataViewProps = {
    deviceType: string | undefined;
};

export const EditDeviceMetadataView = ({ deviceType }: EditDeviceMetadataViewProps) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const { deviceStore, aocStore } = useStore();

    return useObserver(() => {
        let actualDeviceStore = null;
        if (deviceType === 'Aoc') {
            actualDeviceStore = aocStore;
        } else {
            actualDeviceStore = deviceStore;
        }

        if (actualDeviceStore?.deviceDetails.data == null) {
            return null;
        }
        const { operatorMetadata: deviceMetadata } = actualDeviceStore?.deviceDetails.data;
        const internalValidationErrors = observable<ErrorType<OperatorMetadataModel>>({});

        const onValidation = (name: string, isValid: boolean, errors?: string[]) => {
            if (!isValid) {
                internalValidationErrors[name] = errors;
                return;
            }
            delete internalValidationErrors[name];
        };

        const save = () => {
            if (Object.values(internalValidationErrors).length === 0) {
                deviceMetadata?.save();
            }
        };

        return (
            <DataTile className="d-flex justify-content-start">
                <Prompt when={deviceMetadata?.isInEditMode} message={t('Form.EscapeTheRoom')!} />
                <div className="col-12  col-lg-6">
                    <InputRow>
                        <InputSelector className="col-12" model={deviceMetadata} type="text" modelkey="projectName" placeholderLabelKey={'Device.Metadata.ProjectName'} />
                    </InputRow>
                    <InputRow>
                        <InputSelector className="col-12" model={deviceMetadata} type="text" modelkey="lastName" placeholderLabelKey={'Device.Metadata.OperatorName'} />
                    </InputRow>
                    <InputRow>
                        <InputSelector className="col-12" model={deviceMetadata} type="text" modelkey="firstName" placeholderLabelKey={'Device.Metadata.OperatorFirstName'} />
                    </InputRow>
                    <InputRow>
                        <InputSelector className="col-9" model={deviceMetadata} type="text" modelkey="street" placeholderLabelKey={'Device.Metadata.Street'} />
                        <InputSelector className="col-3" model={deviceMetadata} type="text" modelkey="houseNumber" placeholderLabelKey={'Device.Metadata.HouseNumber'} />
                    </InputRow>
                    <InputRow>
                        <InputSelector className="col-3" model={deviceMetadata} type="text" modelkey="postalCode" placeholderLabelKey={'Device.Metadata.PostalCode'} />
                        <InputSelector className="col-9" model={deviceMetadata} type="text" modelkey="city" placeholderLabelKey={'Device.Metadata.City'} />
                    </InputRow>
                    <InputRow>
                        <InputSelector className="col-12" model={deviceMetadata} type="text" modelkey="email" placeholderLabelKey={'Device.Metadata.EMail'} />
                    </InputRow>
                    <InputRow>
                        <InputSelector className="col-12" model={deviceMetadata} type="text" modelkey="phone" placeholderLabelKey={'Device.Metadata.Phone'} helpLabelKey={'Form.PhoneNumberHint'} />
                    </InputRow>
                    <InputRow>
                        <InputSelector
                            className="col-12"
                            model={deviceMetadata}
                            type="datetime"
                            modelkey="installationDate"
                            placeholderLabelKey={'Device.Metadata.DateOfInstallation'}
                            helpLabelKey={'Form.BirthdateHint'}
                            onValidation={onValidation}
                        />
                    </InputRow>
                    <InputRow>
                        <Alert className="col-12" color="danger" isOpen={!!deviceMetadata?.commonErrors}>
                            {deviceMetadata?.commonErrors?.map((e: any) => t(e))}
                        </Alert>
                        <div className="col-12 col-lg-6">
                            <CancelButton onClick={() => deviceMetadata?.cancelEdit()}>{t('Form.Cancel')}</CancelButton>
                        </div>
                        <div className="col-12 col-lg-6">
                            <SaveButton onClick={() => save()}>{t('Form.Save')}</SaveButton>
                        </div>
                    </InputRow>
                </div>
            </DataTile>
        );
    });
};
