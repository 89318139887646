import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export interface IMenuAction {
    actionName: string | number;
    onActionSelectedCallback: () => void;
    onOpenCallback?: () => void;
}

interface IActionsMenuProps {
    actions: Array<IMenuAction>;
    onOpenCallback?: () => void;
}

export const ActionsMenu = (props: IActionsMenuProps) => {
    const [isOpen, setOpen] = useState(false);
    const toggle = () => {
        setOpen(!isOpen);
        if (isOpen && props.onOpenCallback) {
            props.onOpenCallback();
        }
    };

    const renderActionsMenu = () => {
        return (
            <div className="searchActionMenu m-1 align-self-end">
                <ButtonDropdown isOpen={isOpen} direction="down" toggle={toggle}>
                    <DropdownToggle outline className="btn-info-outline" color="secondary" >
                        ...
                    </DropdownToggle>
                    <DropdownMenu>
                        {props.actions.map((x, i, a) => (
                            <React.Fragment key={i}>
                                <DropdownItem onClick={() => x.onActionSelectedCallback()} key={x.actionName}>{x.actionName}</DropdownItem>{i < (a.length - 1) && <DropdownItem divider />}
                            </React.Fragment>))}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        );
    };

    return (props.actions.length !== 0 ? renderActionsMenu() : <React.Fragment></React.Fragment>);
};
