import { observable, action } from 'mobx';
import { DexitNotificationsRepository } from './dexitNotificationsRepository';
import { HyenNotificationsRepository } from './hyenNotificationsRepository';
import { getPortalConfiguration } from '../../../getPortalConfiguration';
import { defaultLogger } from '../../../../logger';

import { EmailPattern, validateModel } from '../../../common';
import { ErrorType } from '../../../common/validation/errorType';
import { ResponseError } from '../../../common/responseError';
import { ModelState } from '../../../common/utils/modelState';
import { Matches } from 'class-validator';
import { DeviceType } from './deviceContracts';
import { ComfionNotificationsRepository } from './comfionNotificationsRepository';

export interface INotificationConfigurationState {
    name: string;
    recipients: string[];
    enabled: boolean;
    deviceId: string;
    state?: ModelState;
}
export class NotificationConfigurationModel {
    @observable
    public state: ModelState = 'initial';

    public deviceId = '';

    public preferredLanguage = '';

    @observable
    public name = '';

    @observable
    @Matches(EmailPattern, {
        each: true,
        message: 'Errors.Email'
    })
    public recipients: string[] = [];

    @observable
    public enabled = false;

    @observable
    public errors: ErrorType<NotificationConfigurationModel & { other: string[] }> = {};

    public notiRepo: DexitNotificationsRepository | HyenNotificationsRepository | ComfionNotificationsRepository | undefined;

    protected snapshot: INotificationConfigurationState | null = null;

    public static create(deviceType:DeviceType, name: string, deviceId: string, language: string): NotificationConfigurationModel {
        const model = new NotificationConfigurationModel();
        model.name = name;
        model.enabled = false;
        model.deviceId = deviceId;
        model.preferredLanguage = language;

        if (deviceType === DeviceType.Dexit) {
            model.notiRepo = new DexitNotificationsRepository(getPortalConfiguration());
        }

        if (deviceType === DeviceType.Hyen) {
            model.notiRepo = new HyenNotificationsRepository(getPortalConfiguration());
        }

        if (deviceType === DeviceType.Comfion) {
            model.notiRepo = new ComfionNotificationsRepository(getPortalConfiguration());
        }
        return model;
    }

    @action
    public updateFromJSON(state: INotificationConfigurationState) {
        this.name = state.name;
        this.enabled = state.enabled;
        this.recipients = state.recipients;
        this.deviceId = state.deviceId;
        return this;
    }

    @action
    public async changeRecipientsAndSave(newRecipients: string[]) {
        this.recipients = newRecipients.filter((v, i, a) => a.indexOf(v) === i);
        if (newRecipients.length === 0) {
            await this.changeEnabled(false);
        } else {
            this.createSnapshot();
            if (this.enabled === false) {
                this.enabled = true;
            }
            await this.save();
        }
    }

    // only handles the change to disabled since this deletes all recipients
    // the change to enabled happens whenever the first new mail address is added => see "changeRecipients"
    @action
    public async changeEnabled(enabled: boolean) {
        this.createSnapshot();
        this.enabled = enabled;

        try {
            if (enabled === false) {
                this.recipients = [];
                await this.saveNotiConfigurationDeletion();
            }
        } catch (e : any) {
            defaultLogger.error('notification setting delete failed', e);
        }
    }

    protected validate() {
        const validationResult: ErrorType<this> | null = validateModel(this);
        this.errors = validationResult || {};
        return validationResult;
    }

    protected async save() {
        try {
            if (this.validate() === null) {
                await this.notiRepo!.saveNotificationSetting(this.toJSON());
            }
        } catch (e : any) {
            defaultLogger.error('notification setting save failed', e);
            this.apiError(e, 'save failed');
        }
    }

    protected async saveNotiConfigurationDeletion() {
        try {
            if (this.validate() === null) {
                await this.notiRepo!.deleteNotificationSetting(this.toJSON());
            }
        } catch (e : any) {
            defaultLogger.error('notification setting save failed', e);
            this.apiError(e, 'save failed');
        }
    }

    public toJSON(): INotificationConfigurationState {
        return {
            enabled: this.enabled,
            deviceId: this.deviceId,
            name: this.name,
            recipients: this.recipients,
            state: this.state
        };
    }

    @action
    public apiError(e: any, defaultText: string) {
        defaultLogger.error('save failed', e);
        this.revertSnapshot();
        if (e instanceof ResponseError) {
            this.errors = { ...this.errors, other: [`Errors.StatusCode.${e.statusCode}`] };
            return;
        }
        this.errors.other = [defaultText];
    }

    protected createSnapshot() {
        this.snapshot = this.toJSON();
    }

    protected revertSnapshot() {
        if (this.snapshot) {
            this.updateFromJSON(this.snapshot);
        }
    }
}