import React from 'react';
import { IDeviceIconProps } from './deviceIcon';
import DexitControlOnline from './icon-dexit-control.svg';
import DexitControlOffline from './icon-dexit-control-offline.svg';
import HyenEN2Online from './icon-secoris-en2.svg';
import HyenEN2Offline from './icon-secoris-en2-offline.svg';
import HyenEN2OfflineCall from './icon-secoris-en2-offline-call.svg';
import HyenEN3Online from './icon-secoris-en3.svg';
import HyenEN3Offline from './icon-secoris-en3-offline.svg';
import HyenEN3OfflineCall from './icon-secoris-en3-offline-call.svg';
import AocOnline from './icon-aoc-control.svg';
import AocOffline from './icon-aoc-control-offline.svg';
import ComfionOnline from './comfion-online.svg';
import ComfionOffline from './comfion-offline.svg';
import { DeviceState } from '../../stores/deviceStore';

export const DexitDeviceIcon: React.FunctionComponent<IDeviceIconProps> = (props) => {
    const icon = props.deviceState === DeviceState.Connected ? DexitControlOnline : DexitControlOffline;
    return <img style={{ width: `${props.iconSize}px`, height: `${props.iconSize}px` }} src={icon} alt="Dexit Logo" />;
};

export const UnknownDeviceIcon: React.FunctionComponent<IDeviceIconProps> = () => {
    return <div className={'icon-PTZ_Focus'} />;
};

export const HyenEN2Icon: React.FunctionComponent<IDeviceIconProps> = (props) => {
    const icon = props.deviceState === DeviceState.Calling ? HyenEN2OfflineCall : props.deviceState === DeviceState.Connected ? HyenEN2Online : HyenEN2Offline;
    return <img style={{ width: `${props.iconSize}px`, height: `${props.iconSize}px` }} src={icon} alt="Secoris Logo" />;
};

export const HyenEN3Icon: React.FunctionComponent<IDeviceIconProps> = (props) => {
    const icon = props.deviceState === DeviceState.Calling ? HyenEN3OfflineCall : props.deviceState === DeviceState.Connected ? HyenEN3Online : HyenEN3Offline;
    return <img style={{ width: `${props.iconSize}px`, height: `${props.iconSize}px` }} src={icon} alt="Secoris Logo" />;
};

export const AocIcon: React.FunctionComponent<IDeviceIconProps> = (props) => {
    const icon = props.deviceState === DeviceState.Connected ? AocOnline : AocOffline;
    return <img style={{ width: `${props.iconSize}px`, height: `${props.iconSize}px` }} src={icon} alt="Aoc Logo" />;
};

export const ComfionIcon: React.FunctionComponent<IDeviceIconProps> = (props) => {
    const icon = props.deviceState === DeviceState.Connected ? ComfionOnline : ComfionOffline;
    return <img style={{ width: `${props.iconSize}px`, height: `${props.iconSize}px` }} src={icon} alt="Aoc Logo" />;
};
