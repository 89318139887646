import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { IDataGridColumn, LocalDataGrid } from '../../common/components/datagrid';
import { nameof } from '../../common/nameof';
import { useStore } from '../../common/stores/useStore';
import { assertState } from '../../common/utils/modelState';
import { AppPairingModel } from '../stores/deviceStore/appPairingModel';
import { IConfirmOptions } from '../../common/components/confirmation/confirm';
import Button from '../../common/components/button';

type RowDataType = AppPairingModel;

export const Users = () => {
    const { deviceStore: { deviceDetails } } = useStore();
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        const appPairings = deviceDetails.data?.appPairings;
        const loaded = !assertState(appPairings?.state ?? 'initial', 'initial', 'loading');
        const error = assertState(appPairings?.state ?? 'initial', 'error');
        const options: IConfirmOptions = {
            calingaNamespace: TRANSLATIONS_NAMESPACE_DEVICE,
            continue: 'Pairings.RevokeDialog.Ok',
            description: 'Pairings.RevokeDialog.Description',
            reject: 'Pairings.RevokeDialog.Reject',
            title: 'Pairings.RevokeDialog.Title'
        };

        const columns: IDataGridColumn<RowDataType>[] = [
            { name: nameof<RowDataType>('email'), title: t('Pairings.Grid.Email').toString() },
            { name: nameof<RowDataType>('pairingsCount'), title: t('Pairings.Grid.PairingsCount').toString() },
            { name: 'actions', title: t('Pairings.Grid.Actions').toString(), cellTemplate: (e) => <Button style='link' className='p-0 no-hover' onClick={() => e.row?.delete(options)} ><i className="icon-Remove_pairing" />{t('Pairings.Revoke')}</Button> }
        ];

        return <React.Fragment>
            <p className="typo-c1">{t('Pairings.Description')}</p>
            <LocalDataGrid
                data={appPairings?.data ?? []}
                noDataText={error ? 'Error' : 'No data'}
                columns={columns}
                loaded={loaded}
            />
        </ React.Fragment>;
    });
};