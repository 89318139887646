import * as deviceModel from '../deviceContracts';

class MockDeviceRepository {
    private mockComponents: deviceModel.IDeviceComponent[] = [
        {
            name: 'BEISPIEL-Haupteingang',
            componentType: deviceModel.DeviceComponentType.Cylinder,
            firmwareVersion: '2.0',
            signalStrength: 140,
            signalState: deviceModel.SignalState.Good,
            dateOfProduction: '2020/03',
            armEnabled: true,
            learnEnabled: true
        } as deviceModel.ICylinderDeviceComponent,
        {
            name: 'BEISPIEL-Büro Verwaltung',
            componentType: deviceModel.DeviceComponentType.Cylinder,
            firmwareVersion: '2.0',
            signalStrength: 85,
            signalState: deviceModel.SignalState.Poor,
            dateOfProduction: '2020/04',
            armEnabled: false,
            learnEnabled: false
        } as deviceModel.ICylinderDeviceComponent,
        {
            name: 'BEISPIEL-Seiteneingang Lager',
            componentType: deviceModel.DeviceComponentType.Wallreader,
            firmwareVersion: '2.1',
            signalStrength: 200,
            signalState: deviceModel.SignalState.Excellent,
            dateOfProduction: '2020/05'
        } as deviceModel.IWallreaderDeviceComponent,
        {
            name: 'BEISPIEL-Repeater Nebengebäude EG',
            componentType: deviceModel.DeviceComponentType.Repeater,
            firmwareVersion: '2.1',
            signalStrength: 260,
            signalState: deviceModel.SignalState.Excellent,
            dateOfProduction: '2020/05',
            connectedComponents: 1
        } as deviceModel.IRepeaterDeviceComponent,
        {
            name: 'BEISPIEL-Serverraum Nebengebäude',
            componentType: deviceModel.DeviceComponentType.Cylinder,
            firmwareVersion: '2.0',
            signalStrength: 150,
            signalState: deviceModel.SignalState.Good,
            dateOfProduction: '2020/02',
            armEnabled: false,
            learnEnabled: false
        } as deviceModel.ICylinderDeviceComponent,
        {
            name: 'BEISPIEL-Schublade Office Cars',
            componentType: deviceModel.DeviceComponentType.Cylinder,
            firmwareVersion: '2.3',
            signalStrength: 100,
            signalState: deviceModel.SignalState.Good,
            dateOfProduction: '2020/03',
            armEnabled: false,
            learnEnabled: false
        } as deviceModel.ICylinderDeviceComponent
    ];

    private mockCylinders: deviceModel.ICylinderDeviceComponent[] = [
        {
            armEnabled: true,
            batteryState: 'Full',
            componentType: deviceModel.DeviceComponentType.Cylinder,
            signalStrength: 100,
            signalState: deviceModel.SignalState.Good,
            type: 'WLXProAccessCylinder',
            dateOfProduction: '2020/03',
            firmwareVersion: '2.3',
            learnEnabled: true,
            deviceId: '',
            id: '',
            name: 'ExampleCylinder01',
            disabled: false
        },
        {
            armEnabled: true,
            batteryState: 'Full',
            componentType: deviceModel.DeviceComponentType.Cylinder,
            signalStrength: 100,
            signalState: deviceModel.SignalState.Good,
            type: 'WLXProAccessCylinder',
            dateOfProduction: '2020/03',
            firmwareVersion: '2.3',
            learnEnabled: true,
            deviceId: '',
            id: '',
            name: 'ExampleCylinder02',
            disabled: false
        },
        {
            armEnabled: true,
            batteryState: 'Full',
            componentType: deviceModel.DeviceComponentType.Cylinder,
            signalStrength: 100,
            signalState: deviceModel.SignalState.Good,
            type: 'WLXProAccessCylinder',
            dateOfProduction: '2020/03',
            firmwareVersion: '2.3',
            learnEnabled: true,
            deviceId: '',
            id: '',
            name: 'ExampleCylinder03',
            disabled: false
        }
    ];

    public async getDeviceComponents(deviceId: string, take: number): Promise<deviceModel.IDeviceComponents> {
        return {
            deviceComponents: this.mockComponents.filter((item, index) => index < take),
            totalDeviceComponentsCount: 6
        };
    }

    public async getCylinders(): Promise<deviceModel.ICylinderDeviceComponent[] | null> {
        return this.mockCylinders;
    }
}

export default MockDeviceRepository;