import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { BaseRepository } from '../../../../common';
import { IPortalConfiguration } from '../../../../common/interfaces';
import { createApiProxy } from '../../../../common/createApiProxy';
import { toIOperatorProfileStoreState, toOperatorPairingModel } from './operatorMapper';
import { OperatorAssignmentModel } from '../../../../devices/operatorDeviceAssignmentsOverview/OperatorAssignmentModel';

/* eslint-disable no-useless-catch */
export class OperatorRepository extends BaseRepository {
    private api: AbusCloudApi.CoreApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.api = new AbusCloudApi.CoreApi(this.apiConfiguration);
    }

    public async getOperatorProfile() {
        try {
            const operatorProfile = await createApiProxy(() => this.api.apiCoreOperatorProfileGet(), toIOperatorProfileStoreState)();
            return operatorProfile;
        } catch (e: any) {
            throw e;
        }
    }

    public async getAssignedOperators(deviceId: string): Promise<OperatorAssignmentModel[]> {
        try {
            const assignedOperators = await createApiProxy(() => this.api.apiCoreOperatorDeviceAssignmentsDeviceIdGet(deviceId), toOperatorPairingModel)();
            return assignedOperators || [];
        } catch (e: any) {
            throw e;
        }
    }

    public async deleteAssignment(deviceId: string, operatorId: string): Promise<void> {
        try {
            await createApiProxy(() => this.api.apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete(deviceId, operatorId, parseInt(operatorId)))();
        } catch (e: any) {
            throw e;
        }
    }
}
