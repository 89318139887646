import React, { ReactNode } from 'react';
import { BackNav } from '../backnav';
import './windowedPage.scss';
import { KnownRoutes } from '../../routing/knownroutes';
import { Skeleton } from '../skeleton';

export interface IWindowedPageProps {
    classNamesRoot?: string;
    classNamesContent?: string;
    headerText: string | React.ReactElement;
    isReady?: boolean;
    backNav?: { backAction?: () => void, backPath?: string, text: string };
    headerChildren?: ReactNode;
    children: React.ReactNode;
}

class WindowedPage extends React.PureComponent<IWindowedPageProps, any> {
    private getDefaults(): Partial<IWindowedPageProps> {
        return {
            isReady: true
        };
    }

    public render() {
        const props = { ...this.getDefaults(), ...this.props };
        const showBacknav = props.backNav !== undefined;
        const { backAction, text, backPath } = props.backNav || { backPath: KnownRoutes.Search, backAction: undefined, text: '' };
        const classes = [
            'container',
            'windowedPage',
            props.isReady === undefined ? null : props.isReady === true ? 'wp-ready' : 'wp-skeleton',
            props.classNamesRoot
        ].filter((x) => x !== null).join(' ');

        return (
            <div className={classes}>
                {showBacknav ? <BackNav backAction={backAction} backPath={backPath} text={text} /> : null}
                <h1 className="vertical-heading-decorator typo-h5">{props.isReady ? props.headerText : <Skeleton className="w-75" />}</h1>
                <header className="container-fluid headerline mt-5"></header>
                <main className={`container-fluid pt-3 ${props.classNamesContent}`}>{props.children}</main>
            </div>
        );
    }
}

export default WindowedPage;
