import React from 'react';
import { Dropdown, IItem } from '../../../common/components/dropdown/dropdown';
import { Label } from '../../../common/components/label/label';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../..';
import { BatteryReportConfigurationModel } from './batteryReportConfigurationModel';
import moment from 'moment';
import NotificationConfiguration from '../notificationConfiguration';
import { useObserver } from 'mobx-react';
import { assertState } from '../../../common/utils/modelState';
import { Paragraph } from '../../../common/components/paragraph/paragraph';
import { translateErrors } from '../../../common/utils/errors';
import { ABUSCloudPortalDexitBatteryReportReportFrequency } from 'portal-bff-proxy-ts';

export interface IBatteryReportScheduleProps {
    allMails: string[];
    batteryReportConfiguration: BatteryReportConfigurationModel;
}

export const BatteryReportSchedule = (props: IBatteryReportScheduleProps) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    const changeBatteryReportInterval = (name: string, newFrequency: string) => {
        const frequencyValue = ABUSCloudPortalDexitBatteryReportReportFrequency[newFrequency as keyof typeof ABUSCloudPortalDexitBatteryReportReportFrequency];
        props.batteryReportConfiguration.changeBatteryReportInterval(frequencyValue);
    };

    const InitializeReportIntervalDropdownItems = () => {
        const frequencies = Object.values(ABUSCloudPortalDexitBatteryReportReportFrequency);
        return frequencies.map((frequency) => ({ key: frequency, text: t(`BatteryReportSchedule.${frequency}`) }));
    };

    const items: IItem[] = InitializeReportIntervalDropdownItems();

    const translatedErrors = translateErrors(props.batteryReportConfiguration!.errors, TRANSLATIONS_NAMESPACE_DEVICE)
        .get()
        .filter((error) => { return !error.includes('e-mail') });

    return useObserver(() => {
        return (
            <React.Fragment>
                <NotificationConfiguration
                    notificationConfiguration={props.batteryReportConfiguration}
                    allEmails={props.allMails}
                />
                <div className="d-flex flex-wrap">
                    <div className="p-0 d-flex align-items-center col-12 col-lg-5">
                        <Label
                            isLoading={assertState(props.batteryReportConfiguration.state, 'initial', 'loading')}
                            text={t('BatteryReport.ReportInterval')}
                        />
                        <Dropdown
                            className="ml-3 col-6"
                            items={items}
                            onChange={changeBatteryReportInterval}
                            value={props.batteryReportConfiguration.frequency}
                            errorMessage={translatedErrors as any}
                            isLoading={assertState(props.batteryReportConfiguration.state, 'initial', 'loading')}
                        />
                    </div>
                    <div className="d-flex align-items-center col-12 col-lg-6 ml-3">
                        <Label
                            isLoading={assertState(props.batteryReportConfiguration.state, 'initial', 'loading')}
                            text={t('BatteryReport.NextReportDate')}
                        />
                        <Paragraph
                            isLoading={assertState(props.batteryReportConfiguration.state, 'initial', 'loading')}
                            className={'ml-2 mb-2 typo-c1'}
                            text={props.batteryReportConfiguration.nextReportDate
                                ? moment(props.batteryReportConfiguration.nextReportDate!).format('L')
                                : t('BatteryReport.NextReportDateNotSet')}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    });
};