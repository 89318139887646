import './datagrid.scss';

import React, { useImperativeHandle, useRef } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, ExportPanel, Toolbar } from '@devexpress/dx-react-grid-bootstrap4';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Skeleton } from '../skeleton';
import saveAs from 'file-saver';
import { ICommonDataGridProps, IDataGridColumn, ILocalDataGridProps, IRemoteDataGridProps, DataGridExtensions } from './interfaces';
import { Worksheet, Workbook } from 'exceljs';
import {
    Template,
    TemplatePlaceholder
} from '@devexpress/dx-react-core';
export const GridWrapper = (props: React.PropsWithChildren<Record<string, any>>) => (<div className='abus-data-grid'>{props.children}</div>);

export const BaseDataGrid = React.forwardRef<unknown, IRemoteDataGridProps & ILocalDataGridProps & ICommonDataGridProps>((props, ref) => {
    const exporterRef = useRef<typeof GridExporter>();

    useImperativeHandle(ref, () => ({
        exportGrid: () => {
            exporterRef!.current!.exportGrid();
        }
    } as DataGridExtensions));

    const loaded = props.loaded;
    const createSkeletonRows: () => any[] = () => {
        const skeletonRows = [];
        for (let index = 0; index < (props.queryableDataModel?.limit ?? 5); index++) {
            const skeletonDummy = {};
            props.columns.forEach((col) => {
                skeletonDummy[col.name] = index;
            });
            skeletonRows.push(skeletonDummy);
        }
        return skeletonRows;
    };
    const createSkeletonColumns: () => IDataGridColumn<any>[] = () => props.columns.map(col => { return { title: col.title, name: col.name } });
    const columns = loaded ? props.columns : createSkeletonColumns();
    const rows: any[] = loaded ? (props.data ?? []) : createSkeletonRows();
    const SkeletonFormatter = () => <Skeleton />;
    const SkeletonTypeProvider = (props: DataTypeProviderProps) => (
        <DataTypeProvider
            formatterComponent={SkeletonFormatter}
            {...props}
        />
    );

    const onSave = (workbook: Workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${props.export?.filename}.xlsx`);
        });
    };

    const startExport = async () => {
        if (props.export) {
            props.export.startExport();
        }
    };

    const customizeExcelHeader = (worksheet: Worksheet) => {
        if (props.export?.header != null) {
            worksheet.addRows(props.export.header);
            const all = props.export.header.map(x => x.length);
            const width = Math.max(...all);
            const height = props.export.header.length;

            const generalStyles = {
                font: { bold: true },
                fill: {
                    type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' }, bgColor: { argb: 'D3D3D3' }
                },
                alignment: { horizontal: 'left' }
            };
            for (let rowIndex = 1; rowIndex <= height; rowIndex += 1) {
                for (let cellIndex = 1; cellIndex <= width; cellIndex += 1) {
                    Object.assign(worksheet.getRow(rowIndex).getCell(cellIndex), generalStyles);
                }
            }

            worksheet.addRow({});
        }
    };

    const columnDefaults: Table.ColumnExtension[] = columns.map(column => ({
        columnName: column.name,
        wordWrapEnabled: column.wordWrapEnabled ?? true,
        width: column.width ?? undefined
    }));

    return <React.Fragment>
        <Grid columns={columns} rows={rows}>
            {props.children}
            {props.columns.filter(x => x.cellTemplate != null).map((x, index) => {
                return (<DataTypeProvider
                    for={[x.name]}
                    key={index}
                    formatterComponent={x.cellTemplate}
                    {...props}
                />);
            })}

            {(props.export || props.toolbarTemplate) && <Toolbar></Toolbar>}
            <Template name="toolbarContent">
                <TemplatePlaceholder />
                {props.toolbarTemplate}
            </Template>
            {props.export && <ExportPanel startExport={startExport} messages={{ exportAll: props.export?.exportText }} />}
            <SkeletonTypeProvider for={!loaded ? props.columns.map(col => col.name) : []} />
            <Table columnExtensions={columnDefaults} messages={props.noDataText != null ? { noData: props.noDataText } : undefined} />
            <TableHeaderRow showSortingControls />
        </Grid>
        <GridExporter
            customizeHeader={customizeExcelHeader}
            onSave={onSave}
            ref={exporterRef}
            columns={props.columns}
            rows={props.export?.data ?? []}
        />
    </ React.Fragment>;
});
