import { ABUSCloudPortalCommonFeaturesAccessDeviceAccessState } from 'portal-bff-proxy-ts';
import { action, computed, observable } from 'mobx';
import { BaseChildModelStore } from '../../../common/stores/baseChildModelStore';
import { DeviceModel } from './deviceModel';
import { KnownRoles } from '../../../common/routing/knownroles';
import DeviceRepository from './repositories/deviceRepository';
import { getPortalConfiguration } from '../../../getPortalConfiguration';
import { ViewStore } from '../../../common/stores/viewStore';
import { getViewStore } from '../../../storeRegistry';
import { Helper } from '../../../common';
import { IModelWithTimeUpdates } from '../../../common/stores/modelWithTimeUpdates.interface';

export interface IDeviceAccessModel {
    requestValidUntil?: string;
    accessValidUntil?: string;
    requestingUserEmail?: string;
    approvingUserEmail?: string;
    accessValidUntilMaximumTime?: string;
    state: DeviceAccessState;
}
export type DeviceAccessState = keyof typeof ABUSCloudPortalCommonFeaturesAccessDeviceAccessState;

export class DeviceAccessModel extends BaseChildModelStore<IDeviceAccessModel, DeviceModel> implements IModelWithTimeUpdates {
    @observable requestValidUntil?: Date;
    @observable accessValidUntil?: Date;
    @observable requestingUserEmail?: string;
    @observable approvingUserEmail?: string;
    @observable accessValidUntilMaximumTime?: Date;
    @observable state: DeviceAccessState = 'None';

    @computed get userHasAccessGrantPermission(): boolean {
        return this.viewStore.user.hasRoles(KnownRoles.Reseller);
    }

    @computed get userHasAccessStatusViewPermission(): boolean {
        return this.viewStore.user.hasRoles(KnownRoles.Reseller);
    }

    @computed get userHasExtendAccessPermission(): boolean {
        return this.viewStore.user.hasRoles(KnownRoles.TechnicalSupport);
    }

    private _deviceRepository = new DeviceRepository(getPortalConfiguration())

    private _viewStore?: ViewStore;
    public get viewStore(): ViewStore {
        if (this._viewStore == null) {
            this._viewStore = getViewStore();
        }
        return this._viewStore;
    }

    @computed get requestTimeLeft(): number {
        const viewStore = this.viewStore;
        if (this.requestValidUntil != null && this.requestValidUntil > viewStore.clock.date) {
            return this.requestValidUntil.getTime() - viewStore.clock.date.getTime();
        }
        return 0;
    }

    @computed get isExtendedToMaxDate(): boolean {
        return this.accessValidUntil! >= this.accessValidUntilMaximumTime!;
    }

    @computed get accessTimeLeft(): number {
        const viewStore = this.viewStore;
        if (this.accessValidUntil != null && this.accessValidUntil > viewStore.clock.date) {
            return this.accessValidUntil.getTime() - viewStore.clock.date.getTime();
        }
        return 0;
    }

    @computed get timeLeft(): number {
        if (this.accessTimeLeft > 0) {
            return this.accessTimeLeft;
        } else {
            return this.requestTimeLeft;
        }
    }

    @action.bound
    public async updateState(state: DeviceAccessState) {
        this.state = state;
    }

    @action.bound
    public async approve() {
        const result = await this._deviceRepository.approveAccessRequest(this.parent.deviceId);
        this.updateFromJSON(result);
    }

    @action.bound
    public async extend(extendAccessUntil: Date) {
        if (!(this.accessValidUntil!.getTime() === extendAccessUntil.getTime())) {
            this.accessValidUntil = extendAccessUntil;
            this.extendAccess();
        }
    }

    @action.bound
    public async extendByOneDay() {
        this.accessValidUntil!.setDate(this.accessValidUntil!.getDate() + 1);
        this.extendAccess();
    }

    private async extendAccess() {
        const result = await this._deviceRepository.extendAccess(this.parent.deviceId, this.accessValidUntil!);
        this.updateFromJSON(result);
    }

    @action.bound
    public timeChanged(): void {
        this.evalState();
    }

    private evalState() {
        if ((this.state === 'Requested' && this.requestTimeLeft <= 0) ||
            (this.state === 'Approved' && this.accessTimeLeft <= 0)) {
            this.state = 'None';
        }
    }

    updateFromJSON(state: IDeviceAccessModel) {
        this.state = state.state;
        this.requestValidUntil = Helper.getDateValueOrUndefined(state.requestValidUntil);
        this.accessValidUntil = Helper.getDateValueOrUndefined(state.accessValidUntil);
        this.requestingUserEmail = state.requestingUserEmail;
        this.approvingUserEmail = state.approvingUserEmail;
        this.accessValidUntilMaximumTime = Helper.getDateValueOrUndefined(state.accessValidUntilMaximumTime);
        return this;
    }

    toJSON(): IDeviceAccessModel {
        return {
            state: this.state,
            accessValidUntil: this.accessValidUntil?.toJSON(),
            approvingUserEmail: this.approvingUserEmail,
            requestValidUntil: this.requestValidUntil?.toJSON(),
            requestingUserEmail: this.requestingUserEmail,
            accessValidUntilMaximumTime: this.accessValidUntilMaximumTime?.toJSON()
        };
    }
}