
/* eslint-disable no-useless-catch */
import { BaseRepository } from '../../../common';
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { IPortalConfiguration } from '../../../common/interfaces';
import { createApiProxy } from '../../../common/createApiProxy';
import { mapDtoToIBatteryReportConfigurationModel } from '../../stores/deviceStore/mapper/device';
import { BatteryReportConfigurationModel, IBatteryReportConfigurationState } from './batteryReportConfigurationModel';

export class BatteryReportRepository extends BaseRepository {
    private batteryReportApiPortal: AbusCloudApi.DexitApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);

        this.batteryReportApiPortal = new AbusCloudApi.DexitApi(
            this.apiConfiguration
        );
    }

    public async getBatteryReportConfigurationPortal(deviceId: string): Promise<IBatteryReportConfigurationState> {
        try {
            const proxy = createApiProxy(
                async () => this.batteryReportApiPortal.apiDexitBatteryReportDeviceIdGet(deviceId),
                mapDtoToIBatteryReportConfigurationModel);
            let settings = await proxy();
            if (settings === null) {
                settings = BatteryReportConfigurationModel.create('dexit_battery_report', deviceId, '');
            }
            return settings;
        } catch (e : any) {
            throw e;
        }
    }

    public async saveBatteryReportConfiguration(batteryReportConfiguration: IBatteryReportConfigurationState):
        Promise<IBatteryReportConfigurationState | null> {
        try {
            const config: AbusCloudApi.ABUSCloudPortalDexitBatteryReportReportScheduleUpdateDto = {
                deviceId: batteryReportConfiguration.deviceId,
                enabled: batteryReportConfiguration.enabled,
                frequency: batteryReportConfiguration.frequency
            };

            const proxy = createApiProxy(async () => this.batteryReportApiPortal.apiDexitBatteryReportDeviceIdPost(batteryReportConfiguration.deviceId, config));
            await proxy();
            return this.getBatteryReportConfigurationPortal(batteryReportConfiguration.deviceId);
        } catch (e) {
            throw e;
        }
    }
}