import React from 'react';
import { useObserver } from 'mobx-react';
import { Skeleton } from '../skeleton';
import './labeledData.scss';

export interface ILabeledDataProps {
    labelName: string;
    data: string | undefined | null;
    showSkeleton?: boolean;
}

export const LabeledData = (props: ILabeledDataProps) => {
    const showSkeleton = props.showSkeleton !== undefined ? props.showSkeleton : (props.data === undefined || props.data === null);
    return useObserver(() => {
        return (<React.Fragment>
            <div className="labeledData">
                <label>{props.labelName}</label>
                {!showSkeleton ? <span>{props.data}</span> : <div className="skeleton-placeholder"><Skeleton className="used" /></div>}
            </div>
        </React.Fragment>);
    });
};