import { Helper } from '../../common';
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { IUserClaimsModel } from './userClaimsModel';

export const toIUserClaimsModel = (userClaimsDto: AbusCloudApi.ABUSCloudPortalCommonFeaturesUserUserClaimsDto): IUserClaimsModel => {
    const result: IUserClaimsModel = {
        claims: []
    };

    if (userClaimsDto && userClaimsDto.claims) {
        result.claims = userClaimsDto.claims.map((c: AbusCloudApi.ABUSCloudPortalCommonFeaturesUserUserClaimDto) => {
            return {
                type: Helper.getStringValueOrDefault(c.type),
                value: Helper.getStringValueOrDefault(c.value)
            };
        });
    }
    return result;
};