import httpContext from 'express-http-context';
import { IStoreRegistery, globalStores } from '../client/storeRegistry';
import { defaultLogger } from '../logger';

const storeServerRegistryFn = (): IStoreRegistery => {
    const registerStore = (storeName: globalStores, store: any) => {
        httpContext.set(`storeRegistry::${storeName}`, store);
    };

    const getRegisteredStore = (storeName: globalStores) => {
        return httpContext.get(`storeRegistry::${storeName}`);
    };
    
    defaultLogger.warn('using storeServerRegistryFn');
    return { registerStore, getRegisteredStore };
};

export const storeServerRegistry = storeServerRegistryFn();