class DexitFirmwareReleaseData {
    FirmwareTypeId: string;
    
    FirmwareFile: File;

    constructor(firmwareTypeId: string, firmwareFile: File) {
        this.FirmwareTypeId = firmwareTypeId;
        this.FirmwareFile = firmwareFile;
    }
}

export default DexitFirmwareReleaseData;