import React from 'react';

export interface ConditionalWrapperProps {
    wrapper?: (children: React.ReactNode) => JSX.Element;
    children: React.ReactNode;
}

export const ConditionalWrapper = (props: ConditionalWrapperProps): JSX.Element => {
    return (
        props.wrapper !== undefined ? props.wrapper!(props.children) : <React.Fragment>{props.children}</React.Fragment>
    );
};
