import { useObserver } from 'mobx-react';
import React from 'react';
import { AocDeviceAccess } from './deviceAccess/aocDeviceAccess';

export function AocDeviceSettingsComponent() {
    return useObserver(() => {
        return (<div className="deviceSettings">
            {<AocDeviceAccess/>}
        </div>);
    });
}