import { action, observable } from 'mobx';
import { ConfirmResult, IConfirmOptions } from '../components/confirmation/confirm';

export class DialogModel {
    @observable isOpen = false;
    @observable options?: IConfirmOptions;

    private resolve?: (value: ConfirmResult | PromiseLike<ConfirmResult>) => void;
    
    @action.bound
    showConfirm(options?: IConfirmOptions): Promise<ConfirmResult> {
        this.options = options;
        this.isOpen = true;
        if (this.resolve != null) {
            this.resolve(ConfirmResult.Close);
        }
        return new Promise((resolve) => {
            this.resolve = resolve;
        });
    }

    @action.bound
    notifyClose(result: ConfirmResult) {
        this.isOpen = false;
        if (this.resolve) {
            this.resolve(result);
        }
    }
}