import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './backnav.scss';

export interface IBackNavProps {
    backAction?: () => void;
    backPath?: string;
    text: string;
    className?: string;
}

export const BackNav = (props: IBackNavProps) => {
    const { t } = useTranslation();
    const onClick = (e: React.SyntheticEvent<any>) => {
        if (props.backAction) {
            e.preventDefault();
            props.backAction();
        }
    };

    return (
        <nav className={`backnavComponent${props.className ? props.className : ''}`}>
            <NavLink onClick={(e) => onClick(e)} to={props.backPath || '#'}>
                <i className="icon-Row_arrow_left" />
                {t(props.text)}
            </NavLink>
        </nav>
    );
};