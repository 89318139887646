import { ComfionDeviceModel, IComfionModelState } from '../comfionDeviceModel';
import DeviceStore from '../deviceStore';
import { DexitDeviceModel, IDexitModelState } from '../dexitDeviceModel';
import { HyenDeviceModel, IHyenDeviceModelState } from '../hyenDeviceModel';

export class DeviceModelFactory {
    public static create(state: IHyenDeviceModelState | IDexitModelState, params: { deviceStore: DeviceStore }) {
        switch (state.type.toString()) {
        case 'Hyen':
            return HyenDeviceModel.create(params.deviceStore, state as IHyenDeviceModelState);
        case 'Dexit':
            return DexitDeviceModel.create(params.deviceStore, state as IDexitModelState);
        case 'Comfion':
            return ComfionDeviceModel.create(params.deviceStore, state as IComfionModelState);
        default:
            throw Error('UnsupportedDeviceType!');
        }
    }
}