import './datagrid.scss';

import React from 'react';
import { useObserver } from 'mobx-react';
import { DefaultPageSize, DefaultPageSizeList } from '../../stores/queryableDataModel';
import { CustomPaging, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { PagingPanel } from '@devexpress/dx-react-grid-bootstrap4';
import { assertState } from '../../utils/modelState';
import { ICommonDataGridProps, IRemoteDataGridProps, DataGridExtensions } from './interfaces';
import { BaseDataGrid, GridWrapper } from './datagrid';

export const RemoteDataGrid = React.forwardRef<DataGridExtensions, IRemoteDataGridProps & ICommonDataGridProps>((props, ref) => {
    return useObserver(() => {
        const loaded = !assertState(props.queryableDataModel?.state ?? 'ready', 'initial', 'loading');
        const offset = props.queryableDataModel?.offset ?? 0;
        const limit = props.queryableDataModel?.limit ?? 1;

        return (<GridWrapper><BaseDataGrid
            ref={ref}
            {...props}
            columns={props.columns}
            data={props.queryableDataModel?.data ?? []}
            queryableDataModel={props.queryableDataModel}
            loaded={loaded}
        >
            <SortingState
                onSortingChange={(sortingArray) => {
                    const sorting = sortingArray[0];
                    if (sorting != null) {
                        props.queryableDataModel?.setSort({ fieldName: sorting.columnName as any, direction: sorting.direction === 'asc' ? 'Ascending' : 'Descending' });
                    }
                }}
                sorting={props.queryableDataModel?.sorting.map(sort => ({ columnName: sort.fieldName as string, direction: sort.direction === 'Ascending' ? 'asc' : 'desc' }))}
            />
            <PagingState
                currentPage={Math.floor(offset / limit)}
                onCurrentPageChange={(currentPage) => props.queryableDataModel?.setOffset(props.queryableDataModel.limit * currentPage)}
                onPageSizeChange={(size) => props.queryableDataModel?.setLimit(size)}
                pageSize={props.queryableDataModel?.limit ?? DefaultPageSize}
            />
            <CustomPaging totalCount={props.queryableDataModel?.totalCount} />
            <PagingPanel pageSizes={DefaultPageSizeList} />
            {props.children}
        </BaseDataGrid></GridWrapper>);
    });
});