import DeviceStore, { IDeviceStoreState } from '../../devices/stores/deviceStore';
import { action, toJS } from 'mobx';
import { IUserProfileStoreState } from '../../access/userprofile/store';
import SearchStore, { ISearchStoreState } from '../../search/stores';
import { ViewStore, IViewStoreState } from './viewStore';
import { IFirmwareStoreState, FirmwareStore } from '../../firmware/store/firmwareStore';
import { AccessIdentityStore, IAccessIdentityStoreState } from '../../access/accessIdentityStore';
import { IUserState } from '../user';
import { AppContext } from '../../appContext';
import { IPortalAuthorizationState } from '../user/portalAuthorization';
import { registerStore } from '../../storeRegistry';
import { i18n } from 'i18next';
import { registerObject } from '../../commonRegistry';
import { IModelWithTimeUpdates } from './modelWithTimeUpdates.interface';
import { AocStore, IAocStoreState } from '../../aoc/common/aocStore';
import { OperatorStore } from '../../devices/operatorDeviceAssignmentsOverview/operatorStore';

export interface IRootStoreState {
  authorization?: IPortalAuthorizationState;
  deviceStore?: IDeviceStoreState;
  userProfileStore?: IUserProfileStoreState;
  searchStore?: ISearchStoreState;
  viewStore?: IViewStoreState;
  firmwareStore?: IFirmwareStoreState;
  accessIdentityStore?: IAccessIdentityStoreState;
  aocStore?: IAocStoreState
  operatorStore?: OperatorStore;
}

export type StoreEnv = {
  i18next: i18n
};

class RootStore implements IModelWithTimeUpdates {
  public authorization: IPortalAuthorizationState;
  public accessIdentityStore: AccessIdentityStore;
  public deviceStore: DeviceStore;
  public searchStore: SearchStore;
  public firmwareStore: FirmwareStore;
  public viewStore: ViewStore;
  public aocStore: AocStore;
  public operatorStore: OperatorStore;

  public static create(userState: IUserState, env: StoreEnv): RootStore {
      const data: IRootStoreState = {
          authorization: userState.portalAuthorization,
          viewStore: {
              errors: [],
              currentAppContext: AppContext.Portal,
              user: userState
          }
      };

      const rootStore = new RootStore(userState.portalAuthorization, env, data);
      return rootStore;
  }

  public static createWithInitialData(context: IRootStoreState, env: StoreEnv) {
      return new RootStore(context.authorization!, env, context);
  }

  private constructor(auth: IPortalAuthorizationState, env: StoreEnv, initialState?: IRootStoreState) {
      const initialStoreData = initialState || {
          authorization: auth
      };

      this.authorization = initialStoreData.authorization || auth;
      this.viewStore = new ViewStore(this, initialStoreData.viewStore);
      this.deviceStore = new DeviceStore(initialStoreData.deviceStore);
      this.searchStore = new SearchStore(initialStoreData.searchStore);
      this.accessIdentityStore = new AccessIdentityStore(initialStoreData.accessIdentityStore);
      this.firmwareStore = new FirmwareStore(initialStoreData.firmwareStore);
      this.aocStore = new AocStore();
      this.operatorStore = new OperatorStore();
      registerStore('rootStore', this);
      registerStore('viewStore', this.viewStore);
      registerObject('applicationStoreEnv', env);
  }

  @action.bound
  timeChanged(): void {
      this.searchStore.timeChanged();
      this.deviceStore.timeChanged();
  }

  public toJSON(): IRootStoreState {
      const state: IRootStoreState = {
          authorization: this.authorization,
          deviceStore: this.deviceStore.toJSON(),
          accessIdentityStore: this.accessIdentityStore.toJson(),
          viewStore: this.viewStore.toJSON(),
          firmwareStore: this.firmwareStore.toJson(),
          searchStore: this.searchStore.toJSON()
      };
      return toJS(state);
  }
}

export default RootStore;