import React from 'react';
import { DeviceState, DeviceType, DeviceSubtype } from '../../stores/deviceStore';
import { AocIcon, DexitDeviceIcon, UnknownDeviceIcon, HyenEN2Icon, HyenEN3Icon, ComfionIcon } from './icons';
import { Skeleton } from '../../../common/components/skeleton/index';

export interface IDeviceIconProps {
    deviceType: DeviceType | undefined;
    deviceSubType: DeviceSubtype | undefined;
    deviceState: DeviceState | undefined;
    iconSize?: number;
}

export const DeviceIcon: React.FunctionComponent<IDeviceIconProps> = (props: IDeviceIconProps) => {
    let Component: React.FunctionComponent<IDeviceIconProps>;
    if (typeof (props.deviceType) === 'undefined') {
        return <div className="deviceIcon"><Skeleton circle={true} style={{ width: `${props.iconSize ?? 100}px`, height: `${props.iconSize ?? 100}px` }} /></div>;
    }

    function getHyenDeviceIcon() {
        if (props.deviceSubType === DeviceSubtype.HyenEN2) {
            return HyenEN2Icon;
        } else {
            return HyenEN3Icon;
        }
    }

    switch (props.deviceType) {
    case DeviceType.Dexit:
        Component = DexitDeviceIcon;
        break;
    case DeviceType.Hyen:
        Component = getHyenDeviceIcon();
        break;
    case DeviceType.Aoc:
        Component = AocIcon;
        break;
    case DeviceType.Comfion:
        Component = ComfionIcon;
        break;
    default:
        Component = UnknownDeviceIcon;
    }
    return <div className={`deviceIcon ${props.deviceState === DeviceState.Connected ? ' Connected' : 'Disconnected'}`}><Component {...props} /></div>;
};