import { observable } from 'mobx';
import { Helper } from '../../../common';
import { BaseModelStore } from '../../../common/stores/baseModelStore';
import { DeviceType } from './deviceContracts';
import { DeviceFeatures, DeviceModel, IDeviceModelState } from './deviceModel';
import DeviceStore from './deviceStore';

export interface IDexitModelState extends IDeviceModelState {
    connectedComponents?: number;
    countCameras: string;
    countSlaves: string;
    countWallreaders: string;
    countCylinders: string;
    countRepeaters: string;
    lastFirmwareUpdate?: string;
    existingUsers?: number;
    existingTags?: number;
    wlxManagerDeeplink?: string;
}

export class DexitDeviceModel extends DeviceModel implements BaseModelStore<IDexitModelState> {
    readonly supportedFeatures: DeviceFeatures = DeviceFeatures.Components | DeviceFeatures.BatteryReport | DeviceFeatures.WLXManager;

    @observable
    connectedComponents?: number;

    @observable
    countCameras = '0'

    @observable
    countSlaves = '0'

    @observable
    countWallreaders ='0'

    @observable
    countCylinders = '0'

    @observable
    countRepeaters = '0'

    @observable
    lastFirmwareUpdate?: Date;

    @observable
    existingUsers?: number;

    @observable
    existingTags?: number;

    @observable
    wlxManagerDeeplink?: string;

    static create(parent: DeviceStore, state: IDexitModelState) {
        const model = new DexitDeviceModel(parent, DeviceType.Dexit).updateFromJSON(state);
        model.type = DeviceType.Dexit;
        return model;
    }

    updateFromJSON(state: IDexitModelState): this {
        super.updateFromJSON(state);
        this.connectedComponents = state.connectedComponents;
        this.lastFirmwareUpdate = Helper.getDateValueOrUndefined(state.lastFirmwareUpdate);
        this.existingUsers = state.existingUsers;
        this.existingTags = state.existingTags;
        this.wlxManagerDeeplink = state.wlxManagerDeeplink;
        return this;
    }

    toJSON(): IDexitModelState {
        return {
            ...super.toJSON(),
            connectedComponents: this.connectedComponents,
            lastFirmwareUpdate: this.lastFirmwareUpdate?.toJSON(),
            existingUsers: this.existingUsers,
            existingTags: this.existingTags,
            wlxManagerDeeplink: this.wlxManagerDeeplink
        };
    }
}