import RootStore from './rootStore';
import { nameof } from '../nameof';
import { AccessIdentityStore } from '../../access/accessIdentityStore';

export interface IAllStores extends
    Pick<RootStore, 'deviceStore' | 'searchStore' | 'viewStore' | 'firmwareStore'>,
    Pick<AccessIdentityStore, 'userProfileStore' > {
}

export class Stores {
    public static DeviceStore: string = nameof<IAllStores>('deviceStore');
    public static SearchStore: string = nameof<IAllStores>('searchStore');
    public static ViewStore: string = nameof<IAllStores>('viewStore');
    public static FirmwareStore: string = nameof<IAllStores>('firmwareStore');
    public static UserProfileStore: string = nameof<IAllStores>('userProfileStore');
}

export const mapRootStore = (rootStore: RootStore): IAllStores => {
    return {
        ...rootStore,
        ...rootStore.accessIdentityStore
    };
};