import { action, computed, observable } from 'mobx';

export class ClockModel {
    @observable date = new Date(Date.now());

    @computed get seconds(): number {
        return this.date.getSeconds();
    }

    @computed get minutes(): number {
        return this.date.getMinutes();
    }

    @computed get hours(): number {
        return this.date.getHours();
    }

    @computed get days(): number {
        return this.date.getDay();
    }

    public _timer?: NodeJS.Timer;

    @action.bound
    public startClock(cbOnTick: (date: Date) => void) {
        this.stopClock();
        this._timer = setInterval(() => {
            this.date = new Date(Date.now());
            cbOnTick(this.date);
        }, 1000);
    }

    @action.bound
    public stopClock() {
        if (this._timer) {
            clearInterval(this._timer);
        }
    }
}