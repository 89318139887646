import { t } from 'i18next';
import React, { useState } from 'react';
import { useStore } from '../../common/stores/useStore';
import { DeviceIcon } from '../../devices/layout/icon/deviceIcon';
import { DeviceState, DeviceType } from '../../devices/stores/deviceStore';
import { AccessRequestContent } from '../../search/accessRequestContent';
import { SearchResultModel } from '../../search/stores';
import { ResetOrDeleteDeviceConfirmation } from '../../search/device/resetOrDeleteConfirmation';
import { IMenuAction } from '../../search/actionsMenu';

import {
    firmwareOutdatedLabelWrapper,
    firmwareOutdatedValueWrapper
} from '../../search/components/firmwareOutdatedWrappers';
import SearchPageResultView, { IKeyValue, ISearchResultDetails } from '../../search/searchPageResultView';
import { KnownRoles } from '../../common/routing/knownroles';

export const ComfionSearchResultTile = (props: { device: SearchResultModel }) => {
    const { viewStore } = useStore();
    const { searchStore } = useStore();

    function getProjectNameOrFallback(props: { device: SearchResultModel }) {
        if (props.device.projectName) {
            return { label: 'Devices.Projectname', value: props.device.projectName };
        } else {
            return { label: 'Devices.DeviceId', value: props.device.registeredDeviceId };
        }
    }

    const searchResultBodyItems: IKeyValue[] = [
        ...(!viewStore.user.hasRoles(KnownRoles.Operator) ? [getProjectNameOrFallback(props)] : []),
        { label: 'Devices.DeviceName', value: props.device.deviceName },
        {
            label: 'Devices.FirmwareVersion',
            value: props.device.firmwareVersion,
            wrapperForLabel: props.device.hasLatestFirmware ? undefined : firmwareOutdatedLabelWrapper,
            wrapperForValue: props.device.hasLatestFirmware ? undefined : firmwareOutdatedValueWrapper
        },
        { label: 'Devices.SerialNumber', value: props.device.serialNumber }
    ];

    const resultView: ISearchResultDetails = {
        searchResultBody: {
            items: searchResultBodyItems
        },
        isEnabled: props.device.deviceAccess?.userCanAccessDevice === true,
        id: props.device.registeredDeviceId,
        action: () => props.device.deviceAccess?.userCanAccessDevice === true ? viewStore.devicesViewStore.navigateToDevice({ deviceid: props.device.registeredDeviceId, deviceType: DeviceType.Comfion }) : null
    };

    const deviceState = props.device.isConnected ? DeviceState.Connected : DeviceState.Disconnected;

    const [resetRequested, setResetRequested] = useState(false);

    const closeDeviceResetDialog = () => {
        setResetRequested(false);
    };

    const resetDevice = async () => {
        await props.device.resetDevice();
        await searchStore.search();
        closeDeviceResetDialog();
    };

    const resetMenuAction = { actionName: t('ResetDevice.MenuButton').toString(), onActionSelectedCallback: () => setResetRequested(true) };

    const getMenuActions = () => {
        const menuActions: Array<IMenuAction> = [];

        if (props.device.userHasResetOrDeleteDevicePermission) {
            menuActions.push(resetMenuAction);
        }
        return menuActions;
    };

    return (
        <React.Fragment >
            {resetRequested && <ResetOrDeleteDeviceConfirmation isOpen={resetRequested} onCancel={closeDeviceResetDialog} onConfirmed={resetDevice} matchValue={props.device.serialNumber} title={t('ResetDevice.Dialog.Title')} actionButtonText={t('ResetDevice.Dialog.ResetButton')} descriptionText={t('ResetDevice.Dialog.DescriptionText')} />}
            <SearchPageResultView
                actionsColumn={<AccessRequestContent key={props.device.registeredDeviceId} deviceAccess={props.device.deviceAccess} />}
                menuActions={getMenuActions()}
                searchResult={resultView}
                key={props.device.registeredDeviceId}
                icon={<DeviceIcon
                    deviceType={DeviceType.Comfion}
                    deviceSubType={undefined}
                    deviceState={deviceState}
                    iconSize={80} />}
            />
        </React.Fragment>
    );
};
