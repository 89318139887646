import { action, observable } from 'mobx';
import DeviceStore from '.';
import { ModelState } from '../../../common/utils/modelState';
import { ErrorType } from '../../../common/validation/errorType';
import { getPortalConfiguration } from '../../../getPortalConfiguration';
import { getViewStore } from '../../../storeRegistry';
import { DexitNotificationsRepository } from './dexitNotificationsRepository';

export interface IDeviceSettingsState {
    deviceSettingsModelState: ModelState;
    currentDeviceNotificationLanguage: string;
    availableNotificationLanguages: string[];
    errors: ErrorType<IDeviceSettingsState & { other: string[] }>;
}

export class DeviceNotificationSettingsModel {
    private static defaultNotificationLanguage = 'en';

    @observable
    public deviceSettingsModelState: ModelState = 'initial';

    @observable
    public currentDeviceNotificationLanguage = 'en';

    @observable
    public availableNotificationLanguages: string[] = [];

    @observable
    public errors: ErrorType<IDeviceSettingsState & { other: string[] }> = {};

    private notificationsRepository: DexitNotificationsRepository;

    constructor(private parent: DeviceStore) {
        this.notificationsRepository = new DexitNotificationsRepository(getPortalConfiguration());
    }

    @action
    public async get() {
        await Promise.all([
            this.getAvailableNotificationLanguages(),
            this.getDeviceNotificationLanguage()
        ]);
    }

    @action
    public async refresh() {
        if (this.deviceSettingsModelState !== 'initial') {
            await Promise.all([
                this.getAvailableNotificationLanguages(),
                this.getDeviceNotificationLanguage()
            ]);
        }
    }

    @action
    public async setDeviceNotificationLanguage(deviceId: string, newLanguage: string) {
        try {
            if (newLanguage !== this.currentDeviceNotificationLanguage && newLanguage !== null) {
                this.currentDeviceNotificationLanguage = newLanguage;
                this.notificationsRepository.saveDeviceLocalizationSettings(deviceId, newLanguage);
            }
        } catch (e: any) {
            const viewStore = getViewStore();
            viewStore.notifyError('save_deviceSettings', e);
        }
    }

    private async getDeviceNotificationLanguage() {
        if (this.parent.deviceId) {
            try {
                this.deviceSettingsModelState = 'loading';
                const existingLanguageSetting = await this.notificationsRepository.getDeviceLocalizationSettings(this.parent.deviceId);
                this.currentDeviceNotificationLanguage = (this.isValidLanguage(existingLanguageSetting))
                    ? this.currentDeviceNotificationLanguage = existingLanguageSetting!
                    : this.getFallbackNotificationLanguage();
                this.deviceSettingsModelState = 'ready';
            } catch (e : any) {
                const viewStore = getViewStore();
                viewStore.notifyError('load_deviceSettings', e);
                this.deviceSettingsModelState = 'error';
            }
        }
    }

    private getFallbackNotificationLanguage() {
        const viewStore = getViewStore();
        const userLanguage = viewStore.user.userSettings.language;
        this.isValidLanguage(userLanguage)
            ? this.currentDeviceNotificationLanguage = userLanguage!
            : this.currentDeviceNotificationLanguage = DeviceNotificationSettingsModel.defaultNotificationLanguage;

        return this.currentDeviceNotificationLanguage;
    }

    private isValidLanguage(languageToCheck: string | undefined | null): boolean {
        return (languageToCheck !== null && languageToCheck !== undefined && this.availableNotificationLanguages.includes(languageToCheck!));
    }

    private async getAvailableNotificationLanguages() {
        try {
            this.deviceSettingsModelState = 'loading';
            this.availableNotificationLanguages = await this.notificationsRepository.getAvailableNotificationLanguages();
            this.deviceSettingsModelState = 'ready';
        } catch (e: any) {
            const viewStore = getViewStore();
            viewStore.notifyError('load_deviceSettings', e);
            this.deviceSettingsModelState = 'error';
        }
    }

    public toJSON(): IDeviceSettingsState {
        return {
            availableNotificationLanguages: this.availableNotificationLanguages,
            currentDeviceNotificationLanguage: this.currentDeviceNotificationLanguage,
            deviceSettingsModelState: this.deviceSettingsModelState,
            errors: this.errors
        };
    }

    public updateFromJSON(existingState: IDeviceSettingsState) {
        this.availableNotificationLanguages = existingState.availableNotificationLanguages;
        this.currentDeviceNotificationLanguage = existingState.currentDeviceNotificationLanguage;
        this.deviceSettingsModelState = existingState.deviceSettingsModelState;
        this.errors = existingState.errors;
    }
}