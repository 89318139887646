import httpContext from 'express-http-context';

export class ExpressHttpContextAdapter {
    public static setAccessToken(accessToken: string | undefined) {
        httpContext.set('accessToken', accessToken);
    }

    public static getAccessToken(): string | undefined {
        return httpContext.get('accessToken');
    }
}