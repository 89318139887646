import { action, observable } from 'mobx';

import { BaseModelStore } from '../../common/stores/baseModelStore';
import { DeferredDataModel } from '../../common/stores/deferredDataModel';
import { getPortalConfiguration } from '../../getPortalConfiguration';
import { AocDeviceSettings, IAocDeviceSettingsState } from '../details/aocDeviceSettings';
import { AocDeviceRepository } from '../details/aocDeviceRepository';

export interface IAocStoreState {
    deviceSettings: IAocDeviceSettingsState | null;
    deviceId: string | undefined;
}
export class AocStore implements BaseModelStore<IAocStoreState> {
    @observable public deviceDetails = new DeferredDataModel<AocDeviceSettings>();
    @observable public deviceId?: string;
    public aocDeviceRepository: AocDeviceRepository;

    constructor() {
        this.aocDeviceRepository = new AocDeviceRepository(getPortalConfiguration());
    }

    @action.bound
    async getDevice(deviceId: string) {
        this.deviceDetails.setInitialData(null);
        try {
            this.deviceDetails.setLoading();
            const metadata = await this.aocDeviceRepository.getOperatorMetadataForDevice(deviceId);
            const data = await this.aocDeviceRepository.getDeviceDetails(deviceId, metadata!);
            if (data) {
                const dataModel = new AocDeviceSettings().updateFromJSON(data);
                this.deviceId = dataModel.deviceId;
                this.deviceDetails.setReadyData(dataModel);
            } else {
                this.deviceDetails.setError();
            }
        } catch (e : any) {
            this.deviceDetails.setError();
            throw e;
        }
    }

    async loadData(deviceId: string) {
        await this.getDevice(deviceId);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateFromJSON(state: IAocStoreState): this {
        throw new Error('Method not implemented.');
    }

    toJSON(): IAocStoreState {
        throw new Error('Method not implemented.');
    }
}
