export const isNonWhitespaceString = (str: any): boolean => {
    return typeof str === 'string' && str.trim().length > 0;
};

export const upperCaseFirstLetter = (str: string | undefined): string => {
    if (str === undefined) {
        return '';
    } else {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
};
