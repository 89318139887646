import React from 'react';
import { SearchPageResultView } from '../../search';
import Avatar from '../../common/components/avatar';
import { useStore } from '../../common/stores/useStore';
import { ISearchResultDetails } from '../../search/searchPageResultView';
import { SearchResultModel } from '../../search/stores';

export const ResellerSearchResultTile = (props: { company: SearchResultModel }) => {
    const { searchStore } = useStore();
    const resultView: ISearchResultDetails = {
        searchResultBody: {
            items: [
                { label: 'Company.Name', value: props.company.companyName },
                { label: 'Company.Address', value: props.company.companyAddress },
                { label: 'Company.Telephone', value: props.company.phone },
                { label: 'Company.Email', value: props.company.mail },
                { label: 'Company.CloudCompanyId', value: props.company.cloudCompanyId }
            ]
        },
        isEnabled: true,
        id: props.company.cloudCompanyId,
        action: () => {
            searchStore.updateCustomerSearchFilter(parseInt(props.company.cloudCompanyId));
            searchStore.updateFilteredCompanyName(props.company.companyName);
        }
    };

    return (
        <SearchPageResultView
            searchResult={resultView}
            key={props.company.cloudCompanyId}
            icon={<Avatar firstname="" lastname="" fallbackIcon="icon-Installer" />}
        />
    );
};