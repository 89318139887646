import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { AocSearchResultTile } from '../aoc/search/aocSearchResultTile';
import { useStore } from '../common/stores/useStore';
import { DexitSearchResultTile } from '../dexit/search/dexitSearchResultTile';
import { HyenSearchResultTile } from '../hyen/search/hyenSearchResultTile';
import { ResellerSearchResultTile } from '../reseller/search/resellerSearchTile';
import { I18NEXT_SEARCH_NAMESPACE } from './constants';
import { ComfionSearchResultTile } from '../comfion/search/comfionSearchResultTile';

export const SearchResults = () => {
    const { searchStore } = useStore();
    return useObserver(() => {
        if (searchStore.searchResults && searchStore.searchResults.length > 0) {
            return (
                <React.Fragment>
                    {searchStore.searchResults.map((e, index) => {
                        switch (e.searchResultType) {
                        case 'Aoc':
                            return <AocSearchResultTile key={index} device={e} />;
                        case 'Comfion':
                            return <ComfionSearchResultTile key={index} device={e} />;
                        case 'Hyen':
                            return <HyenSearchResultTile key={index} device={e} />;
                        case 'Dexit':
                            return <DexitSearchResultTile key={index} device={e} />;
                        case 'Reseller':
                            return <ResellerSearchResultTile key={index} company={e} />;
                        default:
                            break;
                        }
                        return null;
                    })}
                </React.Fragment>
            );
        } else {
            return (
                <div className="row justify-content-center search-message">
                    <Trans ns={I18NEXT_SEARCH_NAMESPACE}>Search.NoResult</Trans>
                </div>
            );
        }
    });
};
