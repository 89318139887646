import React from 'react';
import BatteryStateEmptyIcon from './batteryStateIcons/181-battery_empty.svg';
import { BatteryState } from '../../stores/deviceStore';

export const BatteryStateIcon = (props: { batteryState: BatteryState, }) => {
    return (
        <div>
            {props.batteryState === 'Empty' ? <img className="component-state-icon" src={BatteryStateEmptyIcon} /> : <React.Fragment></React.Fragment>}
        </div>
    );
};