import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { LabeledData } from '../../common/components/labeledData/labeledData';
import { useStore } from '../../common/stores/useStore';
import { assertState } from '../../common/utils/modelState';
import { DeviceIcon } from '../layout/icon/deviceIcon';
import { HyenDeviceModel } from '../stores/deviceStore/hyenDeviceModel';
import { DexitProperties } from './properties/dexitProperties';
import { HyenProperties } from './properties/hyenProperties';
import { ComfionProperties } from './properties/comfionProperties';
import { ComfionDeviceModel } from '../stores/deviceStore/comfionDeviceModel';

export const Properties = () => {
    const {
        deviceStore,
        viewStore: { devicesViewStore }
    } = useStore();
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        const { deviceDetails } = deviceStore;
        const isLoading = assertState(deviceDetails.state, 'initial', 'loading');
        return (
            <React.Fragment>
                <DeviceIcon iconSize={100} deviceType={deviceDetails?.data?.type} deviceState={deviceDetails.data?.state} deviceSubType={deviceDetails?.data?.deviceSubType} />
                <br />
                <LabeledData labelName={t('Devices.DeviceName')} data={deviceDetails.data?.deviceName} showSkeleton={isLoading} />
                <LabeledData labelName={t('Devices.SerialNumber')} data={deviceDetails.data?.serialNumber} showSkeleton={isLoading} />
                <LabeledData labelName={t('Devices.FirmwareVersion')} data={deviceDetails.data?.firmwareVersion} showSkeleton={isLoading} />
                <LabeledData labelName={t('Devices.IPAddress')} data={deviceDetails.data?.ipAddress} showSkeleton={isLoading} />
                {devicesViewStore.lastKnownDeviceType === 'Dexit' && <DexitProperties showSkeleton={isLoading} device={deviceDetails.data} />}
                {devicesViewStore.lastKnownDeviceType === 'Hyen' && <HyenProperties showSkeleton={isLoading} device={deviceDetails.data as HyenDeviceModel} />}
                {devicesViewStore.lastKnownDeviceType === 'Comfion' && <ComfionProperties showSkeleton={isLoading} device={deviceDetails.data as ComfionDeviceModel} />}
            </React.Fragment>
        );
    });
};
