import { observable } from 'mobx';

export class CompanyProfileModel {
    @observable
    public customerId = '';

    @observable
    public cloudCompanyId = 0;

    @observable
    public name = '';

    @observable
    public address = '';

    @observable
    public postCode = '';

    @observable
    public city = '';

    @observable
    public countryRegion = '';

    @observable
    public phoneNo = '';

    @observable
    public email = '';

    @observable
    public contactPersonsFirstName = '';

    @observable
    public contactPersonsLastName = '';
}
