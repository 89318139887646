import React from 'react';
import '../generalErrorComponent.scss';
import { withTranslation, Trans } from 'react-i18next';

class E404Component extends React.Component<any> {
    public render() {
        return (
            <div className="container">
                <div className="general-error-background-overlay" />
                <div className="error-wrapper col-md-7 text-left">
                    <div className="row row-item">
                        <div className="error-header">
                            <h2><Trans>Error.Header</Trans></h2>
                        </div>
                    </div>

                    <div className="row row-item">
                        <div className="error-title">
                            <h5><Trans>Error.Title</Trans></h5>
                        </div>
                    </div>

                    <div className="row row-item">
                        <div className="error-message">
                            <h6><Trans>Error.Message</Trans></h6>
                        </div>
                    </div>

                    <div className="row row-item">
                        <div className="error-hints">
                            <div className="error-hint d-flex">
                                <i className="icon-icon_control_arrow_right" />
                                <span><Trans>Error.HintCheckURL</Trans></span>
                            </div>
                            <div className="error-hint d-flex">
                                <i className="icon-icon_control_arrow_right" />
                                <span><Trans>Error.HintReload</Trans></span>
                            </div>
                            <div className="error-hint d-flex">
                                <i className="icon-icon_control_arrow_right" />
                                <span><Trans>Error.HintClearCache</Trans></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('portal_default')(E404Component);