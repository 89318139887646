import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { BaseRepository } from '../../common';
import { IPortalConfiguration } from '../../common/interfaces';
import { createApiProxy } from '../../common/createApiProxy';

/* eslint-disable no-useless-catch */
export class DexitDevicePairingRepository extends BaseRepository {
    private api: AbusCloudApi.DexitApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.api = new AbusCloudApi.DexitApi(this.apiConfiguration);
    }

    public async postDexitPairing(pairingCode: string): Promise<boolean> {
        try {
            await createApiProxy(() => this.api.apiDexitOperatorPairingPairingPost(pairingCode))();
            return true;
        } catch (e: any) {
            return false;
        }
    }
}
