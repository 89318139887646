import { ABUSCloudPortalDexitSettingsBatteryState, ABUSCloudPortalDexitSettingsCylinderComponentTypes } from 'portal-bff-proxy-ts';

export enum DeviceType {
    Aoc = 'Aoc',
    Comfion = 'Comfion',
    Dexit = 'Dexit',
    Hyen = 'Hyen',
    Unknown = 'Unknown',
}

export enum DeviceSubtype {
    HyenEN2 = 'ESEZ60500',
    HyenEN3 = 'ESEZ70500',
    Unknown = 'Unknown'
}

export enum DeviceState {
    Connected,
    Disconnected,
    Calling
}

export enum DexitComponentType {
    Camera = 'Camera',
    Cylinder = 'Cylinder',
    Repeater = 'Repeater',
    Slave = 'Slave',
    Wallreader = 'Wallreader',
    Unknown = 'Unknown',
}

export interface IDeviceComponent {
    id: string;
    deviceId: string;
    name: string;
    disabled: boolean;
    componentType: DeviceComponentType;
}

export interface ICylinderDeviceComponent extends IDeviceComponent {
    signalStrength: number;
    signalState: SignalState;
    type: CylinderType;
    batteryState: BatteryState;
    firmwareVersion: string;
    dateOfProduction: string | null;
    learnEnabled: boolean;
    armEnabled: boolean;
    lastBatteryChange?: string;
}

export interface IRepeaterDeviceComponent extends IDeviceComponent {
    signalStrength: number;
    signalState: SignalState;
    firmwareVersion: string;
    dateOfProduction: string;
    hasBattery: boolean;
    connectedComponents: number;
}

export interface ICameraDeviceComponent extends IDeviceComponent {
    wapploxxId?: string;
    wapploxxName?: string;
    internalIpAddr: string;
    internalHttpPort: number;
    internalRtspPort: number;
    internalRecordingPort: number;
    connectionStatus: ConnectionStatus;
    componentType: DeviceComponentType.Camera;
}

export interface ISlaveComponent extends IDeviceComponent {
    componentType: DeviceComponentType.Slave
    connectedComponents: number;
    connectionState: ConnectionStatus;
    firmwareVersion: string;
    ipv4Addr: string;
    serialNumber:string;
}

export enum ConnectionStatus {
    Unknown,
    Established,
    Lost
}

export interface IWallreaderDeviceComponent extends IDeviceComponent {
    signalStrength: number;
    signalState: SignalState;
    firmwareVersion: string;
    dateOfProduction: string;
}

export enum SignalState {
    Poor,
    Good,
    Excellent,
    Unknown
}

export type BatteryState = keyof typeof ABUSCloudPortalDexitSettingsBatteryState;

export type CylinderType = keyof typeof ABUSCloudPortalDexitSettingsCylinderComponentTypes;

export enum DeviceComponentType {
    Unknown,
    Cylinder,
    Wallreader,
    Repeater,
    Camera,
    Slave
}

export interface IDeviceComponents {
    totalDeviceComponentsCount: number;
    deviceComponents: IDeviceComponent[];
}