import * as React from 'react';
import './dropdown.scss';
import {
    Dropdown as RSDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup,
    FormFeedback
} from 'reactstrap';
import { Skeleton } from '../skeleton';

export interface IDropdownProps {
  placeholder?: string;
  id?: string;
  name?: string;
  errorMessage?: string[] | null;
  value?: string;
  items: IItem[];
  maxLength?: number;
  className?: string;
  disabled?: boolean;
  onChange?: (name: string, value: string) => void;
  isLoading?: boolean;
}

export interface IItem {
  key: string;
  text: string;
}

// tslint:disable-next-line
export const Dropdown = (props: IDropdownProps) => {
    const [isDropdownOpen, setState] = React.useState(false);

    const getFirstError = (): string | null => {
        if (props.errorMessage && props.errorMessage.length > 0) {
            return props.errorMessage[0];
        }
        return null;
    };

    const getTextForItemKey = (key: string): string | undefined => {
        if (props.items) {
            const filtered = props.items.filter((item) => item.key === key);
            if (filtered.length > 0) {
                return filtered[0].text;
            }
        }
        return undefined;
    };

    const toggle = () => {
        setState(!isDropdownOpen);
    };

    const select = (event: any) => {
        const newValue = event.target.attributes['data-key'].value;
        if (props.onChange) {
            props.onChange(props.name as string, newValue);
        }
    };

    const hasValue = (): boolean => {
        return !!props.value;
    };

    const renderSkeleton = () => {
        return (<Skeleton />);
    };

    const renderDropdownToggle = () => {
        return (
            <DropdownToggle
                className={`w-100 text-left abus-toggle ${hasValue() ? 'used' : 'empty'} ${getFirstError() ? 'custom-invalid' : ''}`}
                color="link"
                caret={false}
            >
                <span>{getTextForItemKey(props.value as string) || '-'}</span>
                <span className="float-right"><i className="icon-icon_control_arrow_down" /></span>
            </DropdownToggle>);
    };

    const renderDropdownItem = (listitem: IItem) => {
        return (
            <DropdownItem
                key={listitem.key}
                data-key={listitem.key}
                onClick={select}
            >
                {listitem.text}
            </DropdownItem>
        );
    };

    const listItems = props.items || [];

    return (
        <FormGroup className={`abus-dropdown ${props.className || ''}`} >
            <div className="abus-dropdown-container">
                <RSDropdown
                    id={props.id}
                    className={'abus-dropdown-control'}
                    isOpen={isDropdownOpen}
                    toggle={toggle}
                    disabled={props.disabled}
                >

                    {props.isLoading ? renderSkeleton() : renderDropdownToggle()}

                    <span className="highlight" />
                    <span className="bar" />
                    <label className={props.disabled ? 'disabled' : ''}>{props.placeholder}</label>
                    <DropdownMenu className="w-100">
                        {listItems.map(renderDropdownItem)}
                    </DropdownMenu>
                </RSDropdown>
                <FormFeedback className={getFirstError() ? 'custom-invalid' : ''}>
                    {getFirstError()}
                </FormFeedback>
            </div>
        </FormGroup>
    );
};
