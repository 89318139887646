import { IPortalConfiguration } from './interfaces';
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { Helper } from './utils/helper';

export abstract class BaseRepository {
    protected apiConfiguration: AbusCloudApi.Configuration;
    constructor(portalConfiguration: IPortalConfiguration) {
        this.apiConfiguration = new AbusCloudApi.Configuration();
        this.apiConfiguration.accessToken = portalConfiguration.accessToken;
        this.apiConfiguration.basePath = portalConfiguration.bffEndpoint();
    }

    protected getStringValueOrDefault(value: string | number | undefined): string {
        return Helper.getStringValueOrDefault(value);
    }

    protected getNumberValueOrDefault(value: number | undefined): number {
        return Helper.getNumberValueOrDefault(value);
    }

    protected getDateValueOrUndefined(value: string | Date | undefined): Date | undefined {
        return Helper.getDateValueOrUndefined(value);
    }
}