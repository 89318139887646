import './searchPage.scss';

import React, { ReactNode } from 'react';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import { observer } from 'mobx-react';
import { I18NEXT_SEARCH_NAMESPACE } from './constants';
import { ActionsMenu, IMenuAction } from './actionsMenu';
import { ConditionalWrapper } from '../common/components/conditionalWrapper/conditionalWrapper';

export interface IKeyValue {
    label: string;
    value: string;
    wrapperForLabel?: (children: React.ReactNode) => JSX.Element;
    wrapperForValue?: (children: React.ReactNode) => JSX.Element;
}

export interface ISearchResultBody {
    items: IKeyValue[];
}

export interface ISearchResultDetails {
    searchResultBody: ISearchResultBody;
    id: string;
    isEnabled: boolean;
    action(): void;
}

export interface ISearchResultProps extends WithTranslation {
    searchResult: ISearchResultDetails;
    icon: any;
    actionsColumn?: React.ReactNode;
    menuActions?: Array<IMenuAction>;
}

export const SearchResultActionCol = (props: React.PropsWithChildren<unknown>) => {
    return (
        <div className="d-flex flex-column mt-auto mb-auto justify-content-center">
            {props.children}
        </div>
    );
};

@observer
class SearchPageResultView extends React.Component<ISearchResultProps> {
    private renderSearchResultBody(bodyProps: IKeyValue): any {
        return (
            <li className="list-group-item" key={bodyProps.label}>
                <span className="profileKey">
                    <ConditionalWrapper wrapper={bodyProps.wrapperForLabel}>
                        <Trans ns={I18NEXT_SEARCH_NAMESPACE}>
                            {bodyProps.label}
                        </Trans>
                        <span>: </span>
                    </ConditionalWrapper>
                </span>
                <span className="profileValue">
                    <ConditionalWrapper wrapper={bodyProps.wrapperForValue}>{bodyProps.value}</ConditionalWrapper>
                </span>
            </li>
        );
    }

    public render() {
        return (
            <React.Fragment>
                <div className="row justify-content-center cardsVerticalMargin no-gutter">
                    <div className="col-sm-2 col-md-2 col-lg-2 columnAvatar">
                        <a className={this.props.searchResult.isEnabled ? 'resultLink' : 'resultLinkDisabled'}
                            onClick={this.props.searchResult.action}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="searchIcon ">
                                        {this.props.icon}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-10 col-md-7 col-lg-6 columnInfo">
                        <a className={this.props.searchResult.isEnabled ? 'resultLink' : 'resultLinkDisabled'}
                            onClick={this.props.searchResult.action}>
                            <div className="card">
                                <div className="card-body">
                                    <ul className="list-group list-group-flush ">
                                        {this.props.searchResult.searchResultBody.items.map((e) => this.renderSearchResultBody(e))}
                                    </ul>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-2 columnInfo d-flex flex-column">
                        {this.props.menuActions && <ActionsMenu actions={this.props.menuActions} />}
                        {this.props.actionsColumn &&
                        <SearchResultActionCol>{this.props.actionsColumn}</SearchResultActionCol>}
                    </div>
                </div>
            </React.Fragment>);
    }
}

export default withTranslation(I18NEXT_SEARCH_NAMESPACE)(SearchPageResultView);
