import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../..';
import { LabeledData } from '../../../common/components/labeledData/labeledData';
import { ComfionDeviceModel } from '../../stores/deviceStore/comfionDeviceModel';

export const ComfionProperties = (props: { device: ComfionDeviceModel | null; showSkeleton: boolean }) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    return useObserver(() => {
        const zones = [props.device?.zonesUsed?.toString() ?? '-', props.device?.zonesUsed?.toString() ?? '-'];
        return (
            <React.Fragment>
                <LabeledData labelName={t('Devices.MacAddress')} data={props.device?.macAddress?.toString()} showSkeleton={props.showSkeleton} />
                <LabeledData labelName={t('Devices.Zones')} data={zones.join(' / ')} showSkeleton={props.showSkeleton} />
                <LabeledData labelName={t('Devices.Automations')} data={props.device?.numberOfAutomations?.toString()} showSkeleton={props.showSkeleton} />
                <LabeledData labelName={t('Devices.Users')} data={props.device?.numberOfUsers?.toString()} showSkeleton={props.showSkeleton} />
            </React.Fragment>
        );
    });
};
