import { observable, toJS, action } from 'mobx';
import { UserProfileModel } from './userProfileModel';
import { Helper } from '../../../common';
import { defaultLogger } from '../../../../logger';
import { getPortalConfiguration } from '../../../getPortalConfiguration';
import { getViewStore } from '../../../storeRegistry';
import { ResellerProfileRepository } from './repositories/resellerProfileRepository';

import { OperatorRepository } from './repositories/operatorRepository';
import { CompanyProfileModel } from '../company/companyProfileModel';

export interface IUserProfileStoreState {
    userProfile: UserProfileModel | null;
    companyProfile: CompanyProfileModel | undefined;
    companyMembers: UserProfileModel[] | undefined;
    operatorProfile: UserProfileModel | undefined;
    isDirty: boolean;
    isSynced: boolean;
}

class UserProfileStore {
    @observable
    public userProfile: UserProfileModel | null;

    @observable
    public companyMembers: UserProfileModel[] = [];

    @observable
    public companyProfile: CompanyProfileModel | undefined;

    @observable
    public operatorProfile: UserProfileModel | undefined;

    @observable
    public isDirty = false;

    @observable
    public isSynced = false;

    @observable
    public isLoading = true;

    public operatorProfileRepository: OperatorRepository;
    public resellerRepository: ResellerProfileRepository;

    constructor(initialState?: IUserProfileStoreState) {
        if (initialState) {
            this.userProfile = initialState.userProfile ? Helper.jsonToModel(initialState.userProfile, UserProfileModel) : null;
            this.companyMembers = initialState.companyMembers ? Helper.jsonToModel(initialState.companyMembers, Array<UserProfileModel>) : [];
            this.companyProfile = initialState.companyProfile ? Helper.jsonToModel(initialState.companyProfile, CompanyProfileModel) : undefined;
            this.operatorProfile = initialState.operatorProfile ? Helper.jsonToModel(initialState.operatorProfile, UserProfileModel) : undefined;
            this.isDirty = initialState.isDirty;
            this.isSynced = initialState.isSynced;
        } else {
            this.userProfile = null;
        }

        this.operatorProfileRepository = new OperatorRepository(getPortalConfiguration());
        this.resellerRepository = new ResellerProfileRepository(getPortalConfiguration());
    }

    @action.bound
    public async sync(): Promise<void> {
        try {
            this.isLoading = true;
            const resellerProfileData = await this.resellerRepository.getResellerProfile();
            this.companyProfile = resellerProfileData?.companyProfile ?? undefined;
            this.companyMembers = resellerProfileData?.employees ? resellerProfileData?.employees : [];
            this.userProfile = resellerProfileData?.userProfile ? resellerProfileData?.userProfile : null;
            this.isSynced = true;
        } catch (e: any) {
            this.handleApiError('resellerProfileSync', e);
            throw e;
        } finally {
            this.isLoading = false;
        }
    }

    @action.bound
    public async syncOperator(): Promise<void> {
        try {
            this.isLoading = true;
            const operatorProfileData = await this.operatorProfileRepository.getOperatorProfile();
            this.operatorProfile = operatorProfileData!;
        } catch (e: any) {
            this.handleApiError('operatorProfileSync', e);
            throw e;
        } finally {
            this.isLoading = false;
        }
    }

    public toJSON(): IUserProfileStoreState {
        const state: IUserProfileStoreState = {
            companyMembers: this.companyMembers,
            userProfile: this.userProfile,
            companyProfile: this.companyProfile,
            operatorProfile: this.operatorProfile!,
            isDirty: this.isDirty,
            isSynced: this.isSynced
        };
        return toJS(state);
    }

    private handleApiError(source: string, err: any) {
        defaultLogger.error(err);
        const viewStore = getViewStore();
        viewStore.notifyError(source, err);
    }
}

export default UserProfileStore;
