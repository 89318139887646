import * as React from 'react';
import { WithTranslation, useTranslation } from 'react-i18next';
import Input from '../../../common/components/input';
import { IAllStores } from '../../../common/stores/stores';
import { useObserver } from 'mobx-react';
import { useStore } from '../../../common/stores/useStore';
import { InputType } from 'reactstrap/types/lib/Input';
import ProfileRow from '../userProfileForm/profileRow';
import { CompanyProfileModel } from './companyProfileModel';

type IInject = Pick<IAllStores, 'userProfileStore'>;

export interface ICompanyViewProps extends WithTranslation, Partial<IInject> {}

export const CompanyProfile = () => {
    const { t } = useTranslation('portal_reseller');
    const { accessIdentityStore } = useStore();

    return useObserver(() => {
        const { userProfileStore } = accessIdentityStore;

        const renderInput = <K extends keyof CompanyProfileModel>(key: K, placeholderLabelKey: string, type?: InputType, helpLabelKey?: string, readOnly?: boolean) => {
            const model = userProfileStore!.companyProfile;

            if (!model) {
                return null;
            }

            const isKeyOfCompanyProfile = (k: any): k is keyof CompanyProfileModel => {
                return k in model;
            };

            const value: any = isKeyOfCompanyProfile(key) ? model[key] : '';

            readOnly = false;
            type = type || 'text';

            return (
                <Input
                    type={type}
                    id={key as string}
                    name={key as string}
                    placeholder={t(placeholderLabelKey).toString()}
                    value={value}
                    help={helpLabelKey ? t(helpLabelKey).toString() : undefined}
                    readOnly={readOnly}
                    isLoading={userProfileStore!.isLoading}
                />
            );
        };

        return (
            <React.Fragment>
                <ProfileRow>
                    <div className="col-9">{renderInput('customerId', 'CompanyProfile.CustomerId')}</div>
                </ProfileRow>
                <ProfileRow>
                    <div className="col-12">{renderInput('cloudCompanyId', 'CompanyProfile.CompanyId', 'text', '', true)}</div>
                </ProfileRow>
                <ProfileRow>
                    <div className="col-12">{renderInput('name', 'CompanyProfile.CompanyName')}</div>
                </ProfileRow>
                <ProfileRow>
                    <div className="col-9">{renderInput('address', 'CompanyProfile.Address')}</div>
                </ProfileRow>
                <ProfileRow>
                    <div className="col-3">{renderInput('postCode', 'CompanyProfile.PostalCode')}</div>
                    <div className="col-9">{renderInput('city', 'CompanyProfile.City')}</div>
                </ProfileRow>
                <ProfileRow>
                    <div className="col-9">{renderInput('countryRegion', 'CompanyProfile.Country')}</div>
                </ProfileRow>
                <ProfileRow>
                    <div className="col-12">{renderInput('email', 'CompanyProfile.Email')}</div>
                </ProfileRow>
                <ProfileRow>
                    <div className="col-12">{renderInput('phoneNo', 'CompanyProfile.Phone')}</div>
                </ProfileRow>
                <ProfileRow>
                    <div className="col-6">{renderInput('contactPersonsFirstName', 'CompanyProfile.ContactPersonsFirstName')}</div>
                    <div className="col-6">{renderInput('contactPersonsLastName', 'CompanyProfile.ContactPersonsLastName')}</div>
                </ProfileRow>
            </React.Fragment>
        );
    });
};
