import './overview.scss';

import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { FirmwareStore } from '../store/firmwareStore';
import { ViewStore } from '../../common/stores/viewStore';
import { ReleaseState, FirmwareRowModel } from '../store/firmwareRowModel';
import { nameof } from '../../common/nameof';

import { computed, observable } from 'mobx';
import { IDataGridColumn, LocalDataGrid } from '../../common/components/datagrid';

import Button from '../../common/components/button';
import { ActionsMenu, IMenuAction } from '../../search/actionsMenu';

import { FirmwareTypeModel } from '../store/firmwareTypesModel';
import { Stores } from '../../common/stores/stores';
import { SelectBox } from 'devextreme-react/select-box';
import { DexitFirmwarePublishModal } from '../dexit/DexitFirmwarePublishModal';
import { HyenFirmwarePublishModal } from '../hyen/HyenFirmwarePublishModal';
import { ResellerNotificationModal } from '../ResellerNotificationModal';
import { ComfionFirmwarePublishModal } from '../comfion/ComfionFirmwarePublishModal';

export interface IOverviewProps extends WithTranslation {
    children?: React.ReactNode;
    firmwareStore?: FirmwareStore;
    viewStore?: ViewStore;
}

@observer
class Overview extends React.Component<IOverviewProps> {
    @computed
    private get isLoading() {
        return this.props.firmwareStore?.firmwareList === null || this.props.firmwareStore?.firmwareList === undefined || this.props.firmwareStore?.firmwareTypes == null;
    }

    @observable
    private sendResellerNotificationRequested = false;

    @observable
    private dexitFirmwareApprovalModalRequested = false;

    @observable
    private hyenFirmwareApprovalModalRequested = false;

    @observable
    private comfionFirmwareApprovalModalRequested = false;

    private openResellerNotificationModal() {
        this.sendResellerNotificationRequested = true;
    }

    private setNewReleaseModalRequested() {
        switch (this.props.firmwareStore?.selectedFirmwareType?.deviceType) {
        case 'dexit':
            this.setDexitFirmwareApprovalModalRequested(true);
            break;
        case 'hyen':
            this.setHyenFirmwareApprovalModalRequested(true);
            break;
        case 'comfion':
            this.setComfionFirmwareApprovalModalRequested(true);
            break;
        default:
            break;
        }
    }

    private setResellerNotificationRequested(requested: boolean) {
        this.sendResellerNotificationRequested = requested;
    }

    private setDexitFirmwareApprovalModalRequested(requested: boolean) {
        if (!requested) {
            this.props.firmwareStore!.firmwareList = undefined;
            this.props.firmwareStore!.sync();
        }
        this.dexitFirmwareApprovalModalRequested = requested;
    }

    private setHyenFirmwareApprovalModalRequested(requested: boolean) {
        if (!requested) {
            this.props.firmwareStore!.firmwareList = undefined;
            this.props.firmwareStore!.sync();
        }
        this.hyenFirmwareApprovalModalRequested = requested;
    }

    private setComfionFirmwareApprovalModalRequested(requested: boolean) {
        if (!requested) {
            this.props.firmwareStore!.firmwareList = undefined;
            this.props.firmwareStore!.sync();
        }
        this.comfionFirmwareApprovalModalRequested = requested;
    }

    private setSelectedFirmware(row: FirmwareRowModel) {
        this.props.firmwareStore!.selectedFirmware = row;
    }

    private setSelectedFirmwareType = ({ component }: { component: any }): void => {
        const firmwareTypeId = component.option('selectedItem')?.id;
        this.props.firmwareStore!.selectedFirmwareType = this.props.firmwareStore?.firmwareTypes!.find((type: FirmwareTypeModel) => type.id === firmwareTypeId) || null;
        this.props.firmwareStore!.loadFirmwareList(firmwareTypeId ?? '');
    };

    private getFirmwareActions(firmwareRow: FirmwareRowModel): IMenuAction[] {
        if (firmwareRow.id === '') {
            return [];
        }
        const actions: IMenuAction[] = [];
        if (firmwareRow.isLatest) {
            actions.push({
                actionName: this.props.t('Common.SendResellerNotification.Button').toString(),
                onActionSelectedCallback: async (): Promise<void> => {
                    this.openResellerNotificationModal();
                }
            });
        }
        return actions;
    }

    public render() {
        const { t } = this.props;
        const translatedDataSource = this.props.firmwareStore?.firmwareTypes?.map((item) => ({
            ...item,
            displayName: t(item.displayName)
        }));

        const ReleaseCell = (column: FirmwareRowModel) => {
            const PUBLISH_STATE = {
                [ReleaseState.Published]: <div className="text-success">published</div>,
                [ReleaseState.Reported]: <div className="text-warning">reported</div>,
                [ReleaseState.Publishing]: <div className="text-warning">publishing</div>
            };

            return <>{PUBLISH_STATE[column.publishState]}</>;
        };

        const columns: IDataGridColumn<FirmwareRowModel>[] = [
            { name: nameof<FirmwareRowModel>('version'), title: this.props.t('Overview.Grid.Version').toString() },
            { name: nameof<FirmwareRowModel>('deviceCount'), title: this.props.t('Overview.Grid.DeviceCount').toString() },
            {
                name: nameof<FirmwareRowModel>('date'),
                title: this.props.t('Overview.Grid.Datetime').toString(),
                getCellValue: (row) => {
                    if (row.date === undefined) {
                        return '';
                    }
                    return <>{moment(row?.date ?? '').format('LL LTS')}</>;
                },
                width: 240
            },
            { name: nameof<FirmwareRowModel>('publishState'), title: this.props.t('Overview.Grid.Release').toString(), cellTemplate: (e) => ReleaseCell(e.row!) },
            {
                name: 'actions',
                title: 'Actions',
                getCellValue: (row) => <ActionsMenu onOpenCallback={() => this.setSelectedFirmware(row)} actions={this.getFirmwareActions(row)} />
            }
        ];

        return (
            <React.Fragment>
                <React.Fragment>
                    <div style={{ display: 'flex', alignItems: 'left', margin: '0 0 25px 0' }}>
                        <SelectBox
                            dataSource={translatedDataSource}
                            displayExpr="displayName"
                            valueExpr="id"
                            width={300}
                            height={50}
                            onValueChanged={this.setSelectedFirmwareType}
                            value={this.props.firmwareStore?.selectedFirmwareType?.id ?? this.props.firmwareStore?.firmwareTypes?.[0]?.id}
                        />
                        <Button onClick={() => this.setNewReleaseModalRequested()} style="secondary">
                            Create new Release
                        </Button>
                    </div>
                </React.Fragment>

                <div>
                    <LocalDataGrid
                        data={this.props.firmwareStore?.firmwareList ?? null}
                        loaded={!this.isLoading}
                        noDataText={this.props.t('Overview.NoData').toString()}
                        columns={columns}
                        sortingState={{ defaultSorting: [{ columnName: 'version', direction: 'desc' }] }}
                    />
                </div>

                {!this.isLoading && (
                    <ResellerNotificationModal
                        isOpen={this.sendResellerNotificationRequested}
                        firmwareStore={this.props.firmwareStore}
                        closeRequested={() => this.setResellerNotificationRequested(false)}
                    />
                )}

                {!this.isLoading && (
                    <DexitFirmwarePublishModal
                        firmware={this.props.firmwareStore?.selectedFirmware}
                        isOpen={this.dexitFirmwareApprovalModalRequested}
                        closeRequested={() => this.setDexitFirmwareApprovalModalRequested(false)}
                        uploadProgress={this.props.firmwareStore!.dexitFirmwareUploadProgress}
                        firmwareStore={this.props.firmwareStore}
                    />
                )}

                {!this.isLoading && (
                    <HyenFirmwarePublishModal
                        isOpen={this.hyenFirmwareApprovalModalRequested}
                        closeRequested={() => this.setHyenFirmwareApprovalModalRequested(false)}
                        uploadProgress={this.props.firmwareStore!.hyenFirmwareUploadProgress}
                        firmwareStore={this.props.firmwareStore}
                    />
                )}

                {!this.isLoading && (
                    <ComfionFirmwarePublishModal
                        isOpen={this.comfionFirmwareApprovalModalRequested}
                        closeRequested={() => this.setComfionFirmwareApprovalModalRequested(false)}
                        firmwareStore={this.props.firmwareStore}
                    />
                )}
            </React.Fragment>
        );
    }
}

const withHoC = inject(Stores.FirmwareStore, Stores.ViewStore)(withTranslation('portal_firmware')(Overview));
export { withHoC as Overview };
