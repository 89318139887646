import { observable, action } from 'mobx';
import { getPortalConfiguration } from '../../../getPortalConfiguration';
import { defaultLogger } from '../../../../logger';
import { BatteryReportRepository } from './batteryReportRepository';
import { INotificationConfigurationState, NotificationConfigurationModel } from '../../stores/deviceStore/notificationConfigurationModel';
import { DexitNotificationsRepository } from '../../stores/deviceStore/dexitNotificationsRepository';
import { ErrorType } from '../../../common/validation/errorType';
import { validateModel } from '../../../common';
import { ABUSCloudPortalDexitBatteryReportReportFrequency } from 'portal-bff-proxy-ts';

export interface IBatteryReportConfigurationState extends INotificationConfigurationState {
    nextReportDate: Date | undefined;
    frequency: ABUSCloudPortalDexitBatteryReportReportFrequency;
}

export class BatteryReportConfigurationModel extends NotificationConfigurationModel {
    public static defaultBatteryReportInterval = '0 3 1 */1 *';
    public static defaultBatteryReportName = 'dexit_battery_report';

    @observable
    public name: string = BatteryReportConfigurationModel.defaultBatteryReportName;

    @observable
    public frequency: ABUSCloudPortalDexitBatteryReportReportFrequency = ABUSCloudPortalDexitBatteryReportReportFrequency.EveryMonth

    @observable
    public nextReportDate: Date | undefined;

    protected validate() {
        const validationResult: ErrorType<this> | null = validateModel(this);
        this.errors = validationResult || {};
        return validationResult;
    }

    public static create(name: string, deviceId: string, language: string): BatteryReportConfigurationModel {
        const model = new BatteryReportConfigurationModel();
        model.name = name;
        model.enabled = false;
        model.deviceId = deviceId;
        model.preferredLanguage = language;
        model.nextReportDate = undefined;
        model.frequency = ABUSCloudPortalDexitBatteryReportReportFrequency.EveryMonth;
        model.notiRepo = new DexitNotificationsRepository(getPortalConfiguration());
        return model;
    }

    @action
    public updateFromJSON(portalState: IBatteryReportConfigurationState) {
        this.name = portalState.name;
        this.enabled = portalState.enabled;
        this.recipients = portalState.recipients ? portalState.recipients : [];
        this.deviceId = portalState.deviceId;
        this.frequency = portalState.frequency;
        this.nextReportDate = portalState.nextReportDate! ? portalState.nextReportDate! : undefined;
        return this;
    }

    @action
    public async changeRecipientsAndSave(newRecipients: string[]) {
        this.recipients = newRecipients.filter((v, i, a) => a.indexOf(v) === i);
        if (newRecipients.length === 0) {
            await this.changeEnabled(false);
        } else {
            this.createSnapshot();
            if (this.enabled === false) {
                this.enabled = true;
            }
            await this.notiRepo?.saveNotificationSetting(this.toJSON());
            await this.saveAndUpdateNextReportDate();
        }
    }

    @action
    public async changeEnabled(enabled: boolean) {
        super.createSnapshot();
        this.enabled = enabled;

        try {
            if (this.validate() === null) {
                if (enabled === false) {
                    this.recipients = [];
                    await this.saveNotiConfigurationDeletion();
                    await this.saveAndUpdateNextReportDate();
                    return;
                }

                if (this.recipients.length !== 0) {
                    this.saveAndUpdateNextReportDate();
                    await this.notiRepo?.saveNotificationSetting(this.toJSON());
                    return;
                }
                this.saveAndUpdateNextReportDate();
            }
        } catch (e : any) {
            defaultLogger.error('notification setting delete failed', e);
            this.apiError(e, 'enabling battery report failed');
        }
    }

    @action
    public async changeBatteryReportInterval(newFrequency: ABUSCloudPortalDexitBatteryReportReportFrequency) {
        try {
            this.frequency = newFrequency;
            if (this.validate() === null) {
                this.saveAndUpdateNextReportDate();
                this.validate();
            }
        } catch (error) {
            defaultLogger.error('update battery report interval failed', error);
            this.apiError(error, 'update battery report interval failed');
        }
    }

    private async saveAndUpdateNextReportDate() {
        try {
            if (this.validate() === null) {
                const batteryReportRepository = new BatteryReportRepository(getPortalConfiguration());
                const savedReportConfiguration = await batteryReportRepository.saveBatteryReportConfiguration(this.toJSON());
                this.nextReportDate = savedReportConfiguration?.nextReportDate;
            }
        } catch (e : any) {
            defaultLogger.error('saveAndUpdateNextReportDate failed', e);
            this.apiError(e, 'saveAndUpdateNextReportDate failed');
        }
    }

    protected async saveNotiConfigurationDeletion() {
        try {
            if (this.validate() === null) {
                await this.notiRepo!.deleteNotificationSetting(this.toJSON());
            }
        } catch (e : any) {
            defaultLogger.error('saveNotiConfigurationDeletion failed', e);
            this.apiError(e, 'saveNotiConfigurationDeletion failed');
        }
    }

    public toJSON(): IBatteryReportConfigurationState {
        return {
            enabled: this.enabled,
            deviceId: this.deviceId,
            name: this.name,
            recipients: this.recipients,
            state: this.state,
            nextReportDate: this.nextReportDate,
            frequency: this.frequency
        };
    }
}