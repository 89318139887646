import { TFunction } from 'i18next';
import moment from 'moment';
import { DeviceLogsModel } from '../stores/deviceStore/deviceLogsModel';

export function createExportHeader(projectTitle: string | undefined, logs: DeviceLogsModel | undefined, t: TFunction) {
    const loggroups: string[] = (logs?.groupNames || []);
    if (loggroups.length === 0) {
        loggroups.push('-');
    }
    const header: Array<Array<string>> = [
        [t('Logs.Export.Header.Project'), projectTitle || t('n/a')],
        [t('Logs.Export.Header.ExportDate'), moment().format('MMMM Do YYYY, h:mm:ss a')],
        [t('Logs.Export.Header.ActiveFilter')],
        [t('Logs.Export.Header.GroupNames'), ...loggroups],
        [t('Logs.Export.Header.FromDate'), logs?.fromDate?.toJSON() || '-'],
        [t('Logs.Export.Header.ToDate'), logs?.toDate?.toJSON() || '-']
    ];

    if ((logs?.totalCount ?? 0) > 500) {
        header.push([t('Logs.Export.Header.ExportLimit', { limit: 500, total: logs?.totalCount })]);
    }

    return header;
}