import { ABUSCloudPortalCommonFeaturesFirmwareFirmwareReleaseData, ABUSCloudPortalCommonFeaturesFirmwareReleaseState } from 'portal-bff-proxy-ts';
import { ReleaseState, IFirmwareRowModelState } from './firmwareRowModel';

export const toReleaseState = (rs: ABUSCloudPortalCommonFeaturesFirmwareReleaseState | undefined): ReleaseState => {
    switch (rs) {
    case ABUSCloudPortalCommonFeaturesFirmwareReleaseState.Reported:
        return ReleaseState.Reported;
    case ABUSCloudPortalCommonFeaturesFirmwareReleaseState.Publishing:
        return ReleaseState.Publishing;
    case ABUSCloudPortalCommonFeaturesFirmwareReleaseState.Published:
        return ReleaseState.Published;
    default:
        return ReleaseState.Reported;
    }
};

export const fromDtoToFirmwareRowModelState = (dto: ABUSCloudPortalCommonFeaturesFirmwareFirmwareReleaseData): IFirmwareRowModelState => {
    const state = {
        id: dto.releaseId!,
        version: dto.version!,
        date: dto.releaseDate,
        publishState: toReleaseState(dto.releaseState),
        deviceCount: dto.deviceCount!,
        typeId: dto.typeId?.toString(),
        isLatest: dto.isLatest
    };
    return state;
};
