import React from 'react';
import { Trans } from 'react-i18next';
import { I18NEXT_SEARCH_NAMESPACE } from '../constants';

export const firmwareOutdatedLabelWrapper = (children: React.ReactNode) => {
    return (
        <React.Fragment>
            <span className={'icon-Warning text-danger'}></span>
            <span className={'text-danger'}>{children}</span>
        </React.Fragment>
    );
};

export const firmwareOutdatedValueWrapper = (children: React.ReactNode) => {
    return (
        <React.Fragment>
            {children}
            <b> <Trans ns={I18NEXT_SEARCH_NAMESPACE}>Devices.FirmwareOutdated</Trans></b>
        </React.Fragment>
    );
};