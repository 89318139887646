import { action, computed, observable, toJS } from 'mobx';
import SearchRepository from './searchRepository';
import { getPortalConfiguration } from '../../getPortalConfiguration';
import { getViewStore } from '../../storeRegistry';
import { SearchResultModel, SearchResultModelState } from './searchResultState';
import { IModelWithTimeUpdates } from '../../common/stores/modelWithTimeUpdates.interface';
import { ViewStore } from '../../common/stores/viewStore';
import { isServer } from '../../../isServer';
import { ABUSCloudPortalCommonFeaturesSearchSearchResultType } from 'portal-bff-proxy-ts';
import { KnownRoles } from '../../common/routing/knownroles';

export interface ISearchStoreState {
    searchResults: SearchResultModelState[] | null;
    isSynced: boolean;
    searchQuery: string;
}

class SearchStore implements IModelWithTimeUpdates {
    private _viewStore?: ViewStore;

    @observable
    public searchResults: SearchResultModel[] | null = null;

    @observable
    public totalCount = 0;

    @observable
    public isSynced = false;

    @observable
    public searchQuery = '';

    @observable
    public customerSearchFilter: number | undefined;

    @observable
    public showOnlyDevicesWithOutdatedFirmware = false;

    @observable
    public numberOfSearchResultsPerPage = 25;

    @observable
    public searchResultOffset = 0;

    @observable
    public searchResultType? = ABUSCloudPortalCommonFeaturesSearchSearchResultType.Devices;

    @observable
    public showOnlyDevices = true;

    @observable
    public showOnlyResellerProfiles = true;

    @observable
    public filteredCompanyName = '';

    private searchRepository: SearchRepository;

    constructor(state?: ISearchStoreState) {
        this.searchRepository = new SearchRepository(getPortalConfiguration());
        this.updateFromJSON(state);
    }

    public timeChanged(): void {
        if (this.searchResults != null) {
            this.searchResults.forEach(sc => {
                sc.timeChanged();
            });
        }
    }

    @computed get canUserFilterForDevicesAndResellers(): boolean {
        return this.viewStore.user.hasRoles(KnownRoles.ProductManager) || this.viewStore.user.hasRoles(KnownRoles.TechnicalSupport);
    }

    public get viewStore(): ViewStore {
        if (this._viewStore == null) {
            this._viewStore = getViewStore();
        }
        return this._viewStore;
    }

    @action
    public setSearchResultType(searchResultType: ABUSCloudPortalCommonFeaturesSearchSearchResultType): void {
        if (searchResultType === this.searchResultType) {
            if (searchResultType === ABUSCloudPortalCommonFeaturesSearchSearchResultType.Devices) {
                this.searchResultType = ABUSCloudPortalCommonFeaturesSearchSearchResultType.ResellerProfiles;
            } else {
                this.searchResultType = ABUSCloudPortalCommonFeaturesSearchSearchResultType.Devices;
            }
        } else {
            this.searchResultType = searchResultType;
        }
        this.search(true);
    }

    @action
    public async sync(): Promise<void> {
        if (this.isSynced && this.searchResults != null) {
            this.runSearchPeriodically(getViewStore());
            return;
        }
        const unlockFunction = getViewStore().lockUI();
        try {
            const data = await this.searchRepository.query(
                this.searchQuery,
                this.customerSearchFilter,
                this.showOnlyDevicesWithOutdatedFirmware,
                this.numberOfSearchResultsPerPage,
                this.searchResultOffset,
                this.searchResultType
            );
            this.setSearchResult(data.searchResults);
            this.totalCount = data.totalCount;
            this.isSynced = true;
            this.runSearchPeriodically(getViewStore());
            return;
        } catch (err) {
            this.handleApiError();
            return;
        } finally {
            unlockFunction();
        }
    }

    private async setSearchResult(searchResultsStates: SearchResultModelState[] | undefined | null) {
        if (searchResultsStates != null) {
            const newModels = searchResultsStates.map((state) => new SearchResultModel(state));
            this.searchResults = newModels;
        }
    }

    @action
    public async search(runPeriodically = false): Promise<void> {
        try {
            const data = await this.searchRepository.query(
                this.searchQuery,
                this.customerSearchFilter,
                this.showOnlyDevicesWithOutdatedFirmware,
                this.numberOfSearchResultsPerPage,
                this.searchResultOffset,
                this.searchResultType
            );
            this.setSearchResult(data.searchResults);
            this.totalCount = data.totalCount;
            this.isSynced = true;
            if (runPeriodically === true) {
                this.runSearchPeriodically(getViewStore());
            }
            return;
        } catch (err) {
            this.handleApiError();
        }
    }

    private runSearchPeriodically(viewStore: ViewStore) {
        if (!isServer()) {
            viewStore.removeTimers();
            const timerId = setInterval(() => {
                this.search(false);
            }, 10000);
            viewStore.addTimer(timerId);
        }
    }

    @action
    public async updateSearchQuery(newValue: string) {
        this.searchQuery = newValue;
        getViewStore().removeTimers();
    }

    @action
    public async updateCustomerSearchFilter(newValue: number) {
        this.customerSearchFilter = newValue;
        this.updateSearchQuery('');
        this.search(true);
    }

    @action
    public async updateFilteredCompanyName(newValue: string) {
        this.filteredCompanyName = newValue;
    }

    private handleApiError() {
        const viewStore = getViewStore();
        viewStore.removeTimers();
    }

    @action.bound
    async setOffset(offset: number) {
        this.searchResultOffset = offset;
        this.search(true);
    }

    @action.bound
    async setNumberOfSearchResultsPerPage(numberOfSearchResultsPerPage: number) {
        this.numberOfSearchResultsPerPage = numberOfSearchResultsPerPage;
        this.search(true);
    }

    public toJSON(): ISearchStoreState {
        const exportState: ISearchStoreState = {
            searchResults: this.searchResults ? this.searchResults.map((x) => x.toJSON()) : null,
            isSynced: this.isSynced,
            searchQuery: this.searchQuery
        };
        return toJS(exportState);
    }

    public updateFromJSON(initialState?: ISearchStoreState) {
        if (initialState != null) {
            this.setSearchResult(initialState.searchResults);
            this.isSynced = initialState.isSynced;
            this.searchQuery = initialState.searchQuery;
        }
    }
}

export default SearchStore;