import React from 'react';
import { useObserver } from 'mobx-react';
import { Skeleton } from '../../common/components/skeleton';
import { DeviceComponentTileBody } from './deviceComponentTileBody';
import './deviceComponentTile.scss';

const numberOfSkeletonRows = 6;

export interface IDeviceComponentTileProps {
    componentTileHeader: React.ReactNode;
    componentTileIcon: React.ReactNode;
    componentTileBodyElements: string[] | undefined;
    className?: string;
}

export const DeviceComponentTile = (props: IDeviceComponentTileProps) => {
    return useObserver(() => {
        return (
            <div className="device-component-tile">
                <div className={`${props.className || ''} device-component-tile--inner d-flex flex-column`}>
                    {props.componentTileHeader ? props.componentTileHeader : <Skeleton style={{ margin: 10, height: 14 }} />}
                    <div className="h-100 d-flex align-items-center">
                        <div className="col-4 deviceComponenttypeIcon">
                            {props.componentTileIcon ? props.componentTileIcon : <Skeleton circle={true} style={{ width: 100, height: 100 }} />}
                        </div>
                        <div className="col-8">
                            <DeviceComponentTileBody elements={props.componentTileBodyElements} numberOfSkeletonRows={numberOfSkeletonRows} />
                        </div>
                    </div>
                </div>
            </div>
        );
    });
};