import { action, computed, observable } from 'mobx';
import { ConfirmResult, IConfirmOptions } from '../../../common/components/confirmation/confirm';
import { BaseChildModelStore } from '../../../common/stores/baseChildModelStore';
import { getViewStore } from '../../../storeRegistry';
import { DeviceModel } from './deviceModel';

export interface IAppPairingModelState {
    deviceId: string;
    pairingDateList: string[];
    email: string;
}

export class AppPairingModel extends BaseChildModelStore<IAppPairingModelState, DeviceModel> {
    @observable
    public deviceId = '';

    @observable
    public pairingDateList: Date[] = [];
    
    @observable
    public email = '';

    @computed
    public get pairingsCount() {
        return this.pairingDateList.length;
    }

    constructor(state: IAppPairingModelState, parent: DeviceModel) {
        super(parent);
        this.updateFromJSON(state);
    }

    @action.bound
    public async delete(confirmOptions: IConfirmOptions) {
        const viewStore = getViewStore();
        const result = await viewStore.dialog.showConfirm(confirmOptions);
        if (result === ConfirmResult.Ok) {
            this.parent.deleteAppPairing(this);
        }
    }
    
    @action.bound
    updateFromJSON(state: IAppPairingModelState) {
        this.deviceId = state.deviceId;
        this.email = state.email;
        this.pairingDateList = state.pairingDateList.map(x => new Date(x));
        return this;
    }

    @action.bound
    toJSON(): IAppPairingModelState {
        return {
            deviceId: this.deviceId,
            email: this.email,
            pairingDateList: this.pairingDateList.map(x => x.toJSON())
        };
    }
}