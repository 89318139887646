import { toJS, observable } from 'mobx';

export interface IUserSettingsModel {
    companyId?: string;
    language?: string;
}

export class UserSettingsModel implements IUserSettingsModel {
    public companyId?: string = '';

    @observable
    public language?: string = '';

    public updateFromJson(obj: IUserSettingsModel) {
        this.companyId = obj.companyId;
        this.language = obj.language;
    }

    public toJSON(): IUserSettingsModel {
        const json = toJS({
            companyId: this.companyId,
            language: this.language
        });
        return json;
    }
}