import React, { useState } from 'react';
import { ModalPopup } from '../../common/components/modalPopup/modalPopup';
import { FirmwareStore } from '../store/firmwareStore';
import { FirmwareRowModel } from '../store/firmwareRowModel';
import DexitFirmwareReleaseData from './DexitFirmwareReleaseData';
import { getViewStore } from '../../storeRegistry';
import { inject } from 'mobx-react';
import { Stores } from '../../common/stores/stores';
import { withTranslation } from 'react-i18next';
import './DexitFirmwarePublishModal.scss';
import ProgressBar from '../ProgressBar';

interface DexitFirmwarePublishModalProps {
  firmware: FirmwareRowModel | undefined;
  isOpen: boolean;
  closeRequested: () => void;
  t: (key: string) => string;
  uploadProgress: number;
  firmwareStore: FirmwareStore | undefined;
}

const DexitFirmwarePublishModal: React.FC<DexitFirmwarePublishModalProps> = ({ isOpen, closeRequested, t, uploadProgress, firmwareStore }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [progressBarVisible, setProgressBarVisible] = useState<boolean>(false);
    const handleFirmwareFileChange = (event:any) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const setCloseRequested = () => {
        closeRequested();
        firmwareStore!.cancelLongRequest();
    };

    const viewStore = getViewStore();

    return (
        <React.Fragment>
            <ModalPopup
                size='xl'
                isOpen={isOpen}
                closeRequested={closeRequested}
                title={t('Dexit.PublishModal.Title')}>

                <p> {t('Dexit.PublishModal.Description')}</p>

                <form onSubmit={async (e) => {
                    e.preventDefault();
                    
                    if (selectedFile) {
                        setProgressBarVisible(true);
                        const result = await firmwareStore!.createNewDexitFirmwareRelease(new DexitFirmwareReleaseData(
                                firmwareStore!.selectedFirmwareType!.id,
                                selectedFile!));
                        if (result) {
                            viewStore.notifySuccess(t('Common.PublishModal.Success'));
                            closeRequested();
                        }
                        setProgressBarVisible(false);
                    } else {
                        setProgressBarVisible(false);
                        viewStore.notifyError(t('Common.PublishModal.FillOutAllFields'), '');
                    }
                }}>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">{t('Dexit.PublishModal.Form.SelectFirmware')} </label>
                        <div className="col-sm-7">
                            <input type="file" className="form-control-file" id="firmwareFile" accept='.img' onChange={handleFirmwareFileChange} />
                        </div>
                    </div>
                    
                    { !progressBarVisible && (<button type="button" className="btn btn-secondary" onClick={setCloseRequested}>{t('Dexit.PublishModal.Form.Close')} </button>)}
                    {progressBarVisible && (<button type="button" className="btn btn-warning" onClick={firmwareStore!.cancelLongRequest}>{t('Dexit.PublishModal.Form.Cancel')} </button>)}
                    <button type="submit" className="btn btn-primary">{t('Dexit.PublishModal.Publish')}</button>

                    {progressBarVisible && (<ProgressBar width="100%" height="30px" percentage={uploadProgress} />)}

                </form>
                
            </ModalPopup>
        </React.Fragment>
    );
};

const withHoC = inject(Stores.FirmwareStore, Stores.ViewStore)(withTranslation('portal_firmware')(DexitFirmwarePublishModal));
export { withHoC as DexitFirmwarePublishModal };
