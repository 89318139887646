import * as proxy from 'portal-bff-proxy-ts';
import * as deviceModel from '../deviceContracts';
import { Helper } from '../../../../common';
import { DexitComponentType } from '../deviceContracts';

const mapCylinderType = (dtoCylinderComponentType: proxy.ABUSCloudPortalDexitSettingsCylinderComponentTypes | undefined): deviceModel.CylinderType => {
    if (dtoCylinderComponentType) {
        return dtoCylinderComponentType;
    }
    return 'Unknown';
};

const mapBatteryState = (dtoBatteryState: proxy.ABUSCloudPortalDexitSettingsBatteryState | undefined): deviceModel.BatteryState => {
    if (dtoBatteryState) {
        return dtoBatteryState;
    }
    return 'Unknown';
};

const mapSignalState = (dtoSignalState: proxy.ABUSCloudPortalDexitSettingsSignalState | undefined): deviceModel.SignalState => {
    switch (dtoSignalState) {
    case proxy.ABUSCloudPortalDexitSettingsSignalState.Excellent: {
        return deviceModel.SignalState.Excellent;
    }
    case proxy.ABUSCloudPortalDexitSettingsSignalState.Good: {
        return deviceModel.SignalState.Good;
    }
    case proxy.ABUSCloudPortalDexitSettingsSignalState.Poor: {
        return deviceModel.SignalState.Poor;
    }
    case proxy.ABUSCloudPortalDexitSettingsSignalState.Unknown:
    default:
        return deviceModel.SignalState.Unknown;
    }
};

export const mapToCylinderComponent = (d: proxy.ABUSCloudPortalDexitSettingsCylinderDto): deviceModel.ICylinderDeviceComponent => {
    const result: deviceModel.ICylinderDeviceComponent = {
        deviceId: Helper.getStringValueOrDefault(d.deviceId),
        id: Helper.getStringValueOrDefault(d.id),
        name: Helper.getStringValueOrDefault(d.name),
        disabled: !!d.disabled,
        componentType: deviceModel.DeviceComponentType.Cylinder,
        dateOfProduction: d.dateOfProduction?.toString() ?? null,
        firmwareVersion: Helper.getStringValueOrDefault(d.firmwareVersion),
        signalStrength: Helper.getNumberValueOrDefault(d.signalStrength),
        signalState: mapSignalState(d.signalState),
        batteryState: mapBatteryState(d.batteryState),
        type: mapCylinderType(d.type),
        armEnabled: d.armEnabled!,
        learnEnabled: d.learnEnabled!,
        lastBatteryChange: Helper.getStringValueOrDefault(d.lastBatteryChange?.toString())
    };
    return result;
};

const mapDexitConnectionStatus = (dtoConnectionStatus: proxy.ABUSCloudPortalDexitMessagesDexitConnectionState | undefined): deviceModel.ConnectionStatus => {
    const map: { [dto: string]: number } = {
        [proxy.ABUSCloudPortalDexitMessagesDexitConnectionState.Connected]: deviceModel.ConnectionStatus.Established,
        [proxy.ABUSCloudPortalDexitMessagesDexitConnectionState.Disconnected]: deviceModel.ConnectionStatus.Lost,
        [proxy.ABUSCloudPortalDexitMessagesDexitConnectionState.Unknown]: deviceModel.ConnectionStatus.Unknown
    };

    return map[dtoConnectionStatus || DexitComponentType.Unknown];
};

const mapToRepeaterComponent = (d: proxy.ABUSCloudPortalDexitSettingsRepeaterDto): deviceModel.IRepeaterDeviceComponent => {
    const result: deviceModel.IRepeaterDeviceComponent = {
        deviceId: Helper.getStringValueOrDefault(d.deviceId),
        id: Helper.getStringValueOrDefault(d.id),
        name: Helper.getStringValueOrDefault(d.name),
        disabled: d.disabled ?? false,
        componentType: deviceModel.DeviceComponentType.Repeater,
        dateOfProduction: Helper.getStringValueOrDefault(d.dateOfProduction?.toString()),
        firmwareVersion: Helper.getStringValueOrDefault(d.firmwareVersion),
        signalStrength: Helper.getNumberValueOrDefault(d.signalStrength),
        signalState: mapSignalState(d.signalState),
        hasBattery: d.hasBattery ?? false,
        connectedComponents: Helper.getNumberValueOrDefault(d.connectedComponents)
    };
    return result;
};

const mapToCameraComponent = (d: proxy.ABUSCloudPortalDexitSettingsCameraDto): deviceModel.ICameraDeviceComponent => {
    const result: deviceModel.ICameraDeviceComponent = {
        deviceId: Helper.getStringValueOrDefault(d.deviceId),
        id: Helper.getStringValueOrDefault(d.id),
        name: Helper.getStringValueOrDefault(d.name),
        disabled: d.disabled ?? false,
        componentType: deviceModel.DeviceComponentType.Camera,
        internalHttpPort: Helper.getNumberValueOrDefault(d.internalHttpPort),
        internalIpAddr: Helper.getStringValueOrDefault(d.internalIpAddr),
        internalRecordingPort: Helper.getNumberValueOrDefault(d.internalRecordingPort),
        internalRtspPort: Helper.getNumberValueOrDefault(d.internalRtspPort),
        wapploxxId: d.wapploxxId,
        wapploxxName: d.wapploxxName,
        connectionStatus: mapDexitConnectionStatus(d.connectionStatus)
    };
    return result;
};

const mapToWallreaderComponent = (source: proxy.ABUSCloudPortalDexitSettingsWallreaderDto): deviceModel.IWallreaderDeviceComponent => {
    const result: deviceModel.IWallreaderDeviceComponent = {
        id: Helper.getStringValueOrDefault(source.id),
        deviceId: Helper.getStringValueOrDefault(source.deviceId),
        name: Helper.getStringValueOrDefault(source.name),
        disabled: source.disabled ?? false,
        componentType: deviceModel.DeviceComponentType.Wallreader,
        signalState: mapSignalState(source.signalState),
        signalStrength: Helper.getNumberValueOrDefault(source.signalStrength),
        firmwareVersion: Helper.getStringValueOrDefault(source.firmwareVersion),
        dateOfProduction: Helper.getStringValueOrDefault(source.dateOfProduction?.toString())
    };
    return result;
};

const mapSlaveConnectionStatus = (dtoConnectionStatus: proxy.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus | undefined): deviceModel.ConnectionStatus => {
    const map: { [dto: string]: number } = {
        [proxy.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus.Connected]: deviceModel.ConnectionStatus.Established,
        [proxy.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus.Connectionlost]: deviceModel.ConnectionStatus.Lost,
        [proxy.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus.UnknownStatus]: deviceModel.ConnectionStatus.Unknown,
        [proxy.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus.Connectedbutwitherrors]: deviceModel.ConnectionStatus.Unknown,
        [proxy.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus.Deslaved]: deviceModel.ConnectionStatus.Unknown,
        [proxy.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus.Connecting]: deviceModel.ConnectionStatus.Unknown,
        [proxy.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus.Disconnecting]: deviceModel.ConnectionStatus.Unknown
    };

    return map[dtoConnectionStatus || proxy.ABUSCloudPortalDexitSettingsCylinderComponentTypes.Unknown];
};

const mapToSlaveComponent = (d: proxy.ABUSCloudPortalDexitSettingsSlaveDto): deviceModel.ISlaveComponent => {
    const result: deviceModel.ISlaveComponent = {
        connectionState: mapSlaveConnectionStatus(d.statusSlave),
        firmwareVersion: Helper.getStringValueOrDefault(d.firmwareVersion),
        ipv4Addr: Helper.getStringValueOrDefault(d.ipv4Addr),
        serialNumber: Helper.getStringValueOrDefault(d.serialNumber),
        connectedComponents: Helper.getNumberValueOrDefault(d.connectedComponents),
        deviceId: Helper.getStringValueOrDefault(d.deviceId),
        id: Helper.getStringValueOrDefault(d.id),
        componentType: deviceModel.DeviceComponentType.Slave,
        disabled: d.disabled ?? false,
        name: Helper.getStringValueOrDefault(d.name)
    };
    return result;
};

export const mapDeviceComponentsDto = (d: proxy.ABUSCloudPortalDexitSettingsDexitComponentsDto): deviceModel.IDeviceComponents => {
    const map: { [componentType: string]: ((d: any) => deviceModel.IDeviceComponent) } = {
        [DexitComponentType.Cylinder]: mapToCylinderComponent,
        [DexitComponentType.Wallreader]: mapToWallreaderComponent,
        [DexitComponentType.Repeater]: mapToRepeaterComponent,
        [DexitComponentType.Camera]: mapToCameraComponent,
        [DexitComponentType.Slave]: mapToSlaveComponent
    };
    const components: deviceModel.IDeviceComponent[] =
        d.components?.filter((dto) => map[dto.componentType!] !== undefined)
            .map((dto) => map[dto.componentType!](dto)) ?? [];

    const result: deviceModel.IDeviceComponents = {
        totalDeviceComponentsCount: d.components!.length!,
        deviceComponents: components
    };
    return result;
};