import { IEnvironmentStamping, IPortalConfiguration, IPortalConfigurationExport } from '../client/common/interfaces';
import { getEnvConfig } from './getEnvConfig';
import { ExpressHttpContextAdapter } from './expressHttpContextAdapter';

class ServerPortalConfiguration implements IPortalConfiguration {
    public get accessToken(): string | undefined {
        const accessToken = ExpressHttpContextAdapter.getAccessToken();
        return accessToken;
    }

    private config = getEnvConfig();

    public readonly clientId: string;
    public readonly loginBaseUrl: string;
    public readonly envStamping: IEnvironmentStamping;
    constructor() {
        this.clientId = this.config.AUTH0.CLIENTID;
        this.loginBaseUrl = `https://${this.config.AUTH0.DOMAIN}`;
        this.envStamping = {
            color: this.config.ENV.UI.COLOR,
            logo: this.config.ENV.UI.LOGO,
            name: this.config.ENV.UI.NAME
        };
    }

    public scsEndpoint(): string {
        return this.config.INTERNAL_BFF_BASE_URL!;
    }

    public bffEndpoint(): string {
        return this.config.INTERNAL_BFF_BASE_URL!;
    }

    public toExternal(): IPortalConfigurationExport {
        return {
            clientId: this.clientId,
            envStamping: this.envStamping,
            loginBaseUrl: this.loginBaseUrl
        };
    }
}

export const getServerPortalConfiguration = (): IPortalConfiguration => {
    return new ServerPortalConfiguration();
};