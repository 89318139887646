import * as proxy from 'portal-bff-proxy-ts';
import * as deviceModel from '../deviceContracts';
import { DeviceSubtype, DeviceType } from '../deviceContracts';
import { Helper } from '../../../../common';
import { IOperatorMetadataState } from '../operatorMetadataModel';
import { IBatteryReportConfigurationState } from '../../../systemServices/batteryReport/batteryReportConfigurationModel';
import { IAppPairingModelState } from '../appPairingModel';
import { DeviceAccessState, IDeviceAccessModel } from '../deviceAccessModel';
import { IDexitModelState } from '../dexitDeviceModel';
import { IHyenDeviceModelState } from '../hyenDeviceModel';
import { IComfionModelState } from '../comfionDeviceModel';

export const mapDeviceAccessState = (dtoDeviceAccessState: proxy.ABUSCloudPortalCommonFeaturesAccessDeviceAccessState | undefined): DeviceAccessState => {
    if (dtoDeviceAccessState) {
        return dtoDeviceAccessState;
    }
    return 'None';
};

export const mapDeviceAccessDtoToDeviceAccessModel = (c: proxy.ABUSCloudPortalCommonFeaturesAccessDeviceAccessDto): IDeviceAccessModel => {
    return {
        accessValidUntil: Helper.getStringValueOrDefault(c.accessValidUntil),
        approvingUserEmail: Helper.getStringValueOrDefault(c.approvingUserEmail),
        accessValidUntilMaximumTime: Helper.getStringValueOrDefault(c.accessValidUntilMaximumTime),
        requestValidUntil: Helper.getStringValueOrDefault(c.requestValidUntil),
        requestingUserEmail: Helper.getStringValueOrDefault(c.requestingUserEmail),
        state: mapDeviceAccessState(c.state)
    };
};

export const mapDeviceMetadataDto = (d: proxy.ABUSCloudPortalCommonFeaturesResellerDeviceOperatorMetadataDto): IOperatorMetadataState => {
    const result: IOperatorMetadataState = {
        registeredDeviceId: d.registeredDeviceId,
        firstName: Helper.getStringValueOrDefault(d.firstName),
        lastName: Helper.getStringValueOrDefault(d.lastName),
        email: Helper.getStringValueOrDefault(d.email?.toString()),
        phone: Helper.getStringValueOrDefault(d.phone),
        street: Helper.getStringValueOrDefault(d.street),
        houseNumber: Helper.getStringValueOrDefault(d.houseNumber),
        city: Helper.getStringValueOrDefault(d.city),
        postalCode: Helper.getStringValueOrDefault(d.postalCode),
        projectName: Helper.getStringValueOrDefault(d.projectName),
        installationDate: Helper.getStringValueOrDefault(d.installationDate?.toString())
    };
    return result;
};

export const mapDexitDeviceDto = (d: proxy.ABUSCloudPortalDexitSettingsDexitSettingsDto): IDexitModelState => {
    const result: IDexitModelState = {
        deviceId: Helper.getStringValueOrDefault(d.id),
        deviceName: Helper.getStringValueOrDefault(d.deviceName),
        firmwareVersion: Helper.getStringValueOrDefault(d.firmwareVersion),
        state: d.status === proxy.ABUSCloudPortalDexitMessagesDexitConnectionState.Connected ? deviceModel.DeviceState.Connected : deviceModel.DeviceState.Disconnected,
        type: DeviceType.Dexit,
        lastActive: d.lastActive,
        internalIpAddress: Helper.getStringValueOrDefault(d.internalIpAddress),
        serialNumber: Helper.getStringValueOrDefault(d.serialNumber),
        deviceComponents: null,
        systemServiceConfigurations: null,
        countCameras: Helper.getStringValueOrDefault(d.countCameras),
        countCylinders: Helper.getStringValueOrDefault(d.countCylinders),
        countRepeaters: Helper.getStringValueOrDefault(d.countRepeaters),
        countWallreaders: Helper.getStringValueOrDefault(d.countWallreaders),
        countSlaves: Helper.getStringValueOrDefault(d.countSlaves),
        deviceAccess: null,
        operatorMetadata: null,
        lastFirmwareUpdate: d.lastFirmwareUpdate,
        existingTags: Helper.getNumberValueOrDefault(d.existingTags),
        existingUsers: Helper.getNumberValueOrDefault(d.existingUsers),
        wlxManagerDeeplink: Helper.getStringValueOrDefault(d.wlxManagerDeeplink),
        deviceSubType: undefined
    };
    return result;
};

export const mapHyenDeviceDto = (d: proxy.ABUSCloudPortalHyenSettingsHyenSettingsDto): IHyenDeviceModelState => {
    const result: IHyenDeviceModelState = {
        deviceId: Helper.getStringValueOrDefault(d.id),
        deviceName: Helper.getStringValueOrDefault(d.deviceName),
        firmwareVersion: Helper.getStringValueOrDefault(d.firmwareVersion),
        state:
            d.status === proxy.ABUSCloudPortalHyenMessagesDeviceConnectionState.Connected
                ? deviceModel.DeviceState.Connected
                : d.status === proxy.ABUSCloudPortalHyenMessagesDeviceConnectionState.Calling
                    ? deviceModel.DeviceState.Calling
                    : deviceModel.DeviceState.Disconnected,
        type: DeviceType.Hyen,
        internalIpAddress: Helper.getStringValueOrDefault(d.internalIpAddress),
        serialNumber: Helper.getStringValueOrDefault(d.serialNumber),
        deviceComponents: null,
        systemServiceConfigurations: null,
        deviceAccess: null,
        operatorMetadata: null,
        siteId: d.siteId ?? null,
        connectionType: d.connectionType?.toLowerCase() === '4g' ? 'Mobile' : d.connectionType ?? null,
        zonesAvailable: d.zonesAvailable ?? null,
        zonesUsed: d.zonesUsed ?? null,
        hardwareExpanders: d.hardwareExpanders ?? null,
        rfExpanders: d.rfExpanders ?? null,
        countCameras: '',
        countCylinders: '',
        countRepeaters: '',
        countWallreaders: '',
        countSlaves: '',
        keypads: d.keypads ?? null,
        psuExpanders: d.psuExpanders ?? null,
        macAddress: d.macAddress ?? null,
        deviceSubType: d.subType === 'ESEZ60500' ? DeviceSubtype.HyenEN2 : DeviceSubtype.HyenEN3
    };
    return result;
};

export const mapComfionDeviceDto = (d: proxy.ABUSCloudPortalComfionSettingsComfionSettingsDto): IComfionModelState => {
    const result: IComfionModelState = {
        deviceId: Helper.getStringValueOrDefault(d.deviceId),
        deviceName: Helper.getStringValueOrDefault(d.deviceName),
        firmwareVersion: Helper.getStringValueOrDefault(d.firmwareVersion),
        state: d.isOnline ? deviceModel.DeviceState.Connected : deviceModel.DeviceState.Disconnected,
        type: DeviceType.Comfion,
        internalIpAddress: Helper.getStringValueOrDefault(d.ipAddress),
        serialNumber: Helper.getStringValueOrDefault(d.serialNumber),
        deviceComponents: null,
        systemServiceConfigurations: null,
        deviceAccess: null,
        operatorMetadata: null,
        availableZones: d.availableZones ?? null,
        zonesUsed: d.usedZones ?? null,
        macAddress: d.macAddress ?? null,
        countCameras: '',
        countCylinders: '',
        countRepeaters: '',
        countWallreaders: '',
        countSlaves: '',
        deviceSubType: undefined,
        numberOfAutomations: d.numberOfAutomations ?? null,
        numberOfUsers: d.numberOfUsers ?? null,
        remoteAccessUrl: d.remoteAccessUrl ?? null
    };
    return result;
};

export const mapAppPairingDto = (d: proxy.ABUSCloudPortalHyenAppPairingAppPairingDto): IAppPairingModelState => {
    const result: IAppPairingModelState = {
        deviceId: Helper.getStringValueOrDefault(d.deviceId),
        email: Helper.getStringValueOrDefault(d.email),
        pairingDateList: [Helper.getStringValueOrDefault(d.pairingDate)]
    };
    return result;
};

export const mapAppPairingDtoList = (d: proxy.ABUSCloudPortalHyenAppPairingAppPairingDto[]): IAppPairingModelState[] => {
    return d.map(mapAppPairingDto);
};

export const mapDeviceMetadataStateToDto = (deviceId: string, d: IOperatorMetadataState): proxy.ABUSCloudPortalCommonFeaturesResellerDeviceOperatorMetadataDto => {
    return {
        firstName: d.firstName,
        lastName: d.lastName,
        email: d.email as any,
        phone: d.phone,
        street: d.street,
        houseNumber: d.houseNumber,
        city: d.city,
        postalCode: d.postalCode,
        projectName: d.projectName,
        installationDate: d.installationDate
    };
};

export const mapDtoToIBatteryReportConfigurationModel = (setting: proxy.ABUSCloudPortalDexitBatteryReportReportScheduleDto): IBatteryReportConfigurationState => {
    return {
        frequency: setting.frequency!,
        nextReportDate: Helper.getDateValueOrUndefined(setting.nextRun?.toString()),
        deviceId: Helper.getStringValueOrDefault(setting.deviceId),
        name: 'dexit_battery_report',
        enabled: setting.enabled!,
        recipients: []
    };
};
