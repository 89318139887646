import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LabeledData } from '../../common/components/labeledData/labeledData';
import { useStore } from '../../common/stores/useStore';
import { assertState } from '../../common/utils/modelState';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../../devices';
import { DeviceIcon } from '../../devices/layout/icon/deviceIcon';
import { DeviceState, DeviceType } from '../../devices/stores/deviceStore';

export const AocProperties = () => {
    const { aocStore } = useStore();
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        const { deviceDetails } = aocStore;
        const isLoading = assertState(deviceDetails.state, 'initial', 'loading');
        
        return (
            <React.Fragment>
                <DeviceIcon iconSize={100} deviceType={DeviceType.Aoc} deviceSubType={undefined} deviceState={DeviceState.Connected} />
                <br />
                <LabeledData
                    labelName={t('Devices.DeviceName')}
                    data={deviceDetails.data?.deviceName}
                    showSkeleton={isLoading}
                />
                <LabeledData
                    labelName={t('Devices.SerialNumber')}
                    data={deviceDetails.data?.serialNumber}
                    showSkeleton={isLoading}
                />
                <LabeledData
                    labelName={t('Devices.FirmwareVersion')}
                    data={deviceDetails.data?.firmwareVersion}
                    showSkeleton={isLoading}
                />
                <LabeledData
                    labelName={t('Devices.IPAddress')}
                    data={deviceDetails.data?.ipAddress}
                    showSkeleton={isLoading}
                />
            </React.Fragment>
        );
    });
};