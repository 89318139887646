import './countdown.scss';
import React from 'react';
import moment from 'moment';

const measurements = ['days', 'hours', 'minutes', 'seconds'];
const withPadding = (duration: moment.Duration) => {
    let step: boolean;
    return measurements.map((m) => duration[m]()).filter((n, i, a) => {
        const nonEmpty = Boolean(n);
        if (nonEmpty || step || i >= a.length - 2) {
            step = true;
        }
        return step;
    }).map((n) => ('0' + n).slice(-2)).join(':');
};

export const Countdown = (props: { milliseconds: number, className?: string }) => {
    const duration = moment.duration(props.milliseconds, 'milliseconds');
    return (<span data-testid="test-CountdownComponent">{withPadding(duration)}</span>);
};