import 'reflect-metadata';
import * as React from 'react';
import {
    FormGroup,
    FormText
} from 'reactstrap';
import DateBox, { IDateBoxOptions } from 'devextreme-react/date-box';
import ValidationSummary from 'devextreme-react/validation-summary';
import ValidationGroup from 'devextreme-react/validation-group';
import { Validator } from 'devextreme-react/validator';
import { v4 as uuidv4 } from 'uuid';

import { Skeleton } from '../skeleton';
import { className } from '../../utils/helper';
import validationEngine from 'devextreme/ui/validation_engine';

import './dateTimeInput.scss';

export interface IDateTimeInputProps {
    className?: string;
    placeholder?: string;
    name: string;
    errorMessage?: string[] | null;
    value: Date | undefined;
    help?: string;
    min?: Date;
    max?: Date;
    showClearButton: boolean;
    minErrorMessage?: string;
    maxErrorMessage?: string;
    malformattedErrorMessage?: string;
    onChange?: (name: string, date: Date | undefined) => void;
    onValidation?: (name: string, isValid: boolean, errors?: string[]) => void;
    readOnly?: boolean;
    isLoading?: boolean;
}

const InnerDateTimeSkeleton = () => {
    return (<div className="skeleton-placeholder"><Skeleton className="used" /></div>);
};

const InnerDateTimePicker = (props: IDateTimeInputProps & { id: string }) => {
    const onValueChange = (date?: Date) => {
        if (props.onChange) {
            props.onChange(props.name, date);
        }

        if (props.onValidation) {
            // value change happens only when a valid value was selected from picker
            props.onValidation(props.name, true);
        }

        return date;
    };

    const onChange = () => {
        const result = validationEngine.validateGroup(props.id);
        if (props.onValidation) {
            props.onValidation(props.name, result.isValid!, result.brokenRules?.map((br) => br.message || 'unknown'));
        }
    };

    const additionaloptions: IDateBoxOptions = {};
    if (props.errorMessage && props.errorMessage.length > 0) {
        // must be set here because setting it to undefined results in invalid state,
        // so it must be completly left out for the internal validation
        additionaloptions.isValid = false;
    }

    return (<React.Fragment>
        <DateBox
            value={props.value}
            className={className(props.value ? ' used' : '')}
            showClearButton={props.showClearButton}
            readOnly={props.readOnly}
            calendarOptions={{ validationMessageMode: 'always' }}
            invalidDateMessage={props.malformattedErrorMessage}
            min={props.min}
            max={props.max}
            onValueChanged={(e) => onValueChange(e.value)}
            onChange={() => onChange()}
            validationMessageMode={undefined}
            {...additionaloptions}
        >
            <Validator validationGroup={props.id} />
        </DateBox>
    </React.Fragment>);
};

export interface IDateTimeInputState {
    internalError: string[] | undefined;
    validating: string | undefined;
}

const DateTimeInput = (props: IDateTimeInputProps) => {
    const [id] = React.useState(uuidv4());

    return (
        <FormGroup className={className(props.className, 'abus-datetimeinput-group')}>
            <div className="abus-datetimeinput-group-container">
                <ValidationGroup id={id}>
                    {props.isLoading ? <InnerDateTimeSkeleton /> : <InnerDateTimePicker id={id} {...props} />}
                    <span className="highlight" />
                    <label className={props.value ? 'used' : ''}>{props.placeholder}</label>
                    {props.errorMessage && <div className="invalid-feedback d-block">{props.errorMessage.map((msg, index) => <div key={index}>{msg}</div>)}</div>}
                    <ValidationSummary validationGroup={id} />
                    <FormText>{props.help}</FormText>
                </ValidationGroup>
            </div>
        </FormGroup>
    );
};

export default DateTimeInput;