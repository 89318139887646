import * as React from 'react';

import './screenlockOverlay.scss';
import { observer, inject } from 'mobx-react';
import { ViewStore } from '../stores/viewStore';
import { Stores } from '../stores/stores';

export interface IScreenlockOverlay {
    viewStore?: ViewStore;
}

@observer
class ScreenlockOverlayInner extends React.Component<IScreenlockOverlay> {
    public render() {
        return (
            <div className={`d-flex justify-content-center screenlockOverlay ${this.props.viewStore!.lockedUI ? 'active' : 'inactive'}`} >
                <div className="align-self-center spinner circles">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );
    }
}

export default inject(Stores.ViewStore)(ScreenlockOverlayInner);