import React from 'react';
import { useObserver } from 'mobx-react-lite';
import DateTimeInput from '../../common/components/dateTimeInput';
import { DeviceLogsModel } from '../stores/deviceStore/deviceLogsModel';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { ToggleButton } from '../../common/components/togglebutton/toggleButton';
import { Skeleton } from '../../common/components/skeleton';

export interface ILogFilterAreaProps {
    logModel: DeviceLogsModel | undefined;
}

export const LogFilterArea = (props: ILogFilterAreaProps) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        const isLoading = props.logModel?.isLoading;

        const renderSkeleton = () => {
            return (
                <div>
                    <Skeleton></Skeleton>
                </div>
            );
        };

        const renderFilterArea = () => {
            return (
                <React.Fragment>
                    <div className="container row">
                        {props.logModel?.availableLogFilterGroupNames.map((groupName) => (
                            <ToggleButton
                                key={groupName}
                                classNameContainer="col-lg p-1"
                                isToggled={props.logModel?.groupNames.includes(groupName)}
                                onClick={() => props.logModel?.updateGroupNameFilter(groupName)}
                            >
                                {t(`Logs.Filter.Group.${groupName}`)}
                            </ToggleButton>
                        ))}
                        <div className="col-12 col-lg-8 col-xl-4 p-1 row">
                            <DateTimeInput
                                className="col-6"
                                name={t('Logs.Filter.FromDate')}
                                value={props.logModel?.fromDate}
                                placeholder={t('Logs.Filter.FromDate.Placeholder').toString()}
                                malformattedErrorMessage={t('Errors.InvalidFormat').toString()}
                                onChange={(name, date) => props.logModel?.updateFromDateFilter(date!)}
                                isLoading={isLoading}
                                showClearButton={true}
                            />
                            <DateTimeInput
                                className="col-6"
                                name={t('Logs.Filter.ToDate')}
                                value={props.logModel?.toDate}
                                placeholder={t('Logs.Filter.ToDate.Placeholder').toString()}
                                malformattedErrorMessage={t('Errors.InvalidFormat').toString()}
                                onChange={(name, date) => props.logModel?.updateToDateFilter(date!)}
                                isLoading={isLoading}
                                showClearButton={true}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        };

        return isLoading ? renderSkeleton() : renderFilterArea();
    });
};
