import React, { useState } from 'react';
import { t } from 'i18next';
import Input from '../../common/components/input';
import { ModalPopup } from '../../common/components/modalPopup/modalPopup';
import Button from '../../common/components/button/button';

export const ResetOrDeleteDeviceConfirmation = (props: { matchValue: string, isOpen: boolean, onConfirmed: () => void, onCancel: () => void, title: string, actionButtonText: string, descriptionText: string }) => {
    const [input, setInput] = useState('');

    return (<React.Fragment>
        <ModalPopup
            isOpen={props.isOpen}
            closeRequested={props.onCancel}
            title={props.title}
            footerChildren={
                <div className='container row pb-3'>
                    <Button style='outline-secondary' className="col-4" onClick={() => props.onCancel()}>
                        <>
                            {t('ResetOrDeleteDevice.Dialog.CancelButton')}
                        </>
                    </Button>
                    <Button style='danger' className="col-4 ml-3" disabled={input !== props.matchValue} onClick={props.onConfirmed}>{props.actionButtonText}</Button>
                </div>}>
            <p>{props.descriptionText}</p>
            <Input className='mt-3' placeholder={t('ResetOrDeleteDevice.Dialog.DeviceSerialNumberInput.Placeholder').toString()} name='sn-check' type='text' value={input} onChange={(name, value) => setInput(value)} />
        </ModalPopup >
    </React.Fragment >);
};