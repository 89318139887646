import React from 'react';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DeviceComponentTile } from '../deviceComponentTile';
import { DeviceComponentTileHeader } from '../deviceComponentTileHeader';
import { CameraIcon } from './cameraTileIcon';
import { ICameraDeviceComponent } from '../../stores/deviceStore/deviceContracts';
import { TRANSLATIONS_NAMESPACE } from '../index';
import { isNonWhitespaceString } from '../../../common/utils/string';

export const CameraTile = (props: ICameraDeviceComponent) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    const bodyElements: string[] = [
        t('Components.Type.Camera'),
        [t('Components.InternalIpAddr'), props.internalIpAddr].join(' '),
        [t('Components.InternalHttpPort'), props.internalHttpPort].join(' '),
        [t('Components.InternalRtspPort'), props.internalRtspPort].join(' '),
        [t('Components.InternalRecordingPort'), props.internalRecordingPort].join(' '),
        isNonWhitespaceString(props.wapploxxId)
            ? [t('Components.Assignment'), isNonWhitespaceString(props.wapploxxName) ? props.wapploxxName : t('[empty]')].join(' ')
            : t('Components.Assignment.None')
    ];

    return useObserver(() => {
        return (
            <DeviceComponentTile
                componentTileHeader={<DeviceComponentTileHeader name={props.name} connectionStatus={props.connectionStatus} />}
                componentTileIcon={<CameraIcon />}
                componentTileBodyElements={bodyElements}
            />
        );
    });
};