import { useObserver } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../..';
import { LabeledData } from '../../../common/components/labeledData/labeledData';
import { DexitDeviceModel } from '../../stores/deviceStore/dexitDeviceModel';

export const DexitProperties = (props: { device: DexitDeviceModel | null, showSkeleton: boolean }) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        return (<React.Fragment >
            <LabeledData
                labelName={t('Devices.LastFirmwareUpdate')}
                data={props.device?.lastFirmwareUpdate ? moment(props.device?.lastFirmwareUpdate).format('L LTS') : undefined}
                showSkeleton={props.showSkeleton}
            />
            <LabeledData
                labelName={t('Devices.NumberOfCameras')}
                data={props.device?.countCameras ? props.device?.countCameras.toString() : '0'}
                showSkeleton={props.showSkeleton}
            />
            <LabeledData
                labelName={t('Devices.NumberOfCylinders')}
                data={props.device?.countCylinders ? props.device?.countCylinders.toString() : '0'}
                showSkeleton={props.showSkeleton}
            />
            <LabeledData
                labelName={t('Devices.NumberOfRepeater')}
                data={props.device?.countRepeaters ? props.device?.countRepeaters.toString() : '0'}
                showSkeleton={props.showSkeleton}
            />
            <LabeledData
                labelName={t('Devices.NumberOfSlaves')}
                data={props.device?.countSlaves ? props.device?.countSlaves.toString() : '0'}
                showSkeleton={props.showSkeleton}
            />
            <LabeledData
                labelName={t('Devices.NumberOfWallreaders')}
                data={props.device?.countWallreaders ? props.device?.countWallreaders.toString() : '0'}
                showSkeleton={props.showSkeleton}
            />
            <LabeledData
                labelName={t('Devices.NumberOfUsers')}
                data={props.device?.existingUsers?.toString()}
                showSkeleton={props.showSkeleton}
            />
            <LabeledData
                labelName={t('Devices.NumberOfTransponders')}
                data={props.device?.existingTags?.toString()}
                showSkeleton={props.showSkeleton}
            />
        </React.Fragment>);
    });
};
