import React from 'react';
import { SignalState } from '../../stores/deviceStore/deviceContracts';
import SignalStrengthFail from './signalStrengthIcons/Signal_Fail.svg';
import SignalStrengthLow from './signalStrengthIcons/Signal_1.svg';
import SignalStrengthMedium from './signalStrengthIcons/Signal_2.svg';
import SignalStrengthStrong from './signalStrengthIcons/Signal_3.svg';

export const SignalStrengthIcon = (props: { signalState: SignalState }) => {
    function getSignalStrengthIcon(signalState: SignalState) {
        switch (signalState) {
        case SignalState.Poor: {
            return SignalStrengthLow;
        }
        case SignalState.Good: {
            return SignalStrengthMedium;
        }
        case SignalState.Excellent: {
            return SignalStrengthStrong;
        }
        case SignalState.Unknown:
        default: {
            return SignalStrengthFail;
        }
        }
    }

    return (
        <div>
            <img src={getSignalStrengthIcon(props.signalState)} className="component-state-icon" />
        </div>
    );
};