import React from 'react';
import { useStore } from '../../../common/stores/useStore';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { IDataGridColumn, LocalDataGrid } from '../../../common/components/datagrid';
import { TRANSLATIONS_NAMESPACE, UserProfileModel } from '../../index';
import { Alert } from '../../../common/components/alert';
import { nameof } from '../../../common/nameof';
import { assertState } from '../../../common/utils/modelState';

export const InviteUsersInformation = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    return (
        <React.Fragment>
            <Alert slideIn={true} color={'info'}>
                {t('CompanyMembers.DatingAdviceForSingleUser')}
            </Alert>
        </React.Fragment>
    );
};

type RowDataType = UserProfileModel;

export const CompanyMembers = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    const { accessIdentityStore } = useStore();

    return useObserver(() => {
        const { companyMembers } = accessIdentityStore.userProfileStore;
        const isSingle = companyMembers?.length === 1;
        const loaded = !assertState(companyMembers, 'loading');

        const columns: IDataGridColumn<RowDataType>[] = [
            { name: nameof<RowDataType>('lastName'), title: t('CompanyMembers.Grid.Name').toString(), getCellValue: (rowdata: RowDataType) => rowdata.lastName || t('n/a') },
            { name: nameof<RowDataType>('firstName'), title: t('CompanyMembers.Grid.FirstName').toString(), getCellValue: (rowdata: RowDataType) => rowdata.firstName || t('n/a') },
            { name: nameof<RowDataType>('email'), title: t('CompanyMembers.Grid.Email').toString(), getCellValue: (data: RowDataType) => data.email || t('n/a') }
        ];

        return (
            <React.Fragment>
                {isSingle ? <InviteUsersInformation /> : null}
                <LocalDataGrid data={companyMembers} columns={columns} loaded={loaded} sortingState={{ defaultSorting: [{ columnName: nameof<RowDataType>('lastName'), direction: 'asc' }] }} />
            </React.Fragment>
        );
    });
};
