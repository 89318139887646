import { Helper } from '../../../../common';
import { IResellerProfileState } from '../profileModel';
import {
    ABUSCloudPortalCommonFeaturesResellerResellerCompanyProfileDto,
    ABUSCloudPortalCommonFeaturesResellerResellerEmployeeDto,
    ABUSCloudPortalCommonFeaturesResellerResellerProfileDto
} from 'portal-bff-proxy-ts';
import { UserProfileModel } from '..';
import { CompanyProfileModel } from '../../company/companyProfileModel';

const transformDtoToUserProfileModel = (dto: ABUSCloudPortalCommonFeaturesResellerResellerEmployeeDto): UserProfileModel => ({
    email: Helper.getStringValueOrDefault(dto.email),
    firstName: Helper.getStringValueOrDefault(dto.firstName),
    lastName: Helper.getStringValueOrDefault(dto.lastName)
});

export const toEmployees = (dtos: ABUSCloudPortalCommonFeaturesResellerResellerEmployeeDto[]): UserProfileModel[] => {
    return dtos.map(transformDtoToUserProfileModel);
};

export const toUserProfileModel = (dto: ABUSCloudPortalCommonFeaturesResellerResellerEmployeeDto): UserProfileModel => {
    return transformDtoToUserProfileModel(dto);
};

export const toCompanyProfile = (companyProfileDto: ABUSCloudPortalCommonFeaturesResellerResellerCompanyProfileDto): CompanyProfileModel => ({
    customerId: Helper.getStringValueOrDefault(companyProfileDto.customerId),
    cloudCompanyId: Helper.getNumberValueOrDefault(companyProfileDto.cloudCompanyId),
    name: Helper.getStringValueOrDefault(companyProfileDto.name),
    address: Helper.getStringValueOrDefault(companyProfileDto.address),
    postCode: Helper.getStringValueOrDefault(companyProfileDto.postCode),
    city: Helper.getStringValueOrDefault(companyProfileDto.city),
    countryRegion: Helper.getStringValueOrDefault(companyProfileDto.countryRegion),
    phoneNo: Helper.getStringValueOrDefault(companyProfileDto.phoneNo),
    contactPersonsFirstName: Helper.getStringValueOrDefault(companyProfileDto.contactPersonsFirstName),
    contactPersonsLastName: Helper.getStringValueOrDefault(companyProfileDto.contactPersonsLastName),
    email: Helper.getStringValueOrDefault(companyProfileDto.eMail)
});

export const toIResellerProfileState = (dto: ABUSCloudPortalCommonFeaturesResellerResellerProfileDto): IResellerProfileState => {
    return {
        companyProfile: toCompanyProfile(dto.companyProfile!),
        employees: toEmployees(dto.employees!),
        userProfile: toUserProfileModel(dto.userProfile!)
    };
};

export const toCompanyProfileDto = (companyProfile: CompanyProfileModel): ABUSCloudPortalCommonFeaturesResellerResellerCompanyProfileDto => {
    const companyProfileDto: ABUSCloudPortalCommonFeaturesResellerResellerCompanyProfileDto = {
        customerId: Helper.getStringValueOrDefault(companyProfile.customerId),
        cloudCompanyId: Helper.getNumberValueOrDefault(companyProfile.cloudCompanyId),
        city: Helper.getStringValueOrDefault(companyProfile.city),
        name: Helper.getStringValueOrDefault(companyProfile.name),
        countryRegion: Helper.getStringValueOrDefault(companyProfile.countryRegion),
        address: Helper.getStringValueOrDefault(companyProfile.address),
        phoneNo: Helper.getStringValueOrDefault(companyProfile.phoneNo),
        postCode: Helper.getStringValueOrDefault(companyProfile.postCode),
        eMail: Helper.getStringValueOrDefault(companyProfile.email),
        contactPersonsFirstName: Helper.getStringValueOrDefault(companyProfile.contactPersonsFirstName),
        contactPersonsLastName: Helper.getStringValueOrDefault(companyProfile.contactPersonsLastName)
    };
    return companyProfileDto;
};

export const toUserProfileDto = (dto: UserProfileModel): ABUSCloudPortalCommonFeaturesResellerResellerEmployeeDto => {
    return {
        email: Helper.getStringValueOrDefault(dto.email),
        firstName: Helper.getStringValueOrDefault(dto.firstName),
        lastName: Helper.getStringValueOrDefault(dto.lastName)
    };
};

export const toEmployeesDto = (companyProfile: UserProfileModel[]): ABUSCloudPortalCommonFeaturesResellerResellerEmployeeDto[] => {
    return companyProfile.map((userProfile) => toUserProfileDto(userProfile));
};
