/* eslint-disable no-useless-catch */
import { BaseRepository } from '../../../common';
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { IPortalConfiguration } from '../../../common/interfaces';
import { createApiProxy } from '../../../common/createApiProxy';
import { INotificationConfigurationState } from './notificationConfigurationModel';
import * as Mappings from './mapper/notifications';

export class ComfionNotificationsRepository extends BaseRepository {
  private notificationsApi: AbusCloudApi.ComfionApi;

  private localizationApi: AbusCloudApi.CoreApi;

  constructor(configuration: IPortalConfiguration) {
      super(configuration);

      this.notificationsApi = new AbusCloudApi.ComfionApi(this.apiConfiguration);
      this.localizationApi = new AbusCloudApi.CoreApi(this.apiConfiguration);
  }

  public async getAvailableNotifications(): Promise<string[]> {
      try {
          const proxy = createApiProxy<string[], string[]>(async () => {
              return this.notificationsApi.apiComfionNotificationSettingsNotificationsGet();
          });

          const availablenotifications = (await proxy()) || [];
          
          return availablenotifications;
      } catch (e : any) {
          throw e;
      }
  }

  public async getNotificationSettings(
      deviceId: string
  ): Promise<INotificationConfigurationState[]> {
      try {
          const proxy = createApiProxy(
              async () => this.notificationsApi.apiComfionNotificationSettingsDeviceIdGet(deviceId),
              (setting) => setting.map(Mappings.mapDtoToNotificationConfigurationState)
          );
          const settings = await proxy();
          return settings || [];
      } catch (e : any) {
          throw e;
      }
  }

  public async saveNotificationSetting(
      devicemetadata: INotificationConfigurationState
  ): Promise<void> {
      try {
          const update: AbusCloudApi.ABUSCloudPortalComfionNotificationsNotificationTypeSettingsDto = {
              deviceId: devicemetadata.deviceId,
              receivers: devicemetadata!.recipients,
              notificationName: devicemetadata.name,
              isEnabled: devicemetadata.enabled
          };
          const proxy = await createApiProxy<AbusCloudApi.ABUSCloudPortalComfionNotificationsNotificationTypeSettingsDto, any>(
              async (data) => {
                  return this.notificationsApi.apiComfionNotificationSettingsDeviceIdPost(devicemetadata.deviceId, data);
              },
              Mappings.mapDtoToNotificationConfigurationState);
          await proxy(update);
          return;
      } catch (e : any) {
          throw e;
      }
  }

  public async deleteNotificationSetting(
      data: INotificationConfigurationState
  ) {
      try {
          const proxy = createApiProxy(async () => {
              return this.notificationsApi.apiComfionNotificationSettingsDeviceIdNotificationTypeDelete(data.deviceId, data.name);
          });
          await proxy();
      } catch (e : any) {
          throw e;
      }
  }

  public async getAvailableNotificationLanguages(): Promise<string[]> {
      try {
          const proxy = createApiProxy(
              async () => this.localizationApi.apiCoreLocalizationLanguagesGet(),
              Mappings.mapNotificationSettingsLanguages);
          const availableNotificationLanguages = await proxy();
          return availableNotificationLanguages!;
      } catch (e : any) {
          throw e;
      }
  }

  public async getDeviceLocalizationSettings(deviceId: string) {
      try {
          const proxy = createApiProxy(
              async () => this.localizationApi.apiCoreLocalizationDeviceIdGet(deviceId),
              Mappings.mapLocalizationSettingDto);
          const localizationSettings = await proxy();
          return localizationSettings;
      } catch (e : any) {
          throw e;
      }
  }

  public async saveDeviceLocalizationSettings(deviceId: string, newLanguage: string) {
      try {
          const localizationSettings: AbusCloudApi.ABUSCloudPortalCommonFeaturesLocalizationLocalizationSettingDto = {
              deviceId,
              languageCode: newLanguage
          };
          const proxy = createApiProxy(async () => this.localizationApi.apiCoreLocalizationDeviceIdPost(deviceId, localizationSettings));
          const result = await proxy();
          return result;
      } catch (e : any) {
          throw e;
      }
  }
}
