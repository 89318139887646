/* eslint-disable no-useless-catch */
import { observable, toJS, computed } from 'mobx';
import { FirmwareStore } from './firmwareStore';

export enum ReleaseState {
    Reported = 'Reported',
    Publishing = 'Publishing',
    Published = 'Published',
}

export interface IFirmwareRowModelState {
    id: string;
    version: string;
    date?: string;
    publishState: ReleaseState;
    deviceCount: number;
    isLatest?: boolean;
    releaseNotes?: string;
    typeId?: string,
}

export class FirmwareRowModel {
    public id = '';

    @observable
    public version = '';

    @observable
    public releaseNotes?: string = undefined;

    @observable
    public date?: Date = undefined;

    @observable
    public publishState: ReleaseState = ReleaseState.Reported;

    @observable
    public isSynced = true;

    @observable
    public deviceCount = 0;

    @observable
    public isLatest = false;

    @observable
    public typeId?: string;

    @computed
    public get isLocked(): boolean {
        return this.publishState === ReleaseState.Published;
    }

    public static createFromJson(context: FirmwareStore, json: IFirmwareRowModelState): FirmwareRowModel {
        const model = new FirmwareRowModel(context);
        model.updateFromJSON(json);
        return model;
    }

    constructor(private context: FirmwareStore) {
        
    }
    
    public toJson(): IFirmwareRowModelState {
        const asJson: IFirmwareRowModelState = {
            id: this.id,
            version: this.version,
            date: this.date ? this.date.toJSON() : undefined,
            publishState: this.publishState,
            deviceCount: this.deviceCount,
            releaseNotes: this.releaseNotes,
            typeId: this.typeId
        };
        return toJS(asJson);
    }

    public updateFromJSON(state: IFirmwareRowModelState): void {
        this.id = state.id;
        this.version = state.version;
        this.date = state.date ? new Date(state.date) : undefined;
        this.publishState = state.publishState;
        this.deviceCount = state.deviceCount;
        this.isLatest = state.isLatest ?? false;
        this.releaseNotes = state.releaseNotes;
        this.typeId = state.typeId;
    }
}
