import { observable } from 'mobx';
import { IOperatorMetadataState, OperatorMetadataModel } from '../../devices/stores/deviceStore/operatorMetadataModel';
import DeviceOperatorMetadataRepository from '../../devices/stores/deviceStore/repositories/deviceOperatorMetadataRepository';
import { getPortalConfiguration } from '../../getPortalConfiguration';
import { AocDeviceRepository } from './aocDeviceRepository';

export interface IAocDeviceSettingsState {
    deviceId: string;
    deviceName: string;
    type: string;
    serialNumber: string;
    firmwareVersion: string;
    ipAddress: string;
    operatorMetadata: IOperatorMetadataState | undefined;
}

export function defaultState(this: any): IAocDeviceSettingsState {
    return {
        deviceId: '',
        deviceName: '',
        type: 'Aoc',
        serialNumber: '',
        firmwareVersion: '',
        ipAddress: '',
        operatorMetadata: {}
    };
}
const initState = defaultState();

export class AocDeviceSettings {
    deviceRepository = new AocDeviceRepository(getPortalConfiguration());
    @observable deviceId = initState.deviceId;
    @observable deviceName = initState.deviceName;
    @observable type = initState.type;
    @observable serialNumber = initState.serialNumber;
    @observable firmwareVersion = initState.firmwareVersion;
    @observable ipAddress = initState.ipAddress;
    @observable operatorMetadata: OperatorMetadataModel = new OperatorMetadataModel(this.deviceId, new DeviceOperatorMetadataRepository(getPortalConfiguration()));

    updateFromJSON(state: IAocDeviceSettingsState): this {
        this.deviceName = state.deviceName;
        this.operatorMetadata = this.operatorMetadata.updateFromJSON(state.operatorMetadata ?? {});
        this.serialNumber = state.serialNumber;
        this.firmwareVersion = state.firmwareVersion;
        this.ipAddress = state.ipAddress;
        this.type = state.type;
        this.deviceId = state.deviceId;
        return this;
    }

    toJSON(): IAocDeviceSettingsState {
        return {
            deviceId: this.deviceId,
            deviceName: this.deviceName,
            operatorMetadata: this.operatorMetadata.toJSON(),
            serialNumber: this.serialNumber,
            firmwareVersion: this.firmwareVersion,
            ipAddress: this.ipAddress,
            type: this.type
        };
    }
}
