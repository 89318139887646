/* eslint-disable no-useless-catch */
import HttpStatusCode from '../../../../common/httpStatusCode';
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { IPortalConfiguration } from '../../../../common/interfaces';
import { BaseRepository } from '../../../../common';
import { mapDeviceMetadataDto } from '../mapper/device';
import { IOperatorMetadataState } from '../operatorMetadataModel';
import { createApiProxy, IApiProxyOptions } from '../../../../common/createApiProxy';

class DeviceOperatorMetadataRepository extends BaseRepository {
    private apiClient: AbusCloudApi.CoreApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.apiClient = new AbusCloudApi.CoreApi(this.apiConfiguration);
    }

    public mapOperatorMetadataDto = (d: AbusCloudApi.ABUSCloudPortalCommonFeaturesResellerDeviceOperatorMetadataDto): IOperatorMetadataState => {
        const result: IOperatorMetadataState = {
            street: d.street,
            city: d.city,
            lastName: d.lastName,
            firstName: d.firstName,
            email: d.email,
            phone: d.phone,
            houseNumber: d.houseNumber,
            postalCode: d.postalCode,
            projectName: d.projectName,
            installationDate: d.installationDate,
            registeredDeviceId: d.registeredDeviceId
        };
        return result;
    };

    public async getDeviceMetadata(deviceId: string): Promise<IOperatorMetadataState | null> {
        try {
            const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
            const proxy = createApiProxy(async () => this.apiClient.apiCoreDeviceOperatorMetadataDeviceIdGet(deviceId), mapDeviceMetadataDto, opt);
            return proxy();
        } catch (e : any) {
            throw e;
        }
    }

    public async saveDeviceMetadata(deviceId: string, deviceMetadata: IOperatorMetadataState): Promise<boolean | null> {
        try {
            const proxy = createApiProxy(() => this.apiClient.apiCoreDeviceOperatorMetadataDeviceIdPost(deviceId, mapDeviceMetadataDto(deviceMetadata)));
            await proxy();
            return true;
        } catch (e : any) {
            throw e;
        }
    }
}

export default DeviceOperatorMetadataRepository;