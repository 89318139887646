import { ViewStore } from '../../common/stores/viewStore';
import { KnownRoutes } from '../../common/routing/knownroutes';
import { ITabConfig } from '../../common/components/tab/tabs';
import { TFunction } from 'i18next';
import React from 'react';
import { ProfileForm } from './userProfileForm/profileForm';
import { CompanyProfile } from './company/companyProfile';
import { CompanyMembers } from './company';
import { KnownRoles } from '../../common/routing/knownroles';

export function createTabsConfiguration(env: { viewStore: ViewStore; t: TFunction }): ITabConfig[] {
    const profileTabConfig: ITabConfig = {
        path: [KnownRoutes.UserProfile],
        exact: true,
        render: () => <ProfileForm />,
        options: {
            text: env.t('Form.UserProfile'),
            onSelected: () => env.viewStore.navigateToMyData()
        }
    };

    const companyProfileTabConfig: ITabConfig = {
        path: [KnownRoutes.Company],
        exact: true,
        render: () => <CompanyProfile />,
        options: {
            text: env.t('Form.Company'),
            onSelected: () => env.viewStore.navigateToUserProfileCompany()
        }
    };
    const companyMembersTabConfig: ITabConfig = {
        path: [KnownRoutes.CompanyMembers],
        exact: true,
        render: () => <CompanyMembers />,
        options: {
            text: env.t('Form.CompanyMembers'),
            onSelected: () => env.viewStore.navigateToUserProfileCompanyMembers()
        }
    };

    if (env.viewStore.user.hasRoles(KnownRoles.Operator)) {
        return [profileTabConfig];
    } else {
        return [profileTabConfig, companyProfileTabConfig, companyMembersTabConfig];
    }
}
