import { observable } from 'mobx';

export class UserProfileModel {
    @observable
    public lastName = '';

    @observable
    public firstName = '';

    @observable
    public email = '';
}
