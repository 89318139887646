import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { ABUSCloudPortalCommonFeaturesSearchSearchResultType } from 'portal-bff-proxy-ts';
import { BaseRepository } from '../../common';
import { defaultLogger } from '../../../logger';
import axiosGlobal, { CancelTokenSource } from 'axios';
import { createApiProxy } from '../../common/createApiProxy';
import { mapDtoToSearchResultState } from './mapper';
import { SearchResultModelState } from '.';
import { SearchResult } from './searchResult';

class SearchRepository extends BaseRepository {
    private searchCancelToken: CancelTokenSource | null = null;

    public async query(
        searchQuery: string,
        customerIdFilter: number | undefined,
        showOnlyOutdated: boolean,
        limitOfSearchResults: number,
        searchResultOffset: number,
        resultType = ABUSCloudPortalCommonFeaturesSearchSearchResultType.Devices): Promise<SearchResult> {
        const customerId = customerIdFilter === 0 ? undefined : customerIdFilter;
        if (this.searchCancelToken) {
            this.searchCancelToken.cancel();
        }
        this.searchCancelToken = axiosGlobal.CancelToken.source();
        const axios = axiosGlobal.create({ cancelToken: this.searchCancelToken.token });
        const searchClient = new AbusCloudApi.CoreApi(this.apiConfiguration, undefined, axios);

        try {
            const requestFn = () => searchClient.apiCoreSearchPost({
                searchTerm: searchQuery,
                customerIdFilter: customerId,
                showOnlyOutdated: showOnlyOutdated,
                limit: limitOfSearchResults,
                offset: searchResultOffset,
                resultType: resultType
            });
            const proxy = createApiProxy(requestFn, (x) => x);
            const data = await proxy();
            const mappedSearchResults = this.toSearchResultList(data?.items || []);
            return {
                searchResults: mappedSearchResults,
                totalCount: data!.totalCount!
            };
        } catch (e: any) {
            defaultLogger.error(e);
            throw e;
        }
    }

    private toSearchResultList(dto: AbusCloudApi.ABUSCloudPortalCommonFeaturesSearchSearchItemDto[]): SearchResultModelState[] {
        const results: SearchResultModelState[] = [];
        if (dto) {
            dto.map((c: AbusCloudApi.ABUSCloudPortalCommonFeaturesSearchSearchItemDto) => results.push(mapDtoToSearchResultState(c)));
        }
        results.sort(function (a, b) {
            if (a.searchResultSortingOrder < b.searchResultSortingOrder) { return -1 }
            if (a.searchResultSortingOrder > b.searchResultSortingOrder) { return 1 }
            return 0;
        });
        return results;
    }
}

export default SearchRepository;
