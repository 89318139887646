/* eslint-disable no-useless-catch */
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { BaseRepository } from '../../../../common';
import { IApiProxyOptions, createApiProxy } from '../../../../common/createApiProxy';
import HttpStatusCode from '../../../../common/httpStatusCode';
import { IPortalConfiguration } from '../../../../common/interfaces';
import { IDeviceAccessModel } from '../deviceAccessModel';
import { mapDeviceAccessDtoToDeviceAccessModel } from '../mapper/device';
import DeviceRepository from './deviceRepository';

export class DeviceAccessRepository extends BaseRepository {
    private deviceAccessApi: AbusCloudApi.CoreApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
       
        this.deviceAccessApi = new AbusCloudApi.CoreApi(this.apiConfiguration);
    }

    public async getDeviceAccess(deviceId: string): Promise<IDeviceAccessModel | null> {
        try {
            const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
            const proxy = createApiProxy(async () => this.deviceAccessApi.apiCoreDeviceAccessDeviceIdGet(deviceId), mapDeviceAccessDtoToDeviceAccessModel, opt);
            const result = await proxy();
            return result;
        } catch (e) {
            throw e;
        }
    }

    public async approveAccessRequest(deviceId: string): Promise<IDeviceAccessModel> {
        try {
            await createApiProxy(async () => this.deviceAccessApi.apiCoreDeviceAccessDeviceIdApprovePut(deviceId))();
            const proxy = createApiProxy(async () => this.deviceAccessApi.apiCoreDeviceAccessDeviceIdGet(deviceId), mapDeviceAccessDtoToDeviceAccessModel);
            const result = await proxy();
            return result!;
        } catch (e) {
            throw e;
        }
    }

    public async extendAccess(deviceId: string, extendUntil: Date): Promise<IDeviceAccessModel> {
        try {
            await createApiProxy(async () => this.deviceAccessApi.apiCoreDeviceAccessDeviceIdExtendPut(deviceId, extendUntil.toUTCString()))();
            const proxy = createApiProxy(async () => this.deviceAccessApi.apiCoreDeviceAccessDeviceIdGet(deviceId), mapDeviceAccessDtoToDeviceAccessModel);
            const result = await proxy();
            return result!;
        } catch (e) {
            throw e;
        }
    }
}

export default DeviceRepository;
