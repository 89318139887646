import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider, withSSR } from 'react-i18next';
import { Router } from 'react-router-dom';
import { Provider, useLocalStore } from 'mobx-react';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { syncHistoryWithStore } from 'mobx-react-router';
import { locale } from 'devextreme/localization';

import App from './app';
import i18nFn from './i18n';
import RootStore, { IRootStoreState } from './client/common/stores/rootStore';
import history from './client/common/history';

import { IAllStores } from './client/common/stores/stores';
import { StoreProvider } from './client/common/stores/storeProvider';

moment.locale(window.initialLanguage);
locale(window.initialLanguage);
momentLocalizer();
const Client = ({ i18n, store, allStores, initialI18nStore, initialLanguage }:
  { i18n: any, store: any, allStores: any, initialI18nStore: any, initialLanguage: any }) => {
    const ExtendedApp = withSSR()(App as any);

    return (
        <I18nextProvider i18n={i18n} >
            <CookiesProvider>
                <StoreProvider store={store}>
                    <Provider {...useLocalStore(() => allStores)}>
                        <Router history={history}>
                            <ExtendedApp
                                initialI18nStore={initialI18nStore}
                                initialLanguage={initialLanguage}
                            />
                        </Router>
                    </Provider>
                </StoreProvider>
            </CookiesProvider>
        </I18nextProvider>
    );
};

const bootstrapApp = async () => {
    const i18next = await i18nFn();
    const store = RootStore.createWithInitialData(window.initialPortalData as IRootStoreState, { i18next });
    syncHistoryWithStore(history, store.viewStore.routerStore);
    const allStores: IAllStores = { ...store, ...store.accessIdentityStore };
    hydrateRoot(
        document.querySelector('#root')!,
        <Client
            i18n={i18next}
            store={store}
            allStores={allStores}
            initialI18nStore={window.initialI18nStore}
            initialLanguage={window.initialLanguage}
        />
    );
    if (module.hot) {
        module.hot.accept();
    }
    store.viewStore.initialize();
};
bootstrapApp();