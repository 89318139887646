
import React from 'react';
import { useStore } from '../../common/stores/useStore';
import { DeviceIcon } from '../../devices/layout/icon/deviceIcon';
import { DeviceState, DeviceType } from '../../devices/stores/deviceStore';
import { SearchPageResultView } from '../../search';
import { IKeyValue, ISearchResultDetails } from '../../search/searchPageResultView';

import { SearchResultModel } from '../../search/stores';
import {
    firmwareOutdatedLabelWrapper,
    firmwareOutdatedValueWrapper
} from '../../search/components/firmwareOutdatedWrappers';
import { KnownRoles } from '../../common/routing/knownroles';

export const AocSearchResultTile = (props: { device: SearchResultModel }) => {
    const { viewStore } = useStore();
    
    const searchResultBodyItems: IKeyValue[] = [
        ...(!viewStore.user.hasRoles(KnownRoles.Operator) ? [{ label: 'Devices.Projectname', value: props.device.projectName }] : []),
        { label: 'Devices.DeviceName', value: props.device.deviceName },
        {
            label: 'Devices.FirmwareVersion',
            value: props.device.firmwareVersion,
            wrapperForLabel: props.device.hasLatestFirmware ? undefined : firmwareOutdatedLabelWrapper,
            wrapperForValue: props.device.hasLatestFirmware ? undefined : firmwareOutdatedValueWrapper
        },
        { label: 'Devices.SerialNumber', value: props.device.serialNumber }
    ];

    const resultView: ISearchResultDetails = {
        searchResultBody: { items: searchResultBodyItems },
        isEnabled: true,
        id: props.device.registeredDeviceId,
        action: () => viewStore.aocViewStore.navigateToDevice({ deviceid: props.device.registeredDeviceId, deviceType: DeviceType.Aoc })
    };

    const deviceState = DeviceState.Connected;
    return (
        <React.Fragment>
            <SearchPageResultView
                searchResult={resultView}
                key={props.device.registeredDeviceId}
                icon={
                    <DeviceIcon
                        deviceType={DeviceType.Unknown}
                        deviceSubType={undefined}
                        deviceState={deviceState}
                        iconSize={80}
                    />}
            />
        </React.Fragment>
    );
};
