import { toJS } from 'mobx';
import { KnownRelationshipsLegacy } from '../routing/knownrelationships';

export interface IPortalAuthorizationState {
  isAuthenticated: boolean;
  userIsVerified: boolean;
  userProfileIsVerified: boolean;
  email: string | null;
  roles: string[];
  relationship: KnownRelationshipsLegacy;
}

export class PortalAuthorization {
  public isAuthenticated = false;
  public userIsVerified = false;
  public userProfileIsVerified = false;
  public roles: string[] = [];
  public relationship = KnownRelationshipsLegacy.None;
  public email: string | null = null;
  
  public updateFromJson(state: IPortalAuthorizationState) {
      this.isAuthenticated = state.isAuthenticated;
      this.userIsVerified = state.userIsVerified;
      this.userProfileIsVerified = state.userProfileIsVerified;
      this.roles = state.roles;
      this.relationship = state.relationship;
      this.email = state.email;
  }

  public toJSON(): IPortalAuthorizationState {
      const portalAuthorizationState = {
          isAuthenticated: this.isAuthenticated,
          userIsVerified: this.userIsVerified,
          userProfileIsVerified: this.userProfileIsVerified,
          roles: this.roles,
          relationship: this.relationship,
          email: this.email
      };
      return toJS(portalAuthorizationState);
  }
}
