import { observable, computed, action, toJS, observe } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import RootStore from './rootStore';
import { IEnvironmentStamping } from '../interfaces';
import { UserModel, IUserState } from '../user';
import { KnownRoutes } from '../routing/knownroutes';
import { matchPath, match, generatePath } from 'react-router-dom';
import RoutingService, { IRouteDefinition } from '../routing/routingservice';
import { Action, Location } from 'history';

import { AppContext } from '../../appContext';
import { isServer } from '../../../isServer';
import { getPortalConfiguration } from '../../getPortalConfiguration';
import { DevicesViewStore } from '../../devices/stores/devicesViewStore';
import { ResponseError } from '../responseError';
import { dxToastOptions } from 'devextreme/ui/toast';
import notify from 'devextreme/ui/notify';
import { getStoreEnv } from '../../commonRegistry';
import { IViewStore } from './viewStore.inerface';
import { DialogModel } from './dialogModel';
import { ClockModel } from './clockModel';
import { createLoggerWithNamespace } from '../../../logger';
import { AocViewStore } from '../../aoc/common/aocViewStore';
import { DeviceType } from '../../devices/stores/deviceStore/deviceContracts';
import { KnownRoles } from '../routing/knownroles';

const logger = createLoggerWithNamespace('ViewStore');

export interface IViewStoreState {
    user: IUserState;
    currentAppContext: AppContext;
    errors: string[];
}

export class ViewStore implements IViewStore {
    public addTimer(timerId: NodeJS.Timeout) {
        logger.debug('Adding timer ' + timerId);
        this.timers.push(timerId);
    }

    public removeTimers() {
        if (this.timers !== undefined && this.timers.length > 0) {
            logger.debug('clearing timers');
            this.timers.forEach((handle: NodeJS.Timeout) => {
                clearInterval(handle);
            });
        }
    }

    private timers: NodeJS.Timeout[] = [];

    public loadRemoteContentOnInit = !isServer();

    public user: UserModel = new UserModel(this);

    public aocViewStore = new AocViewStore();

    public devicesViewStore = new DevicesViewStore();

    public clock = new ClockModel();

    public dialog = new DialogModel();

    @observable
    public isValid: any = undefined;

    @observable
    public currentAppContext: AppContext = AppContext.Portal;

    @computed
    public get isPortalAppContext(): boolean {
        return this.currentAppContext === AppContext.Portal;
    }

    public get envStamping(): IEnvironmentStamping {
        return getPortalConfiguration().envStamping;
    }

    @observable
    public currentPath = '';

    @computed
    public get currentRoute(): IRouteDefinition | null {
        const routes = RoutingService.getNavigatableRoutes(this.rootStore.authorization);
        const activeRoute = routes
            .map((routeDefinition) => {
                const reqRoute = this.currentPath;
                const matched: match | null = matchPath(reqRoute, routeDefinition);
                return { routeDefinition, matched };
            })
            .find((route) => {
                return route.matched != null;
            });
        return activeRoute ? activeRoute.routeDefinition : null;
    }

    @computed
    public get lockedUI(): boolean {
        return this.countOfLockRequests !== 0;
    }

    public get routerStore(): RouterStore {
        return this._routerStore;
    }

    public _routerStore: RouterStore;

    @observable
    public countOfLockRequests = 0;

    private errors: string[] = [];

    constructor(public rootStore: RootStore, initialState?: IViewStoreState) {
        this._routerStore = new RouterStore();
        if (initialState) {
            this.updateFromJson(initialState);
        }
    }

    @action
    public lockUI(): () => void {
        this.countOfLockRequests = this.countOfLockRequests + 1;
        return () => {
            this.countOfLockRequests = this.countOfLockRequests - 1;
        };
    }

    @action
    public notifyError(source: string, e: ResponseError<any> | Error | string) {
        let message: string;
        const responseErrorType = e as ResponseError<any>;
        const descriptionfield = responseErrorType.rawResponseData?.title ?? responseErrorType.rawResponseData;
        const messageField = (e as Error).message;
        if (typeof e === 'string') {
            message = e;
        } else if (typeof descriptionfield === 'string') {
            message = descriptionfield;
        } else if (typeof messageField === 'string' && messageField.length > 0) {
            message = messageField;
        } else {
            message = 'Unexpected Error';
        }
        const { i18next } = getStoreEnv();
        const defaultText = `${source.toLowerCase()}: ${message}`;
        const labelKeys = [`Errors.Messages.Source=(${source.toLowerCase()}).Message=(${message.toLowerCase()})`, `Errors.Messages.Message=(${message.toLowerCase()})`];
        message = i18next.t(labelKeys, { defaultValue: defaultText });
        if (isServer()) {
            this.errors.push(message);
        } else {
            this.showErrorNotification(message);
        }
    }

    @action
    public notifySuccess(message: string) {
        this.showSuccessNotification(message);
    }

    public async initialize(): Promise<void> {
        const pathbeforeinit = this.routerStore.location.pathname;
        this.currentPath = pathbeforeinit;
        await this.currentPathChanged(pathbeforeinit);
        if (pathbeforeinit !== this.currentPath) {
            await this.routerStore.replace(this.currentPath);
        }

        this.startRouteLister();

        // Here the magic happens
        observe(this, 'currentPath', () => {
            const path = this.currentPath;
            logger.debug('currentPath', path);
            if (path !== this.routerStore.location.pathname) {
                logger.debug('currentPath(path !== this.routerStore.location.pathname)', path, this.routerStore.location.pathname);
                this.routerStore.history.push(path);
                this.currentPathChanged(path);
            }
        });

        if (!isServer()) {
            const errors = this.errors;
            this.errors = [];
            errors.forEach((e) => {
                this.showErrorNotification(e);
            });
            this.clock.startClock(() => {
                this.devicesViewStore.timeChanged();
                this.rootStore.timeChanged();
            });
        }
        await this.devicesViewStore.initialize();
    }

    @action.bound
    public async navigateToUnauthorized() {
        this.currentPath = KnownRoutes.Unauthorized;
    }

    @action.bound
    public async navigateToSearch() {
        this.currentPath = generatePath(KnownRoutes.Search);
        await this.initializeSearchRoute();
    }

    @action.bound
    public async navigateToMyData(): Promise<void> {
        const path = generatePath(KnownRoutes.UserProfile);
        this.currentPath = path;
        await this.initializeProfileRoute();
    }

    @action.bound
    public async navigateToUserProfileCompany(): Promise<void> {
        const path = generatePath(KnownRoutes.Company);
        this.currentPath = path;
        await this.initializeCompanyProfileRoute();
    }

    @action.bound
    public async navigateToUserProfileCompanyMembers(): Promise<void> {
        const path = generatePath(KnownRoutes.CompanyMembers);
        this.currentPath = path;
        await this.initializeCompanyMembersRoute();
    }

    @action.bound
    public async navigateToFirmwareOverview(forcerefresh = false): Promise<void> {
        const path = generatePath(KnownRoutes.FirmwareOverview);
        this.currentPath = path;
        await this.initializeFirmwareOverviewRoute(forcerefresh);
    }

    @action.bound
    public async navigateToPasswordReset(email: string): Promise<void> {
        const clientId = getPortalConfiguration().clientId;
        const loginBaseUrl = getPortalConfiguration().loginBaseUrl;
        window.location.assign(`${loginBaseUrl}/login?client=${clientId}#/login/passwordrecovery/${clientId}/${email}`);
    }

    public updateFromJson(state: IViewStoreState) {
        this.currentAppContext = state.currentAppContext;
        this.errors = state.errors;
        if (state.user) {
            this.user.updateFromJson(state.user);
        }
    }

    public toJSON(): IViewStoreState {
        return toJS({
            errors: this.errors,
            user: this.user.toJSON(),
            currentAppContext: this.currentAppContext
        });
    }

    public async handleInitializationErrors(e: any) {
        this.notifyError('Initialization', e);
    }

    public async initStateForPath(path: KnownRoutes, routeParams: Record<string, any>, deviceType: DeviceType = DeviceType.Unknown): Promise<void> {
        logger.debug('viewStore.initStateForPath');

        logger.debug('switching routes: removing timers');
        this.removeTimers();

        let resolvedInitPath: KnownRoutes = path;
        if (resolvedInitPath === KnownRoutes.Search) {
            const customHomePath = RoutingService.getCustomHomeRoutePath(this.rootStore.authorization.roles || []);
            resolvedInitPath = customHomePath || resolvedInitPath;
        }

        switch (resolvedInitPath) {
        case KnownRoutes.UserProfile:
        case KnownRoutes.Company:
            await this.initializeCompanyProfileRoute();
            break;
        case KnownRoutes.CompanyMembers:
            await this.initializeCompanyMembersRoute();
            break;
        case KnownRoutes.Search:
            await this.initializeSearchRoute();
            break;
        case KnownRoutes.FirmwareOverview:
            await this.initializeFirmwareOverviewRoute(false);
            break;
        default:
            await this.devicesViewStore.initStateForPath(resolvedInitPath, routeParams, deviceType);
            await this.aocViewStore.initStateForPath(resolvedInitPath, routeParams);
        }
    }

    public async initStateForPathCompleted(path: KnownRoutes): Promise<void> {
        logger.debug('viewStore.initStateForPathCompleted');
        await this.devicesViewStore.initStateForPathCompleted(path);
    }

    private async initializeSearchRoute(): Promise<void> {
        if (!isServer()) {
            await this.rootStore.searchStore.sync();
        }
    }

    private async initializeCompanyMembersRoute(): Promise<void> {
        if (this.loadRemoteContentOnInit) {
            const userprofileStore = this.rootStore.accessIdentityStore.userProfileStore;
            if (this.user.hasRoles(KnownRoles.Operator)) {
                await userprofileStore.syncOperator();
            } else {
                await userprofileStore.sync();
            }
        }
    }

    private async initializeCompanyProfileRoute(): Promise<void> {
        if (this.loadRemoteContentOnInit) {
            const userprofileStore = this.rootStore.accessIdentityStore.userProfileStore;
            if (this.user.hasRoles(KnownRoles.Operator)) {
                await userprofileStore.syncOperator();
            } else {
                await userprofileStore.sync();
            }
        }
    }

    private async startPathStateUpdateCycle(path: KnownRoutes, routeParams: Record<string, unknown>, deviceType: DeviceType) {
        logger.debug('startPathStateUpdate');
        await this.initStateForPath(path, routeParams, deviceType);
        await this.initStateForPathCompleted(path);
    }

    private async historyChanged(location: Location, historyaction: Action) {
        logger.debug('historyChanged(start)', location, historyaction);
        this.currentPath = location.pathname;
        if (historyaction !== 'POP') {
            this.currentPathChanged(location.pathname);
        }
    }

    private async currentPathChanged(path: string) {
        logger.debug('currentPathChanged(start)', path);
        const routes = RoutingService.getNavigatableRoutes(this.rootStore.authorization);
        const activeRoutes = routes.map((routeDefinition) => {
            const reqRoute = path;
            const matched: match | null = matchPath(reqRoute, routeDefinition);
            return { routeDefinition, matched };
        });

        const matchedRoutes = activeRoutes.filter((route) => {
            return route.matched != null && route.matched.url === this.currentPath;
        });

        if (matchedRoutes && matchedRoutes[0]) {
            await this.startPathStateUpdateCycle(matchedRoutes[0].routeDefinition.path, matchedRoutes[0].matched!.params, matchedRoutes[0].routeDefinition.deviceType);
        }
    }

    private async initializeProfileRoute(): Promise<void> {
        if (this.loadRemoteContentOnInit) {
            const userprofileStore = this.rootStore.accessIdentityStore.userProfileStore;
            if (this.user.hasRoles(KnownRoles.Operator)) {
                await userprofileStore.syncOperator();
            } else {
                await userprofileStore.sync();
            }
        } else {
            this.countOfLockRequests = 1;
        }
    }

    private async initializeFirmwareOverviewRoute(forcerefresh: boolean) {
        try {
            if (this.loadRemoteContentOnInit) {
                if (forcerefresh) {
                    this.rootStore.firmwareStore.firmwareList = undefined;
                }
                this.rootStore.firmwareStore.sync();
            }
        } catch (e: any) {
            this.handleInitializationErrors(e);
        }
    }

    private showErrorNotification(message: string) {
        const notifyoptions: dxToastOptions = {
            message,
            position: { at: 'right top', my: 'right top', offset: '-25 20' },
            closeOnClick: true,
            minHeight: '100',
            minWidth: '150'
        };
        notify(notifyoptions, 'error', 6999);
    }

    private showSuccessNotification(message: string) {
        const notifyoptions: dxToastOptions = {
            message,
            position: { at: 'right top', my: 'right top', offset: '-25 20' },
            closeOnClick: true,
            minHeight: '100',
            minWidth: '150'
        };
        notify(notifyoptions, 'success', 4999);
    }

    private startRouteLister() {
        this.routerStore.history.listen((location, historyaction) => {
            logger.debug('historyListen', location.pathname, this.currentPath);
            if (location.pathname !== this.currentPath) {
                logger.debug('historyListen(start routeChanged)', location.pathname, this.currentPath);
                this.historyChanged(location, historyaction);
            }
        });
    }
}
