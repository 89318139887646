import React from 'react';
import { ConnectionStatus } from '../../stores/deviceStore/deviceContracts';
import CSUnknown from './connectionStatusIcons/440-cam-unknown.svg';
import CSConnected from './connectionStatusIcons/441-cam-established.svg';
import CSDisconnected from './connectionStatusIcons/439-cam-lost.svg';

export type NumericIndexer<T> = { [dto: number]: T | undefined };

export const ConnectionStatusIcon = (props: { connectionStatus: ConnectionStatus }) => {
    function getIcon(connectionStatus: ConnectionStatus) {
        const map: NumericIndexer<string | undefined> = {
            [ConnectionStatus.Established]: CSConnected,
            [ConnectionStatus.Lost]: CSDisconnected,
            [ConnectionStatus.Unknown]: CSUnknown
        };

        return map[connectionStatus] ?? CSUnknown;
    }

    return (
        <div>
            <img src={getIcon(props.connectionStatus)} className="component-state-icon" />
        </div>
    );
};