/* eslint-disable no-useless-catch */
import { FirmwareRowModel } from './firmwareRowModel';
import { FirmwareStore } from './firmwareStore';
import { BaseRepository } from '../../common';
import { IPortalConfiguration } from '../../common/interfaces';
import { fromDtoToFirmwareRowModelState } from './mapper';
import { createApiProxy } from '../../common/createApiProxy';
import { FirmwareTypeModel } from './firmwareTypesModel';
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import HyenFirmwareReleaseData from '../hyen/HyenFirmwareReleaseData';
import DexitFirmwareReleaseData from '../dexit/DexitFirmwareReleaseData';
import ComfionFirmwareReleaseData from '../comfion/ComfionFirmwareReleaseData';

export class FirmwareRepository extends BaseRepository {
    private firmwareClient: AbusCloudApi.CoreApi;

    private firmwarePublishingClientHyen: AbusCloudApi.HyenApi;
    private firmwarePublishingClientDexit: AbusCloudApi.DexitApi;
    private firmwarePublishingClientComfion: AbusCloudApi.ComfionApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.firmwareClient = new AbusCloudApi.CoreApi(this.apiConfiguration);
        this.firmwarePublishingClientHyen = new AbusCloudApi.HyenApi(this.apiConfiguration);
        this.firmwarePublishingClientDexit = new AbusCloudApi.DexitApi(this.apiConfiguration);
        this.firmwarePublishingClientComfion = new AbusCloudApi.ComfionApi(this.apiConfiguration);
    }

    public async getFirmwareTypes(): Promise<FirmwareTypeModel[] | null> {
        try {
            const firmwareTypes = await createApiProxy(
                () => this.firmwareClient.apiCoreFirmwareTypesGet(),
                (dto) =>
                    dto.map((x) => {
                        let displayName = x.deviceType + ' ' + x.firmwareType!;

                        switch (x.deviceType) {
                        case 'dexit':
                            displayName = 'Overview.Firmwaretype.WLXPP';
                            break;
                        case 'hyen':
                            displayName = 'Overview.Firmwaretype.Secoris(' + x.firmwareType + ')';
                            break;
                        case 'comfion':
                            displayName = 'Overview.Firmwaretype.Comfion';
                            break;
                        default:
                            break;
                        }

                        return new FirmwareTypeModel(x.id!, x.firmwareType!, x.deviceType!, displayName);
                    })
            )();
            return firmwareTypes;
        } catch (e: any) {
            throw e;
        }
    }

    public async getFirmwareMetadataForType(context: FirmwareStore, firmwareType: string): Promise<FirmwareRowModel[]> {
        try {
            const firmwareModelState = await createApiProxy(
                () => this.firmwareClient.apiCoreFirmwareGet(firmwareType),
                (dto) => dto.map((x) => fromDtoToFirmwareRowModelState(x))
            )();
            if (firmwareModelState) {
                return firmwareModelState.map((x) => FirmwareRowModel.createFromJson(context, x));
            }
            return [];
        } catch (e) {
            throw e;
        }
    }

    public async sendResellerNotification(firmwareId: string) {
        try {
            await createApiProxy(() => this.firmwareClient.apiCoreFirmwareReleaseIdSendnotificationPost(firmwareId))();
        } catch (e: any) {
            throw e;
        }
    }

    public async createNewDexitFirmwareRelease(releaseData: DexitFirmwareReleaseData, onProgress: (progress: number) => void, signal: AbortSignal): Promise<void> {
        try {
            const formData = new FormData();
            formData.append('FirmwareFile', releaseData.FirmwareFile);
            formData.append('FirmwareTypeId', releaseData.FirmwareTypeId);

            await createApiProxy(() =>
                this.firmwarePublishingClientDexit.apiDexitFirmwarePublishingPublishPost({
                    data: formData,
                    onUploadProgress: (progressEvent: any) => {
                        const progress = (progressEvent.loaded / progressEvent.total) * 100;
                        onProgress(progress);
                    },
                    signal
                })
            )();
        } catch (e: any) {
            throw e;
        }
    }

    public async createNewHyenFirmwareRelease(releaseData: HyenFirmwareReleaseData, onProgress: (progress: number) => void, signal: AbortSignal): Promise<void> {
        try {
            const formData = new FormData();
            formData.append('FirmwareFile', releaseData.FirmwareFile);
            formData.append('ReleaseNotes', releaseData.ReleaseNotes);
            formData.append('IsBeta', releaseData.IsBeta.toString());
            formData.append('MinHardwareVersion', releaseData.MinHardwareVersion);
            formData.append('FirmwareTypeId', releaseData.FirmwareTypeId);

            await createApiProxy(() =>
                this.firmwarePublishingClientHyen.apiHyenFirmwarePublishingPublishPost({
                    Headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData,
                    onUploadProgress: (progressEvent: any) => {
                        const progress = (progressEvent.loaded / progressEvent.total) * 100;
                        onProgress(progress);
                    },
                    signal
                })
            )();
        } catch (e: any) {
            throw e;
        }
    }

    public async createNewComfionFirmwareRelease(releaseData: ComfionFirmwareReleaseData): Promise<void> {
        try {
            const formData = new FormData();
            formData.append('FirmwareTypeId', releaseData.FirmwareTypeId);
            await createApiProxy(() =>
                this.firmwarePublishingClientComfion.apiComfionFirmwarePublishingPublishPost({ version: releaseData.version })
            )();
        } catch (e: any) {
            throw e;
        }
    }
}
