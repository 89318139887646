import './datagrid.scss';

import React from 'react';
import { DefaultPageSize, DefaultPageSizeList } from '../../stores/queryableDataModel';
import { IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { PagingPanel } from '@devexpress/dx-react-grid-bootstrap4';
import { ICommonDataGridProps, ILocalDataGridProps, IRemoteDataGridProps } from './interfaces';
import { BaseDataGrid, GridWrapper } from './datagrid';

export const LocalDataGrid = React.forwardRef<unknown, IRemoteDataGridProps & ILocalDataGridProps & ICommonDataGridProps>((props, ref) => {
    return (
        <GridWrapper>
            <BaseDataGrid {...props} ref={ref} columns={props.columns} data={props.data ?? []} loaded={props.loaded}>
                <SortingState {...props.sortingState} />
                <IntegratedSorting />
                <PagingState defaultCurrentPage={0} defaultPageSize={DefaultPageSize} />
                <IntegratedPaging />
                <PagingPanel pageSizes={[...DefaultPageSizeList, 0]} />
                {props.children}
            </BaseDataGrid>
        </GridWrapper>
    );
});
