import { useObserver } from 'mobx-react';
import React from 'react';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { useStore } from '../../common/stores/useStore';
import { IItem, Dropdown } from '../../common/components/dropdown/dropdown';
import { assertState } from '../../common/utils/modelState';
import { translateErrors } from '../../common/utils/errors';
import { Label } from '../../common/components/label/label';
import { useTranslation } from 'react-i18next';

export const NotificationLanguageSettings = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const rootStore = useStore();
    return useObserver(() => {
        const deviceStore = rootStore.deviceStore;
        const translatedErrors = translateErrors(deviceStore.deviceSettings!.errors, TRANSLATIONS_NAMESPACE_DEVICE);

        const initializeNotificationLanguageDropdownItems = () => {
            const availableNotificationLanguages = deviceStore.deviceSettings!.availableNotificationLanguages;
            return availableNotificationLanguages.map((language) =>
                ({ key: language, text: t(`DeviceSettings.NotificationLanguageSetting.Language.${language}`) }));
        };

        const changeDeviceNotificationLanguage = (name: string, newNotificationLanguage: string) => {
            deviceStore.deviceSettings!.setDeviceNotificationLanguage(deviceStore.deviceId!, newNotificationLanguage);
        };

        const notificationLanguageDropdownItems: IItem[] = initializeNotificationLanguageDropdownItems();

        return (
            <React.Fragment>
                <h2 className="typo-h6 pb-3">{t('DeviceSettings.NotificationLanguageSetting.Header')}</h2>
                <p className="typo-c1" >{t('DeviceSettings.NotificationLanguageSetting.Description')}</p>
                <div className="p-0 d-flex align-items-center col-12 col-lg-5">
                    <Label
                        isLoading={assertState(deviceStore.deviceSettings!.deviceSettingsModelState, 'initial', 'loading')}
                        text={t('DeviceSettings.NotificationLanguageSetting.Dropdown')}
                    />
                    <Dropdown
                        className="ml-3 col-6"
                        items={notificationLanguageDropdownItems}
                        onChange={changeDeviceNotificationLanguage}
                        value={deviceStore.deviceSettings!.currentDeviceNotificationLanguage}
                        errorMessage={translatedErrors.get() as any}
                        isLoading={assertState(deviceStore.deviceSettings!.deviceSettingsModelState, 'initial', 'loading')}
                    />
                </div>
            </React.Fragment>);
    });
};