import './tabs.scss';

import React from 'react';
import { useStore } from '../../stores/useStore';
import { className } from '../../utils/helper';
import { KnownRoutes } from '../../routing/knownroutes';
import { Skeleton } from '../skeleton';

export interface ITabsProps {
    tabs?: ITabConfig[];
    content?: (tabConfig?: ITabConfig) => React.ReactNode;
}

export interface ITabConfig {
    render?: (tabConfig: ITabConfig) => React.ReactNode;
    exact: boolean;
    path: KnownRoutes[];
    displayOrder?: number;
    options: {
        text: string,
        onSelected: () => void;
    };
}

const TabButton = (props: { selected: boolean, config: ITabConfig, last: boolean }) => {
    return (
        <div
            className={className({ condition: !props.last, className: 'pr-5' })}
        >
            <button
                className={className('typo-c2-bold btn btn-tab-switch')}
                onClick={() => props.config.options.onSelected()}
                disabled={props.selected}
            >
                <label>{props.config.options.text}</label>
            </button>
        </div>
    );
};

export const Tabs = (props: ITabsProps) => {
    const { viewStore } = useStore();
    const userRouteAllowedTabs = props.tabs?.filter((tabConfig) => tabConfig.path.some((path) => viewStore.user.canNavigateTo(path))) || [];
    const tabsDisplaySorted = userRouteAllowedTabs.slice().sort((x, y) => (x.displayOrder || 0) - (y.displayOrder || 0));
    const tabConfig = userRouteAllowedTabs.find((tabconfig) => tabconfig.path.some((p) => p === viewStore.currentRoute?.path));

    return (<div className="tabs-component container">
        <div className="d-flex pb-4">
            {props.tabs == null && <><Skeleton className='mr-2' style={{ height: '40px' }} />
                <Skeleton className='mr-2' style={{ height: '40px' }} />
                <Skeleton style={{ height: '40px' }} />
            </>}
            {props.tabs != null && tabsDisplaySorted.map((config, index) =>
                <TabButton
                    key={index}
                    config={config}
                    last={index === tabsDisplaySorted.length - 1}
                    selected={config.path.includes(viewStore.currentRoute?.path ?? '')}
                />)}
        </div>
        <div className="w-100">
            <div>
                {props.tabs == null && <Skeleton className='w-100' style={{ height: '250px' }} />}
                {props.tabs != null && props.content ? props.content(tabConfig) : null}
                {props.tabs != null && tabConfig?.render ? tabConfig?.render(tabConfig) : null}
            </div>
        </div>
    </div>);
};