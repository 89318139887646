class ComfionFirmwareReleaseData {
    FirmwareTypeId: string;
    version: string;

    constructor(firmwareTypeId: string, version: string) {
        this.FirmwareTypeId = firmwareTypeId;
        this.version = version;
    }
}

export default ComfionFirmwareReleaseData;
