import * as React from 'react';
import {
    FormText,
    FormFeedback,
    Input as RSInput,
    FormGroup
} from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import { Skeleton } from '../skeleton';
import 'reflect-metadata';
import './input.scss';

export interface IInputProps {
    placeholder?: string;
    id?: string;
    name: string;
    type: InputType;
    errorMessage?: string[] | null;
    value: string | number | string[] | undefined;
    maxLength?: number;
    help?: string;
    onChange?: (name: string, value: string) => void;
    readOnly?: boolean;
    hidden?: boolean;
    onBlur?: (name: string) => void;
    className?: string;
    isLoading?: boolean;
}

export interface IInputState {
    valid: boolean;
}

export default class Input extends React.Component<IInputProps, IInputState> {
    public getInitialState(): IInputState {
        return { valid: true };
    }

    constructor(props: IInputProps) {
        super(props);
        this.state = this.getInitialState();
        this.onChange = this.onChange.bind(this);
    }

    private isInvalid(): boolean {
        return !!this.props.errorMessage && this.props.errorMessage.length > 0;
    }

    private getFirstError(): string | null {
        if (this.props.errorMessage && this.props.errorMessage.length > 0) {
            return this.props.errorMessage[0];
        }
        return null;
    }

    private onBlur() {
        if (this.props.onBlur) {
            this.props.onBlur(this.props.name);
        }
    }

    private onChange(e: React.SyntheticEvent) {
        const target: HTMLInputElement = e.target as HTMLInputElement;
        const name = target.name;
        const value = target.value;
        if (this.props.onChange) {
            this.props.onChange(name, value);
        }
    }

    public render() {
        return (
            <FormGroup hidden={this.props.hidden} className={`abus-input-group ${this.props.className || ''}`}>
                <div className="abus-input-group-container">
                    {this.props.isLoading ? this.renderSkeleton() : this.renderInput()}
                    <span className="highlight" />
                    <span className="bar" />
                    <label>{this.props.placeholder}</label>
                    <FormFeedback>{this.getFirstError()}</FormFeedback>
                    <FormText>{this.props.help}</FormText>
                </div>
            </FormGroup>
        );
    }

    private renderSkeleton() {
        return (<div className="skeleton-placeholder"><Skeleton className="used" /></div>);
    }

    private renderInput() {
        return (<RSInput
            className={this.props.value ? 'used' : ''}
            invalid={this.isInvalid()}
            maxLength={this.props.maxLength}
            readOnly={this.props.readOnly}
            onChange={this.onChange}
            value={this.props.value || ''}
            type={this.props.type}
            name={this.props.name}
            id={this.props.id}
            hidden={this.props.hidden}
            onBlur={() => this.onBlur()}
        />);
    }
}
