import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../common/components/input';
import { DexitDevicePairingRepository } from '../../dexit/operatorDevicePairing/dexitDevicePairingRepository';
import { getPortalConfiguration } from '../../getPortalConfiguration';
import { HyenDevicePairingRepository } from '../../hyen/operatorDevicePairing/hyenDevicePairingRepository';

enum DeviceType {
    Dexit,
    Hyen,
    DDNS
}

interface AddDeviceModalContentProps {
    onCloseModal: () => void;
}

export const AddDeviceModalContent = ({ onCloseModal }: AddDeviceModalContentProps) => {
    const { t } = useTranslation('portal_reseller');

    const [isDeviceTypeSelection, setIsDeviceTypeSelection] = useState(true);
    const [selectedDeviceType, setSelectedDeviceType] = useState<DeviceType | undefined>(undefined);
    const [pairingCode, setPairingCode] = useState('');
    const [isConfirmedPairingCode, setIsConfirmedPairingCode] = useState<boolean | null>(null);
    const dexitDevicePairingRepository: DexitDevicePairingRepository = new DexitDevicePairingRepository(getPortalConfiguration());
    const hyenDevicePairingRepository: HyenDevicePairingRepository = new HyenDevicePairingRepository(getPortalConfiguration());

    const handleChangePairingCodeInput = (elementName: any, pairingCode: string) => {
        setPairingCode(pairingCode);
    };

    const handleNextButtonClicked = () => {
        setIsDeviceTypeSelection(false);
    };

    const handleSaveButtonClicked = async () => {
        if (pairingCode !== '') {
            let isConfirmedPairingCode = null;
            if (selectedDeviceType === DeviceType.Dexit) {
                isConfirmedPairingCode = await dexitDevicePairingRepository.postDexitPairing(pairingCode);
            } else if (selectedDeviceType === DeviceType.Hyen) {
                isConfirmedPairingCode = await hyenDevicePairingRepository.postHyenPairing(pairingCode);
            }
            if (!isConfirmedPairingCode) {
                setIsConfirmedPairingCode(isConfirmedPairingCode);
            } else {
                onCloseModal();
            }
        }
    };

    const deviceTypeSelection = (
        <>
            <div className='text-muted mb-4'>{t('AddDevice.DeviceSelectionDescriptionText')}</div>
            {/* Uncomment this if DEXIT is ready */}
            {/* <div className="row">
                <button className="btn addDeviceButton rounded" onClick={() => setSelectedDeviceType(DeviceType.Dexit)}>
                    {t('AddDevice.AddDexitButton')}
                </button>
            </div> */}
            <div className='row'>
                <button className='btn addDeviceButton rounded' onClick={() => setSelectedDeviceType(DeviceType.Hyen)}>
                    {t('AddDevice.AddHyenButton')}
                </button>
            </div>
            <div className='row'>
                <button disabled={true} className='btn addDeviceButton rounded' onClick={() => setSelectedDeviceType(DeviceType.DDNS)}>
                    {t('AddDevice.AddDDNSButton')}
                </button>
            </div>
            <div className='row mt-auto'>
                <div className='col-6 text-left'>
                    <button className='btn btn-outline-danger' onClick={onCloseModal}>
                        {t('AddDevice.CancelButton')}
                    </button>
                </div>
                <div className='col-6 text-right'>
                    <button className='btn btn-danger' disabled={isDeviceTypeSelection && selectedDeviceType === undefined} onClick={handleNextButtonClicked}>
                        {isDeviceTypeSelection ? t('AddDevice.NextButton') : t('AddDevice.SaveButton')}
                    </button>
                </div>
            </div>
        </>
    );

    const pairingCodeInput = (
        <>
            <div className='text-muted mb-4'>{t('AddDevice.PairingCodeDescriptionText')}</div>
            <div className='row'>
                <Input className='addDevicePairingCodeInput' name='pairingCode' type='text' value={pairingCode} placeholder='Pairing Code' onChange={handleChangePairingCodeInput}></Input>
            </div>
            {isConfirmedPairingCode !== null && !isConfirmedPairingCode && <div className='text-danger'>{`${t('AddDevice.UnconfirmedPairingCode')}: ${pairingCode}`}</div>}
            <div className='row mt-auto'>
                <div className='col-6 text-left'>
                    <button className='btn btn-outline-danger' onClick={() => setIsDeviceTypeSelection(true)}>
                        {t('AddDevice.BackButton')}
                    </button>
                </div>
                <div className='col-6 text-right'>
                    <button className='btn btn-danger' disabled={!isDeviceTypeSelection && pairingCode === ''} onClick={handleSaveButtonClicked}>
                        {isDeviceTypeSelection ? t('AddDevice.NextButton') : t('AddDevice.SaveButton')}
                    </button>
                </div>
            </div>
        </>
    );

    return <div className='addDeviceModalContent'>{isDeviceTypeSelection ? deviceTypeSelection : pairingCodeInput}</div>;
};
