import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import './menu.scss';
import RoutingService from '../../../common/routing/routingservice';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/useStore';
import useOutsidElementNotifier from '../../hooks/useOutsidElementNotifier';
import { TFunction } from 'i18next';
import { KnownRoutes } from '../../routing/knownroutes';

const MenuItem = ({ t, route, clicked }: { t: TFunction, route: any, clicked: () => void }) => {
    return (
        <div>
            <NavLink exact={route.exact} className="nav-link" onClick={clicked} to={route.path}>
                <>
                    {t(route.menutextid!)}
                </>
            </NavLink>
        </div>
    );
};

const Logout = ({ t, clicked }: { t: TFunction, clicked: () => void }) => {
    return (
        <div>
            <a className="nav-link" href={KnownRoutes.Logout} onClick={clicked}>
                <>
                    {t('Menu.Logout')}
                    <i className="icon-Logout" />
                </>
            </a>
        </div>
    );
};

const Menu = () => {
    const state = useState(false);
    const [revealed, setRevealed] = state;
    const { portalAuthorization } = useStore().viewStore.user;
    const routes = RoutingService.getNavigatableRoutes(portalAuthorization).filter((route) => route.menu);
    const { t } = useTranslation();

    const hide = () => {
        setRevealed(false);
    };

    const toggle = () => {
        setRevealed(!revealed);
    };

    const menuWrapperRef: React.RefObject<any> = useRef(null);
    useOutsidElementNotifier(revealed ? menuWrapperRef : null, () => hide());

    return (
        <nav ref={menuWrapperRef} className="custom-navbar bg-faded" role="navigation">
            <div className={`d-flex justify-content-center custom-navbar-side ${revealed ? 'show' : ''}`}>
                <div className="d-flex flex-column">
                    <div onClick={toggle} className="custom-navbar-header icon-icon_navigation_close closebutton">
                        <span className="custom-navbar-header-content">{t('Menu.Header')}</span>
                    </div>
                    {routes.map((r, i) => <MenuItem key={i} t={t} route={r} clicked={hide} />)}
                    {portalAuthorization.isAuthenticated && <Logout t={t} clicked={hide} />}
                </div>
            </div>
            <div className="custom-navbar-nav">
                <div className="custom-navbar-toggler pull-left" onClick={toggle} >
                    <i className="icon-icon_navigation_menu" />
                </div>
            </div>
        </nav>
    );
};

export default Menu;