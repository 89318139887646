import React, { useEffect, useState } from 'react';
import './back2top.scss';

export const Back2Top = () => {
    const [isRevealed, setIsRevealed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsRevealed(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isRevealed]);
    
    const scrollToTheTopOfThePage = (e: any) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div data-testid='test-backToTopButton' className={'back2Top ' + (isRevealed ? 'reveal' : '')} onClick={scrollToTheTopOfThePage}>
            <span className="icon-icon_control_arrow_up" />
        </div>
    );
};