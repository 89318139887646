import React from 'react';
import { useStore } from '../../common/stores/useStore';
import { useObserver } from 'mobx-react';
import { CylinderTile } from '../deviceComponent/cylinderComponent/cylinderTile';
import { ICylinderDeviceComponent, IDeviceComponent, DeviceComponentType, IWallreaderDeviceComponent, IRepeaterDeviceComponent, ICameraDeviceComponent, ISlaveComponent } from '../stores/deviceStore';
import { SkeletonTile } from './skeletonTile';
import { WallreaderTile } from './wallreaderComponent/wallreaderTile';
import { TRANSLATIONS_NAMESPACE } from './index';
import { useTranslation } from 'react-i18next';
import { RepeaterTile } from './repeaterComponent/repeaterTile';
import { CameraTile } from './cameraComponent/cameraTile';
import { SlaveTile } from './slaveComponent/slaveTile';

const SkeletonColumns = () => {
    const numberOfLoadingSkeletonTiles = 5;
    const componentTiles: JSX.Element[] = [];
    for (let i = 0; i < numberOfLoadingSkeletonTiles; i++) {
        componentTiles.push(
            <div key={i} className="col-4">
                <SkeletonTile key={i} />
            </div>
        );
    }
    return <React.Fragment>{componentTiles}</React.Fragment>;
};

const TileColumn = (props: { component: IDeviceComponent }) => {
    const map: { [dto: number]: React.ReactNode | undefined } = {
        [DeviceComponentType.Cylinder]: <CylinderTile {...(props.component as ICylinderDeviceComponent)} />,
        [DeviceComponentType.Wallreader]: <WallreaderTile {...(props.component as IWallreaderDeviceComponent)} />,
        [DeviceComponentType.Repeater]: <RepeaterTile {...(props.component as IRepeaterDeviceComponent)} />,
        [DeviceComponentType.Camera]: <CameraTile {...(props.component as ICameraDeviceComponent)} />,
        [DeviceComponentType.Slave]: <SlaveTile {...(props.component as ISlaveComponent)} />
    };
    const deviceComponent: React.ReactNode | undefined = map[props.component.componentType];
    return deviceComponent ? <div className="col-12 col-lg-6 col-xl-4">{deviceComponent}</div> : null;
};

export const DeviceComponentOverview = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    const { deviceStore } = useStore();

    return useObserver(() => {
        const deviceComponents = deviceStore.deviceDetails.data?.deviceComponents;

        function loadAdditionalDeviceComponents() {
            deviceStore.deviceDetails.data?.getAdditionalDeviceComponents();
            return null;
        }

        return (
            <>
                <div className="row flex-fill">
                    {deviceComponents == null && <SkeletonColumns />}
                    {deviceComponents?.length === 0 && <p className="col text-center">{t('Components.NoComponentsInotext')}</p>}
                    {deviceComponents?.map((dc, index) => (
                        <TileColumn key={index} component={dc} />
                    ))}
                </div>
                <div className="row">
                    <div className="col text-center">
                        <button type="button" className="btn btn-outline-secondary" hidden={deviceStore.deviceDetails.data?.allComponentsLoaded} onClick={loadAdditionalDeviceComponents}>
                            {t('Components.LoadMoreButton')}
                        </button>
                    </div>
                </div>
            </>
        );
    });
};
