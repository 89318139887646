import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { useStore } from '../../common/stores/useStore';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';

import { nameof } from '../../common/nameof';
import { ABUSCloudPortalHyenLogsHyenLogDto } from 'portal-bff-proxy-ts';
import moment from 'moment';
import { DeviceLogState } from '../stores/deviceStore/deviceLogsModel';
import { IDataGridColumn, IExportOptions, DataGridExtensions, RemoteDataGrid } from '../../common/components/datagrid';
import Button from '../../common/components/button';
import { LogFilterArea } from './logFilterArea';
import { createExportHeader } from './createExportHeader';

export const Log = () => {
    const { deviceStore } = useStore();
    const { viewStore } = useStore();
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const ref = useRef<DataGridExtensions>(null);

    return useObserver(() => {
        const logs = deviceStore.deviceDetails.data?.logs;
        const device = deviceStore.deviceDetails.data;

        const tMessageType = (value: string | null) => t(`Logs.CellProps.MessageType=${value}`, { defaultValue: value });
        const tLogMessage = (value: string | null) => t(`Logs.CellProps.LogMessage=${value}`, { defaultValue: value });

        const columns: IDataGridColumn<DeviceLogState>[] = [
            { name: nameof<ABUSCloudPortalHyenLogsHyenLogDto>('logMessage'), title: t('Logs.Grid.LogMessage').toString(), getCellValue: (row) => row.logMessage, cellTemplate: (props) => <>{tLogMessage(props.row?.logMessage ?? '--')}</> },
            { name: nameof<ABUSCloudPortalHyenLogsHyenLogDto>('zoneName'), title: t('Logs.Grid.ZoneName').toString(), getCellValue: (row) => row.zoneName, cellTemplate: (props) => <>{(props.row?.zoneName ?? '--')}</> },
            { name: nameof<ABUSCloudPortalHyenLogsHyenLogDto>('partition'), title: t('Logs.Grid.Partition').toString(), getCellValue: (row) => row.partition, cellTemplate: (props) => <>{(props.row?.partition ?? '--')}</> },
            { name: nameof<ABUSCloudPortalHyenLogsHyenLogDto>('username'), title: t('Logs.Grid.UserName').toString(), getCellValue: (row) => row.userName, cellTemplate: (props) => <>{(props.row?.userName ?? '--')}</> },
            { name: nameof<ABUSCloudPortalHyenLogsHyenLogDto>('messageType'), title: t('Logs.Grid.MessageType').toString(), getCellValue: (row) => row.messageType, cellTemplate: (props) => <>{tMessageType(props.row?.messageType ?? '--')}</> },
            { name: nameof<ABUSCloudPortalHyenLogsHyenLogDto>('timestamp'), title: t('Logs.Grid.Timestamp').toString(), getCellValue: (row) => row.timestamp, cellTemplate: (props) => <>{moment.utc(props.row?.timestamp ?? '--').format('LL LTS')}</> }
        ];

        const startExport = async () => {
            await logs?.getExportData();
            ref?.current?.exportGrid();
        };

        const filename = `${moment().format('YYYY-MM-DD_hh-mm')}_${device?.deviceName}_Log`;
        const exportProps: IExportOptions = {
            header: createExportHeader(viewStore.devicesViewStore.title, logs, t),
            exportText: t('ExportDataTop500'),
            startExport,
            filename: filename,
            data: logs?.exportData ?? []
        };

        return (
            <><p className="typo-c1">{t('Logs.Description')}</p><LogFilterArea logModel={logs}></LogFilterArea><RemoteDataGrid
                ref={ref}
                toolbarTemplate={<Button style='outline-secondary' className="border-0" onClick={() => logs?.resetFilters()}><i className="p-1 icon-icon_control_reset" />{t('Logs.Filter.ShowAllLogs')}</Button>}
                export={exportProps}
                columns={columns}
                queryableDataModel={logs} /></>

        );
    });
};