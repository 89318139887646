export enum KnownRelationshipsLegacy {
  Employee = 'employee',
  External = 'external',
  Partner = 'partner',
  None = '',
}

export enum CustomerTypesCIAM {
  B2C = 'b2c',
  B2B = 'b2b',
  B2BI = 'b2bi',
  Employee = 'employee',
  External = 'external',
}
