import { useTranslation } from 'react-i18next';
import moment from 'moment';
import React from 'react';
import { assertState } from '../../../common/utils/modelState';
import { nameof } from '../../../common/nameof';
import { ICylinderDeviceComponent } from '../../stores/deviceStore';
import { SystemServicesConfigurationModel } from '../../stores/deviceStore/systemServicesConfigurationModel';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../..';
import { useObserver } from 'mobx-react';
import { BatteryReportSchedule } from './batteryReportSchedule';

import { IDataGridColumn, LocalDataGrid } from '../../../common/components/datagrid';

export interface IBatteryReportProps {
    systemServiceConfigurations: SystemServicesConfigurationModel;
}

export const BatteryReport = (props: IBatteryReportProps) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        type RowDataType = ICylinderDeviceComponent;
        const loaded = assertState(props.systemServiceConfigurations.cylinders, 'ready', 'error');
        const columns: IDataGridColumn<RowDataType>[] = [
            { name: nameof<RowDataType>('name'), title: t('SySe.BatteryReport.Grid.Name').toString() },
            { name: nameof<RowDataType>('type'), title: t('SySe.BatteryReport.Grid.Type').toString(), getCellValue: (cellprops) => t(['SySe.BR.Type', cellprops.type].join('.')) },
            { name: nameof<RowDataType>('batteryState'), title: t('SySe.BatteryReport.Grid.BatteryState').toString(), getCellValue: (cellprops) => t(['SySe.BR.BatteryState', cellprops.batteryState].join('.')) },
            { name: nameof<RowDataType>('lastBatteryChange'), title: t('SySe.BatteryReport.Grid.LastBatteryChange').toString(), cellTemplate: (cellprops) => (<>{cellprops.row?.lastBatteryChange ? moment(cellprops.row.lastBatteryChange).format('L') : t('SySe.BR.NoBatteryLastChangeDate')}</>) }
        ];
        return (
            <React.Fragment>
                <div className="mt-5">
                    <h2 className="typo-h6 pb-3">{t('SySe.BatteryReport')}</h2>
                    <p className="typo-c1" >{t('SySe.BatteryReport.Explanation')}</p>
                    
                    <BatteryReportSchedule
                        batteryReportConfiguration={props.systemServiceConfigurations.batteryReportConfiguration}
                        allMails={props.systemServiceConfigurations.allEmails}
                    />
              
                    <LocalDataGrid
                        data={Array.isArray(props.systemServiceConfigurations.cylinders) ? props.systemServiceConfigurations.cylinders : null}
                        loaded={loaded }
                        columns={columns}
                    />
                
                </div>
            </React.Fragment>
        );
    });
};
