import React from 'react';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DeviceComponentTile } from '../deviceComponentTile';
import { DeviceComponentTileHeader } from '../deviceComponentTileHeader';
import { WallreaderIcon } from './wallreaderTileIcon';
import { IWallreaderDeviceComponent } from '../../stores/deviceStore/deviceContracts';
import { TRANSLATIONS_NAMESPACE } from '../index';
import moment from 'moment';

export const WallreaderTile = (props: IWallreaderDeviceComponent) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    const bodyElements: string[] = [
        t('Components.Type.Wallreader'),
        t('Components.Firmwareversion') + ' ' + props.firmwareVersion,
        t('Components.SignalStrength') + ' ' + props.signalStrength.toString(),
        t('Components.DateOfProduction') + ' ' + moment(props.dateOfProduction).format('YYYY/MM')
    ];

    return useObserver(() => {
        return (
            <DeviceComponentTile
                componentTileHeader={<DeviceComponentTileHeader name={props.name} signalState={props.signalState} />}
                componentTileIcon={<WallreaderIcon />}
                componentTileBodyElements={bodyElements}
            />
        );
    });
};