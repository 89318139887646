import React from 'react';
import Button, { IButtonProps } from './button';

export const CancelButton = (props: React.PropsWithChildren<Pick<IButtonProps, 'onClick'>>) => {
    return (<Button
        onClick={props.onClick}
        className="mt-2 w-100"
        style="outline-secondary"
    >
        {props.children}
    </Button>);
};