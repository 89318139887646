import { action, observable } from 'mobx';
import { DeferredDataModel } from './deferredDataModel';
import { IBaseModelStore } from './baseModelStore';

export type SortDirection = 'Ascending' | 'Descending'

export interface QueryableState {
    limit: number;
    offset: number;
    sorting: Array<{ fieldName: string, direction: SortDirection }>
}

export const DefaultPageSizeList = [15, 30, 50];
export const DefaultPageSize = DefaultPageSizeList[0];

export abstract class QueryableDataModel<T, TSourceDto> extends DeferredDataModel<T[]> implements IBaseModelStore<QueryableState> {
    @observable limit = DefaultPageSize;
    @observable offset = 0;
    @observable sorting: Array<{ fieldName: keyof TSourceDto, direction: SortDirection }> = [];

    @observable totalCount?: number;

    public abstract update(): void;

    @action.bound
    async setLimit(limit: number) {
        this.limit = limit;
        await this.update();
    }

    @action.bound
    async setOffset(offset: number) {
        this.offset = offset;
        await this.update();
    }

    @action.bound
    async setSort(sort: { fieldName: keyof TSourceDto, direction: SortDirection }) {
        this.sorting = [sort];
        this.offset = 0;
        await this.update();
    }

    updateFromJSON(state: QueryableState): this {
        this.limit = state.limit;
        this.offset = state.offset;
        this.sorting = state.sorting as Array<{ fieldName: keyof TSourceDto, direction: SortDirection }>;
        return this;
    }

    toJSON(): QueryableState {
        return {
            limit: this.limit,
            offset: this.offset,
            sorting: this.sorting as Array<{ fieldName: string, direction: SortDirection }>
        };
    }
}