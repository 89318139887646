
import { ABUSCloudPortalHyenAppPairingAppPairingDto } from 'portal-bff-proxy-ts';
import { Helper } from '../../../common';
import { IAppPairingModelState } from './appPairingModel';
import { mapAppPairingDto } from './mapper/device';

export function convertAppPairingData(dtos: ABUSCloudPortalHyenAppPairingAppPairingDto[]): IAppPairingModelState[] {
    const mapkey = (dto: ABUSCloudPortalHyenAppPairingAppPairingDto) => {
        return `deviceId:${dto.deviceId}|email:${dto.email}`;
    };
    const map = new Map<string, IAppPairingModelState>();
    (dtos).forEach((dto) => {
        const existing = map.get(mapkey(dto));
        if (existing == null) {
            map.set(mapkey(dto), mapAppPairingDto(dto));
        } else {
            existing.pairingDateList.push(Helper.getStringValueOrDefault(dto.pairingDate));
        }
    });

    const data: IAppPairingModelState[] = [];
    map.forEach(value => data.push(value));
    return data;
}