import React from 'react';

interface ProgressBarProps {
  width: string;
  height: string;
  percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ width, height, percentage }) => {
    return (
        <div style={{ width, height, border: '1px solid black', marginTop: '10px' }}>
            <div style={{
                width: `${percentage}%`,
                height: '100%',
                backgroundColor: 'green'
            }} />
        </div>
    );
};

export default ProgressBar;
