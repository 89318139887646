import { useObserver } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../../../devices';
import Button from '../button/button';
import { LabeledData } from '../labeledData/labeledData';
import { useStore } from '../../stores/useStore';
import { assertState } from '../../utils/modelState';

function filterEmptyAndJoin(...args: Array<string | undefined>): string | undefined {
    return args.filter((x) => x !== undefined).join(' ');
}

export const Metadata = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const { deviceStore } = useStore();

    return useObserver(() => {
        const deviceMetadata = deviceStore.deviceDetails.data?.operatorMetadata;
        const isLoading = assertState(deviceStore.deviceDetails.state, 'initial', 'loading');
        const showSkeleton = isLoading;
        
        return (
            <React.Fragment>
                <div className="mb-3">{t('Device.Metadata.Headline')}</div>
                <LabeledData
                    labelName={t('Device.Metadata.OperatorName')}
                    data={filterEmptyAndJoin(deviceMetadata?.firstName, deviceMetadata?.lastName)}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.Street')}
                    data={filterEmptyAndJoin(deviceMetadata?.street, deviceMetadata?.houseNumber)}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.City')}
                    data={filterEmptyAndJoin(deviceMetadata?.postalCode, deviceMetadata?.city)}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.Phone')}
                    data={deviceMetadata?.phone}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.EMail')}
                    data={deviceMetadata?.email}
                    showSkeleton={showSkeleton}
                />
                <LabeledData
                    labelName={t('Device.Metadata.DateOfInstallation')}
                    data={deviceMetadata?.installationDate ? moment(deviceMetadata?.installationDate).format('DD/MM/YYYY') : null}
                    showSkeleton={showSkeleton}
                />

                <Button
                    type="button"
                    style="link"
                    disabled={deviceMetadata?.isInEditMode}
                    onClick={() => deviceMetadata?.startEdit()}
                    className="btn btn-link metadata-edit-button"
                >
                    {t('Device.Metadata.EditLink') + ' >'}
                </Button>
            </React.Fragment>
        );
    });
};