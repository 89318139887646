import { useEffect } from 'react';

const useOutsidElementNotifier = (ref: React.RefObject<any> | null, notifiyCallback: () => void) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event: MouseEvent) => {
            if (ref && !ref.current.contains(event.target)) {
                notifiyCallback();
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default useOutsidElementNotifier;