import * as React from 'react';
import './avatar.scss';

export interface IAvatarProps {
    firstname: string;
    lastname: string;
    fallbackIcon?: string;
}

export default class Avatar extends React.Component<IAvatarProps> {
    private getAbbreviation(firstname: string, lastname: string): string {
        let result = '';
        if (firstname && firstname.length > 0) {
            result += firstname.substring(0, 1).toUpperCase();
        }

        if (lastname && lastname.length > 0) {
            result += lastname.substring(0, 1).toUpperCase();
        }

        return result;
    }

    public render() {
        const abbreviation = this.getAbbreviation(this.props.firstname, this.props.lastname);
        const fallbackIcon = this.props.fallbackIcon || 'icon-user';
        return (
            <div className={`avatar ${abbreviation.length === 0 ? fallbackIcon : ''}`}>{abbreviation}</div>
        );
    }
}
