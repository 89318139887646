import { action, observable } from 'mobx';
import { BaseModelStore } from '../../../common/stores/baseModelStore';
import { DeviceType } from './deviceContracts';
import { DeviceFeatures, DeviceModel, IDeviceModelState } from './deviceModel';
import DeviceStore from './deviceStore';
import { SystemServicesConfigurationModel } from './systemServicesConfigurationModel';

export interface IHyenDeviceModelState extends IDeviceModelState {
    siteId: string|null;
    connectionType: string|null;
    zonesAvailable: number|null;
    zonesUsed: number|null;
    hardwareExpanders: number|null;
    rfExpanders: number|null;
    keypads: number|null;
    psuExpanders: number|null;
    macAddress: string|null;
}

export class HyenDeviceModel extends DeviceModel implements BaseModelStore<IHyenDeviceModelState> {
    readonly supportedFeatures: DeviceFeatures = DeviceFeatures.Logs | DeviceFeatures.RemoteConnect | DeviceFeatures.AppPairings;
    
    @observable
    siteId: string|null = null;

    @observable
    connectionType: string|null = null;

    @observable
    zonesAvailable: number|null = null;

    @observable
    zonesUsed: number|null = null;

    @observable
    hardwareExpanders: number|null = null;

    @observable
    rfExpanders: number|null = null;

    @observable
    keypads: number|null = null;

    @observable
    psuExpanders: number|null = null;

    @observable
    macAddress: string|null = null;

    static create(parent: DeviceStore, state: IHyenDeviceModelState) {
        const model = new HyenDeviceModel(parent, DeviceType.Hyen).updateFromJSON(state);
        model.type = DeviceType.Hyen;
        model.systemServiceConfigurations = new SystemServicesConfigurationModel(model);
        return model;
    }

    @action.bound
    updateFromJSON(state: IHyenDeviceModelState): this {
        super.updateFromJSON(state);
        this.siteId = state.siteId;
        this.connectionType = state.connectionType;
        this.zonesAvailable = state.zonesAvailable;
        this.zonesUsed = state.zonesUsed;
        this.hardwareExpanders = state.hardwareExpanders;
        this.rfExpanders = state.rfExpanders;
        this.keypads = state.keypads;
        this.psuExpanders = state.psuExpanders;
        this.macAddress = state.macAddress;
        return this;
    }

    @action.bound
    toJSON(): IHyenDeviceModelState {
        return {
            ...super.toJSON(),
            siteId: this.siteId,
            connectionType: this.connectionType,
            zonesAvailable: this.zonesAvailable,
            zonesUsed: this.zonesUsed,
            hardwareExpanders: this.hardwareExpanders,
            rfExpanders: this.rfExpanders,
            keypads: this.keypads,
            psuExpanders: this.psuExpanders,
            macAddress: this.macAddress
        };
    }
}