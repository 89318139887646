import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { BaseRepository } from '../../common';
import { IPortalConfiguration } from '../../common/interfaces';
import { createApiProxy } from '../../common/createApiProxy';

/* eslint-disable no-useless-catch */
export class HyenDevicePairingRepository extends BaseRepository {
    private api: AbusCloudApi.HyenApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.api = new AbusCloudApi.HyenApi(this.apiConfiguration);
    }

    public async postHyenPairing(pairingCode: string): Promise<boolean> {
        try {
            await createApiProxy(() => this.api.apiHyenOperatorPairingPairingPost(pairingCode))();
            return true;
        } catch (e: any) {
            return false;
        }
    }
}
