import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UserDetails } from '.';
import { useObserver } from 'mobx-react';
import { KnownRoutes } from '../../common/routing/knownroutes';
import WindowedPage from '../../common/components/windowedPage/windowedPage';
import withScrollToTop from '../../common/components/withScrollToTop';

const UserprofilePage = () => {
    const [t] = useTranslation('portal_access');

    return useObserver(() => {
        return (<React.Fragment>
            <WindowedPage
                classNamesRoot="userprofilePage"
                headerText={t('Profile.Title')!}
                backNav={{ backPath: KnownRoutes.Search, text: t('Home') }}
            >
                <UserDetails />
            </WindowedPage>
        </React.Fragment>
        );
    });
};

export default withScrollToTop(UserprofilePage);