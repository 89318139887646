import './deviceStateApprovalBox.scss';
import { useObserver } from 'mobx-react';
import React from 'react';
import { className } from '../../../common';
import { DeviceAccessModel, DeviceAccessState } from '../../stores/deviceStore/deviceAccessModel';
import Button from '../../../common/components/button/button';
import { Countdown } from '../../../common/components/countdown/countdown';
import { DeviceAccessPrefixedTrans } from './deviceAccessPrefixedTrans';

export const DeviceStateApprovalBox = ({ deviceAccess }: { deviceAccess: DeviceAccessModel }) => {
    const boxClassMap: { [state: string]: string } = {
        ['Approved' as DeviceAccessState]: 'border-success',
        ['Requested' as DeviceAccessState]: 'border-danger',
        ['None' as DeviceAccessState]: 'border-dark'
    };
    const boxClassNme = boxClassMap[deviceAccess.state];
    return useObserver(() => {
        const textColorClass = deviceAccess.state === 'Approved' ? 'text-success' : 'text-danger';
        return (
            <div className="deviceStateApprovalBox p-0 d-flex flex-column">
                <div className={className('statusbox border d-flex flex-column justify-content-center', boxClassNme)}>
                    <div className="d-flex align-items-center justify-content-between">
                        {deviceAccess.state !== 'None' && <><p className="typo-c1 p-0 pl-1 m-0"><DeviceAccessPrefixedTrans label="ApprovalBox.Remainingtime" /></p><Countdown className={className('typo-c1', textColorClass)} milliseconds={deviceAccess.timeLeft} /><span /></>}
                        {deviceAccess.state === 'None' && <><span /><p className="typo-c1"><DeviceAccessPrefixedTrans label="ApprovalBox.NoRequest" /></p><span /></>}
                    </div>
                    {deviceAccess.state !== 'None' && <p className="typo-c1 p-0 pl-1 m-0"><DeviceAccessPrefixedTrans label="ApprovalBox.StatusText" /></p>}
                </div>

                {deviceAccess.state === 'Requested' && deviceAccess.userHasAccessStatusViewPermission && <div className="d-flex"><Button className='m-1 ml-auto' onClick={() => deviceAccess.approve()} style='danger'><DeviceAccessPrefixedTrans label="ApprovalBox.ApproveRequest" /></Button></div>}
            </div>
        );
    });
};
