import { NotificationConfigurationModel, INotificationConfigurationState } from './notificationConfigurationModel';
import { getPortalConfiguration } from '../../../getPortalConfiguration';
import { DexitNotificationsRepository } from './dexitNotificationsRepository';
import { HyenNotificationsRepository } from './hyenNotificationsRepository';
import { observable, action, computed, toJS } from 'mobx';
import { getViewStore } from '../../../storeRegistry';
import { ModelState } from '../../../common/utils/modelState';
import { flatten } from '../../../common/utils/flatten';
import DeviceRepository from './repositories/deviceRepository';
import { DeviceType, ICylinderDeviceComponent } from './deviceContracts';
import { defaultLogger } from '../../../../logger';
import { BatteryReportConfigurationModel } from '../../systemServices/batteryReport/batteryReportConfigurationModel';
import { BatteryReportRepository } from '../../systemServices/batteryReport/batteryReportRepository';
import MockDeviceRepository from './repositories/mockDeviceRepository';
import { getStoreEnv } from '../../../commonRegistry';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../..';
import { DeviceFeatures, DeviceModel } from './deviceModel';
import { union } from 'lodash';
import { ComfionNotificationsRepository } from './comfionNotificationsRepository';

export interface ISystemServicesConfigurationState {
    notificationConfigurations: INotificationConfigurationState[];
    notificationConfigurationsState: ModelState;
    batteryReportConfiguration: BatteryReportConfigurationModel;
    batteryReportConfigurationState: ModelState;
    cylinders: ModelState | ICylinderDeviceComponent[];
}
export class SystemServicesConfigurationModel {
    @computed
    public get allEmails() {
        const allNotificationMails = flatten(this.notificationConfigurations.map((nc) => nc.recipients));
        const allBatteryReportMails = this.batteryReportConfiguration.recipients;
        return union(allNotificationMails, allBatteryReportMails).sort();
    }

    @observable
    public notificationConfigurations: NotificationConfigurationModel[] = [];

    @observable
    public notificationConfigurationsState: ModelState = 'initial';

    @observable
    public batteryReportConfiguration: BatteryReportConfigurationModel = new BatteryReportConfigurationModel();

    @observable
    public batteryReportConfigurationState: ModelState = 'initial';

    @observable
    public cylinders: ModelState | ICylinderDeviceComponent[] = 'initial';

    public notiRepo: DexitNotificationsRepository | HyenNotificationsRepository | ComfionNotificationsRepository | undefined;

    public deviceType : DeviceType = DeviceType.Unknown;

    constructor(private parent: DeviceModel) {
        if (this.parent.type === DeviceType.Dexit) {
            this.deviceType = DeviceType.Dexit;
            this.notiRepo = new DexitNotificationsRepository(getPortalConfiguration());
        }

        if (this.parent.type === DeviceType.Hyen) {
            this.deviceType = DeviceType.Hyen;
            this.notiRepo = new HyenNotificationsRepository(getPortalConfiguration());
        }

        if (this.parent.type === DeviceType.Comfion) {
            this.deviceType = DeviceType.Comfion;
            this.notiRepo = new ComfionNotificationsRepository(getPortalConfiguration());
        }
    }

    @action
    public async get() {
        await Promise.all([
            this.getNotificationConfigurations(),
            this.getBatteryReportConfiguration(),
            this.getCylindersWithMockFallback()
        ]);
    }

    @action
    public async refresh(useSkeleton = false) {
        await Promise.all([
            this.notificationConfigurationsState !== 'initial' ? await this.getNotificationConfigurations(useSkeleton) : Promise.resolve(),
            this.batteryReportConfigurationState !== 'initial' ? await this.getBatteryReportConfiguration() : Promise.resolve(),
            this.cylinders !== 'initial' ? this.getCylindersWithMockFallback() : Promise.resolve()
        ]);
    }

    private async getCylindersWithMockFallback() {
        if (this.parent.supportsFeature(DeviceFeatures.BatteryReport)) {
            const deviceRepo = new DeviceRepository(getPortalConfiguration());
            try {
                if (this.parent.deviceId) {
                    const cylinders = await deviceRepo.getDexitCylinders(this.parent.deviceId);
                    this.cylinders = cylinders || [];
                }
            } catch (e : any) {
                defaultLogger.error(e);
                this.cylinders = 'error';
            }
        }
    }

    private async getMockCylinders() {
        const mockRepository = new MockDeviceRepository();
        const mockData = await mockRepository.getCylinders();

        const { i18next } = getStoreEnv();

        mockData!.forEach((cylinder: ICylinderDeviceComponent) => {
            cylinder.name = i18next.t(`DummyData.CylinderNames.${cylinder.name}`, { ns: TRANSLATIONS_NAMESPACE_DEVICE, defaultValue: cylinder.name });
        });
        return mockData;
    }

    private async getNotificationConfigurations(useSkeleton = false) {
        if (this.parent.deviceId) {
            const viewStore = getViewStore();
                    
            try {
                useSkeleton === true ? this.notificationConfigurationsState = 'loading' : this.notificationConfigurationsState = 'ready';
                const notificationNames = (await this.notiRepo!.getAvailableNotifications()).sort((a, b) => a.localeCompare(b));
                this.notificationConfigurations = notificationNames.map((notificationName) =>
                    NotificationConfigurationModel.create(this.parent.type, notificationName, this.parent.deviceId!, viewStore.user.userSettings.language || ''));
                this.notificationConfigurationsState = 'ready';
            } catch (e : any) {
                this.notificationConfigurationsState = 'error';
            }

            try {
                const existingSettings = await this.notiRepo!.getNotificationSettings(this.parent.deviceId);
                this.notificationConfigurations.forEach((existingModel) => {
                    const remoteState = existingSettings.find((x) => x.name === existingModel.name);
                    if (remoteState) {
                        existingModel.updateFromJSON(remoteState);
                    }
                    existingModel.state = 'ready';
                });
            } catch (e : any) {
                this.notificationConfigurations.forEach((existingModel, index) => {
                    if (index === 0) {
                        this.notificationConfigurations[0].apiError(e, 'load failed');
                    }
                    existingModel.state = 'error';
                });
            }
        }
    }

    public reset() {
        this.notificationConfigurations = [];
    }

    public toJSON(): ISystemServicesConfigurationState {
        return {
            cylinders: this.cylinders,
            notificationConfigurations: this.notificationConfigurations.map((x) => x.toJSON()),
            notificationConfigurationsState: toJS(this.notificationConfigurationsState),
            batteryReportConfiguration: this.batteryReportConfiguration,
            batteryReportConfigurationState: this.batteryReportConfigurationState
        };
    }

    @action
    public updateFromJSON(existingState: ISystemServicesConfigurationState) {
        this.notificationConfigurations = existingState.notificationConfigurations.map((s) => NotificationConfigurationModel
            .create(this.deviceType, s.name, s.deviceId, '')
            .updateFromJSON(s));
        this.notificationConfigurationsState = existingState.notificationConfigurationsState;
        this.cylinders = existingState.cylinders;
    }

    @action
    public async getBatteryReportConfiguration() {
        if (this.parent.supportsFeature(DeviceFeatures.BatteryReport)) {
            const viewStore = getViewStore();
            try {
                this.batteryReportConfigurationState = 'loading';
                const configModel = BatteryReportConfigurationModel.create('batteryReport', this.parent.deviceId!, viewStore.user.userSettings.language || '');
                this.batteryReportConfiguration = configModel;
                this.batteryReportConfigurationState = 'ready';
            } catch (e : any) {
                this.batteryReportConfigurationState = 'error';
            }

            try {
                const batteryReportRepository = new BatteryReportRepository(getPortalConfiguration());
                const portalConfiguration = await batteryReportRepository.getBatteryReportConfigurationPortal(this.parent.deviceId!);
                const notificationsRepository = new DexitNotificationsRepository(getPortalConfiguration());
                const notificationConfiguration = await notificationsRepository.getBatteryReportConfigurationReceivers(this.parent.deviceId!);
                portalConfiguration.recipients = notificationConfiguration!;
                this.batteryReportConfiguration.updateFromJSON(portalConfiguration!);
                this.batteryReportConfigurationState = 'ready';
                this.batteryReportConfiguration.state = 'ready';
            } catch (e : any) {
                this.batteryReportConfiguration.apiError(e, 'load failed');
                this.batteryReportConfiguration.state = 'error';
            }
        }
    }
}