import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { BaseRepository } from '../../common';
import { createApiProxy, IApiProxyOptions } from '../../common/createApiProxy';
import HttpStatusCode from '../../common/httpStatusCode';
import { IPortalConfiguration } from '../../common/interfaces';
import { IAocDeviceSettingsState } from './aocDeviceSettings';
import { IOperatorMetadataState, OperatorMetadataModel } from '../../devices/stores/deviceStore/operatorMetadataModel';

export class AocDeviceRepository extends BaseRepository {
    aocSettingsClient: AbusCloudApi.TectiqApi;
    operatorMetadataClient: AbusCloudApi.CoreApi;

    public async getOperatorMetadataForDevice(deviceId: string): Promise<IOperatorMetadataState | null> {
        const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
        const call = createApiProxy(async () => this.operatorMetadataClient.apiCoreDeviceOperatorMetadataDeviceIdGet(
            deviceId), this.mapOperatorMetadataDtoToMetadataState, opt);
        const result = await call();
        return result;
    }

    async saveOperatorMetadata(deviceId: string, data: IOperatorMetadataState) {
        const call = createApiProxy(async () =>
            this.operatorMetadataClient.apiCoreDeviceOperatorMetadataDeviceIdPost(deviceId, this.mapOperatorMetadataStateToMetadataDto(data)));
        const result = await call();
        return result;
    }

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.aocSettingsClient = new AbusCloudApi.TectiqApi(this.apiConfiguration);
        this.operatorMetadataClient = new AbusCloudApi.CoreApi(this.apiConfiguration);
    }

    public async getDeviceDetails(deviceId: string, metadata: IOperatorMetadataState): Promise<IAocDeviceSettingsState | null> {
        const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
        const call = createApiProxy(async () => this.aocSettingsClient.apiTectiqTectiqSettingsDeviceIdGet(deviceId), this.mapDeviceDto, opt);
        const result = await call();
        result!.operatorMetadata = metadata;
        return result;
    }

    mapDeviceDto = (d: AbusCloudApi.ABUSCloudPortalTectiqSettingsTectiqSettingsDto): IAocDeviceSettingsState => {
        const result: IAocDeviceSettingsState = {
            deviceId: d.id ?? 'emptyguid',
            deviceName: d.deviceName!,
            type: 'aoc',
            serialNumber: d.serialNumber ?? '',
            firmwareVersion: d.firmwareVersion ?? '',
            ipAddress: d.internalIpAddress ?? '',
            operatorMetadata: undefined
        };
        return result;
    };

    mapAocOperatorMetaDataToMetadataState = (d: OperatorMetadataModel): IOperatorMetadataState => {
        return {
            firstName: d.firstName,
            lastName: d.lastName,
            email: d.email,
            phone: d.phone,
            street: d.street,
            houseNumber: d.houseNumber,
            city: d.city,
            postalCode: d.postalCode,
            projectName: d.projectName,
            installationDate: d.installationDate?.toISOString(),
            eTag: 'changed'
        };
    }

    mapOperatorMetadataDtoToMetadataState = (dto: AbusCloudApi.ABUSCloudPortalCommonFeaturesResellerDeviceOperatorMetadataDto): IOperatorMetadataState => {
        if (dto === null || dto === undefined) {
            return {};
        } else {
            return {
                firstName: dto.firstName,
                lastName: dto.lastName,
                email: dto.email?.toString(),
                phone: dto.phone,
                street: dto.street,
                houseNumber: dto.houseNumber,
                city: dto.city,
                postalCode: dto.postalCode,
                projectName: dto.projectName,
                installationDate: dto.installationDate,
                eTag: 'changed'
            };
        }
    }

    mapOperatorMetadataStateToMetadataDto = (d: IOperatorMetadataState):AbusCloudApi.ABUSCloudPortalCommonFeaturesResellerDeviceOperatorMetadataDto => {
        return {
            lastName: d.lastName,
            firstName: d.firstName,
            street: d.street,
            houseNumber: d.houseNumber,
            city: d.city,
            postalCode: d.postalCode,
            email: d.email as any, // This will be converted to type of MailAddress by JSON annotation in the backend
            phone: d.phone,
            installationDate: d.installationDate,
            projectName: d.projectName
        };
    }
}