import { NotificationConfigurationModel } from '../stores/deviceStore/notificationConfigurationModel';
import { useObserver } from 'mobx-react';
import React from 'react';
import { Switch } from '../../common/components/switch';
import TagBoxInput from '../../common/components/tagBoxInput/tagBoxInput';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { assertState } from '../../common/utils/modelState';
import { Label } from '../../common/components/label/label';
import { translateErrors } from '../../common/utils/errors';

const NotificationConfiguration = (props: { notificationConfiguration: NotificationConfigurationModel; allEmails: string[] }) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        const translatedErrors = translateErrors(props.notificationConfiguration.errors, TRANSLATIONS_NAMESPACE_DEVICE);

        return (
            <div className="d-flex row justify-content-between">
                <div className="d-flex">
                    <Switch
                        className="pr-2"
                        value={props.notificationConfiguration.enabled}
                        disabled={assertState(props.notificationConfiguration.state, 'initial', 'loading')}
                        onValueChange={(change: any) => {
                            props.notificationConfiguration.changeEnabled(change);
                        }}
                    />
                    <Label isLoading={assertState(props.notificationConfiguration.state, 'initial', 'loading')} text={t('SySe.Notification_' + props.notificationConfiguration.name.toLowerCase())} />
                </div>
                <TagBoxInput
                    className="col-12 col-lg-7 col-xl-9"
                    name="recipients"
                    placeholder={t('Email').toString()}
                    modelTypeRef={props.notificationConfiguration}
                    isLoading={assertState(props.notificationConfiguration.state, 'initial', 'loading')}
                    disabled={assertState(props.notificationConfiguration.state, 'initial', 'loading')}
                    onChange={(name, value) => props.notificationConfiguration.changeRecipientsAndSave(value || [])}
                    value={props.notificationConfiguration.recipients}
                    errorMessage={translatedErrors.get() as any}
                    valuesList={props.allEmails}
                    autoOpenList={props.allEmails.length > 0}
                    showClearButton={true}
                    acceptCustomValue={true}
                    hoverStateEnabled={true}
                    focusStateEnabled={true}
                    activeStateEnabled={true}
                />
            </div>
        );
    });
};

export default NotificationConfiguration;
