
import { ABUSCloudPortalHyenLogsHyenLogDto, ABUSCloudPortalHyenLogsHyenLogQueryResult } from 'portal-bff-proxy-ts';
import { DeviceLogState } from '../deviceLogsModel';

export function mapHyenLogDto(input: ABUSCloudPortalHyenLogsHyenLogDto): DeviceLogState {
    return {
        logMessage: input.logMessage ?? null,
        zoneName: input.zoneName ?? null,
        partition: input.partition ?? null,
        userName: input.username ?? null,
        messageType: input.messageType ?? null,
        timestamp: input.timestamp ?? null
    };
}

export function mapHyenLogDtoQueryResult(input: ABUSCloudPortalHyenLogsHyenLogQueryResult): { totalCount: number, items: DeviceLogState[], availableLogFilterGroupNames: string[] } {
    return {
        totalCount: input.totalCount ?? 0,
        items: input.items?.map(logItem => mapHyenLogDto(logItem)) ?? [],
        availableLogFilterGroupNames: input.groupNames ?? []
    };
}