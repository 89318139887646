import { ABUSCloudPortalCommonFeaturesOperatorAssignedOperatorDto, ABUSCloudPortalCommonFeaturesOperatorOperatorProfileDto } from 'portal-bff-proxy-ts';
import { Helper } from '../../../../common';
import { UserProfileModel } from '../userProfileModel';
import { OperatorAssignmentModel } from '../../../../devices/operatorDeviceAssignmentsOverview/OperatorAssignmentModel';

export const toIOperatorProfileStoreState = (dto: ABUSCloudPortalCommonFeaturesOperatorOperatorProfileDto): UserProfileModel => ({
    lastName: Helper.getStringValueOrDefault(dto.lastName),
    firstName: Helper.getStringValueOrDefault(dto.firstName),
    email: ''
});

export const toOperatorPairingModel = (dtos: ABUSCloudPortalCommonFeaturesOperatorAssignedOperatorDto[]): OperatorAssignmentModel[] =>
    dtos.map((dto) => {
        return {
            eMail: Helper.getStringValueOrDefault(dto.email),
            dateOfPairing: Helper.getStringValueOrDefault(dto.dateOfPairing),
            operatorId: Helper.getStringValueOrDefault(dto.operatorId)
        };
    });
