
import { ABUSCloudPortalCommonFeaturesLocalizationLocalizationSettingDto, ABUSCloudPortalDexitNotificationsNotificationTypeSettingsDto, ABUSCloudPortalHyenNotificationsNotificationTypeSettingsDto } from 'portal-bff-proxy-ts';
import { Helper } from '../../../../common/utils/helper';
import { INotificationConfigurationState } from '../notificationConfigurationModel';

export const mapDtoToNotificationConfigurationState = (setting: ABUSCloudPortalHyenNotificationsNotificationTypeSettingsDto | ABUSCloudPortalDexitNotificationsNotificationTypeSettingsDto): INotificationConfigurationState => {
    return {
        deviceId: Helper.getStringValueOrDefault(setting.deviceId),
        enabled: true,
        name: Helper.getStringValueOrDefault(setting.notificationName),
        recipients: setting.receivers || []
    };
};

export const mapToUpdateNotificationSettingsDto = (settingState: INotificationConfigurationState): ABUSCloudPortalHyenNotificationsNotificationTypeSettingsDto | ABUSCloudPortalDexitNotificationsNotificationTypeSettingsDto => {
    return {
        receivers: settingState.recipients,
        deviceId: settingState.deviceId,
        notificationName: settingState.name
    };
};

export const mapListOfReceiversFromNotificationSettingsDto = (setting: ABUSCloudPortalHyenNotificationsNotificationTypeSettingsDto | ABUSCloudPortalDexitNotificationsNotificationTypeSettingsDto): string[] => {
    return setting.receivers ? setting.receivers : [];
};

export const mapLocalizationSettingDto = (localizationSettingDto: ABUSCloudPortalCommonFeaturesLocalizationLocalizationSettingDto): string => {
    return Helper.getStringValueOrDefault(localizationSettingDto.languageCode);
};

export const mapNotificationSettingsLanguages = (notificationSettingsLanguages: string[]) => {
    if (notificationSettingsLanguages.length === 0) {
        return [];
    }
    return notificationSettingsLanguages;
};