import React from 'react';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DeviceComponentTile } from '../deviceComponentTile';
import { DeviceComponentTileHeader } from '../deviceComponentTileHeader';
import { CylinderTileIcon } from './cylinderTileIcon';
import { ICylinderDeviceComponent } from '../../stores/deviceStore/deviceContracts';
import { TRANSLATIONS_NAMESPACE } from '../index';
import moment from 'moment';

export const CylinderTile = (props: ICylinderDeviceComponent) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    const dateOfProductionLiteral = props.dateOfProduction != null ? moment(props.dateOfProduction!).format('YYYY/MM') : t('n/a');
    const bodyElements: string[] = [
        t('Components.Type.Cylinder'),
        t('Components.Firmwareversion') + ' ' + props.firmwareVersion,
        t('Components.SignalStrength') + ' ' + props.signalStrength.toString(),
        t('Components.DateOfProduction') + ' ' + dateOfProductionLiteral,
        props.armEnabled ? t('Components.ARMEnabled') : t('Components.ARMDisabled'),
        props.learnEnabled ? t('Components.LearnEnabled') : t('Components.LearnDisabled')
    ];

    return useObserver(() => {
        return (
            <DeviceComponentTile
                componentTileHeader={<DeviceComponentTileHeader name={props.name} batteryState={props.batteryState} signalState={props.signalState} />}
                componentTileIcon={<CylinderTileIcon cylinderType={props.type} />}
                componentTileBodyElements={bodyElements}
            />
        );
    });
};