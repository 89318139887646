import './toggleButton.scss';
import React, { ReactNode } from 'react';
import { className } from '../../utils/helper';

export interface IToggleButtonProps {
    isToggled: boolean | undefined,
    onClick?: any;
    children?: ReactNode;
    classNameContainer?: string;
    classNameButton?: string;
}

export const ToggleButton = (props: IToggleButtonProps) => {
    const handleOnClick = (): void => {
        props.onClick();
    };

    return (
        <div className={className('toggleButton', props.classNameContainer)}>
            <button onClick={handleOnClick} className={className(`${'btn btn-block toggleButton rounded'} ${props.isToggled ? 'isToggled' : 'isUnToggled'}`, props.classNameButton)}>{props.children}</button>
        </div>
    );
};
