import React from 'react';
import { useObserver } from 'mobx-react';
import { BatteryStateIcon } from './batteryState/batteryStateIcon';
import { SignalStrengthIcon } from './signalStrength/signalStrengthIcon';
import { BatteryState, SignalState, ConnectionStatus } from '../stores/deviceStore/deviceContracts';
import { ConnectionStatusIcon } from './connectionStatus/connectionStatusIcon';

export interface IDeviceComponentTileHeader {
    name: string;
    batteryState?: BatteryState;
    signalState?: SignalState;
    connectionStatus?: ConnectionStatus;
}

export const DeviceComponentTileHeader = (props: IDeviceComponentTileHeader) => {
    return useObserver(() => {
        return (<div className="d-flex">
            <span className="truncate mr-auto p-2">{props.name}</span>
            {props.batteryState !== undefined && <BatteryStateIcon batteryState={props.batteryState} />}
            {props.signalState !== undefined && <SignalStrengthIcon signalState={props.signalState} />}
            {props.connectionStatus !== undefined && <ConnectionStatusIcon connectionStatus={props.connectionStatus} />}
        </div>
        );
    });
};