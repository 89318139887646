/* eslint-disable no-useless-catch */
import { observable, action } from 'mobx';
import {
    MaxLength,
    IsDate,
    Matches,
    IsOptional,
    ValidateIf
} from 'class-validator';
import {
    PhonePattern,
    EmailPattern,
    NamesPattern,
    DefaultMaxLength
} from '../../../common/validation/constants';
import { ErrorType } from '../../../common/validation/errorType';
import { validateModel } from '../../../common/validation/validateModel';
import { ResponseError } from '../../../common/responseError';
import { getRootStore, getViewStore } from '../../../storeRegistry';
import DeviceOperatorMetadataRepository from './repositories/deviceOperatorMetadataRepository';

export interface IOperatorMetadataState {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    street?: string;
    houseNumber?: string;
    city?: string;
    postalCode?: string;
    projectName?: string;
    installationDate?: string;
    eTag?: string;
    registeredDeviceId?: string;
}

export class OperatorMetadataModel {
    public eTag?: string;
    deviceOperatorMetadataRepository: DeviceOperatorMetadataRepository;

    @observable
    @IsOptional()
    @MaxLength(DefaultMaxLength, { message: 'Errors.MaxLength255' })
    @Matches(NamesPattern as any, { message: 'Errors.NamePattern' })
    @ValidateIf((object: any, value: any) => value && value !== '')
    public firstName?: string;

    @observable
    @IsOptional()
    @MaxLength(DefaultMaxLength, { message: 'Errors.MaxLength255' })
    @Matches(NamesPattern as any, { message: 'Errors.NamePattern' })
    @ValidateIf((object: any, value: any) => value && value !== '')
    public lastName?: string = '';

    @observable
    @MaxLength(DefaultMaxLength, { message: 'Errors.MaxLength255' })
    @Matches(EmailPattern, { message: 'Errors.Email' })
    @ValidateIf((object: any, value: any) => value && value !== '')
    public email?: string = '';

    @observable
    @IsOptional()
    @MaxLength(DefaultMaxLength, { message: 'Errors.MaxLength255' })
    @Matches(PhonePattern as any, { message: 'Errors.PhonenumerInvalid' })
    @ValidateIf((object: any, value: any) => value && value !== '')
    public phone?: string = '';

    @observable
    @IsOptional()
    @MaxLength(DefaultMaxLength, { message: 'Errors.MaxLength255' })
    public street?: string = '';

    @observable
    @IsOptional()
    @MaxLength(DefaultMaxLength, { message: 'Errors.MaxLength255' })
    public houseNumber?: string = '';

    @observable
    @IsOptional()
    @MaxLength(DefaultMaxLength, { message: 'Errors.MaxLength255' })
    public city?: string = '';

    @observable
    @IsOptional()
    @MaxLength(DefaultMaxLength, { message: 'Errors.MaxLength255' })
    public postalCode?: string = '';

    @observable
    @IsOptional()
    @MaxLength(DefaultMaxLength, { message: 'Errors.MaxLength255' })
    public projectName?: string = '';

    @observable
    @IsOptional()
    @IsDate({ message: 'Errors.InvalidFormat' })
    public installationDate: Date | null = null;

    @observable
    public isInEditMode = false;

    @observable
    public validationErrors: ErrorType<OperatorMetadataModel> | null = null;

    @observable
    public commonErrors: string[] | null = null;

    @observable
    public isLoading = false;

    @observable
    public registeredDeviceId?: string;

    constructor(deviceId: string, deviceOperatorMetadataRepository: DeviceOperatorMetadataRepository) {
        this.registeredDeviceId = deviceId;
        this.deviceOperatorMetadataRepository = deviceOperatorMetadataRepository;
    }

    @action.bound
    public async startEdit() {
        this.isInEditMode = true;
        getViewStore().removeTimers();
        await this.refresh();
    }

    @action.bound
    public async cancelEdit() {
        this.isInEditMode = false;
        await this.refresh();
    }

    public async save() {
        this.clearErrors();
        this.validationErrors = validateModel(this);
        if (this.validationErrors === null) {
            const { viewStore } = getRootStore();
            const unlock = viewStore.lockUI();

            try {
                await this.deviceOperatorMetadataRepository.saveDeviceMetadata(this.registeredDeviceId!, this.toJSON());
                this.isInEditMode = false;
            } catch (e : any) {
                if (e instanceof ResponseError) {
                    if (e.data) {
                        this.updateFromJSON(e.data);
                    }
                    this.commonErrors = [`Errors.StatusCode.${e.statusCode}`, e.rawResponseData ?? ''];
                    return;
                }
                this.commonErrors = ['Save Failed'];
            } finally {
                unlock();
            }
        }
    }

    private clearErrors() {
        this.commonErrors = null;
        this.validationErrors = null;
    }

    @action
    public async refresh(): Promise<void> {
        this.getDeviceMetadataInternal();
    }

    private async getDeviceMetadataInternal(): Promise<void> {
        this.isLoading = true;
        try {
            const data = await this.deviceOperatorMetadataRepository.getDeviceMetadata(this.registeredDeviceId!);
            if (data) {
                this.updateFromJSON(data);
            }
        } catch (e : any) {
            throw e;
        } finally {
            this.isLoading = false;
        }
    }

    public reset() {
        this.updateFromJSON({});
        this.isInEditMode = false;
        this.clearErrors();
    }

    public updateFromJSON(deviceMetadataState: IOperatorMetadataState): this {
        this.registeredDeviceId = deviceMetadataState.registeredDeviceId;
        this.firstName = deviceMetadataState.firstName;
        this.lastName = deviceMetadataState.lastName;
        this.email = deviceMetadataState.email;
        this.city = deviceMetadataState.city;
        this.houseNumber = deviceMetadataState.houseNumber;
        this.postalCode = deviceMetadataState.postalCode;
        this.phone = deviceMetadataState.phone;
        this.street = deviceMetadataState.street;
        this.projectName = deviceMetadataState.projectName;
        this.installationDate = deviceMetadataState.installationDate
            ? new Date(deviceMetadataState.installationDate)
            : null;
        this.eTag = deviceMetadataState.eTag;

        return this;
    }

    public toJSON(): IOperatorMetadataState {
        return {
            registeredDeviceId: this.registeredDeviceId,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            city: this.city,
            houseNumber: this.houseNumber,
            postalCode: this.postalCode,
            phone: this.phone,
            street: this.street,
            projectName: this.projectName,
            installationDate: this.installationDate?.toJSON(),
            eTag: this.eTag
        };
    }
}
