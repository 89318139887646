import { Logger as WinstonLogger } from 'winston/index';
import debug from 'debug';
/** backend - frontend bridge for winston logger
 * nodejs applications should configure a default logger when application starts up
 */
export class Logger {
    private static defaultInstance: WinstonLogger | undefined;
    private static enabled = false;
    public static loglevel: string;
    public static configure(enabled: boolean, loglevel: string, defaultLoggerInstance?: WinstonLogger) {
        Logger.defaultInstance = defaultLoggerInstance;
        Logger.enabled = enabled;
        Logger.loglevel = loglevel;
    }

    private _debugLoggerNs?: debug.Debugger;
    private _debugLoggerLogLevel: { [index:string]: debug.Debugger} = {};

    constructor(private instance?: WinstonLogger, private ns?: string) {
    }

    public error(message: string, ...meta: any[]) {
        this.log('error', message, ...meta);
    }

    public warn(message: string, ...meta: any[]) {
        this.log('warn', message, ...meta);
    }

    public info(message: string, ...meta: any[]) {
        this.log('info', message, ...meta);
    }

    public debug(message: string, ...meta: any[]) {
        this.log('debug', message, ...meta);
    }

    public verbose(message: string, ...meta: any[]) {
        this.log('verbose', message, ...meta);
    }

    public log(loglevel: string, message: string, ...meta: any[]) {
        if (Logger.enabled) {
            const winstonlogger = this.instance || Logger.defaultInstance;
            if (winstonlogger) {
                winstonlogger.log(loglevel, message, ...meta);
            }
        }

        if (this._debugLoggerNs == null) {
            this._debugLoggerNs = debug(`abussc:portal:ns:${this.ns ?? 'default'}`);
        }

        if (this._debugLoggerLogLevel[loglevel] == null) {
            this._debugLoggerLogLevel[loglevel] = debug(`abussc:portal:loglevel:${loglevel}`);
        }
        this._debugLoggerNs(message, ...meta);
        this._debugLoggerLogLevel[loglevel](message, ...meta);
    }
}

export const defaultLogger = new Logger();
export const createLoggerWithNamespace = (ns: string) => {
    return new Logger(undefined, ns);
};