import { action, computed } from 'mobx';
import { generatePath } from 'react-router';

import { createLoggerWithNamespace } from '../../../logger';
import { IDeviceDetailsRouteParams } from '../../common/routing/interfaces';
import { KnownRoutes } from '../../common/routing/knownroutes';
import RootStore from '../../common/stores/rootStore';
import { ViewStore } from '../../common/stores/viewStore';
import { IViewStore } from '../../common/stores/viewStore.inerface';
import { getRootStore, getViewStore } from '../../storeRegistry';
import { AocStore } from './aocStore';

const logger = createLoggerWithNamespace('AocViewStore');

export interface IDevicesTabModel {
    name: string;
    exact: boolean;
    path: KnownRoutes[];
    displayOrder: number;
    isVisible: boolean;
    onSelected: () => void;
}

export const NotificationSource = 'devicesviewstore';

export class AocViewStore implements IViewStore {
    private static readonly _contextUris = [
        KnownRoutes.AocDeviceSettings,
        KnownRoutes.AocDevice
    ];

    @computed get title(): string | undefined {
        const device = this.aocStore.deviceDetails.data;
        
        if (device?.operatorMetadata?.projectName?.length &&
            device.operatorMetadata.projectName?.length > 0) {
            return device?.operatorMetadata?.projectName;
        }

        if (device?.deviceId != null) {
            return device?.deviceId;
        }
        
        let header: string | undefined;
        return header;
    }

    public async initialize(): Promise<void> {
        logger.debug('initialize');
    }

    get applicationViewStore(): ViewStore {
        return getViewStore();
    }

    get rootStore(): RootStore {
        return getRootStore();
    }

    get aocStore(): AocStore {
        return getRootStore().aocStore;
    }

    public async initStateForPath(path: KnownRoutes, routeParams: Record<string, any>): Promise<void> {
        logger.debug('devicesViewStore.initStateForPath', path, routeParams);
        if (AocViewStore._contextUris.find((x) => x === path) != null) {
            if (this.applicationViewStore.loadRemoteContentOnInit) {
                try {
                    this.aocStore.loadData(routeParams.deviceid);
                    switch (path) {
                    case KnownRoutes.AocDevice:
                        this.rootStore.viewStore.aocViewStore.navigateToDevice(routeParams as IDeviceDetailsRouteParams);
                        break;
                    case KnownRoutes.AocDeviceSettings:
                        this.rootStore.viewStore.aocViewStore.navigateToDeviceSettings(routeParams as IDeviceDetailsRouteParams);
                        break;
                    default:
                        break;
                    }
                } catch (e : any) {
                    this.applicationViewStore.handleInitializationErrors(e);
                }
            }
        }
    }

    public async initStateForPathCompleted(path: KnownRoutes): Promise<void> {
        logger.debug('AocViewStore: initStateForPathCompleted with path ', path);
    }

    private async setPath(knownRoute: KnownRoutes, routeParams: Record<string, any>) {
        const path = generatePath(knownRoute as string, routeParams);
        this.applicationViewStore.currentPath = path;
    }

    @action.bound
    public async navigateToDevice(params: IDeviceDetailsRouteParams) {
        await this.setPath(KnownRoutes.AocDevice, params);
    }

    @action.bound
    public async navigateToDeviceSettings(params: IDeviceDetailsRouteParams) {
        await this.setPath(KnownRoutes.AocDeviceSettings, params);
    }
}