export type evalType = { condition: boolean, className: string };
export const className = (...classes: Array<string | evalType | undefined | null>): string => {
    const stringsOnly = classes.map((x) => {
        const y = x as evalType;
        if (typeof y !== 'undefined' && typeof y.condition !== 'undefined') {
            if (y.condition === true) {
                return y.className;
            }
        } else {
            return x as string | undefined | null;
        }
        return undefined;
    });
    return stringsOnly.filter((x) => x !== undefined && x !== null).join(' ');
};

export class Helper {
    public static isEmpty(obj: Record<string, unknown>) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                return false;
            }
        }
        return true;
    }

    public static jsonToModel<I, T extends I>(object: I, Type: (new () => T)): T {
        let instance: any = new Type();
        // ts lint rewrites this to something different
        // tslint:disable-next-line
        instance = Object.assign(instance, object);
        return instance;
    }

    public static getStringValueOrDefault(value: string | number | undefined | null, defaultValue = ''): string {
        return value ? value.toString() : defaultValue;
    }

    public static getNumberValueOrDefault(value: number | undefined, defaultValue = 0): number {
        return value || defaultValue;
    }

    public static getDateValueOrUndefined(value: Date | string | undefined): Date | undefined {
        if (value && typeof value === typeof '') {
            const dateObj = new Date(value);
            return dateObj;
        }
        return value as Date ?? undefined;
    }
}