import UserProfileStore, { IUserProfileStoreState } from './userprofile/store';

export interface IAccessIdentityStoreState {
    userProfileStore: IUserProfileStoreState;
}

export class AccessIdentityStore {
    public userProfileStore: UserProfileStore;

    constructor(initialState?: IAccessIdentityStoreState) {
        this.userProfileStore = new UserProfileStore(initialState?.userProfileStore);
    }

    public toJson(): IAccessIdentityStoreState {
        try {
            const plain: IAccessIdentityStoreState = {
                userProfileStore: this.userProfileStore.toJSON()
            };
            return plain;
        } catch (e : any) {
            throw new Error('[Serialization Error]');
        }
    }
}