import React from 'react';
import { ModalPopup } from '../common/components/modalPopup/modalPopup';
import Button from '../common/components/button';
import { getViewStore } from '../storeRegistry';
import { FirmwareStore } from './store/firmwareStore';
import { withTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { Stores } from '../common/stores/stores';

interface ResellerNotificationModalProps {
    isOpen: boolean;
    t: (key: string) => string;
    firmwareStore?: FirmwareStore;
    closeRequested: () => void;
}

const ResellerNotificationModal: React.FC<ResellerNotificationModalProps> = ({ t, firmwareStore, closeRequested, isOpen }) => {
    const sendResellerNotification = async () => {
        const result = await firmwareStore?.sendResellerNotification(firmwareStore.selectedFirmware?.id ?? '');
        const viewStore = getViewStore();
        closeRequested();
        if (!result) {
            viewStore.notifyError(t('Common.SendResellerNotification.ErrorSendingNotification').toString(), '');
        } else {
            viewStore.notifySuccess(t('Common.SendResellerNotification.NotificationSent').toString());
        }
    };

    return (
        <>
            <ModalPopup
                closeRequested={closeRequested}
                isOpen={isOpen}
                title={t('Common.SendResellerNotification.Modal.Title')}
                footerChildren={
                    <div className='container row pb-3'>
                        <Button style='outline-secondary' className="col-5" onClick={closeRequested}>{t('Common.SendResellerNotification.Modal.Button.Cancel')}</Button>
                        <Button style='primary' className="col-6 ml-1" disabled={false} onClick={sendResellerNotification}>{t('Common.SendResellerNotification.Modal.Button.Send')}</Button>
                    </div>
                }>
                <p>{t('Common.SendResellerNotification.Modal.Description')}</p>
            </ModalPopup>
        </>
    );
};

const withHoC = inject(Stores.FirmwareStore, Stores.ViewStore)(withTranslation('portal_firmware')(ResellerNotificationModal));
export { withHoC as ResellerNotificationModal };