import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { useStore } from '../../common/stores/useStore';
import { DexitDeviceModel } from '../stores/deviceStore/dexitDeviceModel';
import Button from '../../common/components/button';

import 'devextreme/ui/html_editor/converters/markdown';
import './wlxManager.scss';
import HtmlEditor from 'devextreme-react/html-editor';

export const WLXManager = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const { deviceStore } = useStore();
    const deviceDetails: DexitDeviceModel = deviceStore.deviceDetails.data as DexitDeviceModel;
    const wlxManagerLink = deviceDetails.wlxManagerDeeplink;

    const isWindows = window.navigator.userAgent.toLowerCase().indexOf('win') !== -1;

    const renderWLXManagerContent = () => {
        const markdownText = t('WLXManager.DescriptionMarkdownWLXManagerContent');
        return (
           
            <React.Fragment>
                <HtmlEditor valueType="markdown" readOnly={true} defaultValue={markdownText}></HtmlEditor>
                <Button type='button' style='primary' className='pl-5 pr-5' disabled={false} onClick={() => window.open(wlxManagerLink, '_blank')}>
                    {t('WLXManager.OpenDesktopApplication')}
                </Button>
            </React.Fragment>
         
        );
    };

    const renderOldFirmwareOrUnsupportedOsContent = () => {
        const markdownText = t('WLXManager.DescriptionMarkdownOldFirmwareOrUnsupportedOs');
        return (
         
            <React.Fragment>
                <HtmlEditor valueType="markdown" readOnly={true} defaultValue={markdownText} />
            </React.Fragment>
            
        );
    };

    return isWindows && wlxManagerLink !== undefined && wlxManagerLink.length > 0 ? renderWLXManagerContent() : renderOldFirmwareOrUnsupportedOsContent();
};
