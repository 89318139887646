import { action, computed, observable } from 'mobx';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../..';
import { Helper } from '../../../common';
import { DeferredDataModel } from '../../../common/stores/deferredDataModel';
import HttpStatusCode from '../../../common/httpStatusCode';
import { ResponseError } from '../../../common/responseError';
import { IModelWithTimeUpdates } from '../../../common/stores/modelWithTimeUpdates.interface';
import { getStoreEnv } from '../../../commonRegistry';
import { getPortalConfiguration } from '../../../getPortalConfiguration';
import { getViewStore } from '../../../storeRegistry';
import { AppPairingModel } from './appPairingModel';
import { DeviceAccessModel, IDeviceAccessModel } from './deviceAccessModel';
import { DeviceState, DeviceSubtype, DeviceType, IDeviceComponent, IDeviceComponents } from './deviceContracts';
import { OperatorMetadataModel, IOperatorMetadataState } from './operatorMetadataModel';
import DeviceStore from './deviceStore';
import DeviceRepository from './repositories/deviceRepository';
import MockDeviceRepository from './repositories/mockDeviceRepository';
import { ISystemServicesConfigurationState, SystemServicesConfigurationModel } from './systemServicesConfigurationModel';
import { DeviceLogsModel } from './deviceLogsModel';
import DeviceOperatorMetadataRepository from './repositories/deviceOperatorMetadataRepository';

export interface IDeviceModelState {
    deviceId: string;
    deviceName: string;
    type: DeviceType;
    serialNumber: string;
    firmwareVersion: string;
    internalIpAddress: string;
    state: DeviceState;
    lastActive?: string;
    deviceComponents: IDeviceComponent[] | null;
    countSlaves: string;
    countCameras: string;
    countCylinders: string;
    countWallreaders: string;
    countRepeaters: string;
    systemServiceConfigurations: ISystemServicesConfigurationState | null;
    deviceAccess: IDeviceAccessModel | null;
    operatorMetadata: IOperatorMetadataState | null;
    deviceSubType: DeviceSubtype | undefined;
}

export function defaultState(): IDeviceModelState {
    return {
        deviceId: '',
        deviceName: '',
        type: DeviceType.Unknown,
        serialNumber: '',
        firmwareVersion: '',
        internalIpAddress: '',
        state: DeviceState.Disconnected,
        lastActive: undefined,
        deviceComponents: null,
        countSlaves: '',
        countCameras: '',
        countCylinders: '',
        countWallreaders: '',
        countRepeaters: '',
        systemServiceConfigurations: null,
        deviceAccess: { state: 'None' },
        operatorMetadata: { },
        deviceSubType: undefined
    };
}
const initState = defaultState();

export enum DeviceFeatures {
    None = 0,
    Components = 1 << 0,
    BatteryReport = 1 << 1,
    RemoteConnect = 1 << 2,
    Logs = 1 << 3,
    AppPairings = 1 << 4,
    WLXManager = 1 << 5
}

export abstract class DeviceModel implements IModelWithTimeUpdates {
    abstract readonly supportedFeatures: DeviceFeatures;
    deviceRepository: DeviceRepository;

    @observable deviceId = initState.deviceId;
    @observable deviceName = initState.deviceName;
    @observable type: DeviceType = initState.type;
    @observable serialNumber = initState.serialNumber;
    @observable firmwareVersion = initState.firmwareVersion;

    @observable ipAddress = initState.internalIpAddress;
    @observable state: DeviceState = initState.state;
    @observable lastActive?: Date;
    @observable allComponentsLoaded = false;
    @observable deviceComponents: IDeviceComponent[] | null = null;
    @observable countSlaves = '0';
    @observable countCameras = '0';
    @observable countCylinders = '0';
    @observable countWallreaders = '0';
    @observable countRepeaters = '0';
    @observable appPairings = new DeferredDataModel<AppPairingModel[]>();
    @observable logs = new DeviceLogsModel(this);
    @observable systemServiceConfigurations = new SystemServicesConfigurationModel(this);
    @observable deviceAccess: DeviceAccessModel = new DeviceAccessModel(this);
    @observable operatorMetadata: OperatorMetadataModel;
    @observable deviceSubType: DeviceSubtype | undefined;

    @computed get totalComponentCount(): number {
        return parseInt(this.countCameras) + parseInt(this.countCylinders) + parseInt(this.countRepeaters) + parseInt(this.countSlaves) + parseInt(this.countWallreaders);
    }

    private static readonly numberOfDeviceComponentsToLoad = 12;

    constructor(private parent: DeviceStore, deviceType: DeviceType) {
        this.deviceRepository = new DeviceRepository(getPortalConfiguration());
        this.type = deviceType;
        this.systemServiceConfigurations = new SystemServicesConfigurationModel(this);
        this.operatorMetadata = new OperatorMetadataModel(parent.deviceId!, new DeviceOperatorMetadataRepository(getPortalConfiguration()));
    }

    @action.bound
    public timeChanged(): void {
        this.deviceAccess.timeChanged();
    }

    @action.bound
    reset(deviceId: string) {
        this.updateFromJSON({
            ...defaultState(),
            deviceId,
            deviceAccess: { state: 'None' }
        });
    }

    @action.bound
    public async refreshComponents() {
        if (this.deviceComponents != null) {
            const componentsCount = this.deviceComponents.length;
            const pages = Math.ceil(componentsCount / DeviceModel.numberOfDeviceComponentsToLoad);
            let iteration = 0;
            do {
                iteration++;
                await this.getDeviceComponents(iteration === 1);
            } while (iteration < pages);
        }
    }

    @action.bound
    public async refreshAppPairings() {
        if (this.appPairings != null) {
            this.getAppPairings(true);
        }
    }

    @action.bound
    public async refreshLogs() {
        if (this.logs != null) {
            this.logs.getLogs(true);
        }
    }

    @action.bound
    public async getDeviceComponents(clearExisting: boolean): Promise<void> {
        if (clearExisting) {
            this.deviceComponents = null;
            this.allComponentsLoaded = true;
        }

        const skip = this.deviceComponents?.length || 0;

        if (this.deviceId) {
            try {
                const data = await this.getDeviceComnponentsWithMockFallback(this.deviceId, DeviceModel.numberOfDeviceComponentsToLoad, skip);
                this.deviceComponents = (this.deviceComponents || []).concat(data?.deviceComponents ?? []);
                this.updateAllComponentsLoadedFlag(data);
                return;
            } catch (e) {
                throw new Error(JSON.stringify(e));
            }
        }
    }

    @action.bound
    public async getAppPairings(clearExisting: boolean): Promise<void> {
        if (clearExisting) {
            this.appPairings.setInitialData(null);
        }

        if (this.deviceId) {
            try {
                const data = await this.deviceRepository.getAppPairings(this.deviceId);
                this.appPairings.setReadyData(data.map(x => new AppPairingModel(x, this)));
                return;
            } catch (e : any) {
                this.appPairings.setError();
                const viewStore = getViewStore();
                viewStore.notifyError('Device.AppPairings', 'FailedToLoad');
                throw new Error(e);
            }
        }
    }

    private updateAllComponentsLoadedFlag(data: IDeviceComponents | null) {
        this.allComponentsLoaded = data === null ||
            this.deviceComponents!.length === this.totalComponentCount ||
            data.totalDeviceComponentsCount === null;
    }

    @action
    public async getAdditionalDeviceComponents() {
        await this.getDeviceComponents(false);
    }

    private async getDeviceComnponentsWithMockFallback(deviceId: string, take: number, skip: number): Promise<IDeviceComponents | null> {
        const viewStore = getViewStore();
       
        try {
            return await this.deviceRepository.getDexitDeviceComponents(deviceId, take, skip);
        } catch (e) {
            if (e instanceof ResponseError && e.statusCode === HttpStatusCode.PAYMENT_REQUIRED) {
                viewStore.notifyError('load_components', e);
            } else {
                throw e;
            }
        }
    
        return this.getMockComponents(deviceId, take);
    }

    private async getMockComponents(deviceId: string, take: number) {
        const mockRepository = new MockDeviceRepository();
        const mockData = await mockRepository.getDeviceComponents(deviceId, take);

        const { i18next } = getStoreEnv();

        mockData.deviceComponents.forEach((component: IDeviceComponent) => {
            component.name = i18next.t(`DummyData.ComponentNames.${component.name}`, { ns: TRANSLATIONS_NAMESPACE_DEVICE, defaultValue: component.name });
        });

        return mockData;
    }

    public deleteAppPairing(appPairing: AppPairingModel) {
        try {
            this.appPairings.setLoading();
            this.parent.deviceRepository.deleteAppPairing(appPairing.deviceId, appPairing.email);
            this.appPairings.setReadyData(this.appPairings.data?.filter(x => !(x.deviceId === appPairing.deviceId && x.email === appPairing.email)) ?? null);
        } catch (e : any) {
            const viewStore = getViewStore();
            viewStore.notifyError('Device.Pairing', 'Unpair failed');
            this.appPairings.setReadyData(this.appPairings.data);
        }
    }

    @action.bound
    toJSON(): IDeviceModelState {
        return {
            deviceId: this.deviceId,
            deviceName: this.deviceName,
            firmwareVersion: this.firmwareVersion,
            internalIpAddress: this.ipAddress,
            serialNumber: this.serialNumber,
            state: this.state,
            type: this.type,
            lastActive: this.lastActive?.toJSON(),
            deviceComponents: this.deviceComponents,
            countCameras: this.countCameras,
            countCylinders: this.countCylinders,
            countSlaves: this.countSlaves,
            countWallreaders: this.countWallreaders,
            countRepeaters: this.countRepeaters,
            systemServiceConfigurations: this.systemServiceConfigurations!.toJSON(),
            deviceAccess: this.deviceAccess.toJSON(),
            operatorMetadata: this.operatorMetadata.toJSON(),
            deviceSubType: this.deviceSubType
        };
    }

    @action.bound
    updateFromJSON(deviceState: IDeviceModelState): this {
        this.deviceId = deviceState.deviceId;
        this.deviceName = deviceState.deviceName;
        this.firmwareVersion = deviceState.firmwareVersion;
        this.ipAddress = deviceState.internalIpAddress;
        this.serialNumber = deviceState.serialNumber;
        this.state = deviceState.state;
        this.type = deviceState.type;
        this.countSlaves = deviceState.countSlaves;
        this.countCameras = deviceState.countCameras;
        this.countCylinders = deviceState.countCylinders;
        this.countWallreaders = deviceState.countWallreaders;
        this.countRepeaters = deviceState.countRepeaters;

        this.deviceSubType = deviceState.deviceSubType;
        this.lastActive = Helper.getDateValueOrUndefined(deviceState.lastActive);
        if (deviceState.systemServiceConfigurations) {
            this.systemServiceConfigurations.updateFromJSON(deviceState.systemServiceConfigurations);
        }
        this.deviceAccess = new DeviceAccessModel(this);
        if (deviceState.deviceAccess) {
            this.deviceAccess.updateFromJSON(deviceState.deviceAccess);
        }
        this.operatorMetadata.updateFromJSON(deviceState.operatorMetadata ?? { registeredDeviceId: deviceState.deviceId });
        return this;
    }

    @action.bound
    updateDeviceAccess(deviceAccessState: IDeviceAccessModel) {
        this.deviceAccess = new DeviceAccessModel(this);
        if (deviceAccessState) {
            this.deviceAccess.updateFromJSON(deviceAccessState);
        }
    }

    supportsFeature(deviceFeature: DeviceFeatures): boolean {
        return !!(deviceFeature & this.supportedFeatures);
    }
}