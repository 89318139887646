import './hyenDevicePage.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../../devices/index';
import { useStore } from '../../common/stores/useStore';
import WindowedPage from '../../common/components/windowedPage/windowedPage';
import { KnownRoutes } from '../../common/routing/knownroutes';
import { Back2Top } from '../../common/components/top/back2top';
import { EditDeviceMetadataView } from '../../common/components/deviceMetadata/editDeviceMetadataView';
import { MainDeviceDetailsView } from '../../devices/layout/mainDeviceDetailsView';
import { DataTile } from '../../common/components/dataTile/dataTile';
import { Metadata } from '../../common/components/deviceMetadata/metadata';
import { Properties } from '../../devices/deviceDetails/properties';
import { KnownRoles } from '../../common/routing/knownroles';

export const HyenDevicePage = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const { deviceStore, viewStore } = useStore();
    return useObserver(() => {
        const deviceMetadata = deviceStore.deviceDetails.data?.operatorMetadata;
        return (
            <WindowedPage
                classNamesRoot="devicePage"
                headerText={
                    !viewStore.user.hasRoles(KnownRoles.Operator)
                        ? viewStore.devicesViewStore.title || t('Devices.UnknownProjectName')!
                        : deviceStore.deviceDetails.data?.deviceName || t('Devices.UnknownProjectName')!
                }
                isReady={deviceStore.deviceDetails.isReady}
                backNav={{ backPath: KnownRoutes.Search, text: t('Home') }}
            >
                <div className="container page">
                    <div className="row">
                        <aside className="col-4 col-lg-3 col-xl-2">
                            {!viewStore.user.hasRoles(KnownRoles.Operator) && (
                                <DataTile>
                                    <Metadata />
                                </DataTile>
                            )}
                            <DataTile>
                                <Properties />
                            </DataTile>
                        </aside>
                        <main className="col-8 col-lg-9 col-xl-10">
                            {!deviceMetadata?.isInEditMode && <MainDeviceDetailsView />}
                            {deviceMetadata?.isInEditMode && <EditDeviceMetadataView deviceType="Dexit" />}
                        </main>
                    </div>
                </div>
                <Back2Top />
            </WindowedPage>
        );
    });
};
