import * as React from 'react';

export interface IProfileRowProps {
    className?: string;
    children: React.ReactNode;
}

const ProfileRow: React.FunctionComponent<IProfileRowProps> = (props: IProfileRowProps) => {
    return <div className={`row ${props.className || ''}`}>{props.children}</div>;
};

export default (ProfileRow);