import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { defaultLogger } from '../../../logger';
import { BaseRepository } from '../../common';
import { createApiProxy } from '../../common/createApiProxy';
import { IPortalConfiguration } from '../../common/interfaces';
import { IDeviceAccessSearchModel } from './deviceAccessSearchModel';
import { mapDeviceAccessDtoToDeviceAccessModel } from './mapper';

class DeviceRepository extends BaseRepository {
    private resetDeleteClient: AbusCloudApi.CoreApi
    private deviceAccessClient: AbusCloudApi.CoreApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.resetDeleteClient = new AbusCloudApi.CoreApi(this.apiConfiguration);
        this.deviceAccessClient = new AbusCloudApi.CoreApi(this.apiConfiguration);
    }

    public async requestAccess(deviceId: string): Promise<IDeviceAccessSearchModel> {
        await createApiProxy(async () => this.deviceAccessClient.apiCoreDeviceAccessDeviceIdRequestaccessPost(deviceId))();
        const proxy = createApiProxy(async () => this.deviceAccessClient.apiCoreDeviceAccessDeviceIdGet(deviceId), mapDeviceAccessDtoToDeviceAccessModel);
        const result = await proxy();
        return result!;
    }

    public async resetDexitDevice(deviceId: string): Promise<unknown | null> {
        try {
            const proxy = createApiProxy(async () => this.resetDeleteClient.apiCoreResetDeviceIdResetPost(deviceId));
            return proxy();
        } catch (e : any) {
            defaultLogger.error(`Error resetting the device with id ${deviceId}`);
            throw e;
        }
    }

    public async deleteDevice(deviceId: string): Promise<unknown | null> {
        try {
            const proxy = createApiProxy(async () => this.resetDeleteClient.apiCoreResetDeviceIdDeletePost(deviceId));
            return proxy();
        } catch (e : any) {
            defaultLogger.error(`Error deleting the device with id ${deviceId}`);
            throw e;
        }
    }
}

export default DeviceRepository;