import { observable } from 'mobx';

export class FirmwareTypeModel {
    @observable
    public id = '';
    
    @observable
    public firmwareType = '';

    @observable
    public deviceType = '';

    @observable
    public displayName = '';

    constructor(id:string, firmwareType: string, deviceType: string, displayName: string) {
        this.id = id;
        this.firmwareType = firmwareType;
        this.deviceType = deviceType;
        this.displayName = displayName;
    }
}