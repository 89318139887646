import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { IDataGridColumn, LocalDataGrid } from '../../common/components/datagrid';
import { nameof } from '../../common/nameof';
import { OperatorAssignmentModel } from './OperatorAssignmentModel';
import Button from '../../common/components/button';
import { useStore } from '../../common/stores/useStore';
import { Skeleton } from '../../common/components/skeleton';
import moment from 'moment';

type RowDataType = OperatorAssignmentModel;

export const OperatorDeviceAssignmentOverview = () => {
    const { t } = useTranslation();
    const { deviceStore, operatorStore } = useStore();

    useEffect(() => {
        const fetchAssignedOperators = async () => {
            const currentDeviceId = deviceStore.deviceId;
            if (currentDeviceId) {
                operatorStore.getAssignedOperators(currentDeviceId);
            }
        };

        fetchAssignedOperators();
    }, []);

    const handleDelete = (rowData: RowDataType) => {
        operatorStore.deleteAssignment(deviceStore.deviceId!, rowData.operatorId);
    };

    return useObserver(() => {
        const DeleteButton = ({ row }: { row?: OperatorAssignmentModel }) => {
            const handleClick = () => {
                if (row) {
                    handleDelete(row);
                }
            };

            return (
                <Button style="secondary" onClick={handleClick}>
                    {t('OperatorDeviceAssignmentOverview.Grid.DeleteButton')}
                </Button>
            );
        };

        const columns: IDataGridColumn<RowDataType>[] = [
            { name: nameof<RowDataType>('eMail'), title: t('OperatorDeviceAssignmentOverview.Grid.EMail').toString(), getCellValue: (rowData: RowDataType) => rowData.eMail || t('n/a') },
            {
                name: nameof<RowDataType>('dateOfPairing'),
                title: t('OperatorDeviceAssignmentOverview.Grid.DateOfPairing').toString(),
                getCellValue: (rowData: RowDataType) => moment.utc(rowData.dateOfPairing).isValid() ? moment.utc(rowData.dateOfPairing).format('LL LTS') : t('n/a')
            },
            { name: '', title: '', cellTemplate: DeleteButton }
        ];

        return (
            <React.Fragment>
                {operatorStore.isLoading
                    ? (
                        <Skeleton style={{ height: 50 }}></Skeleton>
                    )
                    : (
                        <LocalDataGrid
                            data={operatorStore.assignedOperators}
                            columns={columns}
                            loaded={true}
                            sortingState={{ defaultSorting: [{ columnName: nameof<RowDataType>('eMail'), direction: 'asc' }] }}
                        />
                    )}
            </React.Fragment>
        );
    });
};
