import React from 'react';
import { CylinderType } from '../../stores/deviceStore/deviceContracts';
import WLXProAccessCylinderIcon from './cylinderTypeIcons/icon-60-zylinder.svg';
import WLXProIntrusionCylinderIcon from './cylinderTypeIcons/icon-61-zylinder.svg';
import WLXProAccessHalfCylinderIcon from './cylinderTypeIcons/icon-62-zylinder.svg';
import WLXProIntrusionHalfCylinderIcon from './cylinderTypeIcons/icon-63-zylinder.svg';
import WLXProAccessPanicCylinderIcon from './cylinderTypeIcons/icon-64-zylinder.svg';
import WLXProIntrusionPanicCylinderIcon from './cylinderTypeIcons/icon-65-zylinder.svg';
import WLXProIntrusionDoubleCylinderIcon from './cylinderTypeIcons/icon-67-69-zylinder.svg';
import WLXCylinderIcon from './cylinderTypeIcons/icon-66-68-zylinder.svg';

export const CylinderTileIcon = (props: { cylinderType: CylinderType }) => {
    function getCylinderComponentIcon(type: CylinderType) {
        switch (type) {
        case 'WLXCylinder': {
            return WLXCylinderIcon;
        }
        case 'WLXProAccessCylinder': {
            return WLXProAccessCylinderIcon;
        }
        case 'WLXProIntrusionCylinder': {
            return WLXProIntrusionCylinderIcon;
        }
        case 'WLXProAccessHalfCylinder': {
            return WLXProAccessHalfCylinderIcon;
        }
        case 'WLXProIntrusionHalfCylinder': {
            return WLXProIntrusionHalfCylinderIcon;
        }
        case 'WLXProAccessPanicCylinder': {
            return WLXProAccessPanicCylinderIcon;
        }
        case 'WLXProIntrusionPanicCylinder': {
            return WLXProIntrusionPanicCylinderIcon;
        }
        case 'WLXProAccessDoubleCylinder':
        case 'WLXProAccessDoubleCylinderInner': {
            return WLXCylinderIcon;
        }
        case 'WLXProIntrusionDoubleCylinder':
        case 'WLXProIntrusionDoubleCylinderInner': {
            return WLXProIntrusionDoubleCylinderIcon;
        }
        default: {
            return WLXProAccessCylinderIcon;
        }
        }
    }

    return (
        <img className="deviceComponenttypeIcon" src={getCylinderComponentIcon(props.cylinderType)} />
    );
};