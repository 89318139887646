/* eslint-disable no-useless-catch */
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { BaseRepository } from '../../../../common';
import { IPortalConfiguration } from '../../../../common/interfaces';
import { toIResellerProfileState } from './usercompanyMapper';
import { createApiProxy } from '../../../../common/createApiProxy';

export class ResellerProfileRepository extends BaseRepository {
    private api: AbusCloudApi.CoreApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.api = new AbusCloudApi.CoreApi(this.apiConfiguration);
    }

    public async getResellerProfile() {
        try {
            const resellerProfileData = await createApiProxy(
                () => this.api.apiCoreResellerProfileGet(),
                (responseData) => {
                    const resellerProfile = toIResellerProfileState(responseData);
                    return resellerProfile;
                }
            )();

            return resellerProfileData;
        } catch (error) {
            throw error;
        }
    }
}
