import './header.scss';

import React, { CSSProperties } from 'react';
import Menu from '../menu/menu';
import defaultLogo from './ABUS_logo_web_reduziert.svg';
import { useStore } from '../../stores/useStore';
import { isValidEnvValue } from '../isValidEnvValue';

interface ICustomHeaderProps {
    hidden: boolean;
}

const LogoStamp = ({ name }: { name: string | undefined }) => {
    return (
        <span className="logo-env-name">
            <span className="inner">{name}</span>
        </span>
    );
};

const Header = (props: ICustomHeaderProps) => {
    const { viewStore } = useStore();

    if (props.hidden) {
        return null;
    }
    const { logo, name, color } = viewStore.envStamping;
    const headerStyle: CSSProperties = {};

    if (isValidEnvValue(color)) {
        headerStyle.backgroundColor = color;
    }

    return (
        <header className="app-header" style={headerStyle}>
            <nav className="navbar headerbar row no-gutters">
                <div id="" className="menu-wrapper col-1">
                    {viewStore.user.portalAuthorization.isAuthenticated ? <Menu /> : null}
                </div>
                {/* This div is here a fill so that flexbox always centers logo   */}
                <div className="col-10">
                    <span className="navbar-brand w-100 d-flex justify-content-center">
                        <div className="position-relative">
                            <img className="logo" height="40" src={isValidEnvValue(logo) ? logo : defaultLogo} />
                            {isValidEnvValue(name) && <LogoStamp name={name} />}
                        </div>
                    </span>
                </div>
                <div className="col-1" />
            </nav>
        </header>
    );
};

export default Header;
