import { action, observable } from 'mobx';
import { ABUSCloudPortalHyenLogsHyenLogDto } from 'portal-bff-proxy-ts';
import { nameof } from '../../../common/nameof';
import { QueryableDataModel, QueryableState, SortDirection } from '../../../common/stores/queryableDataModel';
import { getViewStore } from '../../../storeRegistry';
import { DeviceModel } from './deviceModel';

export interface DeviceLogState {
    logMessage: string | null;
    zoneName: string | null;
    partition: number | null;
    userName: string | null;
    messageType: string | null;
    timestamp: string | null;
}

export interface DeviceLogsQueryModel extends QueryableState {
    groupNames: string[],
    fromDate: string | undefined,
    toDate: string | undefined
}

export const sortingByNewest: () => { fieldName: keyof ABUSCloudPortalHyenLogsHyenLogDto, direction: SortDirection } = () => ({ fieldName: nameof<ABUSCloudPortalHyenLogsHyenLogDto>('timestamp'), direction: 'Descending' });
export const sortingByName:() => { fieldName: keyof ABUSCloudPortalHyenLogsHyenLogDto, direction: SortDirection } = () => ({ fieldName: nameof<ABUSCloudPortalHyenLogsHyenLogDto>('logMessage'), direction: 'Ascending' });

export class DeviceLogsModel extends QueryableDataModel<DeviceLogState, ABUSCloudPortalHyenLogsHyenLogDto> {
    @observable isLoading = false;
    @observable availableLogFilterGroupNames: string[];
    @observable groupNames: string[] = [];
    @observable fromDate: Date | undefined;
    @observable toDate: Date | undefined;
    @observable exportData: DeviceLogState[] = [];

    constructor(private parent: DeviceModel) {
        super();
        this.sorting = [sortingByNewest(), sortingByName()];
        this.availableLogFilterGroupNames = [];
    }

    @action.bound
    public async updateGroupNameFilter(groupName: string) {
        this.groupNames.includes(groupName) ? this.groupNames = this.groupNames.filter(e => e !== groupName) : this.groupNames.push(groupName);
        this.offset = 0;
        await this.update();
    }

    @action.bound
    public async resetFilters() {
        this.groupNames = [];
        this.fromDate = undefined;
        this.toDate = undefined;
        this.offset = 0;
        await this.update();
    }

    @action.bound
    public async updateFromDateFilter(fromDate: Date) {
        this.fromDate = fromDate;
        this.offset = 0;
        await this.update();
    }

    @action.bound
    public async updateToDateFilter(toDate: Date) {
        this.toDate = toDate;
        await this.update();
    }

    @action.bound
    public async getNew() {
        this.sorting = [sortingByNewest(), sortingByName()];
        this.offset = 0;
        await this.update();
    }

    @action.bound
    public async update() {
        await this.getLogs(true);
    }

    @action.bound
    public async getExportData() {
        const filter = this.toJSON();
        filter.limit = 500;
        const data = await this.parent.deviceRepository.getHyenDeviceLogs(this.parent.deviceId, filter);
        this.exportData = data.logs;
    }
    
    @action.bound
    public async getLogs(clearExisting: boolean) {
        if (clearExisting) {
            this.setInitialData(null);
        }

        try {
            const data = await this.parent.deviceRepository.getHyenDeviceLogs(this.parent.deviceId, this.toJSON());
            this.setReadyData(data.logs);
            this.totalCount = data.totalCount;
            this.availableLogFilterGroupNames = data.availableLogFilterGroupNames;
            this.isLoading = false;
            return;
        } catch (e : any) {
            this.setError();
            const viewStore = getViewStore();
            viewStore.notifyError('Device.Logs', 'FailedToLoad');
            throw new Error(JSON.stringify(e));
        }
    }

    toJSON(): DeviceLogsQueryModel {
        return {
            limit: this.limit,
            offset: this.offset,
            sorting: this.sorting as Array<{ fieldName: string, direction: SortDirection }>,
            groupNames: this.groupNames,
            fromDate: this.fromDate?.toDateString(),
            toDate: this.toDate?.toDateString()
        };
    }
}
