import * as React from 'react';
import { Overview } from './overview/overview';
import WindowedPage from '../common/components/windowedPage/windowedPage';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Switch } from 'react-router';
import { KnownRoutes } from '../common/routing/knownroutes';
import { withTranslation, WithTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Stores } from '../common/stores/stores';
import { ViewStore } from '../common/stores/viewStore';
import { FirmwareStore } from './store/firmwareStore';

export interface IFirmwareAreaPageProps extends WithTranslation {
  viewStore?: ViewStore;
  firmwareStore?: FirmwareStore;
}
const I18NEXT_NAMESPACE = ['portal_firmware'];

interface IPageConfiguration {
  pageTitle: string;
  backNav?: {
    backAction?: () => void;
    backPath?: string;
    text: string
  };
}

@observer
class FirmwareAreaPageInternal extends React.Component<IFirmwareAreaPageProps & RouteComponentProps<any>> {
  private pageConfiguration: { [route: string]: IPageConfiguration } =
    {
        [KnownRoutes.FirmwareOverview]: { pageTitle: 'Overview.Title' }
    };

  private getConfig(): IPageConfiguration {
      const currentRoute = this.props.viewStore!.currentRoute;
      return this.pageConfiguration[currentRoute ? currentRoute.path : ''] || this.pageConfiguration[KnownRoutes.FirmwareOverview];
  }

  public render() {
      return (
          <WindowedPage backNav={this.getConfig().backNav} headerText={this.props.t(this.getConfig().pageTitle)!} >
              <Switch>
                  <Route path={KnownRoutes.FirmwareOverview} exact={true} render={() => (<Overview />)} />
              </Switch>
          </WindowedPage>
      );
  }
}
export const FirmwareAreaPage = inject(Stores.ViewStore, Stores.FirmwareStore)(withTranslation(I18NEXT_NAMESPACE)(FirmwareAreaPageInternal));