import './aocDevicePage.scss';

import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataTile } from '../../common/components/dataTile/dataTile';
import { ITabConfig, Tabs } from '../../common/components/tab/tabs';
import { Back2Top } from '../../common/components/top/back2top';
import WindowedPage from '../../common/components/windowedPage/windowedPage';
import { KnownRoutes } from '../../common/routing/knownroutes';
import { useStore } from '../../common/stores/useStore';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../../devices';
import { EditDeviceMetadataView } from '../../common/components/deviceMetadata/editDeviceMetadataView';
import { AocMetadata } from '../details/aocMetadata';
import { AocProperties } from './aocProperties';
import { AocTabContent } from './aocTabContent';

export const AocDevicePage = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const { viewStore, aocStore } = useStore();

    return useObserver(() => {
        const operatorMetadata = aocStore.deviceDetails.data?.operatorMetadata;
        const tabs: ITabConfig[] | undefined = [
            {
                path: [KnownRoutes.AocDevice, KnownRoutes.AocDeviceSettings],
                displayOrder: 0,
                exact: true,
                options: {
                    text: 'Settings',
                    onSelected: () => viewStore.aocViewStore.navigateToDeviceSettings
                }
            }];
        return (<WindowedPage
            classNamesRoot='devicePage'
            headerText={viewStore.aocViewStore.title || t('Devices.UnknownProjectName')!}
            isReady={true} // TODO: set according to deferred datamodel status
            backNav={{ backPath: KnownRoutes.Search, text: t('Home') }}>
            <div className="container page">
                <div className="row">
                    <aside className="col-4 col-lg-3 col-xl-2">
                        <DataTile ><AocMetadata /></DataTile>
                        <DataTile ><AocProperties /></DataTile>
                    </aside>
                    <main className="col-8 col-lg-9 col-xl-10">
                        <DataTile>
                            {!operatorMetadata?.isInEditMode && <Tabs tabs={tabs} content={(tabs?: ITabConfig) => <AocTabContent tabConfig={tabs} />} />}
                            {operatorMetadata?.isInEditMode && <EditDeviceMetadataView deviceType='Aoc' />}
                        </DataTile>
                    </main>
                </div>
            </div>
            <Back2Top />
        </WindowedPage>);
    });
};
