/* eslint-disable no-useless-catch */
import { FirmwareRowModel, IFirmwareRowModelState } from './firmwareRowModel';
import { observable, toJS, action, computed } from 'mobx';
import { FirmwareRepository } from './firmwareRepository';
import { getPortalConfiguration } from '../../getPortalConfiguration';
import { getViewStore } from '../../storeRegistry';
import { FirmwareTypeModel } from './firmwareTypesModel';
import DexitFirmwareReleaseData from '../dexit/DexitFirmwareReleaseData';
import HyenFirmwareReleaseData from '../hyen/HyenFirmwareReleaseData';
import ComfionFirmwareReleaseData from '../comfion/ComfionFirmwareReleaseData';

export interface IFirmwareStoreState {
    firmwareList?: IFirmwareRowModelState[];
    selectedFirmware?: IFirmwareRowModelState;
}

export class FirmwareStore {
    public repository: FirmwareRepository;

    @observable
    public firmwareList?: FirmwareRowModel[];

    @observable
    public firmwareTypes: FirmwareTypeModel[] | null = [];

    @observable
    public selectedFirmware?: FirmwareRowModel;

    @observable
    public selectedFirmwareType: FirmwareTypeModel | null = null;

    @observable
    public searchterm = '';

    @observable
    public dexitFirmwareUploadProgress = 0;

    @observable
    public hyenFirmwareUploadProgress = 0;

    @computed
    public get firmwareListLoaded(): boolean {
        return this.firmwareList !== undefined;
    }

    private abortController: AbortController | null = null;

    constructor(initialState?: IFirmwareStoreState) {
        if (initialState) {
            this.updateFromJson(initialState);
        }

        this.repository = new FirmwareRepository(getPortalConfiguration());
    }

    public async sync(): Promise<void> {
        if (!this.firmwareList) {
            try {
                let currentSelectedFirmwareType = this.selectedFirmwareType;

                this.firmwareTypes = await this.repository.getFirmwareTypes();
                if (currentSelectedFirmwareType == null || currentSelectedFirmwareType === undefined) {
                    currentSelectedFirmwareType = this.firmwareTypes![0];
                }

                this.firmwareList = await this.repository.getFirmwareMetadataForType(this, currentSelectedFirmwareType!.id);

                return;
            } catch (error) {
                throw error;
            }
        }
    }

    public async loadFirmwareList(id: string) {
        try {
            this.firmwareList = undefined;
            this.firmwareList = await this.repository.getFirmwareMetadataForType(this, id);
            return true;
        } catch (error) {
            return false;
        }
    }

    public async sendResellerNotification(firmwareId: string): Promise<boolean> {
        try {
            await this.repository.sendResellerNotification(firmwareId);
            return true;
        } catch (error) {
            return false;
        }
    }

    public async createNewHyenFirmwareRelease(releaseData: HyenFirmwareReleaseData): Promise<boolean> {
        this.abortController = new AbortController();
        try {
            await this.repository.createNewHyenFirmwareRelease(
                releaseData,
                (progress) => {
                    this.hyenFirmwareUploadProgress = progress;
                },
                this.abortController.signal
            );
            return true;
        } catch (error: any) {
            const errData = error.rawResponseData || error;
            if (errData.message !== 'canceled') {
                this.handleApiError('Error publishing Secoris Firmware', errData);
            }
            return false;
        }
    }

    public async createNewDexitFirmwareRelease(releaseData: DexitFirmwareReleaseData): Promise<boolean> {
        this.abortController = new AbortController();
        try {
            await this.repository.createNewDexitFirmwareRelease(
                releaseData,
                (progress) => {
                    this.dexitFirmwareUploadProgress = progress;
                },
                this.abortController.signal
            );
            return true;
        } catch (error: any) {
            const errData = error.rawResponseData || error;
            if (errData.message !== 'canceled') {
                this.handleApiError('Error publishing Dexit Firmware', errData);
            }
            return false;
        }
    }

    public async createNewComfionFirmwareRelease(releaseData: ComfionFirmwareReleaseData): Promise<boolean> {
        this.abortController = new AbortController();
        try {
            await this.repository.createNewComfionFirmwareRelease(releaseData);
            return true;
        } catch (error: any) {
            const errData = error.rawResponseData || error;
            if (errData.message !== 'canceled') {
                this.handleApiError('Error publishing Comfion Firmware', errData);
            }
            return false;
        }
    }

    private handleApiError(source: string, err: any) {
        const viewStore = getViewStore();
        viewStore.notifyError(source, err);
    }

    @action
    public onPublished() {
        this.firmwareList = undefined; // important so that the list is reloaded within the sync method
        getViewStore().navigateToFirmwareOverview(true);
    }

    public toJson(): IFirmwareStoreState {
        const state: IFirmwareStoreState = {
            firmwareList: this.firmwareList ? this.firmwareList!.map((x) => x.toJson()) : undefined,
            selectedFirmware: this.selectedFirmware ? this.selectedFirmware.toJson() : undefined
        };
        return toJS(state);
    }

    public updateFromJson(storeState: IFirmwareStoreState): void {
        if (storeState.firmwareList) {
            this.firmwareList = storeState.firmwareList.map((x) => FirmwareRowModel.createFromJson(this, x));
        }
        if (storeState.selectedFirmware) {
            this.selectedFirmware = FirmwareRowModel.createFromJson(this, storeState.selectedFirmware);
        }
    }

    public cancelLongRequest = () => {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = null;
        }
    };
}
