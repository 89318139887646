import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/components/button/button';
import { Countdown } from '../common/components/countdown/countdown';
import { I18NEXT_SEARCH_NAMESPACE } from './constants';
import { DeviceAccessSearchModel } from './stores/deviceAccessSearchModel';

const AccessRequestedButton = (props: { deviceAccess: DeviceAccessSearchModel }) => {
    const [t] = useTranslation(I18NEXT_SEARCH_NAMESPACE);
    return useObserver(() => {
        return (
            <Button
                style='primary'
                className="m-1"
                disabled={props.deviceAccess?.state !== 'None'}>
                {t('AccessRequested')} <span className="text-nowrap">(<Countdown milliseconds={props.deviceAccess.requestTimeLeft} />)</span>
            </Button>
        );
    });
};

const AccessGranted = (props: { deviceAccess: DeviceAccessSearchModel }) => {
    const [t] = useTranslation(I18NEXT_SEARCH_NAMESPACE);
    const userCannotAccessDevice = !props.deviceAccess.userCanAccessDevice;
    return useObserver(() => {
        return (
            <React.Fragment>
                {userCannotAccessDevice && <p className="text-danger text-break m-1 typo-c1">{t('Access_granted_to_user')} {props.deviceAccess.requestingUserEmail}</p>}
                <Button
                    style='success'
                    className="m-1"
                    disabled={props.deviceAccess?.state !== 'None'}>
                    {t('AccessGranted')} <span className="text-nowrap">(<Countdown milliseconds={props.deviceAccess.accessTimeLeft} />)</span>
                </Button>
            </React.Fragment>
        );
    });
};

const RequestAccessButton = (props: { deviceAccess: DeviceAccessSearchModel }) => {
    const [t] = useTranslation(I18NEXT_SEARCH_NAMESPACE);
    return useObserver(() => {
        return (
            <Button
                style='outline-danger'
                className="m-1"
                disabled={props.deviceAccess?.state !== 'None'}
                onClick={() => props.deviceAccess?.requestAccess()}>
                {t('RequestAccess')}
            </Button>
        );
    });
};

export const AccessRequestContent = (props: { deviceAccess?: DeviceAccessSearchModel }) => {
    return useObserver(() => {
        if (props.deviceAccess == null || props.deviceAccess.userHasAccessRequestPermission === false) {
            return null;
        }
        switch (props.deviceAccess.state) {
        case 'Approved':
            return <AccessGranted deviceAccess={props.deviceAccess} />;
        case 'Requested':
            return <AccessRequestedButton deviceAccess={props.deviceAccess} />;
        default:
            return <RequestAccessButton deviceAccess={props.deviceAccess} />;
        }
    });
};

export const AccessRequestContentChildComponents = { RequestAccessButton, AccessGranted, AccessRequestedButton };