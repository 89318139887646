import { isServer } from '../isServer';
import { defaultLogger } from '../logger';
import { commonServerRegistry } from '../server/commonServerRegistry';
import { StoreEnv } from './common/stores/rootStore';

export type registryItemNames = 'applicationStoreEnv';

export interface ICommonRegistery {
    registerObject: (itemName: registryItemNames, item: any) => void;
    getRegisteredObject: (itemName: registryItemNames) => any;
}

const commonClientRegistryFn = (): ICommonRegistery => {
    const stores = {};

    const _registerObject = (itemName: registryItemNames, item: any) => {
        stores[itemName] = item;
    };

    const _getRegisteredObject = (itemName: registryItemNames) => {
        return stores[itemName];
    };

    defaultLogger.verbose('using commonClientRegistryFn');
    return { registerObject: _registerObject, getRegisteredObject: _getRegisteredObject };
};

const itemRegistry: ICommonRegistery = isServer() ? commonServerRegistry : commonClientRegistryFn();

export const registerObject = (itemName: registryItemNames, item: any) => {
    itemRegistry.registerObject(itemName, item);
};

export const getRegisteredObject = (itemName: registryItemNames) => {
    return itemRegistry.getRegisteredObject(itemName);
};

export const getStoreEnv = (): StoreEnv => {
    return itemRegistry.getRegisteredObject('applicationStoreEnv');
};
