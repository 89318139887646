/* eslint-disable no-useless-catch */
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { IPortalConfiguration } from '../interfaces';
import { IUserClaimsModel } from './userClaimsModel';
import { BaseRepository } from '..';
import { createApiProxy } from '../createApiProxy';
import { toIUserClaimsModel } from './mapper';

class UserRepository extends BaseRepository {
    private userClaimsClient: AbusCloudApi.CoreApi;
    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.userClaimsClient = new AbusCloudApi.CoreApi(this.apiConfiguration);
    }

    public async getUserClaims(): Promise<IUserClaimsModel | null> {
        try {
            const data = await createApiProxy(
                () => this.userClaimsClient.apiCoreUserClaimsGet(),
                (x) => toIUserClaimsModel(x)
            )();
            return data;
        } catch (e: any) {
            throw e;
        }
    }
}

export default UserRepository;
