import HttpStatusCode from './httpStatusCode';
import { handleResponseErrors } from './handleResponseErrors';
import { ResponseError } from './responseError';
import { trimAllPropertyValues } from './utils/trimAllPropertyValues';
import { AxiosResponse } from 'axios';

export interface IApiProxyOptions {
    ignoreErrorCodes?: [HttpStatusCode];
}

const defaultOptions: IApiProxyOptions = {
    ignoreErrorCodes: [HttpStatusCode.UNAUTHORIZED]
};

export const createApiProxy = <Tintern, Tserver>(
    apiCall: (preprocessedDto?: Tserver) => Promise<AxiosResponse<Tserver>>,
    mapFromDto?: (dto: Tserver) => Tintern, opt?: IApiProxyOptions
) => {
    const executeApiCall = async (dto?: Tserver): Promise<Tintern | null> => {
        const options = { ...defaultOptions, ...opt };
        try {
            if (dto) {
                dto = trimAllPropertyValues(dto);
            }
            const result = await apiCall(dto);
    
            if (result && result.status === HttpStatusCode.NO_CONTENT) {
                return null;
            }
            return mapFromDto ? mapFromDto(result.data) : result.data as unknown as Tintern;
        } catch (e : any) {
            const responseError: ResponseError<any> | null = await handleResponseErrors({ dataParser: mapFromDto })(e);
            if (responseError) {
                const foundIgnore = (options.ignoreErrorCodes || []).find((code) => code.toString() === responseError!.statusCode.toString());
                if (foundIgnore === undefined) {
                    throw responseError;
                }
            } else {
                throw e;
            }
        }
    
        return null;
    };
    return executeApiCall;
};