import React, { useState } from 'react';
import { ModalPopup } from '../../common/components/modalPopup/modalPopup';
import { FirmwareStore } from '../store/firmwareStore';
import HyenFirmwareReleaseData from './HyenFirmwareReleaseData';
import { getViewStore } from '../../storeRegistry';
import { Stores } from '../../common/stores/stores';
import { withTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import './HyenFirmwarePublishModal.scss';
import ProgressBar from '../ProgressBar';

interface HyenFirmwarePublishModalProps {
  isOpen: boolean;
  closeRequested: () => void;
  t: (key: string) => string;
  uploadProgress: number;
  firmwareStore?: FirmwareStore;
}

const HyenFirmwarePublishModal: React.FC<HyenFirmwarePublishModalProps> = ({ isOpen, closeRequested, t, uploadProgress, firmwareStore }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [releaseNotes, setReleaseNotes] = useState<File | null>(null);
    const [isBetaRelease, setIsBetaRelease] = useState<boolean>(false);
    const [minHardwareVersion, setMinHardwareVersion] = useState<string>('1.2');
    const [progressBarVisible, setProgressBarVisible] = useState<boolean>(false);

    const handleIsBetaReleaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsBetaRelease(e.target.checked);
    };

    const handleMinHardwareVersionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMinHardwareVersion(e.target.value);
    };

    const handleReleaseNoteChanges = (event:any) => {
        const file = event.target.files[0];
        setReleaseNotes(file);
    };

    const handleFirmwareFileChange = (event:any) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const setCloseRequested = () => {
        closeRequested();
        firmwareStore!.cancelLongRequest();
    };

    const viewStore = getViewStore();

    return (
        <>
            <ModalPopup
                size='xl'
                isOpen={isOpen}
                closeRequested={closeRequested}
                title={t('Hyen.PublishModal.Title')}>

                <p> {t('Hyen.PublishModal.Description')}</p>
               
                <div>
                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        if (selectedFile && releaseNotes && minHardwareVersion) {
                            setProgressBarVisible(true);
                            const result = await firmwareStore!.createNewHyenFirmwareRelease(new HyenFirmwareReleaseData(
                            firmwareStore!.selectedFirmwareType!.id,
                            selectedFile!,
                            releaseNotes,
                            minHardwareVersion,
                            isBetaRelease));
                            if (result) {
                                viewStore.notifySuccess(t('Common.PublishModal.Success'));
                                closeRequested();
                                setProgressBarVisible(false);
                            }
                            setProgressBarVisible(false);
                        } else {
                            setProgressBarVisible(false);
                            viewStore.notifyError(t('Common.PublishModal.FillOutAllFields'), '❌');
                        }
                    }}>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t('Hyen.PublishModal.Form.SelectFirmware')} </label>
                            <div className="col-sm-7">
                                <input type="file" className="form-control-file" id="firmwareFile" accept='.zip' onChange={handleFirmwareFileChange} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t('Hyen.PublishModal.Form.SelectReleaseNotes')}  </label>
                            <div className="col-sm-7">
                                <input type="file" className="form-control-file" id="releaseNotes" accept='.pdf' onChange={handleReleaseNoteChanges} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t('Hyen.PublishModal.Form.IsBetaRelease')} </label>
                            <div className="col-sm-7">
                                <input type="checkbox" id="isBetaRelease" onChange={handleIsBetaReleaseChange} />
                            </div>
                        </div>
            
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t('Hyen.PublishModal.Form.MinHardwareVersion')} </label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" id="minHardwareVersion" placeholder="1.2" defaultValue="1.2" onChange={handleMinHardwareVersionChange} />
                            </div>
                        </div>
                        { !progressBarVisible && (<button type="button" className="btn btn-secondary" onClick={setCloseRequested}>{t('Hyen.PublishModal.Form.Close')} </button>)}
                        {progressBarVisible && (<button type="button" className="btn btn-warning" onClick={firmwareStore!.cancelLongRequest}>{t('Hyen.PublishModal.Form.Cancel')} </button>)}
                        <button type="submit" className="btn btn-primary">{t('Hyen.PublishModal.Publish')} </button>
                        {progressBarVisible && (<ProgressBar width="100%" height="30px" percentage={uploadProgress} />)}

                    </form>
                </div>
            </ModalPopup>
        </>
    );
};

const withHoC = inject(Stores.FirmwareStore, Stores.ViewStore)(withTranslation('portal_firmware')(HyenFirmwarePublishModal));
export { withHoC as HyenFirmwarePublishModal };