import i18next, { i18n, InitOptions } from 'i18next';
import XHR, { HttpBackendOptions } from 'i18next-http-backend';
import { CalingaBackend, CalingaBackendOptions, Cache } from 'i18next-calinga-backend';
import { ExpressLanguageDetector, HeaderDetector } from '@abussc/i18next-utils';
import { isServer } from './isServer';
import { I18nextAppLogger } from './i18next-app-logger';
import { getEnvConfig } from '../src/server/getEnvConfig';

const config = getEnvConfig();

const calingaBackendOptions: CalingaBackendOptions = {
    cache: undefined,
    project: 'portal_default',
    organization: 'Abus',
    team: 'Default Team',
    includeDrafts: config.CALINGA_INCLUDE_DRAFTS.toLowerCase() === 'true'
};

const detectoptions = {
    // order and from where user language should be detected
    order: [HeaderDetector.detectorName],
    // keys or params to lookup language from
    lookupQuerystring: 'lng',

    // cache user language
    caches: false
};

export const applanguages = ['en', 'de'];

export const defaultapplanguage = 'en';

export const SharedOptions: InitOptions = {
    load: 'languageOnly',
    fallbackLng: defaultapplanguage,
    debug: true,
    ns: ['portal_default', 'portal_access', 'portal_deviceDetails', 'portal_footer', 'portal_legal', 'portal_reseller', 'portal_firmware', 'portal_components'],
    defaultNS: 'portal_default',
    nsSeparator: ':',
    fallbackNS: 'portal_default',
    keySeparator: '',
    interpolation: {
        escapeValue: false,
        formatSeparator: ','
    },
    supportedLngs: applanguages,
    nonExplicitSupportedLngs: false,
    appendNamespaceToMissingKey: true,
    react: {
        transSupportBasicHtmlNodes: true,
        useSuspense: false
    }
};

let instance: i18n | null = null;

export const setCalingaCacheInstance = (cacher: Cache) => {
    calingaBackendOptions.cache = cacher;
};

const geti18n = async (): Promise<i18n> => {
    let options: InitOptions;
    if (instance !== null) {
        return instance;
    }

    i18next.use(I18nextAppLogger);

    if (!isServer()) {
        i18next.use(XHR);
        options = {
            ...SharedOptions,
            ...{
                backend: { loadPath: '/locales/{{lng}}/{{ns}}' }
            }
        };
    } else {
        SharedOptions.lng = defaultapplanguage;
        i18next.use(CalingaBackend)
            .use(ExpressLanguageDetector);
        options = {
            ...SharedOptions,
            ...{
                preload: applanguages,
                backend: calingaBackendOptions as HttpBackendOptions,
                detection: detectoptions
            }
        };
    }

    if (!i18next.isInitialized) {
        await i18next.init(options as InitOptions);
    }

    instance = i18next;
    return instance;
};

export default geti18n;