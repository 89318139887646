import { action, observable } from 'mobx';
import { BaseModelStore } from '../../common/stores/baseModelStore';
import { OperatorAssignmentModel } from './OperatorAssignmentModel';
import { OperatorRepository } from '../../access/userprofile/store/repositories/operatorRepository';
import { getPortalConfiguration } from '../../getPortalConfiguration';
import { getViewStore } from '../../storeRegistry';
import { ConfirmResult } from '../../common/components/confirmation/confirm';

export interface IOperatorStoreState {
    assignedOperators: OperatorAssignmentModel[];
}

export class OperatorStore implements BaseModelStore<IOperatorStoreState> {
    @observable
    public assignedOperators: OperatorAssignmentModel[] = [];

    @observable
    public isLoading: boolean;

    public operatorProfileRepository: OperatorRepository;

    constructor() {
        this.operatorProfileRepository = new OperatorRepository(getPortalConfiguration());
        this.isLoading = false;
    }

    @action.bound
    async getAssignedOperators(deviceId: string) {
        this.isLoading = true;
        this.assignedOperators = await this.operatorProfileRepository.getAssignedOperators(deviceId);
        this.isLoading = false;
    }

    @action.bound
    async deleteAssignment(deviceId: string, operatorId: string) {
        const viewStore = getViewStore();
        const result = await viewStore.dialog.showConfirm({ title: 'DeleteOperatorAssignment.Confirm.Title', description: 'DeleteOperatorAssignment.Confirm.Description', reject: 'DeleteOperatorAssignment.Confirm.Rejection', continue: 'DeleteOperatorAssignment.Confirm.Confirmation' });
        if (result === ConfirmResult.Ok) {
            this.isLoading = true;
            await this.operatorProfileRepository.deleteAssignment(deviceId, operatorId);
            this.assignedOperators = await this.operatorProfileRepository.getAssignedOperators(deviceId);
            this.isLoading = false;
        }
    }
    
    updateFromJSON(): this {
        throw new Error('Method not implemented.');
    }

    toJSON(): IOperatorStoreState {
        throw new Error('Method not implemented.');
    }
}