import React from 'react';
import { Alert as RSAlert, AlertProps as RSAlertProps } from 'reactstrap';

import './alert.scss';

export interface IAlertProps extends Pick<RSAlertProps, 'color' | 'isOpen'> {
    children: React.ReactNode;
    slideIn?: boolean;
    className?: string;
}

export const Alert = (props: IAlertProps) => {
    const classNamesOuter = [
        props.className,
        'abus-alert',
        props.slideIn ? 'abus-alert-slide-in' : ''
    ].filter((cn) => cn !== undefined);

    const classNamesInner = [
        props.slideIn ? 'abus-alert-slide-in' : ''
    ].filter((cn) => cn !== undefined);

    return (<div className={classNamesOuter.join(' ')}><RSAlert className={classNamesInner.join(' ')} {...props} /></div>);
};