import React from 'react';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DeviceComponentTile } from '../deviceComponentTile';
import { DeviceComponentTileHeader } from '../deviceComponentTileHeader';
import { RepeaterIcon } from './repeaterTileIcon';
import { IRepeaterDeviceComponent } from '../../stores/deviceStore/deviceContracts';
import { TRANSLATIONS_NAMESPACE } from '../index';
import moment from 'moment';

export const RepeaterTile = (props: IRepeaterDeviceComponent) => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    const bodyElements: string[] = [
        t('Components.Type.Repeater'),
        [t('Components.Firmwareversion'), props.firmwareVersion].join(' '),
        [t('Components.SignalStrength'), props.signalStrength].join(' '),
        [t('Components.DateOfProduction'), moment(props.dateOfProduction).format('YYYY/MM')].join(' '),
        t(`Components.HasBattery.${props.hasBattery ? 'Yes' : 'No'}`),
        [props.connectedComponents, t('Components.Connected')].join(' ')
    ];

    return useObserver(() => {
        return (
            <DeviceComponentTile
                componentTileHeader={<DeviceComponentTileHeader name={props.name} signalState={props.signalState} />}
                componentTileIcon={<RepeaterIcon />}
                componentTileBodyElements={bodyElements}
            />
        );
    });
};