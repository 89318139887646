class HyenFirmwareReleaseData {
    FirmwareTypeId: string;
    FirmwareFile: File;
    ReleaseNotes: File;
    MinHardwareVersion: string;
    IsBeta: boolean;

    constructor(
        firmwareTypeId: string,
        firmwareFile: File,
        releaseNotes: File,
        minHardwareVersion: string,
        isBeta: boolean) {
        this.FirmwareTypeId = firmwareTypeId;
        this.FirmwareFile = firmwareFile;
        this.ReleaseNotes = releaseNotes;
        this.MinHardwareVersion = minHardwareVersion;
        this.IsBeta = isBeta;
    }
}

export default HyenFirmwareReleaseData;