import { LoggerModule } from 'i18next';
import { defaultLogger, Logger } from './logger';

export class I18nextAppLogger implements LoggerModule {
    public static type = 'logger' as const;

    public type: 'logger' = I18nextAppLogger.type;

    public log(...args: any[]): void {
        switch (Logger.loglevel) {
        case 'error':
        case 'warn':
        case 'info':
        case 'http':
            break;
        case 'verbose':
            defaultLogger.verbose(this.buildmsg(args));
            break;
        case 'debug':
        case 'silly':
            defaultLogger.debug(this.buildmsg(args), ...args);
            break;
        default:
            break;
        }
    }

    public warn(...args: any[]): void {
        defaultLogger.warn(this.buildmsg(args), ...args);
    }

    public error(...args: any[]): void {
        defaultLogger.error(this.buildmsg(args), ...args);
    }

    private buildmsg(...args: any[]) {
        try {
            const inextmsg = args[0][0];
            return `I18nextWinstonLogger::${inextmsg}`;
        } catch (e : any) {
            defaultLogger.warn('I18nextWinstonLogger, extract verbose message error', e);
        }
        return 'I18nextWinstonLogger';
    }
}