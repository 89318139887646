import React from 'react';
import { DeviceAccessPrefixedTrans } from './deviceAccessPrefixedTrans';
import Button from '../../../common/components/button/button';
import { useStore } from '../../../common/stores/useStore';
import DateTimeInput from '../../../common/components/dateTimeInput';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '../..';

export const ExtendDeviceAccess = () => {
    const { deviceStore } = useStore();
    const deviceAccess = deviceStore.deviceDetails.data?.deviceAccess;
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        return (
            <div>
                <p className="typo-c1-bold mb-1">
                    <DeviceAccessPrefixedTrans label="ExtendAccessHeader" />
                </p>
                <Button disabled={deviceAccess?.isExtendedToMaxDate} className="ml-auto" onClick={() => deviceAccess!.extendByOneDay()} style="danger">
                    <DeviceAccessPrefixedTrans label="ExtendAccessByOneDayButton" />
                </Button>
                <p className="mt-4 mb-4 typo-c1">
                    <DeviceAccessPrefixedTrans label="ExtendAccessOr" />
                </p>
                <DateTimeInput
                    name="deviceAccess"
                    value={deviceAccess!.accessValidUntil}
                    min={new Date()}
                    max={new Date(deviceAccess!.accessValidUntilMaximumTime!)}
                    malformattedErrorMessage={t('Errors.InvalidFormat').toString()}
                    help={t('ExtendAccessDatePickerHelp').toString()}
                    onChange={(name, date) => {
                        deviceAccess!.extend(date!);
                    }}
                    showClearButton={false}
                />
            </div>
        );
    });
};
